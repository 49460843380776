import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/warning-logo.svg'


const _hoisted_1 = { class: "req-text-container" }
const _hoisted_2 = { class: "req-text" }

import Popper from "vue3-popper";


type RequiredWarningProps = {
  warningText: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RequiredWarning',
  props: {
    warningText: { default: "" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popper), { hover: true }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.warningText), 1)
      ])
    ]),
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "warning-img"
      }, null, -1))
    ]),
    _: 1
  }))
}
}

})