<template>
  <PhoneInput
    v-if="type === 'phone'"
    @phone="
          (phoneNumber: string) => {
            emit('update:fieldValue', phoneNumber)
          }
        "
    :placeholder="placeHolder"
    class="phone-input"
    :defaultCountry="currentCountry"
    :value="inputValue"
    :required="required"
  />
  <div
    v-else
    class="input-field-wrapper position-relative"
    :class="!rounded && 'w-100'"
    @click="setFocusOnInputField()"
  >
    <label v-if="label" class="mb-1 text-small">{{ label }}</label>
    <div
      class="position-relative"
      :class="rounded && 'd-flex justify-content-center w-100'"
    >
      <input
        :disabled="disabled"
        :readonly="notEditable"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :max="maxValue"
        :min="minValue"
        class="gr-input"
        ref="inputField"
        :class="[
          `input-${width}`,
          errorWithInputValue && 'error',
          flashGreen && 'flash-green-animation',
          bold && 'input-bold',
          rounded && 'rounded'
        ]"
        @blur="
          () => {
            if (!isBlurable) return;

            if (type === 'number' && minValue) {
              !inputValue || inputValue < minValue
                ? (inputValue = minValue)
                : '';
            }
            emit('update:fieldValue', inputValue);
            emit('blur');
          }
        "
        :type="showPassword ? 'text' : type"
        :name="name"
        :required="required"
        v-model="inputValue"
        :id="id"
        :placeholder="placeHolder"
        @input="
          () => {
            if (type === 'number') {
              if (maxValue && inputValue > maxValue) {
                inputValue = maxValue;
              }

              if (minValue && inputValue === 0) {
                inputValue = minValue;
              }
            }
            emit('update:fieldValue', inputValue);
          }
        "
      />
      <div
        class="input-helpers text-tiny"
        v-if="errorWithInputValue || showUnit || passwordHasToggle"
      >
        <img
          v-if="passwordHasToggle"
          src="@/assets/img/eye_icon.svg"
          @click="showPassword = !showPassword"
          class="eye-icon cursor-pointer"
        />
        <p v-if="errorWithInputValue" class="error-text ms-2">
          {{ errorText }}
        </p>
        <p v-if="showUnit" class="ms-2">{{ unitText }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhoneInput } from "@lbgm/phone-number-input";
import {
  defineEmits,
  onMounted,
  Ref,
  ref,
  watch,
  withDefaults,
  defineProps,
  InputHTMLAttributes,
  computed
} from "vue";
type InputFieldProps = {
  label?: string;
  id?: InputHTMLAttributes["id"];
  name?: InputHTMLAttributes["name"];
  type?: InputHTMLAttributes["type"];
  width?: string | number;
  fieldValue: InputHTMLAttributes["value"] | null;
  errorWithInputValue?: boolean;
  showUnit?: boolean;
  errorText?: string;
  unitText?: string | number;
  placeHolder?: InputHTMLAttributes["placeholder"];
  maxlength?: InputHTMLAttributes["maxlength"];
  autocomplete?: "off" | "on";
  setFocus?: boolean;
  passwordHasToggle?: boolean;
  maxValue?: InputHTMLAttributes["max"];
  minValue?: InputHTMLAttributes["min"];
  value?: InputHTMLAttributes["value"];
  disabled?: boolean;
  notEditable?: boolean;
  bold?: boolean;
  flashGreen?: boolean;
  required?: boolean;
  isBlurable?: boolean;
  rounded?: boolean;
};

const inputFieldProps = withDefaults(defineProps<InputFieldProps>(), {
  label: "",
  id: "Input field",
  name: "Input field",
  type: "text",
  width: "",
  maxlength: "60",
  fieldValue: null,
  errorWithInputValue: false,
  showUnit: false,
  errorText: "Error",
  unitText: "mm",
  placeHolder: "",
  autocomplete: "off",
  setFocus: false,
  passwordHasToggle: false,
  maxValue: "",
  minValue: "",
  value: "",
  disabled: false,
  notEditable: false,
  bold: false,
  flashGreen: false,
  required: false,
  isBlurable: true,
  rounded: false
});

const emit = defineEmits([
  "update:fieldValue",
  "hasError",
  "update:hasError",
  "blur",
  "input"
]);

const currentCountry = computed(() => {
  return navigator.language.slice(-2);
});

const inputValue: Ref<InputHTMLAttributes["value"]> = ref(
  inputFieldProps.fieldValue ?? inputFieldProps.value
);
const showPassword = ref(false);
const inputField: Ref<HTMLInputElement | null> = ref(null);

onMounted(() => {
  if (inputFieldProps.setFocus) {
    setFocusOnInputField();
  }
});

const setFocusOnInputField = () => {
  if (!inputField.value) return;
  inputField.value.focus();
};

watch(
  () => inputFieldProps.errorWithInputValue,
  inputValueError => {
    emit("update:hasError", inputValueError);
  }
);

watch(
  () => inputFieldProps.fieldValue,
  newValue => (inputValue.value = newValue)
);
</script>

<style scoped>
@import "@/assets/scss/inputs.scss";

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 44px;
}

.eye-icon {
  width: 24px;
  height: 100%;
}

.flash-green-animation,
.flash-green-animation + .input-helpers {
  animation: flash-green 0.5s linear;
}
@keyframes flash-green {
  0%,
  100% {
    background-color: white;
  }
  50% {
    background-color: #82ab72;
  }
}

.input-field-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}
.gr-input {
  border-radius: 0 !important;
  padding: 13px;
  border: 1.5px solid #000 !important;
  background-color: white !important;
  opacity: 1;
  box-shadow: none !important;
  width: 100%;
}

.gr-input:focus {
  outline: none;
  outline-color: transparent;
  outline-style: none;
  outline-width: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #000;
  background-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="number"] {
  font-size: 12px;
}
.input-large {
  min-width: 14rem;
}

.input-medium {
  min-width: 10rem;
}

.error {
  border-color: #dc3545 !important;
}

.input-helpers {
  display: flex;
  align-items: center;
  position: absolute;
  top: 11%;
  right: 0;
  min-width: 44px;
  z-index: 2;
  background-color: white;
  height: 80%;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.gr-input:disabled {
  color: rgba(0, 0, 0, 0.2);
}

.not-editable:disabled {
  color: rgba(0, 0, 0, 1);
}

.input-bold {
  font-weight: bold;
}

.rounded {
  border-radius: 25px !important;
  font-size: 12spx;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 24px;
  width: 60px;
  text-align: center;
}

@media screen and (max-width: 450px) {
  .gr-input {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
