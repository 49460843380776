<template>
  <LoadingOverlay v-if="isLoading" />
  <template v-else>
    <MainFilter
      :filterTitle="`${t('title.overviewSellGuest')} - ${productQuantity} ${t(
        'title.products'
      )}`"
      :filterText="t('paragraph.overviewSellGuest')"
      :hasProducts="quantities > 0"
      :productStatus="presetFilter.status"
      :translateHeaders="translateHeaders"
      v-model:defaultFilter="filter"
      v-model:defaultSortingSettings="sortingSettings"
    />

    <ProductTable
      v-if="internalProductHeaders"
      :noRowsText="t('label.noRowsPositive')"
      v-model:filter="filter"
      :sortingSettings="sortingSettings"
      :translateHeaders="translateHeaders"
      :presetFilter="presetFilter"
      :table-type="'purchase'"
      @fetch-products="getInternalProducts()"
      :externalProducts="internalProductHeaders"
    />
  </template>
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, onMounted, Ref, ref } from "vue";
import { SortingSettings } from "../library/types/sorting/sorting";
import { FilterOptions, PresetFilter } from "@/library/types/filter/filter";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";
import ProductTable from "@/components/table/ProductTable.vue";
import { useI18n } from "vue-i18n";
import { getGuestAdvertisements } from "@/library/api/products";
import { useRouter } from "vue-router";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import { AdvertisementProductHeader } from "@/library/types/product/productHeader";
import { useIndexStore } from "@/store";

const { t, messages, locale } = useI18n();
const isLoading = ref(false);
const indexStore = useIndexStore();
const { currentRoute } = useRouter();
const internalProductHeaders: Ref<AdvertisementProductHeader | null> =
  ref(null);
const productQuantity = ref(0);
const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const presetFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "sell_tillganglig",
    "green_hash"
  ],
  status: ["I"]
};

const getInternalProducts = async () => {
  isLoading.value = true;
  const guestToken = currentRoute.value.params.token as string;
  internalProductHeaders.value = null;
  const result = await getGuestAdvertisements(guestToken);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  internalProductHeaders.value = result.result;

  for (const key in internalProductHeaders.value) {
    const productHeader = internalProductHeaders.value[key];
    productQuantity.value += productHeader.items.length;
  }

  await indexStore.updateGuestQuantities(guestToken);

  await indexStore.getGuestLocaleTranslations(
    indexStore.currentProject,
    guestToken,
    messages.value[locale.value]
  );

  indexStore.calculateMaxQuantity(internalProductHeaders.value);

  isLoading.value = false;
};

const quantities = computed(() => {
  return getQuantitiesByProductStatus("I");
});

onMounted(() => {
  getInternalProducts();
});
</script>
