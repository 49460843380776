import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, onMounted, Ref, ref } from "vue";
import { SortingSettings } from "../library/types/sorting/sorting";
import { FilterOptions, PresetFilter } from "@/library/types/filter/filter";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";
import ProductTable from "@/components/table/ProductTable.vue";
import { useI18n } from "vue-i18n";
import { getGuestAdvertisements } from "@/library/api/products";
import { useRouter } from "vue-router";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import { AdvertisementProductHeader } from "@/library/types/product/productHeader";
import { useIndexStore } from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'SalesOverviewGuestView',
  setup(__props) {

const { t, messages, locale } = useI18n();
const isLoading = ref(false);
const indexStore = useIndexStore();
const { currentRoute } = useRouter();
const internalProductHeaders: Ref<AdvertisementProductHeader | null> =
  ref(null);
const productQuantity = ref(0);
const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const presetFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "sell_tillganglig",
    "green_hash"
  ],
  status: ["I"]
};

const getInternalProducts = async () => {
  isLoading.value = true;
  const guestToken = currentRoute.value.params.token as string;
  internalProductHeaders.value = null;
  const result = await getGuestAdvertisements(guestToken);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  internalProductHeaders.value = result.result;

  for (const key in internalProductHeaders.value) {
    const productHeader = internalProductHeaders.value[key];
    productQuantity.value += productHeader.items.length;
  }

  await indexStore.updateGuestQuantities(guestToken);

  await indexStore.getGuestLocaleTranslations(
    indexStore.currentProject,
    guestToken,
    messages.value[locale.value]
  );

  indexStore.calculateMaxQuantity(internalProductHeaders.value);

  isLoading.value = false;
};

const quantities = computed(() => {
  return getQuantitiesByProductStatus("I");
});

onMounted(() => {
  getInternalProducts();
});

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(MainFilter, {
          filterTitle: `${_unref(t)('title.overviewSellGuest')} - ${productQuantity.value} ${_unref(t)(
        'title.products'
      )}`,
          filterText: _unref(t)('paragraph.overviewSellGuest'),
          hasProducts: quantities.value > 0,
          productStatus: presetFilter.status,
          translateHeaders: translateHeaders,
          defaultFilter: filter.value,
          "onUpdate:defaultFilter": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event)),
          defaultSortingSettings: sortingSettings.value,
          "onUpdate:defaultSortingSettings": _cache[1] || (_cache[1] = ($event: any) => ((sortingSettings).value = $event))
        }, null, 8, ["filterTitle", "filterText", "hasProducts", "productStatus", "defaultFilter", "defaultSortingSettings"]),
        (internalProductHeaders.value)
          ? (_openBlock(), _createBlock(ProductTable, {
              key: 0,
              noRowsText: _unref(t)('label.noRowsPositive'),
              filter: filter.value,
              "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((filter).value = $event)),
              sortingSettings: sortingSettings.value,
              translateHeaders: translateHeaders,
              presetFilter: presetFilter,
              "table-type": 'purchase',
              onFetchProducts: _cache[3] || (_cache[3] = ($event: any) => (getInternalProducts())),
              externalProducts: internalProductHeaders.value
            }, null, 8, ["noRowsText", "filter", "sortingSettings", "externalProducts"]))
          : _createCommentVNode("", true)
      ], 64))
}
}

})