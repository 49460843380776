import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12 position-relative mt-2" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12 mt-2" }
const _hoisted_7 = { class: "row mt-3" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = {
  key: 0,
  class: "row mt-3"
}
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12 mt-2" }
const _hoisted_12 = {
  key: 1,
  class: "row mt-3 mb-2"
}
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "col-12 mt-2" }
const _hoisted_15 = { class: "row mt-3 mb-2" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "col-12 mt-2" }
const _hoisted_18 = { class: "col-12 d-flex" }

import SelectRoom from "../SelectRoom.vue";
import {
  ref,
  Ref,
  onMounted,
  computed
} from "vue";
import { bolderFont } from "../../library/helpers/typography";
import { ProductInformation } from "../../library/types/product/productGroup";
import { AddressInformation } from "../../library/types/room/rooms";
import { keysExistInObject } from "../../library/helpers/handleObjects";
import { getAddressesFromIds } from "@/library/api/address";
import { getProductInformationApi } from "@/library/api/products";

import { FormFields } from "@/library/types/structure/formStructure";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import { ProductRoomInformation } from "@/library/types/product/productRoom";
import { postNewProductLocation } from "@/library/api/moveProducts";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import FieldLabel from "./fields/FieldLabel.vue";
import InputField from "../common/InputField.vue";
import { useI18n } from "vue-i18n";
import {
  getInternalAdvertisement,
  postInternalAdvertisement,
  updateInternalAdvertisement
} from "@/library/api/sales";
import {
  AdvertisementProductHeader,
  ProductHeaderSale
} from "@/library/types/product/productHeader";
import ProductsSelect from "../common/ProductsSelect.vue";
import { ProductItemSale } from "@/library/types/product/productItem";
import RequiredWarningText from "../common/RequiredWarningText.vue";
import { CustomError, Result } from "@/library/helpers/handleApiRequests";
import { AxiosResponse } from "axios";

type InternalSaleFormProps = {
  selectedProduct:
    | ProductHeaderSale["key"]
    | AdvertisementProductHeader["key"]
    | null;
  selectedProductIds: ProductItemSale["id"][];
  type: "internalSale" | "editInternalSale";
};

export default /*@__PURE__*/_defineComponent({
  __name: 'InternalSaleForm',
  props: {
    selectedProduct: { default: null },
    selectedProductIds: { default: () => [] },
    type: { default: "internalSale" }
  },
  emits: ["refresh", "close"],
  setup(__props: any, { emit: __emit }) {

const internalSaleFormProps = __props;

const { t } = useI18n();
const formIsSubmitted = ref(false);
const productSaleInformation: Ref<FormFields> = ref({});
const currentProductIds: Ref<string[]> = ref([]);
const isLoading = ref(true);
const greenifiedSaleStructure: Ref<FormFields> = ref({});
const productInformation: Ref<ProductInformation | null> = ref(null);
const uniqueRooms: Ref<string[]> = ref([]);
const addresses: Ref<AddressInformation[]> = ref([]);
const currentLocation: Ref<ProductRoomInformation | null> = ref(null);
const publishSuccess = ref(false);
const complementForm: Ref<HTMLFormElement | null> = ref(null);

const countMaxGroupQuantity = computed(() => {
  const productQuantity = internalSaleFormProps.selectedProductIds.length;
  if (!productQuantity) return 0;

  return (
    productQuantity -
    (productQuantity % Number(productSaleInformation.value["ange-antal"]))
  );
});

const productsForSaleDoesNotMatchGroupAmount = computed(() => {
  const soldInGroupOfAmount = Number(
    productSaleInformation.value["ange-antal"]
  );

  if (!internalSaleFormProps.selectedProduct) return true;

  return (
    productSaleInformation.value.saljs_styckvis_eller_i_grupp === "group" &&
    soldInGroupOfAmount &&
    soldInGroupOfAmount % internalSaleFormProps.selectedProduct.group.antal !==
      0 &&
    soldInGroupOfAmount !== 1
  );
});

const getResponseText = () => {
  const bolderString = bolderFont(t("button.otherSales"));
  if (internalSaleFormProps.type === "internalSale")
    return t("paragraph.publishedForSale", [bolderString]);
  return t("paragraph.advertisementHasBeenUpdated");
};

const moveProducts = async () => {
  if (!productInformation.value) return;

  const result = await postNewProductLocation(
    currentProductIds.value,
    productInformation.value.location
  );

  isLoading.value = false;

  if (!result.success) return;

  publishSuccess.value = true;
};

const postAdvertisement = async () => {
  formIsSubmitted.value = true;

  if (complementForm.value && !complementForm.value.reportValidity()) {
    return;
  }

  if (
    !productSaleInformation.value.saljs_styckvis_eller_i_grupp ||
    !internalSaleFormProps.selectedProduct
  )
    return;

  if (
    productsForSaleDoesNotMatchGroupAmount.value &&
    currentProductIds.value.length %
      Number(productSaleInformation.value["ange-antal"]) !==
      0
  ) {
    return;
  }

  if (
    productSaleInformation.value.saljs_styckvis_eller_i_grupp === "single" &&
    "ange-antal" in productSaleInformation.value
  ) {
    delete productSaleInformation.value["ange-antal"];
  }
  if (!currentProductIds.value.length) {
    currentProductIds.value = internalSaleFormProps.selectedProductIds;
  }

  isLoading.value = true;

  let result: Result<AxiosResponse, CustomError> | null = null;
  if (
    internalSaleFormProps.type === "editInternalSale" &&
    "advertisement_id" in internalSaleFormProps.selectedProduct.group
  ) {
    result = await updateInternalAdvertisement(
      currentProductIds.value,
      productSaleInformation.value,
      internalSaleFormProps.selectedProduct.group.advertisement_id
    );
  } else {
    result = await postInternalAdvertisement(
      currentProductIds.value,
      productSaleInformation.value
    );
  }

  if (!result || !result.success) {
    isLoading.value = false;
    return;
  }

  if (
    productInformation.value &&
    currentLocation.value !== productInformation.value.room
  ) {
    moveProducts();
  } else {
    isLoading.value = false;

    publishSuccess.value = true;
  }
};

const getProductInformation = async () => {
  const result = await getProductInformationApi(
    internalSaleFormProps.selectedProductIds[0]
  );

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  productInformation.value = result.result;

  greenifiedSaleStructure.value = result.result.green_struc;

  if (uniqueRooms.value.length > 1) {
    productInformation.value.room = {
      roomName: "",
      building: "",
      floor: "",
      roomCode: ""
    };
  }

  if (internalSaleFormProps.selectedProductIds.length === 1) {
    productSaleInformation.value["ange-antal"] = "1";
    productSaleInformation.value.saljs_styckvis_eller_i_grupp = "single";
  }

  currentLocation.value = productInformation.value.room;

  getProductAddresses();
};

const getProductAddresses = async () => {
  const result = await getAddressesFromIds(
    internalSaleFormProps.selectedProductIds
  );

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  addresses.value = result.result;
  if (
    addresses.value.length > 0 &&
    !keysExistInObject(
      ["adress", "postnummer", "ort", "land"],
      greenifiedSaleStructure.value
    )
  ) {
    greenifiedSaleStructure.value = Object.assign(
      greenifiedSaleStructure.value,
      addresses.value[0].address
    );
  }

  if (
    internalSaleFormProps.selectedProduct &&
    "advertisement_id" in internalSaleFormProps.selectedProduct.group &&
    internalSaleFormProps.type === "editInternalSale"
  ) {
    const advertisementResult = await getInternalAdvertisement(
      internalSaleFormProps.selectedProduct.group.advertisement_id
    );

    if (!advertisementResult.success) {
      isLoading.value = false;
      return;
    }

    productSaleInformation.value = advertisementResult.result.sale_info;
  }

  isLoading.value = false;
};

onMounted(() => {
  if (!internalSaleFormProps.selectedProduct) {
    emit("close");
    return;
  }

  uniqueRooms.value = internalSaleFormProps.selectedProduct.items.map(
    item => item.rum
  );

  productSaleInformation.value["ange-antal"] =
    internalSaleFormProps.selectedProduct.group.sell_antal ?? null;
  productSaleInformation.value["saljs_styckvis_eller_i_grupp"] =
    internalSaleFormProps.selectedProduct.group.sell_grupp ?? null;
  productSaleInformation.value["pris"] =
    internalSaleFormProps.selectedProduct.group.sell_pris ?? null;
  productSaleInformation.value["nar_ar_produkten_tillganglig"] =
    internalSaleFormProps.selectedProduct.items[0].sell_tillganglig ?? null;

  getProductInformation();
});

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      title: _unref(t)('title.PostProductOnInternally'),
      onClose: _cache[8] || (_cache[8] = ($event: any) => (emit('close'))),
      ref: "reconModal",
      type: 'smaller',
      isLoading: isLoading.value
    }, {
      container: _withCtx(() => [
        (productInformation.value)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              id: "form-id",
              ref_key: "complementForm",
              ref: complementForm
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(FieldLabel, {
                    fieldLabel: _unref(t)('label.location'),
                    showTooltip: true,
                    tooltipInstructions: _unref(t)('msg.infoOfChangingRoomForInternalSell'),
                    placing: 'top'
                  }, null, 8, ["fieldLabel", "tooltipInstructions"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(SelectRoom, {
                    isLoadingRoom: isLoading.value,
                    rightMenu: "",
                    isReduced: "",
                    modelValue: productInformation.value.location,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((productInformation.value.location) = $event)),
                    append: true,
                    placeholder: 
                uniqueRooms.value.length > 1 ? _unref(t)('placeholders.differentRooms') : ''
              
                  }, null, 8, ["isLoadingRoom", "modelValue", "placeholder"])
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(FieldLabel, {
                    fieldLabel: _unref(t)('label.whenIsProductAvailable'),
                    placing: 'top',
                    requiredField: 1,
                    presetFieldValue: 
                productSaleInformation.value['nar_ar_produkten_tillganglig']
              ,
                    "form-is-submitted": formIsSubmitted.value
                  }, null, 8, ["fieldLabel", "presetFieldValue", "form-is-submitted"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(InputField, {
                    type: "date",
                    maxValue: "9999-12-31",
                    fieldValue: 
                productSaleInformation.value.nar_ar_produkten_tillganglig
              ,
                    "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((
                productSaleInformation.value.nar_ar_produkten_tillganglig
              ) = $event)),
                    required: true
                  }, null, 8, ["fieldValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(FieldLabel, {
                    fieldLabel: _unref(t)('label.sellInGroupOrEach'),
                    placing: 'top',
                    requiredField: 1,
                    presetFieldValue: 
                productSaleInformation.value['saljs_styckvis_eller_i_grupp']
              ,
                    "form-is-submitted": formIsSubmitted.value
                  }, null, 8, ["fieldLabel", "presetFieldValue", "form-is-submitted"])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList({
              single: _unref(t)('label.single'),
              group: _unref(t)('label.group')
            }, (option, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "col-5 mt-2",
                    key: option
                  }, [
                    _createVNode(GreenifiedButton, {
                      inputType: "radio",
                      disabled: _ctx.selectedProductIds.length === 1 && key === 'group',
                      textAlign: 
                _ctx.selectedProductIds.length === 1 && key === 'group'
                  ? 'left'
                  : 'center'
              ,
                      "input-required": true,
                      inputName: "saljs_styckvis_eller_i_grupp",
                      type: 'button',
                      onClick: 
                () => {
                  if (key === 'single') {
                    delete productSaleInformation.value['ange-antal'];
                  }
                  productSaleInformation.value.saljs_styckvis_eller_i_grupp = key;
                }
              ,
                      text: option,
                      inputValue: option,
                      inputId: option + '-' + key,
                      btnStyle: 
                productSaleInformation.value.saljs_styckvis_eller_i_grupp === key
                  ? 'black'
                  : 'normal'
              
                    }, null, 8, ["disabled", "textAlign", "onClick", "text", "inputValue", "inputId", "btnStyle"])
                  ]))
                }), 128))
              ]),
              (productSaleInformation.value.saljs_styckvis_eller_i_grupp === 'group')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(FieldLabel, {
                        fieldLabel: _unref(t)('label.soldInGroupOf'),
                        placing: 'top',
                        requiredField: 1,
                        presetFieldValue: productSaleInformation.value['ange-antal'],
                        "form-is-submitted": formIsSubmitted.value
                      }, null, 8, ["fieldLabel", "presetFieldValue", "form-is-submitted"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(InputField, {
                        type: "number",
                        fieldValue: productSaleInformation.value['ange-antal'],
                        "onUpdate:fieldValue": [
                          _cache[2] || (_cache[2] = ($event: any) => ((productSaleInformation.value['ange-antal']) = $event)),
                          _cache[3] || (_cache[3] = ($event: any) => (currentProductIds.value = []))
                        ],
                        minValue: "2",
                        maxValue: _ctx.selectedProductIds.length,
                        required: ""
                      }, null, 8, ["fieldValue", "maxValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (
            typeof productSaleInformation.value['ange-antal'] === 'number' &&
            productsForSaleDoesNotMatchGroupAmount.value
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(FieldLabel, {
                        fieldLabel: _unref(t)('label.selectQuantity'),
                        placing: 'top',
                        requiredField: 1
                      }, null, 8, ["fieldLabel"]),
                      (
                currentProductIds.value.length < 2 ||
                currentProductIds.value.length %
                  productSaleInformation.value['ange-antal'] !==
                  0
              )
                        ? (_openBlock(), _createBlock(RequiredWarningText, {
                            key: 0,
                            text: _unref(t)('msg.fieldRequired', [_unref(t)('label.selectQuantity')]),
                            textClass: "form-label"
                          }, null, 8, ["text"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      (_ctx.selectedProduct)
                        ? (_openBlock(), _createBlock(ProductsSelect, {
                            key: 0,
                            maxQuantity: countMaxGroupQuantity.value,
                            "product-items": _ctx.selectedProduct.items,
                            selectableIds: _ctx.selectedProductIds,
                            reservedProductIds: [],
                            soldProductIds: [],
                            requiredQuantity: false,
                            selectedProductIds: currentProductIds.value,
                            "onUpdate:selectedProductIds": _cache[4] || (_cache[4] = ($event: any) => ((currentProductIds).value = $event))
                          }, null, 8, ["maxQuantity", "product-items", "selectableIds", "selectedProductIds"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(FieldLabel, {
                    fieldLabel: _unref(t)('label.price'),
                    placing: 'top',
                    requiredField: 1,
                    presetFieldValue: productSaleInformation.value['pris'],
                    "form-is-submitted": formIsSubmitted.value
                  }, null, 8, ["fieldLabel", "presetFieldValue", "form-is-submitted"])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(InputField, {
                    type: "number",
                    minValue: "0",
                    fieldValue: productSaleInformation.value.pris,
                    "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((productSaleInformation.value.pris) = $event)),
                    required: ""
                  }, null, 8, ["fieldValue"])
                ])
              ])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _createVNode(GreenifiedButton, {
            disabled: isLoading.value,
            btnStyle: "black",
            text: _unref(t)('button.close'),
            size: "thin",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (emit('close'))),
            class: "me-3"
          }, null, 8, ["disabled", "text"]),
          _createVNode(GreenifiedButton, {
            disabled: isLoading.value,
            btnStyle: "green",
            size: "thin",
            text: 
            isLoading.value ? _unref(t)('button.pleaseWait') : _unref(t)('button.postInternally')
          ,
            onClick: _cache[7] || (_cache[7] = ($event: any) => (postAdvertisement()))
          }, null, 8, ["disabled", "text"])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"]),
    (publishSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          closable: false,
          title: 
      _ctx.type === 'internalSale'
        ? _unref(t)('responseTitle.productIsPublished')
        : _unref(t)('paragraph.advertisementHasBeenUpdated')
    ,
          text: getResponseText(),
          onClose: _cache[9] || (_cache[9] = ($event: any) => {
      emit('refresh');
      emit('close');
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})