<template>
  <div class="row ms-0 me-0 border-bottom table-row w-100">
    <template
      v-for="(tableColumn, tableKey, index) in subTableColumns.columns"
      :key="index"
    >
      <div
        v-if="tableColumn"
        class="d-flex align-items-center ps-0 pe-0 pt-0 text-tiny cursor-default"
        :class="tableColumn.columnStyling"
      >
        <div
          class="d-flex align-items-center w-100"
          :class="index === 0 && 'left-margin'"
        >
          <template v-if="tableKey !== 'soldProducts'">
            <GreenifiedCheckbox
              tableMargin="sub"
              v-if="tableColumn.actions.includes('checkbox') && !hideCheckbox"
              @update:modelValue="emit('selectSubRow')"
              :isChecked="rowIsChecked"
            />

            <p
              v-if="String(tableKey) !== 'dimensions' && tableColumn.value"
              v-html="
                translatedKeys.includes(String(tableKey))
                  ? t(`${String(tableKey)}.${tableColumn.value}`)
                  : tableColumn.value
              "
            ></p>
            <div class="dimension-column d-flex flex-wrap" v-else>
              <template
                v-for="(dimensionValue, dimensionKey) in tableColumn.value"
                :key="dimensionKey"
              >
                <div class="d-flex align-items-center" v-if="dimensionValue">
                  <p class="fw-bold me-1 lh-sm">
                    {{ t(`label.${String(dimensionKey)}`) }}
                  </p>
                  <p class="me-1 dimension-text">{{ dimensionValue }}mm</p>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="right-margin">
          <StandardSubActions
            :actions="tableColumn.actions"
            v-if="index === Object.keys(subTableColumns.columns).length - 1"
            @showHistory="emit('showHistory')"
            @showEdit="emit('showEdit')"
            v-model:isChecked="currentRowIsChecked"
            :columnValue="tableColumn.value"
            @update:isChecked="isChecked => emit('selectSubRow', isChecked)"
            :disableCheckbox="disableCheckbox"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import { withDefaults, defineProps, defineEmits, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import StandardSubActions from "../subRowActions/StandardSubActions.vue";
import { TableColumns } from "@/library/types/tables/columns";

type SubTableRowProps = {
  subTableColumns: TableColumns;
  rowIsChecked: boolean;
  hideCheckbox?: boolean;
  disableCheckbox?: boolean;
};

const subTableRowProps = withDefaults(defineProps<SubTableRowProps>(), {
  rowIsChecked: false,
  hideCheckbox: false,
  disableCheckbox: false
});

const emit = defineEmits(["selectSubRow", "showEdit", "showHistory"]);
const currentRowIsChecked = ref(subTableRowProps.rowIsChecked);
const { t } = useI18n();

const translatedKeys = ["skick2", "rum", "till_rum"];

watch(
  () => subTableRowProps.rowIsChecked,
  () => {
    currentRowIsChecked.value = subTableRowProps.rowIsChecked;
  }
);
</script>

<style scoped>
.product-actions-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}

.dimension-column {
  line-height: normal;
}

.subtable-margin {
  margin-left: 28px;
}
</style>
