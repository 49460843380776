import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row ms-0 me-0 border-bottom table-row w-100" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "dimension-column d-flex flex-wrap"
}
const _hoisted_4 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_5 = { class: "fw-bold me-1 lh-sm" }
const _hoisted_6 = { class: "me-1 dimension-text" }
const _hoisted_7 = { class: "right-margin" }

import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import StandardSubActions from "../subRowActions/StandardSubActions.vue";
import { TableColumns } from "@/library/types/tables/columns";

type SubTableRowProps = {
  subTableColumns: TableColumns;
  rowIsChecked: boolean;
  hideCheckbox?: boolean;
  disableCheckbox?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SubTableRow',
  props: {
    subTableColumns: {},
    rowIsChecked: { type: Boolean, default: false },
    hideCheckbox: { type: Boolean, default: false },
    disableCheckbox: { type: Boolean, default: false }
  },
  emits: ["selectSubRow", "showEdit", "showHistory"],
  setup(__props: any, { emit: __emit }) {

const subTableRowProps = __props;

const emit = __emit;
const currentRowIsChecked = ref(subTableRowProps.rowIsChecked);
const { t } = useI18n();

const translatedKeys = ["skick2", "rum", "till_rum"];

watch(
  () => subTableRowProps.rowIsChecked,
  () => {
    currentRowIsChecked.value = subTableRowProps.rowIsChecked;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subTableColumns.columns, (tableColumn, tableKey, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        tableColumn
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["d-flex align-items-center ps-0 pe-0 pt-0 text-tiny cursor-default", tableColumn.columnStyling])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["d-flex align-items-center w-100", index === 0 && 'left-margin'])
              }, [
                (tableKey !== 'soldProducts')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (tableColumn.actions.includes('checkbox') && !_ctx.hideCheckbox)
                        ? (_openBlock(), _createBlock(GreenifiedCheckbox, {
                            key: 0,
                            tableMargin: "sub",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('selectSubRow'))),
                            isChecked: _ctx.rowIsChecked
                          }, null, 8, ["isChecked"]))
                        : _createCommentVNode("", true),
                      (String(tableKey) !== 'dimensions' && tableColumn.value)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            innerHTML: 
                translatedKeys.includes(String(tableKey))
                  ? _unref(t)(`${String(tableKey)}.${tableColumn.value}`)
                  : tableColumn.value
              
                          }, null, 8, _hoisted_2))
                        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableColumn.value, (dimensionValue, dimensionKey) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: dimensionKey }, [
                                dimensionValue
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`label.${String(dimensionKey)}`)), 1),
                                      _createElementVNode("p", _hoisted_6, _toDisplayString(dimensionValue) + "mm", 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ]))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_7, [
                (index === Object.keys(_ctx.subTableColumns.columns).length - 1)
                  ? (_openBlock(), _createBlock(StandardSubActions, {
                      key: 0,
                      actions: tableColumn.actions,
                      onShowHistory: _cache[1] || (_cache[1] = ($event: any) => (emit('showHistory'))),
                      onShowEdit: _cache[2] || (_cache[2] = ($event: any) => (emit('showEdit'))),
                      isChecked: currentRowIsChecked.value,
                      "onUpdate:isChecked": [
                        _cache[3] || (_cache[3] = ($event: any) => ((currentRowIsChecked).value = $event)),
                        _cache[4] || (_cache[4] = isChecked => emit('selectSubRow', isChecked))
                      ],
                      columnValue: tableColumn.value,
                      disableCheckbox: _ctx.disableCheckbox
                    }, null, 8, ["actions", "isChecked", "columnValue", "disableCheckbox"]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})