import suppliersApi, { SearchedSupplier } from "@/services/api/suppliersApi";
import { AxiosResponse } from "axios";
import { useIndexStore } from "@/store";
import { CustomError, handleApiRequest, Result } from "../helpers/handleApiRequests";

export const getSuppliers = async (
  searchValue: string,
): Promise<Result<SearchedSupplier[], CustomError>> => {
  return handleApiRequest(
    suppliersApi.getSuppliers(searchValue),
    "suppliers - getSuppliers",
  );
};

export const postNewSupplier = async (
  supplierCode: SearchedSupplier["levkod"],
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    suppliersApi.postNewSupplier(supplierCode, indexStore.currentProjectNumber),
    "suppliers - postNewSupplier",
  );
};
