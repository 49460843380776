import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import MainLayout from "@/layouts/MainLayout.vue";
import CookieInformation from "@/components/modal/CookieInformation.vue";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
import "@lbgm/phone-number-input/style";
import { computed, onMounted, ref } from "vue";
import { RouterView, useRouter } from "vue-router";
import { useIndexStore } from "./store";
import { useAuthStore } from "./store/auth";
import ProjectModal from "./views/ProjectModal.vue";
import LoadingOverlay from "./components/common/LoadingOverlay.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useIndexStore();
const authStore = useAuthStore();
const { currentRoute } = useRouter();
const cookiesModal = ref(false);
const showProjectModal = computed(() => {
  return !store.currentProjectNumber.length && authStore.isAuthenticated;
});

onMounted(() => {
  store.setUpdatingProjectStatus(false);
  if (!store.cookiesAreVerified) {
    cookiesModal.value = true;
    return;
  }
});

const authenticated = computed(() => {
  return (
    authStore.isAuthenticated && currentRoute.value.meta.middleware !== "guest"
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(store).updatingProject)
      ? (_openBlock(), _createBlock(LoadingOverlay, {
          key: 0,
          overlay: true,
          isMainLoader: _unref(currentRoute).meta.middleware === 'auth'
        }, null, 8, ["isMainLoader"]))
      : _createCommentVNode("", true),
    (authenticated.value && _unref(currentRoute).meta.middleware === 'auth')
      ? (_openBlock(), _createBlock(MainLayout, { key: 1 }, {
          default: _withCtx(() => [
            (_unref(store).cookiesAreVerified)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (
          _unref(store).currentProjectNumber.length &&
          _unref(store).currentProject.active === '1'
        )
                    ? (_openBlock(), _createBlock(_unref(RouterView), {
                        key: _unref(store).currentProjectNumber
                      }, {
                        default: _withCtx(({ Component, route }) => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                            key: route.path
                          }))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (showProjectModal.value)
              ? (_openBlock(), _createBlock(ProjectModal, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(SimpleLayout, { key: 2 }, {
          default: _withCtx(() => [
            (_unref(store).cookiesAreVerified)
              ? (_openBlock(), _createBlock(_unref(RouterView), {
                  key: _unref(currentRoute).fullPath
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })),
    (cookiesModal.value)
      ? (_openBlock(), _createBlock(CookieInformation, {
          key: 3,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (cookiesModal.value = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})