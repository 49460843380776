import { useIndexStore } from "@/store";
import helpersApi, {
  CalculatedPrice,
  IntervalOptions,
  PriceSuggestion,
  Quantities,
  TranslationTable
} from "@/services/api/helpersApi";
import { AxiosResponse } from "axios";
import { ProjectDetails } from "../types/project/project";
import {
  CustomError,
  handleApiRequest,
  Result
} from "../helpers/handleApiRequests";

export const getTranslationTable = async (
  projectLanguage: string
): Promise<Result<TranslationTable[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    helpersApi.getTranslationTable(
      indexStore.currentProjectNumber,
      projectLanguage
    ),
    "helpersApi - getTranslationTable"
  );
};

export const getGuestTranslationTable = async (
  guestToken: string,
  projectLanguage: string
): Promise<Result<TranslationTable[], CustomError>> => {
  return handleApiRequest(
    helpersApi.getGuestTranslationTable(guestToken, projectLanguage),
    "helpersApi - getTranslationTable"
  );
};

export const getPriceEvaluation = async (
  productIds: string[]
): Promise<Result<PriceSuggestion, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    helpersApi.getPriceEvaluation(indexStore.currentProjectNumber, productIds),
    "helpersApi - getPriceEvaluation"
  );
};

export const getCalculatedPrice = async (
  price: number,
  category: string,
  quantity: number
): Promise<Result<CalculatedPrice, CustomError>> => {
  const indexStore = useIndexStore();
  return handleApiRequest(
    helpersApi.getCalculatedPrice(
      price,
      category,
      quantity,
      indexStore.projectDetails.land,
      indexStore.projectDetails.valuta
    ),
    "helpersApi - getCalculatedPrice"
  );
};

export const getQuantities = async (): Promise<
  Result<Quantities, CustomError>
> => {
  const indexStore = useIndexStore();
  const projectNumber = indexStore.currentProjectNumber;

  return handleApiRequest(
    helpersApi.getQuantities(projectNumber),
    "helpersApi - getQuantities"
  );
};

export const getGuestQuantities = async (
  guestToken: string
): Promise<Result<Quantities, CustomError>> => {
  return handleApiRequest(
    helpersApi.getGuestQuantities(guestToken),
    "helpersApi - getGuestQuantities"
  );
};

export const postLocaleCurrency = async (
  currency: ProjectDetails["valuta"]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    helpersApi.postLocaleCurrency(indexStore.currentProjectNumber, currency),
    "helpersApi - postLocaleCurrency"
  );
};

export const getIntervalOptions = async (
  intervalOptions: string[]
): Promise<Result<IntervalOptions[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    helpersApi.getIntervalOptions(
      indexStore.currentProjectNumber,
      intervalOptions
    ),
    "helpersApi - getIntervalOptions"
  );
};

export const getGuestIntervalOptions = async (
  guestToken: string,
  intervalOptions: string[]
): Promise<Result<IntervalOptions[], CustomError>> => {
  return handleApiRequest(
    helpersApi.getGuestIntervalOptions(guestToken, intervalOptions),
    "helpersApi - getGuestIntervalOptions"
  );
};
