import { ProductHeaderSale } from "@/library/types/product/productHeader";
import { ProjectDetails } from "@/library/types/project/project";
import { defineStore } from "pinia";
import { computed, Ref, ref } from "vue";
import { getGreenifiedStructure } from "@/library/api/greenifiedProducts";
import {
  getGuestQuantities,
  getGuestTranslationTable,
  getQuantities,
  getTranslationTable
} from "@/library/api/helpers";
import { Quantities, TranslationTable } from "@/services/api/helpersApi";
import { getProject } from "@/library/api/project";
import { I18nLocale } from "@/library/types/locales/locales";
import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import { useAuthStore } from "./auth";

import {
  CustomLocale,
  CustomLocaleMessage,
  CustomSetLocaleMessage
} from "@/library/types/locales/i18nUtilities";

export const useIndexStore = defineStore(
  "index",
  () => {
    // States
    const updatingProject = ref(false);
    const cookiesAreVerified = ref(false);
    const projectDetails: Ref<ProjectDetails> = ref({
      active: "0",
      land: "",
      valuta: "SEK",
      guest_token: "",
      sprak: null,
      projektbesk: "",
      namn: "",
      projekt: "",
      product_cat: [],
      lev: [],
      role: null,
      struc: [],
      alla_rum: []
    });
    const quantities: Ref<Quantities | null> = ref(null);
    const maxQuantity = ref(1);

    // Getters
    const currentProjectNumber = computed(() => projectDetails.value.projekt);
    const projectIsUpdating = computed(() => updatingProject.value);
    const currentProjectRole = computed(() => projectDetails.value.role);
    const currentProjectLanguage = computed(() => projectDetails.value.sprak);
    const currentProject = computed(() => projectDetails.value);
    const currentProjectCurrency = computed(() => projectDetails.value.valuta);

    // Actions
    async function changeProject(
      projectNumber: ProjectDetails["projekt"],
      setLocaleMessage: CustomSetLocaleMessage,
      localeMessages: CustomLocaleMessage
    ) {
      updatingProject.value = true;

      const projectResult = await getProjectDetails(
        projectNumber,
        setLocaleMessage,
        localeMessages
      );

      if (projectResult !== "success") {
        updatingProject.value = false;
        return "error";
      }

      updatingProject.value = false;

      return "success";
    }

    async function getProjectDetails(
      projectNumber: ProjectDetails["projekt"],
      setLocaleMessage: CustomSetLocaleMessage,
      localeMessages: CustomLocaleMessage
    ) {
      updatingProject.value = true;

      const result = await getProject(projectNumber);

      if (!result.success || !result.result.sprak) {
        useAuthStore().logout();
        return "error";
      }

      const projectResult = result.result;

      projectDetails.value = {
        active: projectResult.active,
        land: projectResult.land,
        valuta: projectResult.valuta,
        guest_token: projectResult.guest_token,
        sprak: projectDetails.value.sprak
          ? projectDetails.value.sprak
          : projectResult.sprak,
        projektbesk: projectResult.projektbesk,
        namn: projectResult.namn,
        projekt: projectResult.projekt,
        product_cat: projectResult.product_cat,
        lev: projectResult.lev,
        role: projectResult.role,
        struc: projectResult.struc,
        alla_rum: projectResult.alla_rum
      };

      const structureResult = await getGreenifiedStructure(projectNumber);

      if (!structureResult.success) {
        updatingProject.value = false;
        return "error";
      }

      projectDetails.value.struc = structureResult.result;

      const localeTranslationsResult = await getLocaleTranslations(
        projectDetails.value,
        localeMessages
      );

      if (localeTranslationsResult !== "success") {
        updatingProject.value = false;
        return "error";
      }

      const quantityResult = await updateQuantities();

      if (quantityResult !== "success") {
        updatingProject.value = false;
        return "error";
      }
      updatingProject.value = false;

      return "success";
    }

    async function getGuestLocaleTranslations(
      projectDetails: ProjectDetails,
      guestToken: string,
      localeMessages: CustomLocaleMessage
    ) {
      if (!projectDetails.sprak) return;
      const currentLang = projectDetails.sprak;

      const translationResult = await getGuestTranslationTable(
        guestToken,
        currentLang
      );

      if (!translationResult.success) {
        updatingProject.value = false;
        return "error";
      }

      const updateResult = await updateLocaleMessages(
        localeMessages,
        translationResult.result
      );

      return updateResult;
    }

    async function getLocaleTranslations(
      projectDetails: ProjectDetails,
      localeMessages: CustomLocaleMessage
    ) {
      if (!projectDetails.sprak) return;
      const currentLang = projectDetails.sprak;

      const translationResult = await getTranslationTable(currentLang);

      if (!translationResult.success) {
        updatingProject.value = false;
        return "error";
      }

      const updateResult = await updateLocaleMessages(
        localeMessages,
        translationResult.result
      );

      return updateResult;
    }

    async function updateLocaleMessages(
      localeMessages: CustomLocaleMessage,
      translationTables: TranslationTable[]
    ) {
      try {
        translationTables.forEach((translation: TranslationTable) => {
          Object.assign(localeMessages, translation.tabell);
        });
      } catch (error) {
        console.error(error);
        return "error";
      }

      return "success";
    }

    async function updateGuestQuantities(guestToken: string) {
      const result = await getGuestQuantities(guestToken);

      if (!result.success) return "error";
      quantities.value = result.result;

      return "success";
    }

    async function updateQuantities() {
      const result = await getQuantities();

      if (!result.success) return "error";

      quantities.value = result.result;

      return "success";
    }

    function changeProjectNumber(newProjectNumber: ProjectDetails["projekt"]) {
      projectDetails.value.projekt = newProjectNumber;
    }

    function setUpdatingProjectStatus(newStatus: boolean) {
      updatingProject.value = newStatus;
    }

    async function changeProjectNumberAndUpdateStructure(
      newProjectNumber: ProjectDetails["projekt"]
    ) {
      projectDetails.value.projekt = newProjectNumber;
      const result = await getGreenifiedStructure(newProjectNumber);
      if (!result.success) return "error";
      projectDetails.value.struc = result.result;
      return "success";
    }

    function changeProjectLanguage(
      newProjectLanguage: I18nLocale,
      locale: CustomLocale
    ) {
      locale.value = newProjectLanguage.toLocaleLowerCase();
      projectDetails.value.sprak = newProjectLanguage;
    }

    function changeQuantities(
      newQuantityValues: Quantities,
      type?: keyof Quantities,
      quantityValue?: number
    ) {
      if (type && quantityValue && quantities.value)
        return (quantities.value[type] = quantityValue);
      quantities.value = newQuantityValues;
    }

    function changeCurrency(newCurrency: ProjectDetails["valuta"]) {
      projectDetails.value.valuta = newCurrency;
    }

    function changeCookies(verifiedCookies: boolean) {
      cookiesAreVerified.value = verifiedCookies;
    }

    function calculateMaxQuantity(
      productHeaders: ProductHeaderSale | GreenifiedProductHeader[]
    ) {
      let quantity = 1;

      if (Array.isArray(productHeaders)) {
        productHeaders.forEach(productHeader => {
          if (quantity < productHeader.items.length) {
            quantity = productHeader.items.length;
          }
        });
      } else {
        Object.keys(productHeaders).forEach(key => {
          if (quantity < productHeaders[key].items.length) {
            quantity = productHeaders[key].items.length;
          }
        });
      }

      maxQuantity.value = quantity;
    }

    return {
      updatingProject,
      cookiesAreVerified,
      projectDetails,
      quantities,
      maxQuantity,
      currentProjectNumber,
      currentProjectRole,
      projectIsUpdating,
      currentProject,
      currentProjectLanguage,
      currentProjectCurrency,
      setUpdatingProjectStatus,
      updateQuantities,
      updateGuestQuantities,
      changeProject,
      getProjectDetails,
      getLocaleTranslations,
      getGuestLocaleTranslations,
      changeProjectNumber,
      changeProjectNumberAndUpdateStructure,
      changeProjectLanguage,
      changeQuantities,
      changeCurrency,
      changeCookies,
      calculateMaxQuantity
    };
  },
  { persist: true }
);
