<template>
  <div class="row mb-3">
    <div class="col-4"></div>
    <div class="col-8">
      <div class="row row-gap-3">
        <div
          class="col-6"
          v-for="(imageValue, imageKey, index) in currentFormImages"
          :key="index"
        >
          <GreenifiedImage
            v-if="currentFormImages[imageKey]"
            v-model:imageUrl="currentFormImages[imageKey].imageHash"
            :imageLabel="currentFormImages[imageKey].fieldKey"
            :imageIndex="index + 1"
            :imageIsRequired="requiredImageIndex.includes(index)"
            @update:imageUrl="handleUpdatedImages(imageKey)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, ref, defineEmits } from "vue";
import GreenifiedImage from "@/components/common/GreenifiedImage.vue";
import {
  FormImagesObject,
  ProductImages,
} from "@/library/types/product/productImage";

type FormImagesProps = {
  formImages: FormImagesObject;
  productImages: ProductImages;
};

const formImagesProps = withDefaults(defineProps<FormImagesProps>(), {
  formImages: () => ({
    bild_1: {
      fieldKey: "",
      imageHash: "",
    },
    bild_2: {
      fieldKey: "",
      imageHash: "",
    },
  }),
  productImages: () => ({
    bild_1: {
      url: "",
      hash: "",
    },
    bild_2: {
      url: "",
      hash: "",
    },
    bild_3: {
      url: "",
      hash: "",
    },
    bild_4: {
      url: "",
      hash: "",
    },
  }),
});

const emit = defineEmits(["update:formImages", "update:productImages"]);
const currentProductImages = ref(formImagesProps.productImages);
const currentFormImages = ref(formImagesProps.formImages);
const requiredImageIndex = [0, 1];

const handleUpdatedImages = (imageKey: keyof ProductImages) => {
  emit("update:formImages", currentFormImages);
  if (
    !currentFormImages.value[imageKey]?.imageHash &&
    imageKey in currentProductImages.value
  ) {
    delete currentProductImages.value[imageKey];
    return emit("update:productImages", currentProductImages.value);
  }

  currentProductImages.value[imageKey] = {
    url: "",
    hash: currentFormImages.value[imageKey]?.imageHash ?? "",
  };
  return emit("update:productImages", currentProductImages.value);
};
</script>
