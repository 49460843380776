<template>
  <GreenifiedModal
    @close="emit('close')"
    type="smaller"
    :title="t('title.orderedLabels')"
    :isLoading="isLoading"
    :headerSpacing="false"
    :key="String(labelOrders)"
  >
    <template #modalHeaderContent>
      <p class="form-label-paragraph mb-2">
        {{ t("paragraph.orderedLabels") }}
      </p>

      <TableHeaderRow
        v-if="labelOrders.length"
        :tableHeaders="tableColumns(labelOrders[0])"
      />
    </template>
    <template #container>
      <div class="border-bottom" v-for="order in labelOrders" :key="order.land">
        <MainTableRow type="other" :tableColumns="tableColumns(order)" />
      </div>
    </template>
    <template #footer>
      <div class="col-5">
        <GreenifiedButton
          btnStyle="green"
          :text="t('button.orderLabels')"
          :disabled="userRole !== 'P'"
          @click="showOrderMore = true"
        />
      </div>
    </template>
  </GreenifiedModal>

  <OrderLabelsForm
    v-if="showOrderMore"
    @close="showOrderMore = false"
    @refresh="getLabelOrders()"
  />
</template>

<script lang="ts" setup>
import { defineEmits, computed, onMounted, Ref, ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { useIndexStore } from "@/store";
import { getLabelOrdersApi } from "@/library/api/labels";
import OrderLabelsForm from "../form/OrderLabelsForm.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import { LabelOrder } from "@/library/types/labels/labels";

const emit = defineEmits(["close"]);
const { t, locale } = useI18n();
const indexStore = useIndexStore();
const labelOrders: Ref<LabelOrder[]> = ref([]);
const showOrderMore = ref(false);
const isLoading = ref(false);

const userRole = computed(() => {
  return indexStore.currentProject.role;
});

const getLabelOrders = async () => {
  isLoading.value = true;
  const result = await getLabelOrdersApi();
  isLoading.value = false;

  if (!result.success) return;

  labelOrders.value = result.result;
};

const getLocaleTime = (date: string) => {
  const utcDate = new Date(date);
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const userDate = new Date(utcDate.getTime() + userTimezoneOffset * 60000);
  const formattedDate = userDate.toLocaleString(
    locale.value.toLocaleLowerCase()
  );
  return formattedDate;
};

const tableColumns = (labelOrder: LabelOrder): TableColumns["columns"] => {
  return {
    createdAt: {
      value: getLocaleTime(labelOrder.created_at),
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.orderDate",
      inputSettings: null,
      selectOptions: []
    },
    address: {
      value: `${labelOrder.adress} <br />
              ${labelOrder.postnr}, ${labelOrder.postadr}, ${labelOrder.land}`,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.address",
      inputSettings: null,
      selectOptions: []
    },
    action: {
      value: labelOrder.antal,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.numberOfLabels",
      inputSettings: null,
      selectOptions: []
    }
  };
};

onMounted(() => {
  getLabelOrders();
});
</script>
