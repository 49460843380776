import projectApi, {
  ProjectProductQuantities,
} from "@/services/api/projectApi";
import { ProjectDetails, SelectableProject } from "../types/project/project";
import { useIndexStore } from "@/store";
import {
  CustomError,
  handleApiRequest,
  Result,
} from "../helpers/handleApiRequests";

export type User = {
  avatar_icon: string;
  name: string;
  usr: string;
  typ: string;
};

export const getClientProjects = async (): Promise<
  Result<SelectableProject[], CustomError>
> => {
  return handleApiRequest(
    projectApi.getClientProjects(),
    "project - getClientProjects",
  );
};

export const getProject = async (
  projectNumber: string,
): Promise<Result<ProjectDetails, CustomError>> => {
  return handleApiRequest(
    projectApi.getProject(projectNumber),
    "project - getProject",
  );
};

export const getProjectInformation = async (): Promise<
  Result<{ data: ProjectProductQuantities }, CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    projectApi.getProjectInformation(indexStore.currentProjectNumber),
    "project - getProjectInformation",
  );
};

export const getProjectUsersApi = async (): Promise<
  Result<User[], CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    projectApi.getProjectUsersApi(indexStore.currentProjectNumber),
    "project - getProjectUsersApi",
  );
};
