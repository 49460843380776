import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import MainFilter from "@/components/filter/MainFilter.vue";
import RoomForm from "@/components/form/RoomForm.vue";
import { useIndexStore } from "@/store";
import { useI18n } from "vue-i18n";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectRoomView',
  setup(__props) {

const { t } = useI18n();
const indexStore = useIndexStore();

const currentProject = indexStore.currentProjectNumber;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      "filter-title": _unref(t)('title.roomsHandler'),
      "filter-text": _unref(t)('paragraph.roomsHandler')
    }, null, 8, ["filter-title", "filter-text"]),
    (_openBlock(), _createBlock(RoomForm, { key: _unref(currentProject) }))
  ], 64))
}
}

})