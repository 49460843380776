import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-12 d-flex" }
const _hoisted_3 = {
  key: 1,
  class: "row"
}
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "text-medium" }

import {
  ref,
  Ref,
  computed,
  onMounted
} from "vue";
import { ProductItem } from "../../library/types/product/productItem";
import { bolderFont } from "../../library/helpers/typography";
import { getItemLogHistory } from "@/library/api/products";
import { HistoryLog } from "@/services/api/productsApi";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { useI18n } from "vue-i18n";
import { TableColumns } from "@/library/types/tables/columns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";

type HistoryModalProps = {
  productId: ProductItem["id"];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryModal',
  props: {
    productId: { default: "" }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const historyButtons = [
  {
    historyLogType: 0,
    text: "allHistory"
  },
  {
    historyLogType: 1,
    text: "statusHistory"
  },
  {
    historyLogType: 2,
    text: "moveHistory"
  },
  {
    historyLogType: 3,
    text: "salesHistory"
  }
];

const historyModalProps = __props;

const emit = __emit;
const isLoading = ref(true);
const logType = ref(0);
const allProductLogs: Ref<HistoryLog[]> = ref([]);
const statusLogs: Ref<HistoryLog[]> = ref([]);
const moveLogs: Ref<HistoryLog[]> = ref([]);
const saleLogs: Ref<HistoryLog[]> = ref([]);

const getHistoryColumns = (historyLog: HistoryLog): TableColumns["columns"] => {
  return {
    date: {
      value: historyLog.when.substring(0, 10),
      columnStyling: "col-2",
      tooltip: null,
      actions: [],
      isEditable: false,
      translate: false,
      isSortable: false,
      headerText: "label.date",
      inputSettings: null,
      selectOptions: []
    },
    user: {
      value: historyLog.who,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "title.users",
      inputSettings: null,
      selectOptions: []
    },
    action: {
      value: `
      <p> 
        ${
          historyLog.type !== "4"
            ? t(`logStatus.${historyLog.type_name.toLowerCase()}`)
            : `${t("logStatus.delivered_internal")}`
        } 
    ${
      historyLog.fromText && historyLog.type !== "4"
        ? `<span>
             ${t("label.from")}
             ${historyLog.fromText}
            </span>`
        : ""
    }
    ${
      historyLog.toText && historyLog.type !== "4"
        ? `<span>
             ${t("label.to")}
              ${historyLog.toText}
          </span>`
        : ""
    }
      </p>`,
      columnStyling: "col-6",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "placeholders.action",
      inputSettings: null,
      selectOptions: []
    }
  };
};

const currentHistoryLogs = computed(() => {
  if (logType.value === 1) return statusLogs.value;
  if (logType.value === 2) return moveLogs.value;
  if (logType.value === 3) return saleLogs.value;
  return allProductLogs.value;
});

const getProductHistory = async () => {
  isLoading.value = true;
  const result = await getItemLogHistory(historyModalProps.productId);

  if (!result.success) {
    isLoading.value = false;
    return;
  }
  saleLogs.value = saleLogTexts([...result.result]);

  moveLogs.value = moveLogTexts([...result.result]);
  statusLogs.value = statusLogTexts([...result.result]);
  allProductLogs.value = [
    ...saleLogs.value,
    ...moveLogs.value,
    ...statusLogs.value
  ];
  isLoading.value = false;
};

const moveLogTexts = (historyLogs: HistoryLog[]) => {
  const currentMoveLogs = historyLogs.filter(
    log =>
      log.fromText &&
      log.fromText !== log.toText &&
      (log.type === "2" || log.type === "3")
  );
  currentMoveLogs.forEach(moveLog => {
    moveLog.fromText = bolderFont(t(`rum.${moveLog.fromText}`));

    moveLog.toText = bolderFont(t(`rum.${moveLog.toText}`));
  });

  return currentMoveLogs;
};

const statusLogTexts = (historyLogs: HistoryLog[]) => {
  const currentStatusLogs = historyLogs.filter(log => log.type === "1");
  currentStatusLogs.forEach(statusLog => {
    statusLog.fromText = statusLog.fromText
      ? translateStatus(statusLog.fromText as ProductStatuses)
      : null;
    statusLog.toText = translateStatus(statusLog.toText as ProductStatuses);
  });

  return currentStatusLogs;
};

const saleLogTexts = (historyLogs: HistoryLog[]) => {
  const currentSaleLogs = historyLogs.filter(
    log =>
      log.type === "4" ||
      log.type === "5" ||
      log.type === "6" ||
      (log.type === "8" && log.fromText !== log.toText)
  );

  currentSaleLogs.forEach(saleLog => {
    if (saleLog.type === "8") {
      saleLog.toText = bolderFont(t(`list.internalStatuses.${saleLog.toText}`));
      saleLog.fromText = bolderFont(
        t(`list.internalStatuses.${saleLog.fromText}`)
      );
    }
  });

  return currentSaleLogs;
};

onMounted(() => {
  getProductHistory();
});

const translateStatus = (status: ProductStatuses) => {
  if (status === "S") {
    return bolderFont(t("title.sell"));
  } else if (status === "Å") {
    return bolderFont(t("title.repurpose"));
  } else if (status === "G") {
    return bolderFont(t("button.onGreenifiedWeb"));
  } else if (status === "I") {
    return bolderFont(t("button.otherSales"));
  }
  return bolderFont(t(`list.actionStatusLocation.${status}`));
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('close'))),
    size: 'medium',
    title: _unref(t)('title.productHistory'),
    headerSpacing: false,
    isLoading: isLoading.value
  }, {
    modalHeaderContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(historyButtons, (button) => {
            return _createElementVNode("div", {
              key: button.historyLogType,
              class: "me-3"
            }, [
              _createVNode(GreenifiedButton, {
                onClick: ($event: any) => (logType.value = button.historyLogType),
                size: 'thin',
                text: _unref(t)(`button.${button.text}`),
                btnStyle: logType.value === button.historyLogType ? 'black' : 'normal'
              }, null, 8, ["onClick", "text", "btnStyle"])
            ])
          }), 64))
        ])
      ]),
      (allProductLogs.value.length)
        ? (_openBlock(), _createBlock(TableHeaderRow, {
            key: 0,
            tableHeaders: getHistoryColumns(allProductLogs.value[0])
          }, null, 8, ["tableHeaders"]))
        : _createCommentVNode("", true),
      (!allProductLogs.value.length && !isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)("label.historyIsMissing")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    container: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentHistoryLogs.value, (log, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "border-bottom",
          key: index
        }, [
          _createVNode(MainTableRow, {
            tableColumns: getHistoryColumns(log),
            type: "other"
          }, null, 8, ["tableColumns"])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title", "isLoading"]))
}
}

})