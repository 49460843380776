import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



type LoadingOverlayProps = {
  innerStyle?: string;
  overlay?: boolean;
  isMainLoader?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingOverlay',
  props: {
    innerStyle: { default: "" },
    overlay: { type: Boolean, default: false },
    isMainLoader: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader-wrapper", [_ctx.overlay && 'bg-overlay']])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["spring-spinner", _ctx.isMainLoader && 'main-loader-spring']),
      style: _normalizeStyle(_ctx.innerStyle)
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "spring-spinner-part top" }, [
        _createElementVNode("div", { class: "spring-spinner-rotator" })
      ], -1),
      _createElementVNode("div", { class: "spring-spinner-part bottom" }, [
        _createElementVNode("div", { class: "spring-spinner-rotator" })
      ], -1)
    ]), 6)
  ], 2))
}
}

})