<template>
  <GreenifiedButton
    v-if="multipleCheckboxField"
    inputType="checkbox"
    @click="addOrRemoveOption(option)"
    :inputRequired="multipleCheckboxField.required === 0 ? false : true"
    id="name-id"
    :name="multipleCheckboxField.key"
    :value="localMultipleCheckboxValues"
    :text="option"
    :btnStyle="isChecked ? 'black' : 'normal'"
  />
</template>

<script lang="ts" setup>
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { CheckboxFieldType } from "@/library/types/structure/subfieldTypes";
import { defineProps, withDefaults, defineEmits, ref } from "vue";

type MultipleCheckboxFieldProps = {
  option: string;
  presetMultipleCheckboxValues: FormFields["key"];
  multipleCheckboxField: CheckboxFieldType | null;
};

const multipleCheckboxFieldProps = withDefaults(
  defineProps<MultipleCheckboxFieldProps>(),
  {
    option: "",
    presetMultipleCheckboxValues: () => ({}),
    multipleCheckboxField: null
  }
);

const localMultipleCheckboxValues = ref(
  multipleCheckboxFieldProps.presetMultipleCheckboxValues as string
);

const isChecked = ref(false);

const emit = defineEmits(["delete", "update:presetMultipleCheckboxValues"]);

const addOrRemoveOption = (option: string) => {
  if (!Array.isArray(localMultipleCheckboxValues.value)) return;

  if (!localMultipleCheckboxValues.value) {
    return;
  }

  const descriptionIndex = localMultipleCheckboxValues.value.indexOf(option);
  if (descriptionIndex === -1) {
    localMultipleCheckboxValues.value.push(option);
    isChecked.value = true;
  } else {
    localMultipleCheckboxValues.value.splice(descriptionIndex, 1);
    isChecked.value = false;
  }
  emit(
    "update:presetMultipleCheckboxValues",
    localMultipleCheckboxValues.value
  );

  if (localMultipleCheckboxValues.value.length === 0) {
    emit("delete");
  }
};
</script>
