import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/img/remove.svg'


const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "text-tiny m-2"
}

import { postImage } from "@/library/api/media";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import GreenifiedLoader from "./GreenifiedLoader.vue";

type ImageFieldProps = {
  imageUrl: string | null;
  imageLabel: string;
  imageIsRequired?: boolean;
  imageIndex: number;
  selectable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedImage',
  props: {
    imageUrl: { default: "" },
    imageLabel: { default: "" },
    imageIsRequired: { type: Boolean, default: false },
    imageIndex: { default: 0 },
    selectable: { type: Boolean, default: true }
  },
  emits: ["update:imageUrl"],
  setup(__props: any, { emit: __emit }) {

const imageFieldProps = __props;

const { t } = useI18n();

const contentUrl = process.env.VUE_APP_CONTENT_URL;

const emit = __emit;

const loadingImage = ref(false);

const currentImageUrl = ref(imageFieldProps.imageUrl ?? "");

const deleteImageFile = () => {
  currentImageUrl.value = "";
  emit("update:imageUrl", currentImageUrl.value);
};

const uploadImageFile = async (event: Event) => {
  loadingImage.value = true;
  const formData = new FormData();
  const eventTarget = event.target as HTMLInputElement;
  if (eventTarget.files?.length) {
    formData.append("file", eventTarget.files[0]);

    const result = await postImage(formData);
    loadingImage.value = false;

    if (!result.success) return;
    currentImageUrl.value = result.result;

    emit("update:imageUrl", currentImageUrl.value);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "greenified-image position-relative cursor-pointer",
    key: currentImageUrl.value
  }, [
    (loadingImage.value)
      ? (_openBlock(), _createBlock(GreenifiedLoader, {
          key: 0,
          color: 'black'
        }))
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      class: _normalizeClass(["w-100 h-100", _ctx.selectable ? 'cursor-pointer' : 'cursor-default']),
      for: _ctx.imageLabel
    }, [
      (_ctx.selectable)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "file",
            accept: "image/*",
            id: _ctx.imageLabel,
            maxlength: "60",
            class: "hidden-input",
            onChange: uploadImageFile
          }, null, 40, _hoisted_2))
        : _createCommentVNode("", true),
      (currentImageUrl.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: `${_unref(contentUrl)}${currentImageUrl.value}`,
            alt: "form image",
            class: "form-image cursor-pointer"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(`${_unref(t)("label.image")} ${_ctx.imageIndex}`), 1))
    ], 10, _hoisted_1),
    (!_ctx.imageIsRequired && currentImageUrl.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "delete-icon-wrapper",
          onClick: deleteImageFile
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "delete image icon",
            class: "delete-image-icon cursor-pointer"
          }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})