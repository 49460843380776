import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loader-wrapper" }
const _hoisted_2 = { class: "spring-spinner-part top" }
const _hoisted_3 = { class: "spring-spinner-part bottom" }



type GreenifiedLoaderProps = {
  color: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedLoader',
  props: {
    color: { default: "black" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "spring-spinner",
      style: _normalizeStyle(
        'border-right-color:' + _ctx.color + ';border-top-color: ' + _ctx.color + ';'
      )
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "spring-spinner-rotator",
          style: _normalizeStyle(
            'border-right-color:' + _ctx.color + ';border-top-color: ' + _ctx.color + ';'
          )
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "spring-spinner-rotator",
          style: _normalizeStyle(
            'border-right-color:' + _ctx.color + ';border-top-color: ' + _ctx.color + ';'
          )
        }, null, 4)
      ])
    ], 4)
  ]))
}
}

})