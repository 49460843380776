import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex me-3"
}
const _hoisted_2 = {
  key: 0,
  class: "text-small fw-bolder me-1"
}
const _hoisted_3 = { class: "text-small" }
const _hoisted_4 = {
  key: 1,
  class: "col-12 ps-0 pe-0"
}
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "text-small" }
const _hoisted_7 = { class: "col-6 ps-3" }
const _hoisted_8 = {
  key: 0,
  class: "text-small"
}
const _hoisted_9 = ["href"]

import IconButton from "./common/IconButton.vue";
import RotateArrow from "./common/RotateArrow.vue";
import AccordionContainer from "./common/AccordionContainer.vue";
import { ref, Ref, onMounted, onUpdated } from "vue";
import { InformationsRows } from "./ProductGroupInformation.vue";
import { useI18n } from "vue-i18n";
import addIcon from "@/assets/img/add_icon.svg";
import minusIcon from "@/assets/img/minus_icon.svg";

type InformationRowProps = {
  label?: string;
  showTopBorder?: boolean;
  subheaderLabel?: boolean;
  accordionRows?: [];
  accordionKey?: string;
  accordionValue?: string;
  accordionLabelText?: string;
  links?: boolean;
  informationRows: InformationsRows["rows"];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InformationRow',
  props: {
    label: { default: "" },
    showTopBorder: { type: Boolean, default: true },
    subheaderLabel: { type: Boolean, default: true },
    accordionRows: { default: () => [] },
    accordionKey: { default: "" },
    accordionValue: { default: "" },
    accordionLabelText: { default: "" },
    links: { type: Boolean, default: false },
    informationRows: { default: () => [] }
  },
  setup(__props: any) {

const { t } = useI18n();



const hideOverflow = ref(false);
const textHeight = ref(0);
const showMore = ref(false);
const textContainer: Ref<HTMLElement | null> = ref(null);
const currentAddIcon = addIcon;
const currentMinusIcon = minusIcon;

const containerHeight = () => {
  if (textContainer.value) {
    let containerHeight = textContainer.value.clientHeight;
    hideOverflow.value = containerHeight && containerHeight > 50 ? true : false;
    showMore.value = hideOverflow.value;
  }
};

onMounted(() => {
  containerHeight();
});

onUpdated(() => {
  if (hideOverflow.value && textContainer.value) {
    let containerHeight = textContainer.value.clientHeight;
    if (containerHeight) {
      textHeight.value = containerHeight;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row info-row me-0 ms-0", [_ctx.showTopBorder && 'top-border', !showMore.value && 'align-items-center']])
  }, [
    _createElementVNode("div", {
      class: "col-2 ps-0 d-flex align-items-center",
      style: _normalizeStyle({ height: `${textHeight.value}px !important` }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (hideOverflow.value = !hideOverflow.value))
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createElementVNode("p", {
          class: _normalizeClass([_ctx.subheaderLabel ? 'subheading-small' : 'text-small'])
        }, _toDisplayString(_ctx.label), 3)
      ])
    ], 4),
    _createElementVNode("div", {
      class: _normalizeClass(["pe-0 d-flex justify-content-between align-items-center", showMore.value ? 'col-7' : 'col-9']),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (hideOverflow.value = !hideOverflow.value)),
      ref_key: "textContainer",
      ref: textContainer
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex align-items-center flex-wrap", [hideOverflow.value ? 'hide-overflow' : 'sliding-text']])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.informationRows, (informationsRow) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: informationsRow.rowValue
          }, [
            (informationsRow.rowValue)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                  (informationsRow.rowLabel)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(informationsRow.rowLabel), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(informationsRow.rowValue), 1)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ], 2),
      (_ctx.accordionRows.length)
        ? (_openBlock(), _createBlock(RotateArrow, {
            key: 0,
            rotate: hideOverflow.value,
            size: "small"
          }, null, 8, ["rotate"]))
        : _createCommentVNode("", true)
    ], 2),
    (showMore.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (hideOverflow.value = !hideOverflow.value)),
          class: "col-2 d-flex align-items-center justify-content-end pe-0",
          style: _normalizeStyle({ height: `${textHeight.value}px !important` })
        }, [
          _createVNode(IconButton, {
            iconImage: hideOverflow.value ? _unref(currentAddIcon) : _unref(currentMinusIcon),
            width: '22',
            height: '22',
            "alt-text": 'show more icon',
            noWrapper: ""
          }, null, 8, ["iconImage"])
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.accordionRows)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(AccordionContainer, { toggleAccordion: hideOverflow.value }, {
            content: _withCtx(() => [
              _renderSlot(_ctx.$slots, "accordionContent", {}, () => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accordionRows, (row, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row mt-3",
                    key: i
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.accordionLabelText
                      ? row[_ctx.accordionKey]
                      : _unref(t)(row[_ctx.accordionKey])) + " " + _toDisplayString(_ctx.accordionLabelText), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      (!_ctx.links)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(row[_ctx.accordionValue]), 1))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            class: "text-small link",
                            href: row[_ctx.accordionValue]
                          }, _toDisplayString(row[_ctx.accordionValue]), 9, _hoisted_9))
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 3
          }, 8, ["toggleAccordion"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})