import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-fluid mt-3 vs__dropdown-menu"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "bold" }

import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
import { AddressInformation } from "@/library/types/room/rooms";
import { FormFields } from "@/library/types/structure/formStructure";
import { TextFieldType } from "@/library/types/structure/subfieldTypes";
import { OnClickOutside } from "@vueuse/components";
import { computed } from "vue";

type AddressFieldProps = {
  addressField: TextFieldType | null;
  addressFieldValues: FormFields;
  addressSuggestions: AddressInformation[];
  toggleDropdown: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressField',
  props: {
    addressField: { default: null },
    addressFieldValues: { default: () => ({}) },
    addressSuggestions: { default: () => [] },
    toggleDropdown: { type: Boolean, default: false }
  },
  emits: [
  "update:addressFieldValues",
  "update:toggleDropdown"
],
  setup(__props: any, { emit: __emit }) {

const addressFieldProps = __props;

const emit = __emit;

const changeAddress = (newAddress: AddressInformation["address"]) => {
  let updatedAddress = {};

  Object.keys(newAddress).forEach(addressKey => {
    const updatedFieldKey = getProjectStructureFieldKey(addressKey);
    const addressValue =
      newAddress[addressKey as keyof AddressInformation["address"]];

    updatedAddress = Object.assign(addressFieldProps.addressFieldValues, {
      [updatedFieldKey]: addressValue
    });
  });

  emit("update:addressFieldValues", updatedAddress);
  emit("update:toggleDropdown", false);
};

const suggestedAddresses = computed((): AddressInformation[] => {
  if (
    Array.isArray(addressFieldProps.addressFieldValues.adress) &&
    addressFieldProps.addressFieldValues.adress.length > 0
  ) {
    const filteredAdressSuggestions =
      addressFieldProps.addressSuggestions.filter(() => {
        return true;
      });
    return filteredAdressSuggestions.slice(0, 5);
  }
  return addressFieldProps.addressSuggestions.slice(0, 5);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(OnClickOutside), {
    onTrigger: _cache[0] || (_cache[0] = ($event: any) => (emit('update:toggleDropdown', false)))
  }, {
    default: _withCtx(() => [
      (_ctx.addressField && _ctx.toggleDropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(suggestedAddresses.value, (address) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row manual-option mb-2",
                key: address.id,
                onClick: ($event: any) => (changeAddress(address.address))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("strong", _hoisted_4, _toDisplayString(address.address.adress), 1),
                  _createElementVNode("div", null, _toDisplayString(address.address.postnummer +
              ", " +
              address.address.ort +
              ", " +
              address.address.land), 1)
                ])
              ], 8, _hoisted_2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})