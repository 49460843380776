<template>
  <LoadingOverlay v-if="isLoading" />
  <form
    id="room-id"
    ref="roomForm"
    class="pb-4"
    @submit.prevent="postRooms"
    v-if="rooms.length && !isLoading"
  >
    <TableHeaderRow
      :table-headers="getRoomTableColumns(rooms[0]).columns"
      hideHeaderCheckbox
      v-model:sortingOptions="sortingSettings"
      isSticky
    />
    <div
      class="border-bottom"
      v-for="(room, index) in roomsToPost.insert"
      :key="room.rum"
    >
      <RoomHandlerLine
        :roomColumns="getRoomTableColumns(room).columns"
        :projectAddresses="projectAddresses"
        :roomToUpdate="room"
        @update="(room: RoomObject) => update(room)"
        @delete="roomsToPost.insert.splice(index, 1)"
        @refreshAddress="getProjectAddress()"
      />
    </div>

    <div
      class="border-bottom"
      v-for="(room, i) in sortedRoomRows(rooms, sortingSettings)"
      :key="room.rum"
    >
      <RoomHandlerLine
        :roomColumns="getRoomTableColumns(room).columns"
        :projectAddresses="projectAddresses"
        :roomToUpdate="room"
        @update="(room: RoomObject) => update(room)"
        @delete="verifyRoomDelete(room, i)"
        @refreshAddress="getProjectAddress()"
      />
    </div>
  </form>
  <TableFooter
    v-if="!isLoading"
    :footerText="
      roomsToPost.insert.length + roomsToPost.update.length > 0
        ? t('label.roomsTransactionChanged', {
            count: roomsToPost.insert.length + roomsToPost.update.length
          })
        : ''
    "
    :action-statuses="['AR', 'SR']"
    @action="(action: TableFooterButton) => handleSelectAction(action.action)"
  />

  <ProductsInDeletedRoomModal
    v-if="showProductsInDeletedRoomModal"
    @close="showProductsInDeletedRoomModal = false"
    @removeRoom="
      () => {
        if (!roomToDelete) return;
        removeRoom(roomToDelete);
      }
    "
    :productsInDeletedRoom="productsInDeletedRoom"
    :roomToDelete="roomToDelete"
  />

  <GreenifiedModal
    v-if="verifyDelete && roomToDelete"
    type="verification"
    :closable="false"
    :title="t('title.removeRoom')"
    :text="t('msg.verifyRemove', [bolderFont(roomToDelete.rumsnamn)])"
    @close="verifyDelete = false"
    @confirm="
      () => {
        verifyDelete = false;
        if (!roomToDelete) return;
        removeRoom(roomToDelete);
      }
    "
  />
</template>

<script setup lang="ts">
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import RoomHandlerLine from "@/components/RoomHandlerLine.vue";
import { onMounted, Ref, ref } from "vue";
import { SortingSettings } from "../../library/types/sorting/sorting";
import { RoomObject } from "../../library/types/room/rooms";
import { RoomsToPost } from "../../services/api/roomsApi";
import { ProjectSettingsAdress } from "../../services/api/addressApi";
import { ProductHeaderSale } from "../../library/types/product/productHeader";
import { useIndexStore } from "@/store";
import { getAddresses } from "@/library/api/address";
import { getProductItemsInRoom } from "@/library/api/products";
import { getProjectRooms, updateRooms } from "@/library/api/rooms";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { getRoomTableColumns } from "@/library/helpers/structureTableColumns";
import TableFooter from "../table/tableFooter/TableFooter.vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/library/types/actions/actions";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TableFooterButton } from "@/library/types/tables/footer";
import { sortedRoomRows } from "@/library/helpers/sortTableRows";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import { bolderFont } from "@/library/helpers/typography";
import ProductsInDeletedRoomModal from "../modal/ProductsInDeletedRoomModal.vue";
import { CustomSetLocaleMessage } from "@/library/types/locales/i18nUtilities";

const indexStore = useIndexStore();
const { t, messages, locale, setLocaleMessage } = useI18n();
const isLoading = ref(false);
const rooms: Ref<RoomObject[]> = ref([]);
const roomsToPost: Ref<RoomsToPost> = ref({
  update: [],
  delete: [],
  insert: []
});
const sortingSettings: Ref<SortingSettings> = ref({ col: "rum", asc: false });
const productsInDeletedRoom: Ref<ProductHeaderSale> = ref({});
const projectAddresses: Ref<ProjectSettingsAdress[]> = ref([]);
const roomToDelete: Ref<RoomObject | null> = ref(null);
const deletedIndex: Ref<null | number> = ref(null);
const verifyDelete = ref(false);
const showProductsInDeletedRoomModal = ref(false);
const roomForm: Ref<HTMLFormElement | null> = ref(null);

onMounted(() => {
  getRooms();
  getProjectAddress();
});

const handleSelectAction = (action: Actions) => {
  if (action === "addRoom") {
    roomsToPost.value.insert.push({
      van: "",
      hus: "",
      rumskod: "",
      rumsnamn: "",
      enhet: "",
      rum: "",
      new_room: "0"
    });

    return;
  }
  postRooms();
};

const removeRoom = (room: RoomObject) => {
  if (!room) return;

  const indexUpdate = findIDIndex(roomsToPost.value.update, room.rum);
  const index = findIDIndex(rooms.value, room.rum);

  if (indexUpdate !== -1) {
    roomsToPost.value.update.splice(indexUpdate, 1);
  }
  if (index !== -1) {
    rooms.value.splice(index, 1);
  }
  roomsToPost.value.delete.push(room);
  postRooms();
};

const update = (room: RoomObject) => {
  if (room.rum) {
    const indexUpdate = findIDIndex(roomsToPost.value.update, room.rum);
    if (indexUpdate !== -1) {
      roomsToPost.value.update[indexUpdate] = room;
    } else {
      roomsToPost.value.update.push(room);
    }
  }
};

const findIDIndex = (rooms: RoomObject[], roomId: RoomObject["rum"]) => {
  return rooms.findIndex(room => room.rum === roomId);
};

const verifyRoomDelete = async (room: RoomObject, index: number) => {
  roomToDelete.value = room;
  deletedIndex.value = index;

  const result = await getProductItemsInRoom(room.rum);

  if (!result.success) return;

  productsInDeletedRoom.value = result.result;
  if (Object.keys(productsInDeletedRoom.value).length) {
    return (showProductsInDeletedRoomModal.value = true);
  }
  return (verifyDelete.value = true);
};

const getRooms = async () => {
  isLoading.value = true;
  roomToDelete.value = null;

  const result = await getProjectRooms();
  isLoading.value = false;
  if (!result.success) return;

  rooms.value = result.result;
};

const getProjectAddress = async () => {
  const result = await getAddresses();

  if (!result.success) return;

  projectAddresses.value = result.result;
};

const postRooms = async () => {
  if (roomForm.value && !roomForm.value.reportValidity()) {
    return;
  }

  isLoading.value = true;
  const result = await updateRooms(roomsToPost.value);
  isLoading.value = false;

  if (!result.success) return;

  indexStore.getProjectDetails(
    indexStore.currentProjectNumber,
    setLocaleMessage as CustomSetLocaleMessage,
    messages.value[locale.value]
  );
  roomsToPost.value = { update: [], delete: [], insert: [] };
  getRooms();
};
</script>

<style scoped>
.items-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.items-image-wrapper {
  height: 2.4rem;
  width: 2.4rem;
}
</style>
