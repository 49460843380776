<template>
  <GreenifiedModal
    @close="emit('close')"
    :size="'medium'"
    :title="t('title.combineProducts')"
    :isLoading="isLoading"
  >
    <template #modalHeaderContent>
      <div class="row mt-4">
        <div class="col">
          <p class="subheading-small">
            {{
              currentView === 0
                ? `1/2 ${t("paragraph.selectMainProduct")}`
                : `2/2 ${t("paragraph.selectImagesForMainProduct")}`
            }}
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-7">
          <p class="text-small">
            {{
              currentView === 0
                ? t("paragraph.combineProductInformation")
                : t("paragraph.selectImageInformation")
            }}
          </p>
        </div>
      </div>
    </template>
    <template #container>
      <div class="row row-gap-3 mt-3" v-if="currentView === 0">
        <template
          v-for="(productHeader, index) in selectedProductHeaders"
          :key="index"
        >
          <ProductCard
            @click="mainProduct = productHeader"
            :productImage="productHeader.items[0].images?.bild_1.url"
            :productName="productHeader.group.benamn"
            :quantity="productHeader.items.length"
            :description="productHeader.group.besk"
            :supplier="t(`levkod.${productHeader.group.levkod}`)"
            :category="
              productHeader.group.kategori_id.substring(0, 2) === 'ex'
                ? t('label.unkownSupplier')
                : t(`kategori_id.${productHeader.group.kategori_id}`)
            "
            :active="mainProduct === productHeader"
          />
        </template>
      </div>
      <div class="row mt-3" v-if="currentView === 1">
        <div class="col-12 d-flex row-gap-5 gap-5 flex-wrap">
          <template
            v-for="(image, imageIndex) in productHeaderImages"
            :key="imageIndex"
          >
            <div
              class="product-image-wrapper"
              @click="handleSelectImage(image)"
            >
              <img
                :src="image?.url"
                alt="product image"
                class="combine-product-image"
              />
              <div class="checkbox-wrapper">
                <GreenifiedCheckbox
                  size="large"
                  :isChecked="selectedImages.includes(image)"
                  :disabled="selectedImages.length === 4"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row p-0 justify-content-end align-items-end h-100">
        <div class="col-6 d-flex justify-content-end">
          <GreenifiedButton
            v-if="currentView === 0"
            :text="t('button.selectMainProduct')"
            btnStyle="green"
            size="thin"
            @click="getProductInfo()"
            :disabled="!mainProduct"
          />

          <GreenifiedButton
            v-else
            :text="t('button.finishCombining')"
            btnStyle="green"
            size="thin"
            @click="combineProducts()"
            :disabled="selectedImages.length < 2"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-if="combineSuccess"
    type="response"
    :title="t('title.productsHaveBeenCombined')"
    :text="t('paragraph.combineProductsText')"
    @close="emit('refresh')"
  />
</template>

<script setup lang="ts">
import {
  defineEmits,
  ref,
  defineProps,
  withDefaults,
  onMounted,
  Ref
} from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import {
  ProductHeaderSale,
  ProductStatuses
} from "@/library/types/product/productHeader";
import ProductCard from "../common/ProductCard.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import {
  ProductImage,
  ProductImages
} from "@/library/types/product/productImage";
import GreenifiedCheckbox from "../common/GreenifiedCheckbox.vue";
import { useI18n } from "vue-i18n";
import { getProductInformationApi } from "@/library/api/products";
import { ProductInformation } from "@/library/types/product/productGroup";
import { updateProductsWithSameGreenifiedHash } from "@/library/api/greenifiedProducts";

type CombineProductsModalProps = {
  productHeaders: ProductHeaderSale;
  selectedProductGroups: string[];
  productItems: string[];
};

const combineProductsModalProps = withDefaults(
  defineProps<CombineProductsModalProps>(),
  {
    productHeaders: () => ({}),
    selectedProductGroups: () => [],
    productItems: () => []
  }
);

const { t } = useI18n();
const emit = defineEmits(["close", "refresh"]);
const currentView = ref(0);
const isLoading = ref(true);
const selectedProductHeaders: Ref<ProductHeaderSale> = ref({});
const mainProduct: Ref<ProductHeaderSale["key"] | null> = ref(null);
const selectedImages: Ref<ProductImage[]> = ref([]);
const productHeaderImages: Ref<ProductImage[]> = ref([]);
const allProductIds: Ref<string[]> = ref([]);
const allProductHeaderHash: Ref<string[]> = ref([]);
const combineSuccess = ref(false);
const productStatus: Ref<ProductStatuses | null> = ref(null);
const productInformation: Ref<ProductInformation | null> = ref(null);

const getProductInfo = async () => {
  if (!mainProduct.value) return;
  isLoading.value = true;

  const result = await getProductInformationApi(mainProduct.value.items[0].id);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  productInformation.value = result.result;
  currentView.value = 1;
  isLoading.value = false;
};

const handleSelectImage = (selectedImage: ProductImage) => {
  const existingImageIndex = selectedImages.value.findIndex(
    image => image.url === selectedImage.url
  );

  if (existingImageIndex !== -1) {
    selectedImages.value.splice(existingImageIndex, 1);
    return;
  }
  if (selectedImages.value.length === 4) return;

  selectedImages.value.push(selectedImage);
  return;
};

const combineProducts = async () => {
  if (!productStatus.value) return;

  const currImageKeys = Object.keys(selectedImages.value);
  if (currImageKeys.length < 2 || currImageKeys.length > 4) return;

  if (!productInformation.value || !allProductIds.value.length) return;
  isLoading.value = true;

  const currentProductInformation = { ...productInformation.value };

  currentProductInformation.images = {
    bild_1: { url: "", hash: "" },
    bild_2: { url: "", hash: "" }
  };

  selectedImages.value.forEach((image, index) => {
    currentProductInformation.images[
      `bild_${index + 1}` as keyof ProductImages
    ] = image;
  });

  const combineResult = await updateProductsWithSameGreenifiedHash(
    allProductHeaderHash.value,
    currentProductInformation.green_struc,
    currentProductInformation.supplier_code,
    currentProductInformation.category,
    productStatus.value,
    allProductIds.value
  );

  if (!combineResult.success) {
    isLoading.value = false;
    return;
  }

  combineSuccess.value = true;

  isLoading.value = false;
};

onMounted(() => {
  for (const productHeaderKey in combineProductsModalProps.productHeaders) {
    const currProductHeader =
      combineProductsModalProps.productHeaders[productHeaderKey];

    if (
      combineProductsModalProps.selectedProductGroups.includes(
        currProductHeader.group.green_hash
      ) &&
      combineProductsModalProps.productItems.some(productId =>
        currProductHeader.items.some(item => item.id === productId)
      )
    ) {
      selectedProductHeaders.value[productHeaderKey] = currProductHeader;
      allProductHeaderHash.value.push(currProductHeader.group.green_hash);
      productStatus.value = currProductHeader.items[0].status;
      const currentProductIds = currProductHeader.items.map(item => item.id);
      allProductIds.value.push(...currentProductIds);
      for (const imageKey in currProductHeader.items[0].images) {
        const currentImage =
          currProductHeader.items[0].images[imageKey as keyof ProductImages];
        if (
          currentImage &&
          !productHeaderImages.value.some(
            productImage => productImage.hash === currentImage.hash
          )
        )
          productHeaderImages.value.push(currentImage);
      }
    }
  }
  isLoading.value = false;
});
</script>

<style scoped>
.product-card-container {
  max-height: 100%;
  overflow: scroll;
}

.product-image-wrapper {
  position: relative;
  height: 121px;
  width: 121px;
  cursor: pointer;
}

.checkbox-wrapper {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.combine-product-image {
  position: absolute; /* Ensure the image fills the wrapper completely */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
