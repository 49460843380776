import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/copy.svg'


const _hoisted_1 = { class: "form-label-paragraph mb-3" }
const _hoisted_2 = {
  style: {"position":"relative"},
  class: "mb-3"
}
const _hoisted_3 = ["value"]

import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import { computed } from "vue";
import { toast } from "vue3-toastify";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'UrlModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const router = useRouter();
const store = useIndexStore();
const emit = __emit;

const copy = (url: string) => {
  navigator.clipboard.writeText(url);
  toast(t("label.copied"), {
    autoClose: 2000,
    theme: "light",
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    closeButton: false
  });
};

const getShareUrl = computed(() => {
  const routeName = "guestInternalSell";
  const resolvedRoute = router.resolve({
    name: routeName,
    params: { token: store.currentProject.guest_token }
  });
  const routePath = resolvedRoute.href;
  return window.location.origin + routePath;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    title: _unref(t)('title.shareURL'),
    type: "response",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
  }, {
    container: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("msg.shareURL")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          disabled: "",
          type: "text",
          class: "w-100 mt-2 pe-4",
          value: getShareUrl.value
        }, null, 8, _hoisted_3),
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _imports_0,
            height: "20",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (copy(getShareUrl.value))),
            style: {"cursor":"pointer","position":"absolute","right":"6px","top":"calc(0.5rem + 7px)"}
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})