<template>
  <div
    class="d-flex align-items-center"
    v-if="sortingColumn !== 'soldProducts'"
    @click="isSortable && handleClick(sortingColumn)"
    :class="isSortable ? 'cursor-pointer' : 'cursor-default'"
  >
    <p
      class="me-1 text-tiny bolder"
      :class="[
        sortingOptions.col === headerText && 'fw-bold',
        isSortable ? 'cursor-pointer' : 'cursor-default',
      ]"
    >
      {{ t(headerText) }}
    </p>

    <RotateArrow v-if="isSortable" size="tiny" :rotate="isAscending" />
  </div>

  <div class="d-flex justify-content-end w-100 right-margin pe-1" v-else>
    <p class="text-tiny bolder pe-2">
      {{ t(headerText) }} ({{ soldProducts }}/{{ productsForSale }})
    </p>
    <GreenifiedCheckbox
      v-model="currentIsChecked"
      :trueValue="true"
      :falseValue="false"
      @update:modelValue="emit('update:isChecked', currentIsChecked)"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults, defineEmits, watch } from "vue";
import RotateArrow from "../../../components/common/RotateArrow.vue";
import { useI18n } from "vue-i18n";
import { SortingSettings } from "@/library/types/sorting/sorting";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";

type TableHeaderProps = {
  headerText: string | number;
  sortingOptions: SortingSettings;
  isSortable: boolean;
  sortingColumn: string | number;
  soldProducts?: number;
  productsForSale?: number;
  isChecked?: boolean;
};

const tableHeaderProps = withDefaults(defineProps<TableHeaderProps>(), {
  headerText: "",
  sortingOptions: () => ({
    asc: false,
    col: "",
  }),
  isSortable: true,
  sortingColumn: "",
  soldProducts: 0,
  productsForSale: 0,
  isChecked: false,
});

const emit = defineEmits(["update:sortingOptions", "update:isChecked"]);

const { t } = useI18n();
const currentIsChecked = ref(tableHeaderProps.isChecked);
const isAscending = ref(false);

const handleClick = (sortingColumn: string | number) => {
  isAscending.value = !isAscending.value;

  const currentSortingOptions: SortingSettings = {
    asc: isAscending.value,
    col: sortingColumn,
  };

  emit("update:sortingOptions", currentSortingOptions);
};

watch(
  () => tableHeaderProps.isChecked,
  () => {
    currentIsChecked.value = tableHeaderProps.isChecked;
  },
);
</script>

<style scoped>
.sorting-arrow {
  width: 12px;
  height: 12px;
  transition: 0.2s;
}

.rotate {
  transform: rotate(180deg);
  transition: 0.2s;
}
</style>
