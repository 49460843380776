<template>
  <GreenifiedModal
    @close="emit('close')"
    :title="t('title.inviteUser')"
    type="x-small"
    :isLoading="isLoading"
  >
    <template #container>
      <form @submit.prevent="inviteUser()" ref="inviteForm" id="invite-form">
        <div class="row mb-3 mt-2">
          <div class="col-4 d-flex align-items-center">
            <label class="form-label">{{ t("label.emailAddress") }} *</label>
          </div>
          <div class="col-8">
            <InputField
              type="email"
              required
              :placeHolder="t('placeholders.emailAdress')"
              v-model:field-value="invitedUser.email"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4">
            <label class="form-label">{{ t("label.role") }}</label>
          </div>
          <div class="col-8">
            <CustomSelect
              required
              v-model="invitedUser.role"
              :reducedKey="'value'"
              :options="roleOptions"
              label="label"
              :clearable="false"
            />
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="col-4">
        <GreenifiedButton :text="t('button.cancel')" @click="emit('close')" />
      </div>
      <div class="col-4">
        <GreenifiedButton
          :text="t('button.send')"
          :disabled="!invitedUser.email || !invitedUser.role"
          :type="'submit'"
          form="invite-form"
          btn-style="black"
        />
      </div>
    </template>
  </GreenifiedModal>
</template>

<script lang="ts" setup>
import { withDefaults, defineProps, defineEmits, ref, Ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { postUserInvite } from "@/library/api/user";
import CustomSelect from "../common/CustomSelect.vue";
import InputField from "../common/InputField.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { SelectableAction } from "@/library/helpers/structureTableColumns";

export type UserRoleOption = {
  label: string;
  value: string;
};

type InviteUserModalProps = {
  roleOptions: SelectableAction[];
};

withDefaults(defineProps<InviteUserModalProps>(), {
  roleOptions: () => []
});

const { t } = useI18n();
const emit = defineEmits(["close", "refresh"]);
const isLoading = ref(false);
const invitedUser: Ref<{ email: string | null; role: string }> = ref({
  email: null,
  role: "S"
});
const inviteForm: Ref<HTMLFormElement | null> = ref(null);

const inviteUser = async () => {
  if (inviteForm.value && !inviteForm.value.reportValidity()) {
    return;
  }
  isLoading.value = true;
  if (invitedUser.value.email) {
    const result = await postUserInvite(
      invitedUser.value.email,
      invitedUser.value.role
    );

    isLoading.value = false;

    if (!result.success) return;

    emit("refresh");
  }
};
</script>
