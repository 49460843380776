import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "product-info-container" }
const _hoisted_3 = { class: "subheading-small mt-3 cursor-pointer" }
const _hoisted_4 = { class: "text-small mt-2 cursor-pointer" }
const _hoisted_5 = { class: "d-flex mt-2 cursor-pointer" }
const _hoisted_6 = { class: "subheading-small cursor-pointer" }
const _hoisted_7 = { class: "text-small ms-2 cursor-pointer" }
const _hoisted_8 = { class: "d-flex mt-2 cursor-pointer" }
const _hoisted_9 = { class: "subheading-small cursor-pointer" }
const _hoisted_10 = { class: "text-small ms-2 cursor-pointer" }
const _hoisted_11 = { class: "d-flex mt-2 description-container" }
const _hoisted_12 = { class: "subheading-small cursor-pointer" }
const _hoisted_13 = { class: "text-small ms-2 cursor-pointer" }


import { useI18n } from "vue-i18n";

type ProductCardProps = {
  productImage: string;
  productName: string;
  supplier: string;
  quantity: number;
  description: string;
  active: boolean;
  category: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductCard',
  props: {
    productImage: { default: "" },
    productName: { default: "" },
    supplier: { default: "" },
    quantity: { default: 0 },
    description: { default: "" },
    active: { type: Boolean, default: false },
    category: { default: "" }
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["col-4 card-wrapper", _ctx.active && 'active-card-wrapper'])
  }, [
    _createElementVNode("img", {
      src: _ctx.productImage,
      alt: "product image",
      class: "product-card-image"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.productName), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.supplier), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)("label.kategori_id")) + ": ", 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.category), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)("label.quantity")) + ": ", 1),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.quantity) + " " + _toDisplayString(_unref(t)("label.pcs")), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", _hoisted_12, [
          _createTextVNode(_toDisplayString(_unref(t)("placeholders.reconditionInfo")) + ": ", 1),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.description), 1)
        ])
      ])
    ])
  ], 2))
}
}

})