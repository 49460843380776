<template>
  <GreenifiedModal
    :title="t('title.packingList')"
    type="response"
    @close="emit('close')"
    :is-loading="isLoading"
  >
    <template #container>
      <p class="text-small mb-4">
        {{ t("paragraph.generatePackinglistInfo") }}
      </p>
      <div class="mb-5">
        <CustomSelect
          :options="ordersWithOrderNumber"
          label="order_number"
          :placeholder="
            ordersWithOrderNumber.length
              ? t('label.orderNumber')
              : t('placeholders.noConfirmedOrdersExist')
          "
          reduced-key="order_number"
          v-model="orderNumber"
          :clearable="true"
        />
      </div>
    </template>
    <template #footer>
      <div class="row p-0">
        <div class="col-7">
          <GreenifiedButton
            v-if="noConfirmedOrders"
            btnStyle="green"
            :text="t('button.noApprovedOrders')"
            :disabled="true"
          />
          <GreenifiedButton
            v-else
            btnStyle="green"
            :text="!pdfIsLoading ? t('button.download') : t('button.loading')"
            @click="fetchPackingList()"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script lang="ts" setup>
import GreenifiedModal from "./GreenifiedModal.vue";
import {
  defineEmits,
  onMounted,
  Ref,
  ref,
  withDefaults,
  defineProps
} from "vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import { getInternalOrders } from "@/library/api/sales";
import CustomSelect from "../common/CustomSelect.vue";
import { getPackingList } from "@/library/api/media";
import { InternalSaleOrder, OrderStatuses } from "@/services/api/salesApi";

type PackingListModalProps = {
  orderStatus: OrderStatuses;
};
const packingListModalProps = withDefaults(
  defineProps<PackingListModalProps>(),
  {
    orderStatus: "A"
  }
);

const { t } = useI18n();
const emit = defineEmits(["close"]);
const orderNumber: Ref<string | null> = ref(null);
const pdfIsLoading = ref(false);
const isLoading = ref(false);
const ordersWithOrderNumber: Ref<InternalSaleOrder[]> = ref([]);
const noConfirmedOrders = ref(false);

const fetchInternalOrders = async () => {
  isLoading.value = true;

  const result = await getInternalOrders([packingListModalProps.orderStatus]);

  if (!result.success) {
    isLoading.value = false;
    return;
  }
  if (!result.result.length) {
    isLoading.value = false;
    noConfirmedOrders.value = true;
    return;
  }
  const seenOrderNumbers = new Set<number | string>();

  ordersWithOrderNumber.value = result.result.filter(order => {
    if (order.order_number !== null) {
      if (!seenOrderNumbers.has(order.order_number)) {
        seenOrderNumbers.add(order.order_number);
        return true;
      }
    }
    return false;
  });

  if (!ordersWithOrderNumber.value.length) {
    noConfirmedOrders.value = true;
  }

  isLoading.value = false;
};

const fetchPackingList = async () => {
  pdfIsLoading.value = true;
  const result = await getPackingList(
    orderNumber.value,
    packingListModalProps.orderStatus
  );
  if (!result.success) {
    pdfIsLoading.value = false;
    return;
  }

  let blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);
  window.open(url);

  pdfIsLoading.value = false;
};

onMounted(() => {
  fetchInternalOrders();
});
</script>
