<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <div class="d-flex align-items-center">
        <InputField
          v-model:field-value="currentIntervalSettings.from"
          :disabled="maxValue === 1"
          rounded
          type="number"
          :min-value="1"
          :max-value="maxValue"
          @update:field-value="
            range[0] = currentIntervalSettings.from;
            emit('update:intervalsSettings', currentIntervalSettings);
          "
        />
        <p class="fw-bolder ms-1 me-1">-</p>
        <InputField
          v-model:field-value="currentIntervalSettings.to"
          :disabled="maxValue === 1"
          rounded
          type="number"
          :min-value="1"
          :max-value="maxValue"
          @update:field-value="
            range[1] = currentIntervalSettings.to;
            emit('update:intervalsSettings', currentIntervalSettings);
          "
        />
        <p class="text-tiny ms-2 emphasis">{{ t("label.pcs") }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <VRangeSlider
        v-model="range"
        @update:modelValue="
          sliderValueIsUpdated = !sliderValueIsUpdated;
          currentIntervalSettings.from = range[0];
          currentIntervalSettings.to = range[1];
          emit('update:intervalsSettings', currentIntervalSettings);
        "
        :max="maxValue"
        :track-fill-color="'black'"
        :track-size="'1px'"
        :thumb-size="'10px'"
        :disabled="maxValue === 1"
        :min="1"
        :step="1"
        strict
        class="align-center"
        hide-details
      >
        <template v-slot:prepend></template>
        <template v-slot:append></template>
      </VRangeSlider>
    </div>
  </div>
</template>

<script setup lang="ts">
import "vuetify/styles";
import { VRangeSlider } from "vuetify/lib/components/index.mjs";
import {
  Ref,
  ref,
  withDefaults,
  defineProps,
  defineEmits,
  defineExpose
} from "vue";
import { FilterInterval } from "@/library/types/filter/filter";
import InputField from "./InputField.vue";
import { useI18n } from "vue-i18n";
type DoubleRangeSliderProps = {
  maxValue: number;
  intervalsSettings: FilterInterval;
};

const doubleRangeSliderProps = withDefaults(
  defineProps<DoubleRangeSliderProps>(),
  {
    maxValue: 999,
    intervalsSettings: () => ({
      to: 999,
      from: 1
    })
  }
);
const { t } = useI18n();
const emit = defineEmits(["update:intervalsSettings"]);
const currentIntervalSettings = ref(doubleRangeSliderProps.intervalsSettings);
const range: Ref<number[]> = ref([1, doubleRangeSliderProps.maxValue]);

const sliderValueIsUpdated = ref(false);

const resetIntervalRange = () => {
  range.value = [1, doubleRangeSliderProps.maxValue];
};
defineExpose({ resetIntervalRange });
</script>

<style scoped>
@import "../../assets/scss/slider.scss";
</style>
