<template>
  <GreenifiedButton
    v-if="radioField"
    inputType="radio"
    :inputName="radioField.key"
    @click="inputRadio(String(optionKey))"
    :text="
      localRadioFieldValues && String(localRadioFieldValues).includes(option)
        ? 'checked'
        : ''
    "
    :inputValue="option"
    :inputRequired="radioField.required === 0 ? false : true"
    :inputId="radioField.key + '-' + optionKey"
  />
</template>

<script lang="ts" setup>
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { RadioFieldType } from "@/library/types/structure/subfieldTypes";
import { ref, withDefaults, defineProps, defineEmits } from "vue";

type RadioFieldProps = {
  option: string;
  optionKey: string | number;
  presetRadioFieldValues: FormFields["key"];
  radioField: RadioFieldType | null;
};

const radioFieldProps = withDefaults(defineProps<RadioFieldProps>(), {
  option: "",
  optionKey: "",
  presetRadioFieldValues: () => ({}),
  radioField: null,
});

const emit = defineEmits(["update:presetRadioFieldValues"]);

const localRadioFieldValues = ref(radioFieldProps.presetRadioFieldValues);

const inputRadio = (key: string) => {
  localRadioFieldValues.value = key;
  emit("update:presetRadioFieldValues", localRadioFieldValues);
};
</script>
