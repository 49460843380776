import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid login-window gr-color p-5 mt-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "subheading-large" }
const _hoisted_4 = { class: "row mt-4" }
const _hoisted_5 = { class: "row mt-4" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "mt-3 row" }
const _hoisted_8 = { class: "col-4" }

import axios from "axios";
import ResetPasswordModal from "@/components/modal/ResetPasswordModal.vue";
import { getCurrentInstance, ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import InputField from "@/components/common/InputField.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const authStore = useAuthStore();
const instance = getCurrentInstance();
const router = useRouter();
const { t } = useI18n();

const loginFields = ref({
  name: "",
  password: "",
  rememberMe: false
});
const processing = ref(false);
const showError = ref(false);
const verifyReset = ref(false);

const handleLoginFormSubmit = async () => {
  processing.value = true;

  await axios.get("/sanctum/csrf-cookie").catch(({ response: { data } }) => {
    if (instance) {
      instance.appContext.config.globalProperties.$handleAxiosError(
        data.message,
        "LoginView - login"
      );
    }
  });

  await axios
    .post("/api/login", loginFields.value)
    .then(async ({ data }) => {
      localStorage.setItem("token", data.access_token);
      await authStore.login();
      if (!authStore.isAuthenticated) {
        showError.value = true;
        processing.value = false;
        return;
      }
      router.push({ name: "projectOverview" });
    })
    .catch(() => {
      showError.value = true;
      processing.value = false;
      return;
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)("title.signIn")), 1)
      ]),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleLoginFormSubmit, ["prevent"]),
        id: "loginForm"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(InputField, {
            type: "text",
            fieldValue: loginFields.value.name,
            "onUpdate:fieldValue": _cache[0] || (_cache[0] = ($event: any) => ((loginFields.value.name) = $event)),
            name: "email",
            required: "",
            placeHolder: _unref(t)('placeholders.emailAdress')
          }, null, 8, ["fieldValue", "placeHolder"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(InputField, {
            type: "password",
            fieldValue: loginFields.value.password,
            "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((loginFields.value.password) = $event)),
            name: "password",
            required: "",
            placeHolder: _unref(t)('placeholders.password'),
            passwordHasToggle: true
          }, null, 8, ["fieldValue", "placeHolder"])
        ])
      ], 32),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", {
          class: "text-tiny cursor-pointer mt-3",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (verifyReset.value = true))
        }, _toDisplayString(_unref(t)("label.haveYouForgottenThePassword")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(GreenifiedButton, {
            type: "submit",
            form: "loginForm",
            text: processing.value ? _unref(t)('button.pleaseWait') : _unref(t)('button.signIn')
          }, null, 8, ["text"])
        ])
      ])
    ]),
    (verifyReset.value)
      ? (_openBlock(), _createBlock(ResetPasswordModal, {
          key: 0,
          onAccept: _cache[3] || (_cache[3] = ($event: any) => (verifyReset.value = false)),
          onCancel: _cache[4] || (_cache[4] = ($event: any) => (verifyReset.value = false)),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (verifyReset.value = false))
        }))
      : _createCommentVNode("", true),
    (showError.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (showError.value = false)),
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.loginError'),
          text: _unref(t)('msg.loginError')
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})