<template>
  <MainFilter
    :filterTitle="`${t('title.recycleOverview')} - ${quantities} ${t(
      'title.products'
    )}`"
    :filterText="t('paragraph.recycleOverview')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="filter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :productStatus="presetFilter.status"
    :pdfRoute="'recycle-overview'"
    :hasProducts="quantities > 0"
  />
  <ProductTable
    :actionStatuses="[]"
    :hideCheckboxes="true"
    :noRowsText="t('label.noRowsPositive')"
    v-model:filter="filter"
    :sortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :presetFilter="presetFilter"
  />
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, Ref, ref, defineProps, withDefaults } from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";
import ProductTable from "@/components/table/ProductTable.vue";

type ProjectRecycleOverviewProps = {
  filterQuery?: string;
};

withDefaults(defineProps<ProjectRecycleOverviewProps>(), {
  filterQuery: ""
});

const { t } = useI18n();

const quantities = computed(() => {
  return getQuantitiesByProductStatus("C");
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});

const presetFilter: PresetFilter = {
  filter: [],
  group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
  status: ["C"]
};
</script>
