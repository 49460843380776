import userApi from "@/services/api/userApi";
import { AxiosResponse } from "axios";
import { useIndexStore } from "@/store";
import {
  CustomError,
  handleApiRequest,
  Result,
} from "../helpers/handleApiRequests";

export const postPasswordReset = async (
  email: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    userApi.postPasswordReset(email),
    "user - postPasswordReset",
  );
};

export const postTokenReset = async (
  token: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    userApi.postTokenReset(token),
    "user - postTokenReset",
  );
};

export const postPassword = async (
  token: string,
  password: string,
  name?: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    userApi.postPassword(token, password, name),
    "user - postPassword",
  );
};

export const postTokenVerification = async (
  token: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    userApi.postTokenVerification(token),
    "user - postTokenVerification",
  );
};

export const updateUserRole = async (
  user: string,
  newRole: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    userApi.updateUserRole(indexStore.currentProjectNumber, user, newRole),
    "user - updateUserRole",
  );
};

export const postUserInvite = async (
  email: string,
  role: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    userApi.postUserInvite(indexStore.currentProjectNumber, email, role),
    "user - postUserInvite",
  );
};

export const deleteUser = async (
  userToRemove: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    userApi.deleteUser(indexStore.currentProjectNumber, userToRemove),
    "user - deleteUser",
  );
};
