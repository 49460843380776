<template>
  <GreenifiedModal
    type="verification"
    :title="
      changeLocation ? t('button.changeLocation') : t('label.moveProduct')
    "
    @close="emit('close')"
  >
    <template #container>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col">
            {{
              changeLocation
                ? t("paragraph.changeLocationInfo")
                : t("paragraph.moveProductForm")
            }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row mb-4 p-0">
        <div class="col-sm-7">
          <SelectRoom
            v-model="selectedRoom"
            :isReduced="false"
            :append="true"
          />
        </div>
        <div class="col-sm-5">
          <GreenifiedButton
            btnStyle="black"
            :disabled="selectedRoom === null"
            @click="changeLocation ? changeProductLocation() : moveProducts()"
            :text="t('placeholders.selectRoom')"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-if="showSuccess && selectedRoom"
    type="response"
    :closable="false"
    :title="t('responseTitle.productMoved')"
    :text="
      changeLocation
        ? t('paragraph.productHasBeenMoved', [
            bolderFont(selectedRoom.rumsnamn)
          ])
        : t('paragraph.productWillBeMovedTo', [
            bolderFont(selectedRoom.rumsnamn)
          ])
    "
    @close="
      emit('moved');
      emit('close');
    "
  />
</template>

<script setup lang="ts">
import { bolderFont } from "../../library/helpers/typography";
import SelectRoom from "../SelectRoom.vue";
import { ref, withDefaults, defineProps, defineEmits } from "vue";
import { RoomObject } from "@/library/types/room/rooms";
import { ProductStatuses } from "@/library/types/product/productHeader";
import {
  postNewProductLocation,
  postNewProductRoom
} from "@/library/api/moveProducts";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";

type MoveProductsModalPropType = {
  currentRoom?: RoomObject | null;
  productItemIds: string[];
  changeLocation: boolean;
  statuses: ProductStatuses[];
};

const moveProductsModalProps = withDefaults(
  defineProps<MoveProductsModalPropType>(),
  {
    currentRoom: null,
    productItemIds: () => [],
    changeLocation: false,
    statuses: () => []
  }
);
const { t } = useI18n();

const selectedRoom = ref(moveProductsModalProps.currentRoom);
const showSuccess = ref(false);
const isLoading = ref(false);

const emit = defineEmits(["close", "moved"]);

const moveProducts = async () => {
  if (!selectedRoom.value) return;

  isLoading.value = true;
  const result = await postNewProductRoom(
    moveProductsModalProps.productItemIds,
    selectedRoom.value.rum,
    moveProductsModalProps.statuses
  );
  isLoading.value = false;

  if (!result.success) return;

  showSuccess.value = true;
};
const changeProductLocation = async () => {
  if (!selectedRoom.value) return;

  isLoading.value = true;

  const result = await postNewProductLocation(
    moveProductsModalProps.productItemIds,
    selectedRoom.value.rum
  );
  isLoading.value = false;

  if (!result.success) return;

  showSuccess.value = true;
};
</script>
