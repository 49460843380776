import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row align-items-center mt-2"
}
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "form-label" }
const _hoisted_4 = { class: "col-8" }
const _hoisted_5 = { class: "form-label" }
const _hoisted_6 = { class: "row p-0" }
const _hoisted_7 = { class: "col-4 d-flex align-items-center" }

import GreenifiedForm from "@/components/form/GreenifiedForm.vue";
import debounce from "debounce";
import {
  ref,
  Ref,
  computed,
  onMounted
} from "vue";
import { AddressInformation, NewAddress } from "../../library/types/room/rooms";
import { bolderFont } from "../../library/helpers/typography";
import { ProductInformation } from "../../library/types/product/productGroup";
import { keysExistInObject } from "../../library/helpers/handleObjects";
import { useIndexStore } from "@/store";
import { getAddressesFromIds } from "@/library/api/address";
import { completeGreenifiedStructure } from "@/library/api/greenifiedProducts";
import { getCalculatedPrice, getPriceEvaluation } from "@/library/api/helpers";
import {
  getProductInformationApi,
  getProductItemsApi
} from "@/library/api/products";
import { postGreenifiedSale } from "@/library/api/sales";
import { PriceSuggestion } from "@/services/api/helpersApi";
import { FormFields } from "@/library/types/structure/formStructure";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
import { useI18n } from "vue-i18n";
import { convertCurrencyCodeToSymbol } from "@/library/helpers/convertCurrencyCodeToSymbol";

type GreenifiedSaleFormProps = {
  productIds: string[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedSaleForm',
  props: {
    productIds: { default: () => [] }
  },
  emits: ["refresh", "close"],
  setup(__props: any, { emit: __emit }) {

const greenifiedSaleFormProps = __props;

const { t, locale } = useI18n();
const indexStore = useIndexStore();
const isLoading = ref(false);
const loadingPost = ref(false);
const showValidation = ref(false);
const saleStructure: Ref<FormFields> = ref({});
const formIsSubmitted = ref(false);
const currentAddressInformation: Ref<AddressInformation["address"]> = ref({
  adress: "",
  postnummer: "",
  ort: "",
  land: ""
});
const productGroup: Ref<ProductInformation | null> = ref(null);
const uniqueRooms: Ref<string[]> = ref([]);
const addresses: Ref<AddressInformation[]> = ref([]);
const calculatedPrice = ref("");
const category = ref("");
const priceSuggestion: Ref<PriceSuggestion> = ref([]);
const publishSuccess = ref(false);
const test: Ref<HTMLElement | null> = ref(null);
const currentLocation: Ref<ProductInformation["location"]> = ref("");
const greenifiedForm = ref<InstanceType<typeof GreenifiedForm>>();
const loadingPrice = ref(false);

const emit = __emit;

const parseCalculatedPrice = computed(() => {
  const parsedPrice = parseInt(
    calculatedPrice.value?.substring(
      calculatedPrice.value?.indexOf(indexStore.projectDetails.land + ":") + 3
    )
  );

  if (!parsedPrice) return "0";
  return `${parsedPrice} ${convertCurrencyCodeToSymbol(
    indexStore.projectDetails.valuta,
    locale.value
  )}`;
});

const handleFormValidation = () => {
  formIsSubmitted.value = true;
  if (!greenifiedForm.value?.validateGreenifiedForm()) return false;

  completeGreenStruc();
};

const getResponseText = () => {
  const bolderString = bolderFont("button.onGreenified");
  return t("paragraph.publishedForSale", [bolderString]);
};

const updateAddressFields = (newAddress: NewAddress) => {
  currentAddressInformation.value[
    newAddress.key as keyof AddressInformation["address"]
  ] = newAddress.value;
  currentAddressInformation.value[
    newAddress.name as keyof AddressInformation["address"]
  ] = newAddress.value;
};

const completeGreenStruc = async () => {
  showValidation.value = false;
  loadingPost.value = true;

  saleStructure.value["pris"] =
    saleStructure.value[getProjectStructureFieldKey("pris")];
  saleStructure.value["saljs_styckvis_eller_i_grupp"] =
    saleStructure.value[
      getProjectStructureFieldKey("saljs_styckvis_eller_i_grupp")
    ];
  saleStructure.value["ange-antal"] =
    saleStructure.value[getProjectStructureFieldKey("ange-antal")];

  const result = await completeGreenifiedStructure(
    greenifiedSaleFormProps.productIds,
    "D",
    saleStructure.value
  );

  if (!result.success) return;

  postSellOnGreenified();
};

const postSellOnGreenified = async () => {
  showValidation.value = false;

  const result = await postGreenifiedSale(greenifiedSaleFormProps.productIds);

  loadingPost.value = false;
  if (!result.success) return;

  publishSuccess.value = true;
};

const getCalculatedPriceDebounce = debounce(() => {
  loadingPrice.value = true;
  getProductPrice();
}, 1000);

const emitRefresh = () => {
  emit("refresh");
  emit("close");
};

const getProductHeaders = async () => {
  isLoading.value = true;
  const result = await getProductItemsApi({
    filter: [
      {
        key: "id",
        operator: "in",
        value: greenifiedSaleFormProps.productIds
      }
    ],
    group: [],
    status: ["S"]
  });

  if (!result.success) {
    return (isLoading.value = false);
  }
  uniqueRooms.value = result.result.map(productItem => {
    return productItem.rum;
  });
  getProductInformation();
};

const getProductInformation = async () => {
  const result = await getProductInformationApi(
    greenifiedSaleFormProps.productIds[0]
  );

  if (!result.success) {
    return (isLoading.value = false);
  }

  productGroup.value = result.result;

  if (uniqueRooms.value.length > 1) {
    productGroup.value.location = "";
  }

  currentLocation.value = productGroup.value.location;
  saleStructure.value = result.result.sale_info;
  saleStructure.value[getProjectStructureFieldKey("produktionsar")] = String(
    productGroup.value.green_struc[getProjectStructureFieldKey("produktionsar")]
  );
  category.value = result.result.category;
  getProjectAddresses();
};
const getProjectAddresses = async () => {
  const result = await getAddressesFromIds(greenifiedSaleFormProps.productIds);

  if (!result.success) {
    return (isLoading.value = false);
  }

  addresses.value = result.result;

  if (
    addresses.value.length > 0 &&
    !keysExistInObject(["adress", "postnummer", "ort"], saleStructure.value)
  ) {
    const currentAddress = { ...addresses.value[0].address };
    Object.keys(currentAddress).forEach(addressKey => {
      const updatedFieldKey = getProjectStructureFieldKey(addressKey);
      const address =
        currentAddress[addressKey as keyof AddressInformation["address"]];

      saleStructure.value = Object.assign(saleStructure.value, {
        [updatedFieldKey]: address
      });
    });
  }
  getProductPrice();
};

const getProductPrice = async () => {
  const productPrice =
    saleStructure.value[getProjectStructureFieldKey("pris")] ?? 0;
  const productQuantity =
    saleStructure.value[
      getProjectStructureFieldKey("saljs_styckvis_eller_i_grupp")
    ] === "group"
      ? saleStructure.value[getProjectStructureFieldKey("ange-antal")] ?? 1
      : 1;

  const result = await getCalculatedPrice(
    Number(productPrice),
    category.value,
    Number(productQuantity)
  );
  loadingPrice.value = false;
  isLoading.value = false;
  if (!result.success) {
    return;
  }

  calculatedPrice.value = result.result.calcPrice;
};
const getEvaluation = async () => {
  const result = await getPriceEvaluation(greenifiedSaleFormProps.productIds);
  if (!result.success) return;

  priceSuggestion.value = result.result;
};

onMounted(() => {
  getProductHeaders();
  getEvaluation();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      title: _unref(t)('title.PostProductOnGreenified'),
      onClose: _cache[5] || (_cache[5] = ($event: any) => (emit('close'))),
      ref: "reconModal",
      isLoading: isLoading.value,
      autoHeight: true,
      type: 'auto'
    }, {
      container: _withCtx(() => [
        (_openBlock(), _createElementBlock("form", {
          id: "form-id",
          class: "h-100",
          key: String(isLoading.value)
        }, [
          _createElementVNode("div", {
            class: "action-form",
            ref_key: "test",
            ref: test
          }, [
            _createVNode(GreenifiedForm, {
              formValues: saleStructure.value,
              "onUpdate:formValues": _cache[0] || (_cache[0] = ($event: any) => ((saleStructure).value = $event)),
              priceSuggestion: priceSuggestion.value,
              ref_key: "greenifiedForm",
              ref: greenifiedForm,
              onPriceChange: _cache[1] || (_cache[1] = ($event: any) => (_unref(getCalculatedPriceDebounce)())),
              includedFields: [
              'adress',
              'postnummer',
              'ort',
              'land',
              'intro',
              'pris',
              'information',
              'lest_og_godkjent',
              'produktionsar',
              'nar_ar_produkten_tillganglig',
              'saljs_styckvis_eller_i_grupp',
              'ange-antal',
              'info'
            ],
              addressSuggestion: addresses.value,
              formIsSubmitted: formIsSubmitted.value,
              "onUpdate:formIsSubmitted": _cache[2] || (_cache[2] = ($event: any) => ((formIsSubmitted).value = $event)),
              formFields: _unref(indexStore).projectDetails.struc,
              onInput: _cache[3] || (_cache[3] = (address) => updateAddressFields(address))
            }, null, 8, ["formValues", "priceSuggestion", "addressSuggestion", "formIsSubmitted", "formFields"])
          ], 512)
        ])),
        (
          parseCalculatedPrice.value !== '0' &&
          saleStructure.value[_unref(getProjectStructureFieldKey)('pris')]
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)("label.salesPriceOnGreenified")), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(loadingPrice.value ? `${_unref(t)("button.loading")}...` : parseCalculatedPrice.value), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(GreenifiedButton, {
              form: 'form-id',
              size: "thin",
              type: 'submit',
              disabled: loadingPost.value,
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (handleFormValidation()), ["prevent"])),
              btnStyle: "green",
              text: 
              loadingPost.value
                ? _unref(t)('button.pleaseWait')
                : _unref(t)('button.postOnGreenified')
            
            }, null, 8, ["disabled", "text"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"]),
    (publishSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.productIsPublished'),
          text: getResponseText(),
          onClose: _cache[6] || (_cache[6] = ($event: any) => (emitRefresh()))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})