import { useIndexStore } from "@/store";
import { RoomObject } from "../types/room/rooms";
import moveProductsApi from "@/services/api/moveProductsApi";
import { AxiosResponse } from "axios";
import { ProductItem } from "../types/product/productItem";
import { ProductStatuses } from "../types/product/productHeader";
import {
  CustomError,
  handleApiRequest,
  Result
} from "../helpers/handleApiRequests";

export const postNewProductRoomOnDelete = async (
  roomToDelete: RoomObject["rum"],
  moveToRoom: RoomObject["rum"]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    moveProductsApi.postNewProductRoomOnDelete(
      indexStore.currentProjectNumber,
      roomToDelete,
      moveToRoom
    ),
    "moveProducts - postNewProductRoomOnDelete"
  );
};

export const postNewProductRoom = async (
  productIds: ProductItem["id"][],
  moveToRoom: RoomObject["rum"],
  productStatuses?: ProductStatuses[]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    moveProductsApi.postNewProductRoom(
      indexStore.currentProjectNumber,
      productIds,
      moveToRoom,
      productStatuses
    ),
    "moveProducts - postNewProductRoom"
  );
};

export const postNewProductLocation = async (
  productIds: ProductItem["id"][],
  moveToRoom: RoomObject["rum"]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    moveProductsApi.postNewProductLocation(
      indexStore.currentProjectNumber,
      productIds,
      moveToRoom
    ),
    "moveProducts - postNewProductLocation"
  );
};
