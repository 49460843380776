import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/img/download-icon.svg'
import _imports_1 from '@/assets/img/resize-icon.svg'


const _hoisted_1 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_2 = { class: "main-image-wrapper gr-color position-relative d-flex justify-content-center align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "missing-text"
}
const _hoisted_4 = { class: "d-flex justify-content-between arrow-box-wrapper w-100 px-2" }
const _hoisted_5 = { class: "arrow-box" }
const _hoisted_6 = { class: "arrow-box" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "action-box-wrapper" }
const _hoisted_9 = { class: "action-box me-3" }
const _hoisted_10 = { class: "action-box" }
const _hoisted_11 = { class: "gallery-image-wrapper" }
const _hoisted_12 = ["onClick", "src"]

import { ref, computed, onMounted, Ref } from "vue";
import {
  ProductImage,
  ProductImages
} from "@/library/types/product/productImage";
import { downloadImageApi } from "@/library/api/media";
import { useI18n } from "vue-i18n";

type ProductImageContainerProps = {
  mainImage: { url: string; hash: string };
  itemImages: ProductImages;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductImageContainer',
  props: {
    mainImage: { default: () => ({ url: "", hash: "" }) },
    itemImages: { default: () => ({
      bild_1: { url: "", hash: "" },
      bild_2: { url: "", hash: "" }
    }) }
  },
  setup(__props: any) {

const productImageContainerProps = __props;

const { t } = useI18n();
const productImages: Ref<ProductImage[]> = ref([]);
const currentMainImage = ref(productImageContainerProps.mainImage);

const contentUrl = computed(() => {
  return process.env.VUE_APP_CONTENT_URL;
});

onMounted(() => {
  if (!productImageContainerProps.itemImages) return;
  const sortedImageKeys = Object.keys(
    productImageContainerProps.itemImages
  ).sort((a, b) => {
    const numA = parseInt(a.split("_")[1]);
    const numB = parseInt(b.split("_")[1]);
    return numA - numB;
  });

  sortedImageKeys.forEach(key => {
    productImages.value.push(
      productImageContainerProps.itemImages[key as keyof ProductImages] ?? {
        hash: "",
        url: ""
      }
    );
  });

  if (productImages.value.length < 5) {
    let imagesMissing = 5 - productImages.value.length;
    for (let i = 0; i < imagesMissing; i++) {
      productImages.value.push({ hash: "", url: "" });
    }
  }
});

const downloadImage = async (imageUrl: string, hash: string) => {
  const result = await downloadImageApi(imageUrl);

  if (!result.success) return;

  const url = window.URL.createObjectURL(new Blob([result.result]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", hash + ".jpg");
  document.body.appendChild(link);
  link.click();
};

const openImage = (url: string, hash: string) => {
  window.open(url ?? contentUrl.value + hash, "_blank", "noreferrer");
};

const changeMainImage = (direction: "left" | "right"): ProductImage => {
  const currentImageIndex = productImages.value.findIndex(
    image => image.hash === currentMainImage.value.hash
  );

  if (direction === "left") {
    let lastAvailableIndex = null;

    for (let i = productImages.value.length - 1; i !== 0; i--) {
      if (productImages.value[i].hash.length) {
        lastAvailableIndex = i;
        break;
      }
    }

    if (!lastAvailableIndex) return { hash: "", url: "" };

    return currentImageIndex === 0
      ? productImages.value[lastAvailableIndex]
      : productImages.value[currentImageIndex - 1];
  }

  const validImages: ProductImage[] = productImages.value.filter(
    image => image.hash.length
  );

  const firstAvailableIndex = productImages.value.findIndex(
    productImage => productImage.hash.length
  );

  return currentImageIndex === validImages.length - 1
    ? productImages.value[firstAvailableIndex]
    : productImages.value[currentImageIndex + 1];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!currentMainImage.value?.hash)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)("label.missingImage")), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: "https://greenified.se/wp-content/themes/input/arrow_next.svg",
                  alt: "gallery-left-arrow",
                  class: "gallery-left-arrow",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (currentMainImage.value = changeMainImage('left')))
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: "https://greenified.se/wp-content/themes/input/arrow_next.svg",
                  alt: "gallery-right-arrow",
                  class: "gallery-right-arrow",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (currentMainImage.value = changeMainImage('right')))
                })
              ])
            ]),
            _createElementVNode("img", {
              src: `${contentUrl.value}${currentMainImage.value?.hash}`,
              alt: "main-image",
              class: "main-image"
            }, null, 8, _hoisted_7)
          ], 64)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "download icon",
            class: "action-icon",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (
              downloadImage(currentMainImage.value?.url, currentMainImage.value?.hash)
            ))
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "change size icon",
            class: "action-icon size-icon",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (openImage(currentMainImage.value?.url, currentMainImage.value?.hash)))
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productImages.value, (image, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "gr-color gallery-image",
          key: i
        }, [
          (image.hash)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                onClick: ($event: any) => (currentMainImage.value = image),
                src: `${contentUrl.value}${image.hash}`,
                alt: "gallery-image",
                class: _normalizeClass(["w-100 h-100 object-fit-contain", currentMainImage.value.hash === image.hash && 'active-border'])
              }, null, 10, _hoisted_12))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}
}

})