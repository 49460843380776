<template>
  <MainFilter
    :filter-title="t('title.users')"
    :filter-text="t('paragraph.users')"
  />

  <LoadingOverlay v-if="isLoading" />
  <template v-else>
    <TableHeaderRow
      :table-headers="tableColumns(users[0])"
      v-model:sorting-options="sortingSettings"
      isSticky
    />

    <div
      class="border-bottom"
      v-for="user in sortedUserRows(users, sortingSettings)"
      :key="user.name"
    >
      <MainTableRow
        type="other"
        @imageError="user.avatar_icon = defaultImageSrc"
        :tableColumns="tableColumns(user)"
        :rowImage="user.avatar_icon ?? defaultImageSrc"
        :defaultSelectedAction="user.typ"
        @delete="userToRemove = user"
      />
    </div>
    <TableFooter
      v-if="currentProjectRole === 'P'"
      :action-statuses="['IU']"
      @action="openInvite = true"
    />
  </template>

  <GreenifiedModal
    type="verification"
    :closable="false"
    :title="t('title.areYouSureRemoveUser')"
    :text="
      t('msg.areYouSureRemoveUser', {
        name:
          '<strong>' + (userToRemove?.name ?? userToRemove?.usr) + '</strong>'
      })
    "
    @confirm="removeUserClick()"
    v-if="userToRemove"
    @close="userToRemove = null"
  />
  <GreenifiedModal
    type="verification"
    :closable="false"
    @confirm="changeRole()"
    :text="
      t('msg.areYouSureChangeRole', {
        role:
          '<strong>' +
          t('list.role.' + selectedUser?.role).toLowerCase() +
          '</strong>',
        user: '<strong>' + selectedUser?.user + '</strong>'
      })
    "
    :title="t('title.areYouSureChangeRole')"
    v-if="selectedUser"
    @close="resetRole()"
  />

  <InviteUserModal
    v-if="openInvite"
    :roleOptions="roleOptions"
    @close="openInvite = false"
    @refresh="
      getProjectUsers();
      openInvite = false;
    "
  />
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from "vue";
import { I18nMessage } from "../library/types/locales/i18nMessage";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { getProjectUsersApi, User } from "@/library/api/project";
import { deleteUser, updateUserRole } from "@/library/api/user";
import MainFilter from "@/components/filter/MainFilter.vue";
import TableHeaderRow from "@/components/table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import MainTableRow from "@/components/table/tableRows/MainTableRow.vue";
import { sortedUserRows } from "@/library/helpers/sortTableRows";
import { SortingSettings } from "@/library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import avatarIcon from "@/assets/img/avatar-icon.svg";
import TableFooter from "@/components/table/tableFooter/TableFooter.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import InviteUserModal, {
  UserRoleOption
} from "@/components/modal/InviteUserModal.vue";
import { SelectableAction } from "@/library/helpers/structureTableColumns";

const authStore = useAuthStore();
const { t, locale, messages } = useI18n();
const indexStore = useIndexStore();
const defaultImageSrc = avatarIcon;
const isLoading = ref(true);
const sortingSettings: Ref<SortingSettings> = ref({ col: "usr", asc: false });
const openInvite = ref(false);
const selectedUser: Ref<{
  role: string;
  user: string;
  old_role: UserRoleOption;
} | null> = ref(null);
const users: Ref<User[]> = ref([]);
const userToRemove: Ref<User | null> = ref(null);
const invitedUser: Ref<{ email: string | null; role: "S" | "P" }> = ref({
  email: null,
  role: "S"
});

onMounted(() => {
  getProjectUsers();
});

const currentProject = computed(() => {
  return indexStore.currentProjectNumber;
});

const currentProjectRole = computed(() => {
  return indexStore.currentProject.role;
});

const currentUser = computed(() => {
  return authStore.currentUser;
});

const tableColumns = (userColumn: User): TableColumns["columns"] => {
  const hasPermissionToEditUsers =
    currentProjectRole.value === "P" &&
    userColumn.usr !== currentUser.value.email;

  if (hasPermissionToEditUsers) {
    return {
      usr: {
        value: userColumn.usr,
        columnStyling: "col-4",
        tooltip: null,
        actions: [],
        isSortable: true,
        headerText: "tableTitle.email",
        isEditable: false,
        translate: false,
        inputSettings: null,
        selectOptions: []
      },
      name: {
        value: userColumn.name,
        columnStyling: "col-4",
        actions: [],
        tooltip: null,
        isSortable: true,
        isEditable: false,
        translate: false,
        headerText: "tableTitle.name",
        inputSettings: null,
        selectOptions: []
      },
      actions: {
        value: "",
        columnStyling: "col-4 justify-content-between pe-1",
        tooltip: null,
        actions: ["select", "delete"],
        isSortable: false,
        isEditable: false,
        translate: false,
        headerText: "tableTitle.role",
        inputSettings: null,
        selectOptions: roleOptions.value
      }
    };
  }

  return {
    usr: {
      value: userColumn.usr,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: true,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.email",
      inputSettings: null,
      selectOptions: []
    },
    name: {
      value: userColumn.name,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      isSortable: true,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.name",
      inputSettings: null,
      selectOptions: []
    },
    typ: {
      value: t(`list.role.${userColumn.typ}`),
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.role",
      inputSettings: null,
      selectOptions: []
    }
  };
};

const roleOptions = computed((): SelectableAction[] => {
  const localeRole = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).role;

  const roleOption = Object.keys(localeRole).map(key => ({
    value: key,
    label: localeRole[key as keyof I18nMessage["list"]["role"]],
    selectable: true
  }));

  return roleOption;
});

const removeUserClick = async () => {
  isLoading.value = true;
  if (userToRemove.value?.usr) {
    const result = await deleteUser(userToRemove.value.usr);

    userToRemove.value = null;
    isLoading.value = false;

    if (!result.success) return;

    getProjectUsers();
  }
};

const resetRole = () => {
  getProjectUsers();
  selectedUser.value = null;
};

const changeRole = async () => {
  isLoading.value = true;
  if (selectedUser.value) {
    const result = await updateUserRole(
      selectedUser.value.user,
      selectedUser.value.role
    );

    isLoading.value = false;
    if (!result.success) return;

    selectedUser.value = null;
  }
};

const getProjectUsers = async () => {
  isLoading.value = true;
  const result = await getProjectUsersApi();
  isLoading.value = false;

  if (!result.success) return;

  users.value = result.result;
  invitedUser.value = { email: null, role: "S" };
  openInvite.value = false;
};

watch(
  () => currentProject.value,
  () => {
    getProjectUsers();
  }
);
</script>
