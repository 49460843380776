<template>
  <div class="position-relative" v-if="!hasSelectableOptions">
    <vSelect
      :reduce="(option: string | Record<string, string>) => reducedKey && typeof option !== 'string'  ? option[reducedKey] : option"
      :class="[customClass, showError && 'warning']"
      :filterBy="filterBy"
      :options="options"
      :components="{ Deselect }"
      :label="label"
      v-model="localModelValue"
      :value="value"
      @option:selected="(option: string | Record<string, string>) => {
      if (keepPlaceholder) localModelValue = placeholder
      emit('update:modelValue',  reducedKey && typeof option !== 'string' ? option[reducedKey] : option)
      }"
      :clearable="clearable"
      :placeholder="isLoading ? '' : placeholder"
      :append-to-body="appendToBody"
      :required="required"
      :id="id"
      :name="name"
      :closeOnSelect="closeOnSelect"
    >
      <template #selected-option>
        <slot name="selected-option" />
      </template>
      <template
        #open-indicator="{ attributes }: { attributes: VueSelectSlotScope }"
      >
        <span v-bind="attributes">
          <img src="@/assets/img/drop-arrow.svg" />
        </span>
      </template>
      <template #list-header>
        <slot name="list-header" />
      </template>
      <template #option="option">
        <slot name="option" :option="option" />
      </template>
      <template #list-footer>
        <slot name="list-footer" />
      </template>
    </vSelect>
    <div class="error-wrapper" v-if="showError">
      <p class="text-tiny emphasis">{{ errorText }}</p>
    </div>
    <div class="loader-spinner-wrapper" v-if="isLoading">
      <LoaderSpinner />
    </div>
  </div>
  <div class="position-relative" v-else>
    <vSelect
      :reduce="(option: SelectableAction) => reducedKey && typeof option !== 'string'  ? option[reducedKey as keyof SelectableAction] : option"
      :class="[customClass, showError && 'warning']"
      :filterBy="filterBy"
      :options="options"
      :components="{ Deselect }"
      :label="label"
      v-model="localModelValue"
      :value="value"
      :selectable="(option: SelectableAction) => option.selectable"
      @option:selected="(option: SelectableAction) => {
      if (keepPlaceholder) localModelValue = placeholder
      emit('update:modelValue',  reducedKey && typeof option !== 'string' ? option[reducedKey  as keyof SelectableAction] : option)
      }"
      :clearable="clearable"
      :placeholder="isLoading ? '' : placeholder"
      :append-to-body="appendToBody"
      :required="required"
      :id="id"
      :name="name"
      :closeOnSelect="closeOnSelect"
    >
      <template #selected-option>
        <slot name="selected-option" />
      </template>
      <template
        #open-indicator="{ attributes }: { attributes: VueSelectSlotScope }"
      >
        <span v-bind="attributes">
          <img src="@/assets/img/drop-arrow.svg" />
        </span>
      </template>
      <template #list-header>
        <slot name="list-header" />
      </template>
      <template #option="option">
        <slot name="option" :option="option" />
      </template>
      <template #list-footer>
        <slot name="list-footer" />
      </template>
    </vSelect>
    <div class="error-wrapper" v-if="showError">
      <p class="text-tiny emphasis">{{ errorText }}</p>
    </div>
    <div class="loader-spinner-wrapper" v-if="isLoading">
      <LoaderSpinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SelectableAction } from "@/library/helpers/structureTableColumns";
import { RoomObject } from "@/library/types/room/rooms";
import { withDefaults, defineProps, ref, defineEmits, h, watch } from "vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import LoaderSpinner from "./LoaderSpinner.vue";
export type LocalVueSelectOption = string | object;

export type FilterByOption = LocalVueSelectOption | RoomObject;

type LocalFilterBy = (
  option: FilterByOption,
  label: string,
  search: string
) => boolean;

type CustomSelectProps = {
  customClass?: string;
  options: LocalVueSelectOption[];
  modelValue?: LocalVueSelectOption | number | null;
  label?: string;
  reducedKey?: string | null;
  filterBy?: LocalFilterBy;
  value?: LocalVueSelectOption | null;
  placeholder?: string;
  appendToBody?: boolean;
  clearable?: boolean;
  required?: boolean;
  id?: string;
  name?: string;
  closeOnSelect?: boolean;
  keepPlaceholder?: boolean;
  showError?: boolean;
  errorText?: string;
  hasSelectableOptions?: boolean;
  isLoading?: boolean;
};

const customSelectProps = withDefaults(defineProps<CustomSelectProps>(), {
  customClass: "",
  options: () => [],
  objectOptions: () => [],
  modelValue: null,
  label: "",
  reducedKey: null,
  filterBy: () => false,
  value: null,
  placeholder: "",
  appendToBody: true,
  clearable: false,
  required: false,
  id: "",
  name: "",
  withComponents: false,
  closeOnSelect: true,
  keepPlaceholder: false,
  showError: false,
  errorText: "",
  hasSelectableOptions: false,
  isLoading: false
});
const emit = defineEmits(["update:modelValue"]);

const Deselect = {
  render: () =>
    h("img", {
      src: require("@/assets/img/remove.svg")
    })
};

const localModelValue = ref(
  customSelectProps.keepPlaceholder
    ? customSelectProps.placeholder
    : customSelectProps.modelValue ?? customSelectProps.value
);

watch(
  () => localModelValue.value,
  newValue => {
    if ((typeof newValue === "string" && !newValue.length) || !newValue) {
      emit("update:modelValue", null);
    }
  }
);
</script>

<style scoped>
.error-wrapper {
  position: absolute;
  right: 48px;
  top: 7px;
  color: #dc3545;
  background-color: white;
  z-index: 4;
  font-size: 13px;
}

.loader-spinner-wrapper {
  position: absolute;
  top: 6px;
  left: 20px;
}
</style>
