import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "ms-1"
}

import InformationTooltip from "@/components/common/InformationTooltip.vue";
import RequiredWarningText from "@/components/common/RequiredWarningText.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type RequiredFieldProps = {
  placing?: "top" | "left";
  fieldKey?: string;
  fieldLabel: string;
  requiredField?: 0 | 1;
  presetFieldValue?: FormFields["key"] | null;
  showTooltip?: boolean;
  showLabel?: boolean;
  tooltipInstructions?: string;
  formIsSubmitted?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldLabel',
  props: {
    placing: { default: "left" },
    fieldKey: { default: "" },
    fieldLabel: { default: "" },
    requiredField: { default: 0 },
    presetFieldValue: { default: null },
    showTooltip: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: true },
    tooltipInstructions: { default: "" },
    formIsSubmitted: { type: Boolean, default: false }
  },
  setup(__props: any) {

const requiredFieldProps = __props;
const { t } = useI18n();

const showRequiredText = ref(false);

const checkFieldValidation = () => {
  if (requiredFieldProps.requiredField === 0) return true;
  return requiredFieldProps.presetFieldValue &&
    String(requiredFieldProps.presetFieldValue).length > 0
    ? true
    : false;
};

watch(
  () => requiredFieldProps.formIsSubmitted,
  isSubmitted => {
    if (isSubmitted) showRequiredText.value = !checkFieldValidation();
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.placing === 'left' ? 'col-4' : 'col-12')
  }, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex align-items-center w-100", _ctx.placing === 'left' && 'justify-content-between'])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("label", {
                class: "form-label",
                for: _ctx.fieldKey
              }, [
                _createTextVNode(_toDisplayString(_ctx.fieldLabel) + " ", 1),
                (_ctx.requiredField === 1 && _ctx.fieldLabel.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_2)
            ]),
            (_ctx.showTooltip)
              ? (_openBlock(), _createBlock(InformationTooltip, {
                  key: 0,
                  class: _normalizeClass(_ctx.placing === 'top' && 'ms-1'),
                  formTooltip: true,
                  infoText: _ctx.tooltipInstructions,
                  justify: 'right'
                }, null, 8, ["class", "infoText"]))
              : _createCommentVNode("", true)
          ], 2),
          (showRequiredText.value && !checkFieldValidation())
            ? (_openBlock(), _createBlock(RequiredWarningText, {
                key: 0,
                text: _unref(t)('msg.fieldRequired', [_ctx.fieldLabel]),
                textClass: "form-label"
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}
}

})