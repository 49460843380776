<template>
  <MainFilter
    :filterTitle="`${t('button.otherSales')} - ${quantities} ${t(
      'title.products'
    )}`"
    :filterText="t('paragraph.overviewSell')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="currentFilter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :productStatus="currentTab === 1 || currentTab === 2 ? ['I'] : ['B']"
    :hasProducts="quantities > 0"
    :filterButtons="filterButtons"
    :pdfRoute="currentTab !== 1 ? 'internal-order' : null"
    :orderStatus="currentTab === 2 ? 'A' : 'C'"
    :showUrlButton="currentTab === 1"
    v-model:filterTab="currentTab"
  />

  <LoadingOverlay v-if="isLoading" />
  <template v-else>
    <ProductTable
      v-if="currentTab === 1"
      :actionStatuses="['CP', 'CA', 'EI']"
      :noRowsText="t('label.noRowsPositive')"
      :tableType="'internal'"
      v-model:filter="currentFilter"
      :sortingSettings="sortingSettings"
      :translateHeaders="translateHeaders"
      :presetFilter="availableFilter"
      :externalProducts="availableProductHeaders"
      @fetch-products="getProductHeaders()"
    />

    <ProductTable
      v-if="currentTab === 2"
      :actionStatuses="['CA', 'EI']"
      :noRowsText="t('label.noRowsPositive')"
      :tableType="'internalOngoing'"
      v-model:filter="currentFilter"
      :sortingSettings="sortingSettings"
      :translateHeaders="translateHeaders"
      :presetFilter="ongoingFilter"
      :externalProducts="ongoingProductHeaders"
      @fetch-products="getProductHeaders()"
    />

    <template v-if="currentTab === 3">
      <TableHeaderRow
        v-if="
          !Object.keys(deliveredProductHeaders).length &&
          !Object.keys(soldProductHeaders).length
        "
        :tableHeaders="null"
        :noRowsText="t('label.noRowsPositive')"
      />

      <template v-if="Object.keys(deliveredProductHeaders).length">
        <div class="row pb-2">
          <div class="col-12 d-flex">
            <p class="subheading-medium">{{ t("title.sold") }}</p>
          </div>
        </div>

        <ProductTable
          :actionStatuses="['A']"
          :noRowsText="t('label.noRowsPositive')"
          :tableType="'internalSold'"
          v-model:filter="currentFilter"
          :sortingSettings="sortingSettings"
          :translateHeaders="translateHeaders"
          :presetFilter="deliveredFilter"
          :externalProducts="deliveredProductHeaders"
          @fetch-products="getProductHeaders()"
          :footerIsViewable="orderTableFooterIsViewable"
          @footerIsOpen="
            bool => {
              soldTableFooterIsViewable = !bool;
            }
          "
        />
      </template>
      <template v-if="Object.keys(soldProductHeaders).length">
        <div
          class="row pb-2"
          :class="Object.keys(deliveredProductHeaders).length && 'mt-4'"
        >
          <div class="col-12 d-flex">
            <p class="subheading-medium">
              {{ t("title.sold") }} ({{ t("label.withoutOrders") }})
            </p>
          </div>
        </div>
        <ProductTable
          :actionStatuses="['SI']"
          :noRowsText="t('label.noRowsPositive')"
          :tableType="'internalSold'"
          :key="String(isLoading)"
          v-model:filter="currentFilter"
          :sortingSettings="sortingSettings"
          :translateHeaders="translateHeaders"
          :presetFilter="deliveredFilter"
          :externalProducts="soldProductHeaders"
          @fetch-products="getProductHeaders()"
          :footerIsViewable="soldTableFooterIsViewable"
          @footerIsOpen="
            bool => {
              orderTableFooterIsViewable = !bool;
            }
          "
        />
      </template>
    </template>
  </template>
</template>

<script setup lang="ts">
import MainFilter, { FilterButton } from "@/components/filter/MainFilter.vue";
import {
  computed,
  Ref,
  ref,
  withDefaults,
  defineProps,
  watch,
  onMounted
} from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

import ProductTable from "@/components/table/ProductTable.vue";
import {
  AdvertisementProductHeader,
  OrderProductHeader,
  ProductHeaderSale
} from "@/library/types/product/productHeader";
import { useIndexStore } from "@/store";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import TableHeaderRow from "@/components/table/tableHeader/TableHeaderRow.vue";
import { getInternalAdvertisements } from "@/library/api/sales";

type InternalSalesViewProps = {
  filterQuery?: string;
  setTab: "1" | "2" | "3";
};

const internalSalesViewProps = withDefaults(
  defineProps<InternalSalesViewProps>(),
  {
    filterQuery: "",
    setTab: "1"
  }
);

const indexStore = useIndexStore();

const currentFilter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});

const availableFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "green_hash"
  ],
  status: ["I"]
};

const deliveredFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "green_hash"
  ],
  status: ["B"]
};

const ongoingFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "green_hash"
  ],
  status: ["I", "O"]
};

const isLoading = ref(false);
const orderTableFooterIsViewable = ref(true);
const soldTableFooterIsViewable = ref(true);

const { t } = useI18n();
const filterButtons: Ref<FilterButton[]> = computed(() => [
  {
    text: "button.available",
    amount: countTotalQuantities(availableProductHeaders.value),
    show: true
  },
  {
    text: "button.ongoing",
    amount: countTotalQuantities(ongoingProductHeaders.value),
    show: indexStore.currentProjectRole === "P"
  },
  {
    text: "button.delivered",
    amount: getQuantitiesByProductStatus("B"),
    show: indexStore.currentProjectRole === "P"
  }
]);

const quantities = computed(() => {
  return getQuantitiesByProductStatus("I") + getQuantitiesByProductStatus("B");
});

const currentTab = ref(1);
const availableProductHeaders: Ref<AdvertisementProductHeader> = ref({});
const ongoingProductHeaders: Ref<OrderProductHeader> = ref({});
const soldProductHeaders: Ref<ProductHeaderSale> = ref({});
const deliveredProductHeaders: Ref<OrderProductHeader> = ref({});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];

const selectedProductIds: Ref<string[]> = ref([]);

const countTotalQuantities = (
  productHeaders: OrderProductHeader | AdvertisementProductHeader
) => {
  let quantity = 0;

  for (const key in productHeaders) {
    const currentProductHeader = productHeaders[key];
    const currentQuantity =
      currentProductHeader.group.quantity ?? currentProductHeader.group.antal;
    quantity += Number(currentQuantity);
  }

  return quantity;
};

const getProductHeaders = async () => {
  isLoading.value = true;
  soldProductHeaders.value = {};
  availableProductHeaders.value = {};
  ongoingProductHeaders.value = {};
  deliveredProductHeaders.value = {};

  const result = await getInternalAdvertisements();

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  availableProductHeaders.value = result.result.advertisements;
  ongoingProductHeaders.value = result.result.ongoingOrders;
  deliveredProductHeaders.value = result.result.completedOrders;
  soldProductHeaders.value = result.result.oldOrders;

  isLoading.value = false;
};

onMounted(() => {
  getProductHeaders();
  const tabOptions = ["1", "2", "3"];
  if (
    internalSalesViewProps.setTab &&
    indexStore.currentProjectRole === "P" &&
    tabOptions.includes(internalSalesViewProps.setTab)
  ) {
    currentTab.value = Number(internalSalesViewProps.setTab);
  }
});

watch(
  () => currentTab.value,
  () => {
    selectedProductIds.value = [];
  }
);
</script>
