import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row pb-0" }
const _hoisted_2 = { class: "col-12 d-flex" }
const _hoisted_3 = {
  key: 0,
  class: "subheading-medium"
}
const _hoisted_4 = {
  key: 1,
  class: "subheading-medium"
}

import MainFilter from "@/components/filter/MainFilter.vue";
import {
  computed,
  Ref,
  ref,
  watch,
  onMounted
} from "vue";
import { FilterOptions } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import {
  getGreenifiedData,
  getSaleProductGroups
} from "@/library/api/greenifiedProducts";
import { GreenifiedItem } from "@/library/types/product/productItem";
import GreenifiedProductTable from "@/components/table/GreenifiedProductTable.vue";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import { useIndexStore } from "@/store";
import { convertCurrencyCodeToSymbol } from "@/library/helpers/convertCurrencyCodeToSymbol";

type GreenifiedSalesViewProps = {
  filterQuery?: string;
  setTab: "1" | "2";
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedSalesView',
  props: {
    filterQuery: { default: "" },
    setTab: { default: "1" }
  },
  setup(__props: any) {

const greenifiedSalesViewProps = __props;

const currentFilter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const { t, locale, messages } = useI18n();
const indexStore = useIndexStore();

const productsForSale: Ref<GreenifiedProductHeader[]> = ref([]);
const totalForSaleAmount = ref(0);
const totalProductsForSaleQuantity = ref(0);
const totalProductsSoldQuantity = ref(0);
const currentTab = ref(
  greenifiedSalesViewProps.setTab ? Number(greenifiedSalesViewProps.setTab) : 1
);
const currentCurrency = useIndexStore().currentProjectCurrency;

const isLoading = ref(false);
const soldProducts: Ref<GreenifiedProductHeader[]> = ref([]);
const totalSalesAmount = ref(0);

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const selectedProductIds: Ref<string[]> = ref([]);

const getProductItemQuantity = (productHeader: GreenifiedProductHeader) => {
  return productHeader.items.filter(productItem =>
    checkFilter(productItem, productHeader)
  ).length;
};

const checkFilter = (
  productItem: GreenifiedItem | null,
  productGroup: GreenifiedProductHeader
) => {
  const filterCategories = {
    ...currentFilter.value.categories
  };
  for (const key in filterCategories) {
    const categories = filterCategories[key];
    if (!checkGroupFilter(categories, productItem, productGroup)) {
      return false;
    }
  }

  for (const key in currentFilter.value.intervals) {
    const interval = currentFilter.value.intervals[key];
    if (productItem && key in productItem) {
      if (
        "from" in interval &&
        !(interval.from <= Number(productItem[key as keyof GreenifiedItem]))
      ) {
        return false;
      }
      if (
        "to" in interval &&
        !(interval.to >= Number(productItem[key as keyof GreenifiedItem]))
      ) {
        return false;
      }
    }
  }

  return true;
};

const checkGroupFilter = (
  categories: FilterOptions["categories"]["key"],
  productItem: GreenifiedItem | null,
  productGroup: GreenifiedProductHeader
) => {
  if (productItem && categories[0].key in productItem) {
    for (let category of categories) {
      if (
        (category.key in productItem &&
          String(productItem[category.key as keyof GreenifiedItem]) ===
            category.value) ||
        (category.key === "levkod" &&
          category.value === "ex" &&
          productItem[category.key].substring(0, 2) === "ex")
      ) {
        return true;
      }
    }
    return false;
  }
  if (categories[0].key === "search" && productGroup) {
    for (let category of categories) {
      const currProductItem = { ...productItem };
      const currProductGroup = { ...productGroup };
      for (let key in Object.assign(currProductItem, currProductGroup)) {
        if (translateHeaders.includes(key)) {
          if (
            t(key + "." + currProductItem[key as keyof GreenifiedItem])
              .toLowerCase()
              .indexOf(category.value.toLowerCase()) !== -1
          ) {
            return true;
          }
        }
        if (
          currProductItem[key as keyof GreenifiedItem] &&
          typeof currProductItem[key as keyof GreenifiedItem] === "string" &&
          String(currProductItem[key as keyof GreenifiedItem])
            .toLowerCase()
            .indexOf(category.value.toLowerCase()) !== -1
        ) {
          return true;
        }
      }
    }
    return false;
  }

  return true;
};

const setGreenifiedStatus = async (productHeader: GreenifiedProductHeader) => {
  if (productHeader.missingOnGreenified) {
    productHeader.loadingStatus = true;
    const greenifiedResult = await getGreenifiedData(productHeader);

    if (!greenifiedResult.success) return productHeader;
    Object.assign(productHeader, greenifiedResult.result);

    productHeader.loadingStatus = false;
  }
  if (productHeader.quantity === 0 && productHeader.salda === 0) {
    productHeader.missingOnGreenified = true;
  }
  return productHeader;
};

const getGreenifiedProducts = async () => {
  isLoading.value = true;
  const result = await getSaleProductGroups();

  if (!result.success) {
    return (isLoading.value = false);
  }

  const quantityResult = await indexStore.updateQuantities();

  if (quantityResult !== "success") {
    return (isLoading.value = false);
  }

  if (!("levkod" in messages.value[locale.value.toLocaleLowerCase()])) {
    const translationResult = await indexStore.getLocaleTranslations(
      indexStore.projectDetails,
      messages.value[locale.value]
    );

    if (translationResult !== "success") {
      return (isLoading.value = false);
    }
  }

  productsForSale.value = result.result.productsForSale;
  soldProducts.value = result.result.soldProducts;
  isLoading.value = false;

  if (productsForSale.value) {
    productsForSale.value.map(async productHeader => {
      productHeader.itemQuantity = getProductItemQuantity(productHeader);
      totalProductsForSaleQuantity.value += productHeader.quantity;
      if (productHeader.quantity && productHeader.pris) {
        totalForSaleAmount.value +=
          productHeader.quantity * Number(productHeader.pris);
      }

      return await setGreenifiedStatus(productHeader);
    });
  }
  if (soldProducts.value) {
    soldProducts.value.map(async productHeader => {
      productHeader.itemQuantity = getProductItemQuantity(productHeader);
      totalProductsSoldQuantity.value += productHeader.salda;

      if (productHeader.salda > 0) {
        totalSalesAmount.value +=
          productHeader.salda * Number(productHeader.pris);
      }
      return productHeader;
    });
  }
};

const filterButtons = computed(() => [
  {
    text: "button.onGreenified",
    amount: totalProductsForSaleQuantity.value,
    show: true
  },
  {
    text: "title.sold",
    amount: totalProductsSoldQuantity.value,
    show: true
  }
]);

const quantities = computed(() => {
  return getQuantitiesByProductStatus("G");
});

onMounted(() => {
  getGreenifiedProducts();
});

watch(
  () => currentTab.value,
  () => {
    selectedProductIds.value = [];
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      filterTitle: `${_unref(t)(
      'button.onGreenified'
    )} - ${totalProductsForSaleQuantity.value} ${_unref(t)('title.products')}`,
      filterText: _unref(t)('paragraph.overviewSell'),
      filterQuery: _ctx.filterQuery,
      defaultFilter: currentFilter.value,
      "onUpdate:defaultFilter": _cache[0] || (_cache[0] = ($event: any) => ((currentFilter).value = $event)),
      defaultSortingSettings: sortingSettings.value,
      "onUpdate:defaultSortingSettings": _cache[1] || (_cache[1] = ($event: any) => ((sortingSettings).value = $event)),
      translateHeaders: translateHeaders,
      productStatus: ['G'],
      hasProducts: quantities.value > 0,
      filterButtons: filterButtons.value,
      filterTab: currentTab.value,
      "onUpdate:filterTab": _cache[2] || (_cache[2] = ($event: any) => ((currentTab).value = $event))
    }, null, 8, ["filterTitle", "filterText", "filterQuery", "defaultFilter", "defaultSortingSettings", "hasProducts", "filterButtons", "filterTab"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (currentTab.value === 1)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)("title.sell")) + ": " + _toDisplayString(totalForSaleAmount.value
              ? totalForSaleAmount.value.toLocaleString().replace(/,/g, " ")
              : 0) + " " + _toDisplayString(_unref(convertCurrencyCodeToSymbol)(_unref(currentCurrency), _unref(locale))), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)("title.totalSales")) + ": " + _toDisplayString(totalSalesAmount.value
              ? totalSalesAmount.value.toLocaleString().replace(/,/g, " ")
              : 0) + " " + _toDisplayString(_unref(convertCurrencyCodeToSymbol)(_unref(currentCurrency), _unref(locale))), 1))
            ])
          ]),
          (currentTab.value === 1)
            ? (_openBlock(), _createBlock(GreenifiedProductTable, {
                key: 0,
                noRowsText: _unref(t)('label.noRowsPositive'),
                sortingSettings: sortingSettings.value,
                translateHeaders: translateHeaders,
                filter: currentFilter.value,
                productHeaders: productsForSale.value,
                onGetProducts: getGreenifiedProducts,
                tableType: 'sale'
              }, null, 8, ["noRowsText", "sortingSettings", "filter", "productHeaders"]))
            : (_openBlock(), _createBlock(GreenifiedProductTable, {
                key: 1,
                noRowsText: _unref(t)('label.noRowsPositive'),
                sortingSettings: sortingSettings.value,
                translateHeaders: translateHeaders,
                filter: currentFilter.value,
                productHeaders: soldProducts.value,
                onGetProducts: getGreenifiedProducts,
                tableType: 'sold'
              }, null, 8, ["noRowsText", "sortingSettings", "filter", "productHeaders"]))
        ], 64))
  ], 64))
}
}

})