<template>
  <div class="d-flex" v-if="presetSupplierField">
    <CustomSelect
      class="w-100 me-3"
      :options="projectSuppliers"
      label="varumarke"
      v-model="supplierCode"
      @update:modelValue="(supplier: ProjectSupplier) => inputSupplier(supplier)"
      :clearable="false"
      :placeholder="String(localSupplierFieldValues)"
      :appendToBody="true"
      :showError="showError"
    />
    <GreenifiedButton
      @click="emit('showSupplierModal')"
      size="thin"
      noWrap
      :text="t('button.add')"
    />
  </div>
  <input
    v-if="presetSupplierField"
    required="true"
    :name="presetSupplierField.key"
    v-model="localSupplierFieldValues"
    style="
      height: 0px;
      width: 0px;
      padding: 0px;
      margin: 0px;
      display: block;
      border: 0px !important;
    "
  />
</template>

<script lang="ts" setup>
import CustomSelect from "@/components/common/CustomSelect.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { ProjectSupplier } from "@/library/types/project/project";
import { FormFields } from "@/library/types/structure/formStructure";
import { TextFieldType } from "@/library/types/structure/subfieldTypes";
import { useIndexStore } from "@/store";
import {
  computed,
  defineProps,
  ref,
  withDefaults,
  defineEmits,
  watch
} from "vue";
import { useI18n } from "vue-i18n";

type SupplierFieldProps = {
  currentSupplierCode: string;
  presetSupplierField: TextFieldType | null;
  supplierFieldValues: FormFields["key"];
  showError: boolean;
};

const supplierFieldProps = withDefaults(defineProps<SupplierFieldProps>(), {
  currentSupplierCode: "",
  presetSupplierField: null,
  supplierFieldValues: () => ({}),
  showError: false
});

const indexStore = useIndexStore();
const { t } = useI18n();
const localSupplierFieldValues = ref(supplierFieldProps.supplierFieldValues);
const emit = defineEmits([
  "showSupplierModal",
  "update:supplierFieldValues",
  "update:currentSupplierCode"
]);

const projectSuppliers = computed(() => {
  const filteredSupplierList: ProjectSupplier[] = [];
  indexStore.projectDetails.lev.forEach((supplier: ProjectSupplier) => {
    if (!supplier.levkod.includes("ex")) {
      filteredSupplierList.push(supplier);
    } else if (supplier.levkod === "ex01") {
      filteredSupplierList.push({
        levkod: supplier.levkod,
        varumarke: t("label.unkownSupplier")
      });
    }
  });
  return filteredSupplierList;
});

const supplierCode = ref(
  projectSuppliers.value.find(
    supplier => supplier.levkod === supplierFieldProps.currentSupplierCode
  ) ?? ""
);

const inputSupplier = (supplier: ProjectSupplier) => {
  localSupplierFieldValues.value = supplier.varumarke;
  emit("update:supplierFieldValues", localSupplierFieldValues.value);
  emit("update:currentSupplierCode", supplier.levkod);
};

watch(
  () => supplierFieldProps.currentSupplierCode,
  newSupplierCode => {
    localSupplierFieldValues.value = newSupplierCode;
    supplierCode.value = newSupplierCode;

    emit("update:supplierFieldValues", localSupplierFieldValues.value);
  }
);
</script>
