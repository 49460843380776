import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row pb-1" }
const _hoisted_2 = { class: "col text-small" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "col-12 d-flex" }
const _hoisted_5 = { class: "form-label-paragraph mb-3" }
const _hoisted_6 = { class: "col-1" }
const _hoisted_7 = ["value", "onInput"]
const _hoisted_8 = { class: "col-11" }
const _hoisted_9 = { class: "form-label" }
const _hoisted_10 = { class: "form-label-paragraph" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-11" }
const _hoisted_13 = { class: "form-label" }
const _hoisted_14 = { class: "container-fluid p-0" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "form-label mt-4" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "form-label-paragraph mb-3" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "row justify-content-end" }
const _hoisted_24 = { class: "col-3" }

import SelectRoom from "../SelectRoom.vue";
import {
  ref,
  Ref,
  computed
} from "vue";
import { ReconditionUpdate } from "../../services/api/productsApi";
import { ProductItem } from "../../library/types/product/productItem";
import { I18nMessage } from "../../library/types/locales/i18nMessage";
import { bolderFont } from "../../library/helpers/typography";
import { useI18n } from "vue-i18n";
import { updateRecondition } from "@/library/api/products";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import InputTextField from "../common/InputTextField.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import InputField from "../common/InputField.vue";

type ProductRenovationFormProps = {
  isValidated: boolean;
  productIds: ProductItem["id"][];
  noRecondition: boolean;
  moveText: string;
  moveTitle: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductRenovationForm',
  props: {
    isValidated: { type: Boolean, default: false },
    productIds: { default: () => [] },
    noRecondition: { type: Boolean, default: false },
    moveText: { default: "" },
    moveTitle: { default: "" }
  },
  emits: ["closeModal", "refresh"],
  setup(__props: any, { emit: __emit }) {

const { messages, locale } = useI18n();

const emit = __emit;

const productRenovationFormProps = __props;
const { t } = useI18n();
const showSuccess = ref(false);
const isLoading = ref(false);
const reconditionInformation: Ref<ReconditionUpdate> = ref({
  comment: null,
  to_room: null,
  recondition: {}
});
const reconditionForm: Ref<HTMLFormElement | null> = ref(null);

const translatedReconditions = computed(() => {
  return (messages.value[locale.value.toLocaleLowerCase()] as I18nMessage).list.reconditions;
});

const updateActions = async () => {
  if (
    productRenovationFormProps.isValidated &&
    reconditionForm.value &&
    !reconditionForm.value.reportValidity()
  ) {
    return;
  }
  isLoading.value = true;

  const result = await updateRecondition(
    productRenovationFormProps.productIds,
    reconditionInformation.value
  );

  isLoading.value = false;
  if (!result.success) return;
  showSuccess.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.noRecondition)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          title: _unref(t)('label.moveKeepCondition'),
          type: 'x-small',
          text: _unref(t)('paragraph.moveKeepConditionForm'),
          onDecline: _cache[2] || (_cache[2] = ($event: any) => (emit('closeModal'))),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (emit('closeModal'))),
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => (updateActions())),
          confirmText: _unref(t)('button.save'),
          isLoading: isLoading.value
        }, {
          container: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)("paragraph.moveKeepConditionForm")), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(SelectRoom, {
                    modelValue: reconditionInformation.value.to_room,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((reconditionInformation.value.to_room) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(GreenifiedButton, {
                text: _unref(t)('button.save'),
                size: "thin",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (updateActions()))
              }, null, 8, ["text"])
            ])
          ]),
          _: 1
        }, 8, ["title", "text", "confirmText", "isLoading"]))
      : (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (emit('closeModal'))),
          title: _unref(t)('title.selectRecondition'),
          type: 'small',
          isLoading: isLoading.value
        }, {
          container: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)("paragraph.reconditionForm", { count: _ctx.productIds.length })), 1),
              (!isLoading.value)
                ? (_openBlock(), _createElementBlock("form", {
                    key: 0,
                    ref_key: "reconditionForm",
                    ref: reconditionForm,
                    onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"])),
                    class: "action-form h-100 d-flex flex-column justify-content-between"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(translatedReconditions.value, (rec, key) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "row mb-3",
                        key: rec.name
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("input", {
                            type: "checkbox",
                            value: reconditionInformation.value.recondition[key],
                            onInput: 
                  () => {
                    if (reconditionInformation.value.recondition[key]) {
                      delete reconditionInformation.value.recondition[key];
                    } else {
                      reconditionInformation.value.recondition[key] = {
                        text: '',
                        key: key
                      };
                    }
                  }
                ,
                            class: "small-checkbox mt-1"
                          }, null, 40, _hoisted_7)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("label", _hoisted_9, _toDisplayString(rec.name), 1),
                          _createElementVNode("p", _hoisted_10, _toDisplayString(rec.desc), 1),
                          (reconditionInformation.value.recondition[key])
                            ? (_openBlock(), _createBlock(InputField, {
                                key: 0,
                                type: "text",
                                class: "mt-2",
                                required: "",
                                maxlength: "500",
                                placeHolder: _unref(t)('placeholders.reconditionInfo'),
                                fieldValue: 
                  reconditionInformation.value.recondition[key].text
                ,
                                "onUpdate:fieldValue": ($event: any) => ((
                  reconditionInformation.value.recondition[key].text
                ) = $event)
                              }, null, 8, ["placeHolder", "fieldValue", "onUpdate:fieldValue"]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "col-1" }, null, -1)),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("label", _hoisted_13, _toDisplayString(_unref(t)("label.possibleComment")), 1),
                        _createVNode(InputTextField, {
                          modelValue: reconditionInformation.value.comment,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((reconditionInformation.value.comment) = $event)),
                          rows: 2
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.moveTitle ?? _unref(t)("label.moveProduct")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.moveText ?? _unref(t)("paragraph.moveRecondtionForm")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(SelectRoom, {
                            modelValue: reconditionInformation.value.to_room,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((reconditionInformation.value.to_room) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ])
                  ], 544))
                : _createCommentVNode("", true)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(GreenifiedButton, {
                  text: _unref(t)('button.save'),
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (updateActions()))
                }, null, 8, ["text"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "isLoading"])),
    (showSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 2,
          type: "response",
          closable: false,
          title: 
      _ctx.noRecondition
        ? _unref(t)('responseTitle.keptInCurrentCondition')
        : _unref(t)('responseTitle.reconditionRegistered')
    ,
          text: 
      _ctx.noRecondition
        ? `${_unref(t)('paragraph.productsKeptInCurrentCondition2')} ${_unref(bolderFont)(
            _unref(t)('title.repurposeOverview')
          )}`
        : `${_unref(t)('paragraph.reconditionIsRegistered2')} ${_unref(bolderFont)(
            _unref(t)('title.recondition')
          )}`
    ,
          onClose: _cache[10] || (_cache[10] = ($event: any) => {
      emit('refresh');
      showSuccess.value = false;
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})