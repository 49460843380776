import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["required", "id", "name", "true-value", "false-value", "disabled", "value", "checked"]

import { ref, watch, Ref } from "vue";

type GreenifiedCheckboxProps = {
  required?: boolean;
  id?: string;
  name?: string;
  value?: string | number | boolean;
  modelValue?: string | number | boolean | null;
  label?: string;
  vertical?: boolean;
  showError?: boolean;
  trueValue?: string | number | boolean | null;
  falseValue?: string | number | boolean | null;
  tableMargin?: "main" | "sub" | "none";
  isChecked?: boolean;
  size?: "large" | "small";
  disabled?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedCheckbox',
  props: {
    required: { type: Boolean, default: false },
    id: { default: "" },
    name: { default: "" },
    value: { type: [String, Number, Boolean], default: "" },
    modelValue: { type: [String, Number, Boolean, null], default: null },
    label: { default: "" },
    vertical: { type: Boolean, default: false },
    showError: { type: Boolean, default: false },
    trueValue: { type: [String, Number, Boolean, null], default: true },
    falseValue: { type: [String, Number, Boolean, null], default: false },
    tableMargin: { default: "none" },
    isChecked: { type: Boolean, default: false },
    size: { default: "small" },
    disabled: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "input"],
  setup(__props: any, { emit: __emit }) {

const greenifiedCheckboxProps = __props;

const emit = __emit;

const selected: Ref<string | number | boolean | null> = ref(
  greenifiedCheckboxProps.modelValue ?? greenifiedCheckboxProps.value
);

watch(
  () => greenifiedCheckboxProps.modelValue,
  newValue => {
    selected.value = newValue;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["checkbox-wrapper position-relative", [
      _ctx.showError && 'error-checkbox',
      `${_ctx.tableMargin}-margin`,
      `checkbox-${_ctx.size}`
    ]]),
      onClick: _cache[2] || (_cache[2] = 
      () => {
        if (selected.value === _ctx.trueValue) {
          selected.value = _ctx.falseValue;
        } else {
          selected.value = _ctx.trueValue;
        }
      }
    )
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        required: _ctx.required,
        id: _ctx.id,
        name: _ctx.name,
        class: _normalizeClass(`checkbox-${_ctx.size}`),
        "true-value": _ctx.trueValue,
        "false-value": _ctx.falseValue,
        disabled: _ctx.disabled,
        value: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
        onInput: _cache[1] || (_cache[1] = 
        event => {
          if (_ctx.disabled) return;
          emit('update:modelValue', selected.value);
          emit('input', event);
        }
      ),
        checked: _ctx.isChecked
      }, null, 42, _hoisted_1), [
        [_vModelCheckbox, selected.value]
      ])
    ], 2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(["subheading-tiny", [_ctx.vertical && 'mb-1', _ctx.showError && 'error-text']])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true)
  ], 64))
}
}

})