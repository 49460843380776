import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  onMounted,
  ref,
  nextTick,
  watch,
} from "vue";

type AccordionContainerProps = {
  toggleAccordion: boolean;
  backgroundColor?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionContainer',
  props: {
    toggleAccordion: { type: Boolean, default: false },
    backgroundColor: { default: "white" }
  },
  setup(__props: any) {

const accordionContainerProps = __props;
let showContent = ref(false);
let contentRef = ref<HTMLElement | null>(null);

onMounted(() => {
  showContent.value = accordionContainerProps.toggleAccordion;
});

watch(
  () => accordionContainerProps.toggleAccordion,
  (expanded: boolean) => {
    if (expanded !== showContent.value) {
      nextTick(() => {
        const contentReference = contentRef.value;
        if (contentReference) {
          showContent.value = expanded;
        }
      });
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["accordion-wrapper", { visible: _unref(showContent) }]),
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["accordion-content", { expanded: _unref(showContent) }]),
      ref_key: "contentRef",
      ref: contentRef
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ], 6))
}
}

})