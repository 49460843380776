import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex gap-5 flex-wrap" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import MainFilter from "@/components/filter/MainFilter.vue";
import GrCard from "@/components/common/GrCard.vue";
import LabelOrderModal from "@/components/modal/LabelOrderModal.vue";
import CurrencyModal from "@/components/modal/CurrencyModal.vue";
import AddressModal from "@/components/modal/AddressModal.vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectSettingsView',
  setup(__props) {

const { t } = useI18n();
const indexStore = useIndexStore();
const router = useRouter();
const showAddresses = ref(false);
const loadingPostAddress = ref(false);
const showCurrency = ref(false);
const showLabel = ref(false);

const currencyName = computed(() => {
  return t("list.currencies." + indexStore.currentProjectCurrency + ".name");
});

const totalLabels = computed(() => {
  return indexStore.quantities?.["E"];
});

const orderedLabels = computed(() => {
  return indexStore.quantities?.["EO"];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      filterTitle: _unref(t)('title.settings'),
      filterText: _unref(t)('paragraph.settings'),
      hideFilterOptions: true
    }, null, 8, ["filterTitle", "filterText"]),
    (loadingPostAddress.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(GrCard, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (showAddresses.value = true)),
        title: _unref(t)('label.addresses')
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: "card-paragraph mt-2",
            innerHTML: _unref(t)('paragraph.settingsAddress')
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(GrCard, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (
        _unref(router).push({
          name: 'projectRoom',
        })
      )),
        title: _unref(t)('label.rooms')
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: "card-paragraph mt-2",
            innerHTML: _unref(t)('paragraph.settingsRooms')
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(GrCard, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (showLabel.value = true)),
        title: _unref(t)('label.orderLabels')
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: "card-paragraph mt-2",
            innerHTML: 
          _unref(t)('paragraph.settingsLabelOrder', {
            tot: totalLabels.value,
            ordered: orderedLabels.value,
          })
        
          }, null, 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(GrCard, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (showCurrency.value = true)),
        title: _unref(t)('label.currency')
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: "card-paragraph mt-2",
            innerHTML: 
          _unref(t)('paragraph.settingsCurrency', {
            currency: currencyName.value,
          })
        
          }, null, 8, _hoisted_5)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    (showCurrency.value)
      ? (_openBlock(), _createBlock(CurrencyModal, {
          key: 1,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showCurrency.value = false))
        }))
      : _createCommentVNode("", true),
    (showLabel.value)
      ? (_openBlock(), _createBlock(LabelOrderModal, {
          key: 2,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (showLabel.value = false))
        }))
      : _createCommentVNode("", true),
    (showAddresses.value)
      ? (_openBlock(), _createBlock(AddressModal, {
          key: 3,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (showAddresses.value = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})