import { useIndexStore } from "@/store";
import { AddressInformation } from "../types/room/rooms";
import addressApi, { ProjectSettingsAdress } from "@/services/api/addressApi";
import { AxiosResponse } from "axios";
import {
  CustomError,
  handleApiRequest,
  Result,
} from "../helpers/handleApiRequests";

export const postNewAddress = async ({
  building,
  address,
}: {
  building: AddressInformation["building"];
  address: AddressInformation["address"];
}): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    addressApi.postNewAddress(
      indexStore.currentProjectNumber,
      building,
      address,
    ),
    "address - postNewAddress",
  );
};

export const updateAddress = async (
  {
    building,
    address,
  }: {
    building: AddressInformation["building"];
    address: AddressInformation["address"];
  },
  id: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    addressApi.updateAddress(
      indexStore.currentProjectNumber,
      building,
      address,
      id,
    ),
    "address - updateAddress",
  );
};

export const deleteAddress = async (
  id: string,
): Promise<Result<string, CustomError>> => {
  const indexStore = useIndexStore();
  return handleApiRequest(
    addressApi.deleteAddress(indexStore.currentProjectNumber, id),
    "address - deleteAddress",
  );
};

export const getAddressesFromIds = async (
  productIds: string[],
): Promise<Result<ProjectSettingsAdress[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    addressApi.getAddressesFromIds(indexStore.currentProjectNumber, productIds),
    "address - getAddressesFromIds",
  );
};

export const getAddresses = async (): Promise<
  Result<ProjectSettingsAdress[], CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    addressApi.getAddresses(indexStore.currentProjectNumber),
    "address - getAddresses",
  );
};
