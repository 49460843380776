<template>
  <GreenifiedModal @close="emit('close')" :type="'large'" :title="''">
    <template #container>
      <LoadingOverlay v-if="isLoading && !filteredRows.length" />

      <div class="row position-relative h-100" v-else>
        <div class="col-6">
          <ProductImageContainer
            :mainImage="
              productGroupInformation?.images.bild_1 ?? { url: '', hash: '' }
            "
            :itemImages="
              productGroupInformation?.images ?? {
                bild_1: { url: '', hash: '' },
                bild_2: { url: '', hash: '' }
              }
            "
          />
        </div>

        <div
          class="col-6 ps-5 info-wrapper d-flex flex-column justify-content-between"
        >
          <div>
            <div class="row">
              <div class="col-12">
                <p class="heading-small">
                  {{ productName }}
                </p>
              </div>
              <div class="col-12 mt-2">
                <p class="text-medium">
                  {{ getSupplierCode }}
                </p>
              </div>
            </div>
            <div class="mt-4 info-row-container">
              <template v-for="(informationRow, i) in filteredRows" :key="i">
                <InformationRow
                  :label="informationRow.label"
                  :showTopBorder="i === 0 ? true : false"
                  :informationRows="informationRow.rows"
                />
              </template>
            </div>
          </div>

          <div class="row" v-if="productGroupInformation?.status !== 'B'">
            <div class="col-12 d-flex justify-content-end">
              <GreenifiedButton
                size="thin"
                btn-style="black"
                :text="t('title.removeProduct')"
                @click="showDeleteProduct = true"
              />
            </div>
          </div>
        </div>
      </div>
      <RemoveProductsModal
        v-if="showDeleteProduct"
        @delete="
          showDeleteProduct = false;
          emit('update');
        "
        @close="showDeleteProduct = false"
        :productGroup="productGroupInformation"
        :productName="productName"
      />
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import "vue-select/dist/vue-select.css";
import RemoveProductsModal from "./modal/RemoveProductsModal.vue";
import InformationRow from "./InformationRow.vue";
import ProductImageContainer from "./ProductImageContainer.vue";
import LoadingOverlay from "./common/LoadingOverlay.vue";
import {
  withDefaults,
  defineProps,
  computed,
  ref,
  Ref,
  onMounted,
  defineEmits
} from "vue";
import { getProductInformationApi } from "@/library/api/products";
import { ProductInformation } from "@/library/types/product/productGroup";
import GreenifiedButton from "./common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./modal/GreenifiedModal.vue";
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
const { t } = useI18n();
export type InformationsRows = {
  label?: string;
  rows: {
    rowLabel: string | null;
    rowValue: string | number;
  }[];
};

type ProductGroupInformationProps = {
  productId: string;
  productName: string;
  supplierCode?: string;
};

const productGroupInformationProps = withDefaults(
  defineProps<ProductGroupInformationProps>(),
  {
    productId: "",
    productName: "",
    supplierCode: ""
  }
);

const isLoading = ref(false);
const productGroupInformation: Ref<ProductInformation | null> = ref(null);
const informationRows: Ref<InformationsRows[]> = ref([]);
const showDeleteProduct = ref(false);

const getSupplierCode = computed(() => {
  if (!productGroupInformation.value) return;
  let supplierCode = null;
  const currentSupplier = productGroupInformationProps.supplierCode;
  if (currentSupplier) {
    supplierCode = currentSupplier.includes("ex")
      ? t("label.unkownSupplier")
      : t(`levkod.${currentSupplier}`);
  }
  return supplierCode;
});

const filteredRows = computed(() => {
  return informationRows.value.filter(infoRow => {
    const hasRowValues = infoRow.rows.find(
      rowValue => rowValue.rowValue && rowValue.rowValue !== "undefined"
    );
    if (hasRowValues) {
      return infoRow;
    }
  });
});

const getProductInformation = async () => {
  if (!productGroupInformationProps.productId) return;
  isLoading.value = true;
  const result = await getProductInformationApi(
    productGroupInformationProps.productId
  );
  isLoading.value = false;

  if (!result.success) return;

  productGroupInformation.value = result.result;
  const productGroup = productGroupInformation.value;
  informationRows.value = [
    {
      label: t("label.besk"),
      rows: [
        {
          rowLabel: null,
          rowValue: productGroup.description
        }
      ]
    },
    {
      label: t("tableTitle.quantity"),
      rows: [
        {
          rowLabel: null,
          rowValue: `${productGroup.same_products.length} ${t("label.pcs")}`
        }
      ]
    },
    {
      label: t("label.manufactured"),
      rows: [
        {
          rowLabel: null,
          rowValue: String(
            productGroup.green_struc[
              getProjectStructureFieldKey("produktionsar")
            ]
          )
        }
      ]
    },
    {
      label: t("label.skick2"),
      rows: [
        {
          rowLabel: null,
          rowValue: t(`skick2.${productGroup.condition}`)
        }
      ]
    },
    {
      label: t("label.designer"),
      rows: [
        {
          rowLabel: null,
          rowValue: String(
            productGroup.green_struc[getProjectStructureFieldKey("designer")]
          )
        }
      ]
    },
    {
      label: t("tableTitle.matt"),
      rows: [
        {
          rowLabel: t("label.length"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("langd")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("langd")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.height"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("hojd")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("hojd")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.width"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("bredd")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("bredd")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.depth"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("djup")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("djup")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.diameter"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("diameter")
          ]
            ? `${
                productGroup.green_struc[
                  getProjectStructureFieldKey("diameter")
                ]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.seat_height"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("sitthojd")
          ]
            ? `${
                productGroup.green_struc[
                  getProjectStructureFieldKey("sitthojd")
                ]
              } mm`
            : ""
        }
      ]
    },
    {
      label: t("label.other"),
      rows: [
        {
          rowLabel: null,
          rowValue: String(productGroup.green_struc.other_information)
        }
      ]
    }
  ];
};
onMounted(() => {
  getProductInformation();
});

const emit = defineEmits(["close", "update"]);
</script>

<style scoped>
.info-row-container {
  height: 48vh;
  overflow: auto;
}
</style>
