<template>
  <MainFilter
    :filterTitle="`${t(
      'button.onGreenified'
    )} - ${totalProductsForSaleQuantity} ${t('title.products')}`"
    :filterText="t('paragraph.overviewSell')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="currentFilter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :productStatus="['G']"
    :hasProducts="quantities > 0"
    :filterButtons="filterButtons"
    v-model:filterTab="currentTab"
  />
  <LoadingOverlay v-if="isLoading" />
  <template v-else>
    <div class="row pb-0">
      <div class="col-12 d-flex">
        <p class="subheading-medium" v-if="currentTab === 1">
          {{ t("title.sell") }}:
          {{
            totalForSaleAmount
              ? totalForSaleAmount.toLocaleString().replace(/,/g, " ")
              : 0
          }}
          {{ convertCurrencyCodeToSymbol(currentCurrency, locale) }}
        </p>
        <p class="subheading-medium" v-else>
          {{ t("title.totalSales") }}:
          {{
            totalSalesAmount
              ? totalSalesAmount.toLocaleString().replace(/,/g, " ")
              : 0
          }}
          {{ convertCurrencyCodeToSymbol(currentCurrency, locale) }}
        </p>
      </div>
    </div>

    <GreenifiedProductTable
      v-if="currentTab === 1"
      :noRowsText="t('label.noRowsPositive')"
      :sortingSettings="sortingSettings"
      :translateHeaders="translateHeaders"
      :filter="currentFilter"
      :productHeaders="productsForSale"
      @getProducts="getGreenifiedProducts"
      :tableType="'sale'"
    />

    <GreenifiedProductTable
      v-else
      :noRowsText="t('label.noRowsPositive')"
      :sortingSettings="sortingSettings"
      :translateHeaders="translateHeaders"
      :filter="currentFilter"
      :productHeaders="soldProducts"
      @getProducts="getGreenifiedProducts"
      :tableType="'sold'"
    />
  </template>
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import {
  computed,
  Ref,
  ref,
  withDefaults,
  defineProps,
  watch,
  onMounted
} from "vue";
import { FilterOptions } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import {
  getGreenifiedData,
  getSaleProductGroups
} from "@/library/api/greenifiedProducts";
import { GreenifiedItem } from "@/library/types/product/productItem";
import GreenifiedProductTable from "@/components/table/GreenifiedProductTable.vue";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import { useIndexStore } from "@/store";
import { convertCurrencyCodeToSymbol } from "@/library/helpers/convertCurrencyCodeToSymbol";

type GreenifiedSalesViewProps = {
  filterQuery?: string;
  setTab: "1" | "2";
};

const greenifiedSalesViewProps = withDefaults(
  defineProps<GreenifiedSalesViewProps>(),
  {
    filterQuery: "",
    setTab: "1"
  }
);

const currentFilter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const { t, locale, messages } = useI18n();
const indexStore = useIndexStore();

const productsForSale: Ref<GreenifiedProductHeader[]> = ref([]);
const totalForSaleAmount = ref(0);
const totalProductsForSaleQuantity = ref(0);
const totalProductsSoldQuantity = ref(0);
const currentTab = ref(
  greenifiedSalesViewProps.setTab ? Number(greenifiedSalesViewProps.setTab) : 1
);
const currentCurrency = useIndexStore().currentProjectCurrency;

const isLoading = ref(false);
const soldProducts: Ref<GreenifiedProductHeader[]> = ref([]);
const totalSalesAmount = ref(0);

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const selectedProductIds: Ref<string[]> = ref([]);

const getProductItemQuantity = (productHeader: GreenifiedProductHeader) => {
  return productHeader.items.filter(productItem =>
    checkFilter(productItem, productHeader)
  ).length;
};

const checkFilter = (
  productItem: GreenifiedItem | null,
  productGroup: GreenifiedProductHeader
) => {
  const filterCategories = {
    ...currentFilter.value.categories
  };
  for (const key in filterCategories) {
    const categories = filterCategories[key];
    if (!checkGroupFilter(categories, productItem, productGroup)) {
      return false;
    }
  }

  for (const key in currentFilter.value.intervals) {
    const interval = currentFilter.value.intervals[key];
    if (productItem && key in productItem) {
      if (
        "from" in interval &&
        !(interval.from <= Number(productItem[key as keyof GreenifiedItem]))
      ) {
        return false;
      }
      if (
        "to" in interval &&
        !(interval.to >= Number(productItem[key as keyof GreenifiedItem]))
      ) {
        return false;
      }
    }
  }

  return true;
};

const checkGroupFilter = (
  categories: FilterOptions["categories"]["key"],
  productItem: GreenifiedItem | null,
  productGroup: GreenifiedProductHeader
) => {
  if (productItem && categories[0].key in productItem) {
    for (let category of categories) {
      if (
        (category.key in productItem &&
          String(productItem[category.key as keyof GreenifiedItem]) ===
            category.value) ||
        (category.key === "levkod" &&
          category.value === "ex" &&
          productItem[category.key].substring(0, 2) === "ex")
      ) {
        return true;
      }
    }
    return false;
  }
  if (categories[0].key === "search" && productGroup) {
    for (let category of categories) {
      const currProductItem = { ...productItem };
      const currProductGroup = { ...productGroup };
      for (let key in Object.assign(currProductItem, currProductGroup)) {
        if (translateHeaders.includes(key)) {
          if (
            t(key + "." + currProductItem[key as keyof GreenifiedItem])
              .toLowerCase()
              .indexOf(category.value.toLowerCase()) !== -1
          ) {
            return true;
          }
        }
        if (
          currProductItem[key as keyof GreenifiedItem] &&
          typeof currProductItem[key as keyof GreenifiedItem] === "string" &&
          String(currProductItem[key as keyof GreenifiedItem])
            .toLowerCase()
            .indexOf(category.value.toLowerCase()) !== -1
        ) {
          return true;
        }
      }
    }
    return false;
  }

  return true;
};

const setGreenifiedStatus = async (productHeader: GreenifiedProductHeader) => {
  if (productHeader.missingOnGreenified) {
    productHeader.loadingStatus = true;
    const greenifiedResult = await getGreenifiedData(productHeader);

    if (!greenifiedResult.success) return productHeader;
    Object.assign(productHeader, greenifiedResult.result);

    productHeader.loadingStatus = false;
  }
  if (productHeader.quantity === 0 && productHeader.salda === 0) {
    productHeader.missingOnGreenified = true;
  }
  return productHeader;
};

const getGreenifiedProducts = async () => {
  isLoading.value = true;
  const result = await getSaleProductGroups();

  if (!result.success) {
    return (isLoading.value = false);
  }

  const quantityResult = await indexStore.updateQuantities();

  if (quantityResult !== "success") {
    return (isLoading.value = false);
  }

  if (!("levkod" in messages.value[locale.value.toLocaleLowerCase()])) {
    const translationResult = await indexStore.getLocaleTranslations(
      indexStore.projectDetails,
      messages.value[locale.value]
    );

    if (translationResult !== "success") {
      return (isLoading.value = false);
    }
  }

  productsForSale.value = result.result.productsForSale;
  soldProducts.value = result.result.soldProducts;
  isLoading.value = false;

  if (productsForSale.value) {
    productsForSale.value.map(async productHeader => {
      productHeader.itemQuantity = getProductItemQuantity(productHeader);
      totalProductsForSaleQuantity.value += productHeader.quantity;
      if (productHeader.quantity && productHeader.pris) {
        totalForSaleAmount.value +=
          productHeader.quantity * Number(productHeader.pris);
      }

      return await setGreenifiedStatus(productHeader);
    });
  }
  if (soldProducts.value) {
    soldProducts.value.map(async productHeader => {
      productHeader.itemQuantity = getProductItemQuantity(productHeader);
      totalProductsSoldQuantity.value += productHeader.salda;

      if (productHeader.salda > 0) {
        totalSalesAmount.value +=
          productHeader.salda * Number(productHeader.pris);
      }
      return productHeader;
    });
  }
};

const filterButtons = computed(() => [
  {
    text: "button.onGreenified",
    amount: totalProductsForSaleQuantity.value,
    show: true
  },
  {
    text: "title.sold",
    amount: totalProductsSoldQuantity.value,
    show: true
  }
]);

const quantities = computed(() => {
  return getQuantitiesByProductStatus("G");
});

onMounted(() => {
  getGreenifiedProducts();
});

watch(
  () => currentTab.value,
  () => {
    selectedProductIds.value = [];
  }
);
</script>
