import { AxiosResponse } from "axios";
import api from "../api";
import { OrderStatuses } from "./salesApi";

export default {
  postImage(formdata: FormData): Promise<AxiosResponse<string>> {
    return api().post("/api/image", formdata, {
      headers: {
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data"
      }
    });
  },

  getPdfFromUrl(url: string): Promise<AxiosResponse<BlobPart>> {
    return api().get(url, {
      responseType: "arraybuffer"
    });
  },

  getPdfFromRoute(
    projectNumber: string,
    language: string,
    route: string
  ): Promise<AxiosResponse<BlobPart>> {
    return api().get(
      `api/project/${projectNumber}/pdf/${route}?lang=${language}`,
      {
        responseType: "arraybuffer"
      }
    );
  },

  getPackingList(
    projectNumber: string,
    language: string,
    orderNumber: string | null,
    orderStatus: OrderStatuses
  ): Promise<AxiosResponse<BlobPart>> {
    return api().get(
      `api/project/${projectNumber}/pdf/packing-list/${orderNumber}/${orderStatus}?lang=${language}`,
      {
        responseType: "arraybuffer"
      }
    );
  },

  getReconditionListsApi(
    projectNumber: string
  ): Promise<AxiosResponse<string[]>> {
    return api().get("/api/project/" + projectNumber + "/recondition-lists");
  }
};
