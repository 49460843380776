import labelsApi from "@/services/api/labelsApi";
import { AxiosResponse } from "axios";
import { useIndexStore } from "@/store";
import {
  CustomError,
  handleApiRequest,
  Result,
} from "../helpers/handleApiRequests";
import { LabelOrder, PostLabelOrder } from "../types/labels/labels";

export const getOrderVerification = async (
  token: string,
): Promise<Result<LabelOrder, CustomError>> => {
  return handleApiRequest(
    labelsApi.getOrderVerification(token),
    "labels - getOrderVerification",
  );
};

export const postGrantedLabelOrder = async (
  token: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    labelsApi.postGrantedLabelOrder(token),
    "labels - postGrantedLabelOrder",
  );
};

export const postDeniedLabelOrder = async (
  token: string,
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    labelsApi.postDeniedLabelOrder(token),
    "labels - postDeniedLabelOrder",
  );
};

export const getLabelOrderFile = async (
  token: string,
): Promise<Result<BlobPart, CustomError>> => {
  return handleApiRequest(
    labelsApi.getLabelOrderFile(token),
    "labels - getLabelOrderFile",
  );
};

export const postLabelOrderApi = async (
  labelOrder: PostLabelOrder,
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    labelsApi.postLabelOrderApi(indexStore.currentProjectNumber, labelOrder),
    "labels - postLabelOrderApi",
  );
};

export const getLabelOrdersApi = async (): Promise<
  Result<LabelOrder[], CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    labelsApi.getLabelOrdersApi(indexStore.currentProjectNumber),
    "labels - getLabelOrdersApi",
  );
};
