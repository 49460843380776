<template>
  <GreenifiedModal
    :title="t('title.addSupplier')"
    @close="emit('close')"
    :isLoading="isLoading"
    :type="'small'"
    :fullHeight="true"
  >
    <template #container>
      <InputField
        v-model:fieldValue="searchValue"
        :placeHolder="t('placeholders.search')"
        :isBlurable="false"
        @update:fieldValue="debounceInput()"
      />
      <div class="supplier-list">
        <p v-if="!searchValue.length && !searchResult.length">
          {{ t("msg.startSearching") }}
        </p>
        <template v-else>
          <div
            class="row supplier-row"
            v-for="supplier in searchResult"
            :key="supplier.varumarke"
          >
            <div class="col">
              {{
                supplier.levkod.startsWith("ex")
                  ? t("label.unkownSupplier")
                  : supplier.varumarke
              }}
            </div>
            <div class="col d-flex justify-content-end">
              <GreenifiedButton
                :disabled="supplier.status === 2"
                :btnStyle="'green'"
                :text="
                  supplier.status === 1
                    ? t('button.loading')
                    : supplier.status === 2
                    ? t('button.added')
                    : t('button.add')
                "
                @click="addSupplier(supplier)"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import debounce from "debounce";
import { defineEmits, Ref, ref } from "vue";
import { SearchedSupplier } from "../../services/api/suppliersApi";
import { getSuppliers, postNewSupplier } from "@/library/api/suppliers";
import GreenifiedModal from "./GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import InputField from "../common/InputField.vue";
import { useI18n } from "vue-i18n";
const emit = defineEmits(["close", "added"]);

const { t } = useI18n();

const searchValue = ref("");
const searchResult: Ref<SearchedSupplier[]> = ref([]);
const isLoading = ref(false);

const addSupplier = async (supplier: SearchedSupplier) => {
  isLoading.value = true;
  supplier["status"] = 1;
  const result = await postNewSupplier(supplier.levkod);

  if (!result.success) {
    isLoading.value = false;
  }

  supplier["status"] = 2;
  emit("added", supplier.levkod);
  emit("close");
};

const debounceInput = debounce(async () => {
  if (searchValue.value.length) {
    isLoading.value = true;

    const result = await getSuppliers(searchValue.value);
    isLoading.value = false;

    if (!result.success) return;

    searchResult.value = result.result;
  }
}, 300);
</script>

<style scoped>
.supplier-row {
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 4rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.supplier-list {
  overflow-y: auto;
  margin-top: 2rem;
  height: 80%;
}
</style>
