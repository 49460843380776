import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = { class: "row table-header ms-0 me-0 d-flex align-items-center" }
const _hoisted_5 = { class: "col-4 ps-0" }
const _hoisted_6 = { class: "col-4 d-flex align-items-center" }
const _hoisted_7 = { class: "col-4 d-flex align-items-center" }
const _hoisted_8 = { class: "col-4 ps-0 d-flex align-items-center" }
const _hoisted_9 = { class: "col-4 d-flex align-items-center flex-wrap" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex me-2"
}
const _hoisted_11 = { class: "fst-italic me-2" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_14 = { class: "fst-italic me-2" }
const _hoisted_15 = { class: "me-2" }
const _hoisted_16 = { class: "col-4 d-flex align-items-center justify-content-between" }

import { ref, onMounted, Ref, watch } from "vue";
import { ExtendedSearch } from "../library/types/search/search";
import {
  RouteLocationAsPathGeneric,
  RouteLocationAsRelativeGeneric,
  useRouter
} from "vue-router";
import { ExtendedSearchFilters, searchAllProducts } from "@/library/api/search";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
import GreenifiedButton from "./common/GreenifiedButton.vue";
type FilteredProduct = {
  group: string;
  value: string | null;
};

type ExtendedFilteredProductsProps = {
  extendedSearchFilters: ExtendedSearchFilters;
  productStatus?: ProductStatuses[];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ExtendedFilteredProducts',
  props: {
    extendedSearchFilters: { default: () => ({}) },
    productStatus: { default: () => ["J"] }
  },
  setup(__props: any) {

const { t, locale } = useI18n();
const router = useRouter();

const extendedFilteredProductsProps = __props;

const searchKeys = ["hit_on", "search_hit", "hit_field", "status", "quantity"];
const showSearchDropdown = ref(false);
const extendedSearchResult: Ref<ExtendedSearch[]> = ref([]);
const isSearching = ref(true);

onMounted(() => {
  getExtendedSearchResults();
});

const getExtendedSearchResults = async () => {
  isSearching.value = true;
  const result = await searchAllProducts(
    extendedFilteredProductsProps.extendedSearchFilters,
    extendedFilteredProductsProps.productStatus,
    locale.value.toLocaleLowerCase()
  );
  isSearching.value = false;

  if (!result.success) return;

  extendedSearchResult.value = result.result;
};

const findFieldKey = (searchResult: ExtendedSearch): FilteredProduct[] => {
  let filteredProducts: FilteredProduct[] = [];

  for (const key in searchResult) {
    if (key !== null && searchResult[key as keyof ExtendedSearch] !== null) {
      const productField = getProductField(
        key,
        searchResult[key as keyof ExtendedSearch]
      );
      if (!searchKeys.includes(key)) {
        filteredProducts.push({
          group: productField.group,
          value: productField.value
        });
      } else if (key === "hit_field") {
        filteredProducts.push({
          group: key,
          value: searchResult[key as keyof ExtendedSearch]
        });
      }
    }
  }
  return filteredProducts;
};

const getProductField = (
  key: string,
  searchResult: string | null
): FilteredProduct => {
  if (
    key === "levkod" ||
    key === "kategori_id" ||
    key === "rum" ||
    key === "main_category"
  ) {
    return {
      group: t(`label.${key}`),
      value: t(`${key}.${searchResult}`)
    };
  }
  return { group: t(`label.${key}`), value: searchResult };
};

const formattedText = (
  valueMatchedBySearch: ExtendedSearch["hit_on"],
  searchHit: ExtendedSearch["search_hit"]
): string => {
  const index = valueMatchedBySearch
    .toLowerCase()
    .indexOf(searchHit.toLowerCase());

  if (index !== -1) {
    const matchedText = valueMatchedBySearch.substr(index, searchHit.length);
    const boldText = `<b>${matchedText}</b>`;
    return valueMatchedBySearch.replace(new RegExp(matchedText, "i"), boldText);
  } else {
    return valueMatchedBySearch;
  }
};

const stringifyObjectQuery = (searchResult: ExtendedSearch): string => {
  const currentSearchResult: { [key: string]: string | null } = {};
  for (const key in searchResult) {
    if (
      searchResult[key as keyof ExtendedSearch] &&
      key !== "quantity" &&
      key !== "status" &&
      key !== "search_hit" &&
      key !== "hit_field"
    ) {
      currentSearchResult[key] = searchResult[key as keyof ExtendedSearch];
    }
  }
  return JSON.stringify(currentSearchResult);
};

const getStatusTextsAndRoutes = (
  status: ExtendedSearch["status"],
  searchResult: ExtendedSearch
): {
  text: string;
  route: string | RouteLocationAsRelativeGeneric | RouteLocationAsPathGeneric;
} => {
  const queryProduct = stringifyObjectQuery(searchResult);
  switch (status) {
    case "B":
      return {
        text: `${t("button.otherSales")} - ${t("title.sold")}`,
        route: {
          name: "internalSaleOverview",
          params: { setTab: 2, filterQuery: queryProduct }
        }
      };
    case "I":
      return {
        text: `${t("button.otherSales")} - ${t("button.otherSales")}`,
        route: {
          name: "internalSaleOverview",
          params: { setTab: 1, filterQuery: queryProduct }
        }
      };
    case "O":
    case "A":
      return {
        text: `${t("button.otherSales")} - ${t("button.otherSales")}`,
        route: {
          name: "internalSaleOverview",
          params: { setTab: 2, filterQuery: queryProduct }
        }
      };

    case "G":
      return {
        text: `Greenified Web`,
        route: {
          name: "projectSellOverview",
          params: { setTab: 1, filterQuery: queryProduct }
        }
      };
    case "C":
      return {
        text: t("label.overviewRecycle"),
        route: {
          name: "projectRecycleOverview",
          params: { filterQuery: queryProduct }
        }
      };
    case "J":
      return {
        text: t("title.handleProducts"),
        route: {
          name: "projectAction",
          params: { filterQuery: queryProduct }
        }
      };
    case "Å":
      return {
        text: t("button.repurpose"),
        route: {
          name: "projectRepurpose",
          params: { filterQuery: queryProduct }
        }
      };
    case "S":
      return {
        text: t("label.sell"),
        route: {
          name: "projectSell",
          params: { filterQuery: queryProduct }
        }
      };
    case "K":
      return {
        text: t("label.recycle"),
        route: {
          name: "projectRecycle",
          params: { filterQuery: queryProduct }
        }
      };
    case "R":
      return {
        text: t("title.recondition"),
        route: {
          name: "projectRecondition",
          params: { filterQuery: queryProduct }
        }
      };
    case "M":
      return {
        text: t("label.overviewRepurpose"),
        route: {
          name: "projectRepurposeOverview",
          params: { filterQuery: queryProduct }
        }
      };
  }

  return {
    text: "",
    route: {}
  };
};
watch(
  () => extendedFilteredProductsProps.extendedSearchFilters,
  () => {
    getExtendedSearchResults();
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!extendedSearchResult.value.length || isSearching.value)
            ? (_openBlock(), _createBlock(GreenifiedButton, {
                key: 0,
                disabled: "",
                text: 
            isSearching.value
              ? _unref(t)('button.searchingForMoreProducts')
              : _unref(t)('button.couldNotFindMoreProducts')
          
              }, null, 8, ["text"]))
            : _createCommentVNode("", true),
          (extendedSearchResult.value.length && !isSearching.value)
            ? (_openBlock(), _createBlock(GreenifiedButton, {
                key: 1,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showSearchDropdown.value = !showSearchDropdown.value)),
                text: 
            !showSearchDropdown.value
              ? _unref(t)('button.showOtherSearchHits', [extendedSearchResult.value.length])
              : _unref(t)('button.hideOtherSearchHits', [extendedSearchResult.value.length])
          
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (showSearchDropdown.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)("tableTitle.state")), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)("tableTitle.searchHit")), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)("tableTitle.quantity")), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(extendedSearchResult.value, (searchResult, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "row table-item ms-0 me-0"
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(getStatusTextsAndRoutes(searchResult.status, searchResult).text), 1),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(findFieldKey(searchResult).slice().reverse(), (field) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: field.group
                  }, [
                    (searchKeys.includes(field.group))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(t)(`tableTitle.${field.value}`)), 1),
                          _createElementVNode("p", {
                            innerHTML: 
                  formattedText(searchResult.hit_on, searchResult.search_hit)
                
                          }, null, 8, _hoisted_12)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(field.group), 1),
                          _createElementVNode("p", _hoisted_15, _toDisplayString(field.value), 1)
                        ]))
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createTextVNode(_toDisplayString(searchResult.quantity) + " ", 1),
                _createVNode(GreenifiedButton, {
                  onClick: ($event: any) => (
              _unref(router).push(
                getStatusTextsAndRoutes(searchResult.status, searchResult).route
              )
            ),
                  text: _unref(t)('button.goToTheProduct'),
                  btnStyle: 'green',
                  size: "auto"
                }, null, 8, ["onClick", "text"])
              ])
            ]))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})