import { TableFooterButton } from "../types/tables/footer";

export const tableFooterButtons: TableFooterButton[] = [
  {
    status: "CP",
    toggleDisabled: true,
    tooltipText: null,
    buttonText: {
      text: "button.combineProducts",
      translate: true
    },
    action: "combineProducts",
    color: "normal"
  },
  {
    status: "SI",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "label.moveBackToAvailable",
      translate: true
    },
    action: "soldInternal",
    color: "black"
  },
  {
    status: "A",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "label.moveBackToAvailable",
      translate: true
    },
    action: "availableInternal",
    color: "black"
  },

  {
    status: "F",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.changeLocation",
      translate: true
    },
    action: "move",
    color: "normal"
  },

  {
    status: "R",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.selectRenovation",
      translate: true
    },
    action: "renovation",
    color: "green"
  },
  {
    status: "CR",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.changeRenovation",
      translate: true
    },
    action: "renovation",
    color: "green"
  },
  {
    status: "G",
    toggleDisabled: true,
    tooltipText: "label.crossGroupDisable",
    buttonText: {
      text: "button.sellOnGreenified",
      translate: true
    },
    action: "greenifiedSale",
    color: "green"
  },
  {
    status: "I",
    toggleDisabled: true,
    tooltipText: "label.crossGroupDisable",
    buttonText: {
      text: "button.sellInternal",
      translate: true
    },
    action: "internalSale",
    color: "green"
  },
  {
    status: "FT",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.move",
      translate: true
    },
    action: "toRoom",
    color: "normal"
  },
  {
    status: "KC",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.keepCondition",
      translate: true
    },
    action: "keepCondition",
    color: "green"
  },
  {
    status: "Å",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.toBeRepurposed",
      translate: true
    },
    action: "changeStatus",
    color: "green"
  },

  {
    status: "S",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.toBeSold",
      translate: true
    },
    action: "changeStatus",
    color: "green"
  },
  {
    status: "K",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.toBeRecycled",
      translate: true
    },
    action: "changeStatus",
    color: "green"
  },
  {
    status: "M",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.markAsFixed",
      translate: true
    },
    action: "changeStatus",
    color: "green"
  },
  {
    status: "C",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.markAsRecycled",
      translate: true
    },
    action: "changeAction",
    color: "green"
  },
  {
    status: "EI",
    toggleDisabled: true,
    tooltipText: "label.crossGroupDisable",
    buttonText: {
      text: "button.edit",
      translate: true
    },
    action: "editInternalSale",
    color: "black"
  },
  {
    status: "CA",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.changeAction",
      translate: true
    },
    action: "changeAction",
    color: "normal"
  },
  {
    status: "AR",
    toggleDisabled: false,
    tooltipText: null,
    buttonText: {
      text: "button.addRoom",
      translate: true
    },
    action: "addRoom",
    color: "normal"
  },
  {
    status: "SR",
    toggleDisabled: true,
    tooltipText: null,
    buttonText: {
      text: "button.save",
      translate: true
    },
    action: "saveRoom",
    color: "green"
  },
  {
    status: "IU",
    toggleDisabled: true,
    tooltipText: null,
    buttonText: {
      text: "button.inviteUser",
      translate: true
    },
    action: "inviteUser",
    color: "green"
  },

  {
    status: "US",
    toggleDisabled: true,
    tooltipText: null,
    buttonText: {
      text: "button.updateSoldProducts",
      translate: true
    },
    action: "updateSoldProducts",
    color: "black"
  }
];
