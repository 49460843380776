import axios from "axios";
import { createI18n } from "vue-i18n";
import { I18nMessageSchema } from "./library/types/locales/i18nMessage";
import { Pinia } from "pinia";
import { useIndexStore } from "./store";

const i18nMessages = async (): Promise<I18nMessageSchema> => {
  return await axios
    .get(
      `https://app-ext-api.inputinterior.se/api/get-translations/INVENTORY`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => {
      return {
        sv: response.data.sv,
        en: response.data.en,
        da: response.data.da,
        no: response.data.no,
        fi: response.data.fi
      };
    });
};

const getLocaleFromLocationHost = (): string => {
  const host = window.location.host;
  if (
    host.includes("se") ||
    host.includes("localhost") ||
    host.includes("staging")
  ) {
    return "sv";
  }
  if (host.includes("dk")) {
    return "da";
  }
  if (host.includes("no")) {
    return "no";
  }
  if (host.includes("fi")) {
    return "fi";
  }
  return "en";
};

export const loadI18n = async (pinia: Pinia) => {
  const messages = await i18nMessages();

  const localeStore = useIndexStore(pinia);
  const fallbackLocale =
    localeStore.currentProjectLanguage ?? getLocaleFromLocationHost();

  return createI18n({
    legacy: false,
    fallbackLocale,
    locale: fallbackLocale,
    missingWarn: false,
    inheriteLocale: true,
    fallbackWarn: false,
    warnHtmlMessage: false,
    globalInjection: true,
    silentFallbackWarn: true,
    messages
  });
};
