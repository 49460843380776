import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = {
  key: 1,
  class: "me-1 d-flex"
}
const _hoisted_3 = {
  key: 0,
  class: "fst-italic me-2"
}

import historyIcon from "@/assets/img/history-thin.svg";
import penIcon from "@/assets/img/pen.svg";
import InformationTooltip from "@/components/common/InformationTooltip.vue";
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import { ColumnActions } from "@/library/types/tables/columns";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import { Dimensions } from "@/library/types/product/productItem";

type StandardSubActionsProps = {
  actions: ColumnActions[];
  isChecked?: boolean;
  columnValue?: string | number | Dimensions;
  disableCheckbox?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'StandardSubActions',
  props: {
    actions: { default: () => [] },
    isChecked: { type: Boolean, default: false },
    columnValue: { default: "" },
    disableCheckbox: { type: Boolean, default: false }
  },
  emits: ["showEdit", "showHistory", "update:isChecked"],
  setup(__props: any, { emit: __emit }) {

const standardSubActionsProps = __props;

const { t } = useI18n();
const currIsChecked = ref(standardSubActionsProps.isChecked);
const emit = __emit;

watch(
  () => standardSubActionsProps.isChecked,
  () => {
    currIsChecked.value = standardSubActionsProps.isChecked;
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.actions.includes('history'))
      ? (_openBlock(), _createBlock(InformationTooltip, {
          key: 0,
          class: "me-1",
          tooltipIcon: _unref(historyIcon),
          altText: "History icon",
          size: 'large',
          infoText: _unref(t)('title.productHistory'),
          offset: "0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('showHistory')))
        }, null, 8, ["tooltipIcon", "infoText"]))
      : _createCommentVNode("", true),
    (_ctx.actions.includes('checkbox'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (typeof _ctx.columnValue === 'string' && _ctx.columnValue.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)(_ctx.columnValue)), 1))
            : _createCommentVNode("", true),
          _createVNode(GreenifiedCheckbox, {
            trueValue: true,
            disabled: _ctx.disableCheckbox && !_ctx.isChecked,
            falseValue: false,
            modelValue: currIsChecked.value,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((currIsChecked).value = $event)),
              _cache[2] || (_cache[2] = ($event: any) => (emit('update:isChecked', currIsChecked.value)))
            ]
          }, null, 8, ["disabled", "modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.actions.includes('edit'))
      ? (_openBlock(), _createBlock(InformationTooltip, {
          key: 2,
          tooltipIcon: _unref(penIcon),
          altText: "Pen icon",
          size: 'small',
          infoText: _unref(t)('title.editProduct'),
          offset: "0",
          class: "me-2",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('showEdit')))
        }, null, 8, ["tooltipIcon", "infoText"]))
      : _createCommentVNode("", true)
  ]))
}
}

})