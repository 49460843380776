import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/img/drop-arrow.svg'


const _hoisted_1 = {
  key: 0,
  class: "row ms-0 me-0 table-row"
}
const _hoisted_2 = { style: {"font-weight":"400"} }
const _hoisted_3 = { class: "d-flex align-items-center px-3 py-2" }
const _hoisted_4 = {
  key: 1,
  class: "d-flex align-items-center justify-content-between w-100"
}

import vSelect, { VueSelectSlotScope } from "vue-select";
import { ref } from "vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AddressInformation, RoomObject } from "../library/types/room/rooms";
import { withPopper } from "../modules/popperModule";
import { TableColumns } from "@/library/types/tables/columns";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { useI18n } from "vue-i18n";
import InputField from "./common/InputField.vue";
import GreenifiedCheckbox from "./common/GreenifiedCheckbox.vue";
import GreenifiedButton from "./common/GreenifiedButton.vue";
import ProjectAddressForm from "./ProjectAddressForm.vue";

type RoomHandlerLineProps = {
  roomToUpdate: RoomObject | null;
  roomColumns: TableColumns["columns"];
  projectAddresses: ProjectSettingsAdress[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomHandlerLine',
  props: {
    roomToUpdate: { default: null },
    roomColumns: {},
    projectAddresses: { default: () => [] }
  },
  emits: ["update", "refreshAddress", "delete"],
  setup(__props: any, { emit: __emit }) {

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { t } = useI18n();
const emit = __emit;

const roomHandlerLineProps = __props;

const currentRoomToUpdate = ref(roomHandlerLineProps.roomToUpdate);
const showAddAddress = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.roomColumns && currentRoomToUpdate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roomColumns, (columnValue, columnKey) => {
            return (_openBlock(), _createElementBlock("div", {
              key: columnKey,
              class: _normalizeClass(["d-flex align-items-center ps-0", [columnValue.columnStyling]])
            }, [
              (columnKey === 'hus')
                ? (_openBlock(), _createBlock(_unref(vSelect), {
                    key: String(showAddAddress.value),
                    clearable: false,
                    "onOption:selecting": _cache[1] || (_cache[1] = ($event: any) => (emit('update', currentRoomToUpdate.value))),
                    options: _ctx.projectAddresses,
                    class: "w-75",
                    label: "building",
                    placeholder: 
            columnValue.inputSettings?.placeholder &&
            _unref(t)(columnValue.inputSettings.placeholder)
          ,
                    reduce: (option) => option.id,
                    modelValue: currentRoomToUpdate.value.hus,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentRoomToUpdate.value.hus) = $event)),
                    "calculate-position": _unref(withPopper),
                    "append-to-body": ""
                  }, {
                    "open-indicator": _withCtx(({ attributes }: { attributes: VueSelectSlotScope }) => [
                      _createElementVNode("span", _mergeProps({ ref_for: true }, attributes), _cache[9] || (_cache[9] = [
                        _createElementVNode("img", { src: _imports_0 }, null, -1)
                      ]), 16)
                    ]),
                    option: _withCtx(({
              building,
              address
            }: {
              building: AddressInformation['building'],
              address: AddressInformation['address']
            }) => [
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(building) + " ", 1),
                        _createElementVNode("p", _hoisted_2, [
                          _createTextVNode(_toDisplayString(address.adress), 1),
                          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(_toDisplayString(address.postnummer) + "," + _toDisplayString(address.ort) + "," + _toDisplayString(address.land), 1)
                        ])
                      ])
                    ]),
                    "list-footer": _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(GreenifiedButton, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (showAddAddress.value = true)),
                          btnStyle: "black",
                          text: _unref(t)('button.addBuildingAddress'),
                          size: "thin"
                        }, null, 8, ["text"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["options", "placeholder", "reduce", "modelValue", "calculate-position"]))
                : _createCommentVNode("", true),
              (columnKey === 'new_room')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(GreenifiedCheckbox, {
                      trueValue: '1',
                      falseValue: '0',
                      modelValue: currentRoomToUpdate.value.new_room,
                      "onUpdate:modelValue": [
                        _cache[3] || (_cache[3] = ($event: any) => ((currentRoomToUpdate.value.new_room) = $event)),
                        _cache[4] || (_cache[4] = ($event: any) => (emit('update', currentRoomToUpdate.value)))
                      ],
                      size: 'large'
                    }, null, 8, ["modelValue"]),
                    _createVNode(GreenifiedButton, {
                      "btn-style": "black",
                      onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (emit('delete')), ["prevent"])),
                      text: _unref(t)('button.remove'),
                      size: "thin"
                    }, null, 8, ["text"])
                  ]))
                : _createCommentVNode("", true),
              (columnKey !== 'new_room' && columnKey !== 'hus')
                ? (_openBlock(), _createBlock(InputField, {
                    key: 2,
                    "field-value": currentRoomToUpdate.value[columnKey as keyof RoomObject],
                    "onUpdate:fieldValue": [
                      ($event: any) => ((currentRoomToUpdate.value[columnKey as keyof RoomObject]) = $event),
                      _cache[6] || (_cache[6] = ($event: any) => (emit('update', currentRoomToUpdate.value)))
                    ],
                    placeHolder: 
            columnValue.inputSettings?.placeholder &&
            _unref(t)(columnValue.inputSettings.placeholder)
          ,
                    type: columnValue.inputSettings?.type,
                    maxlength: columnValue.inputSettings?.maxlength,
                    required: columnValue.inputSettings?.required
                  }, null, 8, ["field-value", "onUpdate:fieldValue", "placeHolder", "type", "maxlength", "required"]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (showAddAddress.value)
      ? (_openBlock(), _createBlock(ProjectAddressForm, {
          key: 1,
          onClose: _cache[7] || (_cache[7] = ($event: any) => (showAddAddress.value = false)),
          title: _unref(t)('title.addAddress'),
          onRefresh: _cache[8] || (_cache[8] = ($event: any) => {
      showAddAddress.value = false;
      emit('refreshAddress');
    })
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})