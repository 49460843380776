<template>
  <div class="col-4 card-wrapper" :class="active && 'active-card-wrapper'">
    <img :src="productImage" alt="product image" class="product-card-image" />
    <div class="product-info-container">
      <p class="subheading-small mt-3 cursor-pointer">{{ productName }}</p>
      <p class="text-small mt-2 cursor-pointer">{{ supplier }}</p>
      <div class="d-flex mt-2 cursor-pointer">
        <p class="subheading-small cursor-pointer">
          {{ t("label.kategori_id") }}:
        </p>
        <p class="text-small ms-2 cursor-pointer">
          {{ category }}
        </p>
      </div>
      <div class="d-flex mt-2 cursor-pointer">
        <p class="subheading-small cursor-pointer">
          {{ t("label.quantity") }}:
        </p>
        <p class="text-small ms-2 cursor-pointer">
          {{ quantity }} {{ t("label.pcs") }}
        </p>
      </div>

      <div class="d-flex mt-2 description-container">
        <p class="subheading-small cursor-pointer">
          {{ t("placeholders.reconditionInfo") }}:
          <span class="text-small ms-2 cursor-pointer">{{ description }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { withDefaults, defineProps } from "vue";
import { useI18n } from "vue-i18n";

type ProductCardProps = {
  productImage: string;
  productName: string;
  supplier: string;
  quantity: number;
  description: string;
  active: boolean;
  category: string;
};

withDefaults(defineProps<ProductCardProps>(), {
  productImage: "",
  productName: "",
  supplier: "",
  quantity: 0,
  description: "",
  active: false,
  category: ""
});

const { t } = useI18n();
</script>

<style scoped>
.card-wrapper {
  padding: 0.6rem;
  cursor: pointer;
  height: 21.6rem;
  border: 1.5px solid transparent;
}

.active-card-wrapper,
.card-wrapper:hover {
  transition: 0.2s;
  border: 1.5px solid black;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.product-card-image {
  width: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.product-info-container {
  height: 12rem;
  overflow: scroll;
}
</style>
