<template>
  <GreenifiedModal
    v-if="productId"
    @close="emit('close')"
    :type="'medium'"
    :maxHeight="true"
    :title="modalTitle"
    :isLoading="isLoading"
    :key="editType"
  >
    <template #modalHeaderContent>
      <div class="d-flex" v-if="productType === 'item'">
        <GreenifiedButton
          :btnStyle="editType === 0 ? 'black' : 'normal'"
          @click="editType = 0"
          :text="t('button.specificInformation')"
          size="thin"
          class="me-4"
        />

        <GreenifiedButton
          :btnStyle="editType === 1 ? 'black' : 'normal'"
          @click="editType = 1"
          :text="t('button.extendedInformation')"
          size="thin"
        />
      </div>
    </template>
    <template #container>
      <div v-if="productInformation">
        <template v-if="productType === 'item'">
          <SpecificProductInformation
            v-if="editType === 0 && productItemSpecifics"
            :key="editType"
            v-model:productSpecifics="productItemSpecifics"
          />
          <GeneralProductInformation
            v-if="editType === 1"
            ref="generalProductInformation"
            :key="editType"
            :productInformation="productInformation"
          />
        </template>
        <GeneralProductInformation
          v-else
          :key="editType"
          ref="generalProductInformation"
          :productInformation="productInformation"
        />
      </div>
    </template>

    <template #footer>
      <div class="row justify-content-end">
        <div class="col-2">
          <GreenifiedButton
            :text="t('button.save')"
            @click="handleFormSubmit()"
            :disabled="isLoading"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-if="updateStatus === 'success'"
    :type="'response'"
    :closable="false"
    :title="t('responseTitle.successfullyUpdated')"
    :text="t('paragraph.productHasBeenUpdated')"
    @close="
      updateStatus = null;
      emit('refresh');
    "
  />
</template>

<script setup lang="ts">
import { ProductInformation } from "../../library/types/product/productGroup";
import {
  ProductItemInformation,
  ProductItemSale
} from "../../library/types/product/productItem";
import {
  withDefaults,
  defineProps,
  ref,
  defineEmits,
  Ref,
  onMounted
} from "vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import GeneralProductInformation from "../form/editProduct/GeneralProductInformation.vue";
import { unexpectedError } from "@/modules/errorToastModule";
import {
  getProductInformationApi,
  updateProductItemSpecifics,
  updateProductItemStructure
} from "@/library/api/products";
import GreenifiedModal from "./GreenifiedModal.vue";
import SpecificProductInformation from "../form/editProduct/SpecificProductInformation.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { updateProductsWithSameGreenifiedHash } from "@/library/api/greenifiedProducts";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
type EditProductsModalProps = {
  productType: "item" | "group";
  productId: ProductItemSale["id"] | null;
  modalTitle: string;
  productStatus: ProductStatuses;
  productIds: string[];
};

const editProductsModalProps = withDefaults(
  defineProps<EditProductsModalProps>(),
  {
    productType: "group",
    productId: null,
    modalTitle: "",
    productStatus: "J",
    productIds: () => []
  }
);

const emit = defineEmits(["close", "refresh"]);
const isLoading = ref(false);
const editType = ref(0);
const productInformation: Ref<ProductInformation | null> = ref(null);
const updateStatus: Ref<"success" | "fail" | null> = ref(null);
const productItemSpecifics: Ref<ProductItemInformation | null> = ref(null);
const generalProductInformation =
  ref<InstanceType<typeof GeneralProductInformation>>();

const getProductInformation = async () => {
  isLoading.value = true;
  if (!editProductsModalProps.productId) {
    unexpectedError();
    return emit("close");
  }

  const result = await getProductInformationApi(
    editProductsModalProps.productId
  );
  isLoading.value = false;

  if (!result.success) return;

  productInformation.value = result.result;
  const currentProductSpecifics = productInformation.value.same_products.find(
    product => product.id === editProductsModalProps.productId
  );

  if (currentProductSpecifics) {
    productItemSpecifics.value = currentProductSpecifics;
    if (!Object.keys(productItemSpecifics.value.productSpecifics).length) {
      productItemSpecifics.value.productSpecifics = {};
    }
  }
};

const handleFormSubmit = () => {
  if (editProductsModalProps.productType === "item") {
    return editType.value === 0 ? updateItemSpecifics() : updateItemStructure();
  }

  return updateProductsWithSameHash();
};

const updateItemStructure = async () => {
  if (!editProductsModalProps.productId || !productInformation.value) return;
  if (!generalProductInformation.value?.validateForm()) return;

  isLoading.value = true;
  const currentProductInformation = { ...productInformation.value };
  currentProductInformation.same_products = currentProductInformation[
    "same_products"
  ].filter(elem => elem.id === currentProductInformation.id);

  const result = await updateProductItemStructure(
    editProductsModalProps.productId,
    currentProductInformation
  );
  isLoading.value = false;

  if (!result.success) {
    updateStatus.value = "fail";
    return;
  }

  updateStatus.value = "success";
  getProductInformation();
};

const updateItemSpecifics = async () => {
  if (!productItemSpecifics.value) return;
  isLoading.value = true;
  const result = await updateProductItemSpecifics(productItemSpecifics.value);
  isLoading.value = false;

  if (!result.success) {
    updateStatus.value = "fail";
    return;
  }
  updateStatus.value = "success";
};

const updateProductsWithSameHash = async () => {
  const currentProductInformation = productInformation.value;
  if (!currentProductInformation || !editProductsModalProps.productIds.length)
    return;
  if (!generalProductInformation.value?.validateForm()) return;

  isLoading.value = true;
  const result = await updateProductsWithSameGreenifiedHash(
    [currentProductInformation.green_hash],
    currentProductInformation.green_struc,
    currentProductInformation.supplier_code,
    currentProductInformation.category,
    editProductsModalProps.productStatus,
    editProductsModalProps.productIds
  );
  isLoading.value = false;

  if (!result.success) {
    updateStatus.value = "fail";
    return;
  }

  updateStatus.value = "success";

  getProductInformation();
};

onMounted(() => {
  getProductInformation();
});
</script>
