import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/img/logo.svg'


const _hoisted_1 = { class: "sidebar-container pt-5 d-flex flex-column justify-content-between h-100" }
const _hoisted_2 = { class: "container-fluid ps-0 pe-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 0,
  class: "h-100"
}
const _hoisted_6 = { class: "row mt-5" }
const _hoisted_7 = { class: "action-buttons-container" }
const _hoisted_8 = {
  key: 0,
  class: "mt-3 row"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "row mt-2" }
const _hoisted_11 = { class: "row mt-3" }
const _hoisted_12 = { class: "mt-3 row" }
const _hoisted_13 = { class: "row align-items-center mt-4" }
const _hoisted_14 = { class: "col-2" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "col-10 ps-4" }
const _hoisted_17 = { class: "yellow-text" }
const _hoisted_18 = { class: "name-text" }

import { sidebarButtons } from "@/library/helpers/sidebarButtons";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import SidebarButton from "./SidebarButton.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";

type SidebarContentProps = {
  showContent: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBarContent',
  props: {
    showContent: { type: Boolean, default: false }
  },
  setup(__props: any) {

const sidebarContentProps = __props;

const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const store = useIndexStore();
const authStore = useAuthStore();
const toggleShowContent = ref(true);
const quantities = computed(() => {
  return store.quantities;
});

const imageSrc = authStore.user.avatarIcon;
const defaultImageSrc = computed(() => {
  return require("@/assets/img/avatar-icon.svg");
});

const userEmail = computed(() => {
  return authStore.currentUser.email;
});

const userName = computed(() => {
  return authStore.currentUser.name;
});

watch(
  () => sidebarContentProps.showContent,
  showContent => {
    if (showContent) {
      window.setTimeout(() => {
        toggleShowContent.value = true;
      }, 150);
    } else {
      toggleShowContent.value = false;
    }
  },
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'projectOverview' })))
          })
        ])
      ]),
      (!toggleShowContent.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (toggleShowContent.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(SidebarButton, {
                routeName: "projectAction",
                mainHeader: 1,
                buttonText: "button.handleProductsSidebar",
                "main-quantities": ['J'],
                isCompleted: quantities.value?.J === 0,
                isMainButton: "",
                subRoutes: []
              }, null, 8, ["isCompleted"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sidebarButtons)(), (button, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (button.showButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(SidebarButton, {
                          buttonText: button.buttonText,
                          isCompleted: button.isCompleted,
                          mainHeader: button.mainHeader,
                          subRoutes: button.subRoutes,
                          mainQuantities: button.mainQuantities,
                          routeName: button.routeName,
                          isMainButton: button.isMainButton
                        }, null, 8, ["buttonText", "isCompleted", "mainHeader", "subRoutes", "mainQuantities", "routeName", "isMainButton"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (toggleShowContent.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          ((Number(_unref(store).currentProjectNumber) ?? 0) !== 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_router_link, { to: { name: 'projectOverview' } }, {
                    default: _withCtx(() => [
                      _createVNode(GreenifiedButton, {
                        btnStyle: _unref(route).meta.mainHeader === 5 ? 'black' : 'normal',
                        text: _unref(t)('button.dashboard'),
                        size: "thin"
                      }, null, 8, ["btnStyle", "text"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_router_link, { to: { name: 'projectUsers' } }, {
                    default: _withCtx(() => [
                      _createVNode(GreenifiedButton, {
                        btnStyle: _unref(route).meta.mainHeader === 6 ? 'black' : 'normal',
                        text: _unref(t)('button.user'),
                        size: "thin"
                      }, null, 8, ["btnStyle", "text"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_router_link, { to: { name: 'projectSettings' } }, {
                    default: _withCtx(() => [
                      _createVNode(GreenifiedButton, {
                        btnStyle: _unref(route).meta.mainHeader === 7 ? 'black' : 'normal',
                        text: _unref(t)('button.settings'),
                        size: "thin"
                      }, null, 8, ["btnStyle", "text"])
                    ]),
                    _: 1
                  })
                ])
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("img", {
                class: "avatar-icon g-0",
                src: _unref(imageSrc) ?? defaultImageSrc.value,
                alt: "Avatar",
                onError: _cache[1] || (_cache[1] = ($event: any) => (imageSrc.value = defaultImageSrc.value))
              }, null, 40, _hoisted_15)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(t)("label.loggedInAs")), 1),
              _createElementVNode("div", _hoisted_18, _toDisplayString(userName.value ?? userEmail.value), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})