import { AxiosResponse } from "axios";
import api from "../api";

export type CalculatedPrice = {
  calcPrice: string;
};

export type TranslationTable = {
  projekt: string;
  iso: string;
  tabell: object;
};

export type Quantities = {
  B: number;
  C: number;
  E: number;
  EO: number;
  F: number;
  G: number;
  I: number;
  J: number;
  K: number;
  M: number;
  R: number;
  S: number;
  Å: number;
  O: number;
};

export type PriceSuggestion = {
  estimatedvalue: string;
}[];

export type IntervalOptions = {
  sort: string;
  label: string;
  max: string;
  min: string;
};

export default {
  getTranslationTable(
    projectNr: string,
    iso: string,
  ): Promise<AxiosResponse<TranslationTable[]>> {
    return api().get("/api/project/" + projectNr + "/translation-table/" + iso);
  },

  getGuestTranslationTable(
    guestToken: string,
    iso: string,
  ): Promise<AxiosResponse<TranslationTable[]>> {
    return api().get("/api/guest/" + guestToken + "/translation-table/" + iso);
  },

  getPriceEvaluation(
    projectNumber: string,
    ids: string[],
  ): Promise<AxiosResponse<PriceSuggestion>> {
    return api().get(
      `/api/project/${projectNumber}/evaluation?${encodeURI(
        "ids[]=" + ids.join("&ids[]="),
      )}`,
    );
  },

  getCalculatedPrice(
    price: number,
    category: string,
    quantity: number,
    country: string,
    currency: string,
  ): Promise<AxiosResponse<CalculatedPrice>> {
    return api().get(
      `/api/calculate-sale-price?${encodeURI(
        "price=" +
          price +
          "&category=" +
          category +
          "&quantity=" +
          quantity +
          "&country=" +
          country +
          "&currency=" +
          currency,
      )}`,
    );
  },

  getQuantities(projectNr: string): Promise<AxiosResponse<Quantities>> {
    return api().get(`/api/project/${projectNr}/quantities`);
  },

  getGuestQuantities(guestToken: string): Promise<AxiosResponse<Quantities>> {
    return api().get(`/api/guest/${guestToken}/quantities`);
  },

  postLocaleCurrency(
    projectNr: string,
    currency: string,
  ): Promise<AxiosResponse> {
    return api().post(
      "/api/project/" + projectNr + "/currency",
      JSON.stringify({ currency: currency }),
    );
  },

  getIntervalOptions(
    projectNumber: string,
    intervalOptions: string[],
  ): Promise<AxiosResponse<IntervalOptions[]>> {
    return api().get(
      `/api/project/${projectNumber}/interval-min-max?${encodeURI(
        "col[]=" + intervalOptions.join("&col[]="),
      )}`,
    );
  },

  getGuestIntervalOptions(
    guestToken: string,
    intervalOptions: string[],
  ): Promise<AxiosResponse<IntervalOptions[]>> {
    return api().get(
      `/api/guest/${guestToken}/interval-min-max?${encodeURI(
        "col[]=" + intervalOptions.join("&col[]="),
      )}`,
    );
  },
};
