import router from "@/router";
import axios, { AxiosError } from "axios";
import { useAuthStore } from "@/store/auth";
import { useIndexStore } from "@/store";

const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": process.env.VUE_APP_LARAVEL_API,
};

export default () => {
  const authStore = useAuthStore();
  const indexStore = useIndexStore();

  const instance = axios.create({
    baseURL: process.env.VUE_APP_LARAVEL_API,
    withCredentials: false,
    headers: header,
  });

  instance.interceptors.request.use(response => {
    return response;
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    (error: AxiosError) => {
      if (
        error.response?.status === 401 &&
        router.currentRoute.value.meta.middleware !== "guest"
      ) {
        authStore.logout();
      }

      if (error.response?.status === 403) {
        indexStore.projectDetails = {
          alla_rum: [],
          active: "0",
          land: "",
          valuta: "SEK",
          guest_token: "",
          sprak: "en",
          projektbesk: "",
          namn: "",
          projekt: "",
          product_cat: [],
          lev: [],
          role: null,
          struc: [],
        };
      }

      return Promise.reject(error);
    },
  );

  return instance;
};
