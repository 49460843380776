import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12 d-flex align-items-center" }
const _hoisted_3 = { class: "subheading-tiny" }
const _hoisted_4 = { class: "row mt-2" }
const _hoisted_5 = { class: "col-sm-12" }

import RequiredWarning from "@/components/common/RequiredWarning.vue";
import { computed, Ref, ref } from "vue";

import { postPasswordReset } from "@/library/api/user";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import InputField from "../common/InputField.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordModal',
  emits: ["accept", "close"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const emit = __emit;

const email = ref("");
const resetSuccess = ref(false);
const resetError = ref(false);
const showWarning = ref(false);
const resetform: Ref<HTMLFormElement | null> = ref(null);

const translatedText = computed(() => {
  return t("msg.passwordResetSuccess", [
    `<span style="font-weight: bolder">${email.value}</span>`
  ]);
});

const resetPassword = async () => {
  if (email.value.length) {
    const result = await postPasswordReset(email.value);

    if (!result.success) {
      resetError.value = true;
      return;
    }

    resetSuccess.value = true;
  } else {
    showWarning.value = true;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      type: "verification",
      closable: false,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
      title: _unref(t)('title.resetPassword'),
      "confirm-text": _unref(t)('button.send'),
      onConfirm: _cache[2] || (_cache[2] = 
      () => {
        if (resetform.value && resetform.value.reportValidity()) {
          resetPassword();
        }
      }
    )
    }, {
      container: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)("label.enterYourEmail")), 1),
            (showWarning.value)
              ? (_openBlock(), _createBlock(RequiredWarning, {
                  key: 0,
                  warningText: _unref(t)('msg.requiredField')
                }, null, 8, ["warningText"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("form", {
              ref_key: "resetform",
              ref: resetform
            }, [
              _createVNode(InputField, {
                type: "email",
                "field-value": email.value,
                "onUpdate:fieldValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                required: ""
              }, null, 8, ["field-value"])
            ], 512)
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "confirm-text"]),
    (resetSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.passwordReset'),
          text: translatedText.value,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (emit('accept')))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (resetError.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.passwordResetError'),
          text: _unref(t)('msg.passwordResetError'),
          onClose: _cache[4] || (_cache[4] = ($event: any) => (resetError.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})