import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, Ref, ref } from "vue";
import { I18nMessage } from "../library/types/locales/i18nMessage";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import ProductTable from "@/components/table/ProductTable.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

type ProjectRepurposeOverviewProps = {
  filterQuery?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectRepurposeOverview',
  props: {
    filterQuery: { default: "" }
  },
  setup(__props: any) {



const { t, messages, locale } = useI18n();
const moveList: Ref<{ label: string; url: string }> = ref({
  label: "",
  url: ""
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];

const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});

const presetFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "besk",
    "kategori_id",
    "levkod",
    "recondition",
    "kommentar",
    "status",
    "green_hash"
  ],
  status: ["M"]
};

onMounted(() => {
  moveList.value = localeMoveLists.value[0];
});

const localeMoveLists = computed(() => {
  const moveLists = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).moveLists;
  return Object.keys(moveLists).map(
    localeKey => moveLists[localeKey as keyof I18nMessage["list"]["moveLists"]]
  );
});

const quantities = computed(() => {
  return getQuantitiesByProductStatus("M") + getQuantitiesByProductStatus("F");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      filterTitle: `${_unref(t)('title.repurposeOverview')} - ${quantities.value} ${_unref(t)(
      'title.products'
    )}`,
      filterText: _unref(t)('paragraph.repurposeOverview'),
      filterQuery: _ctx.filterQuery,
      defaultFilter: filter.value,
      "onUpdate:defaultFilter": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event)),
      defaultSortingSettings: sortingSettings.value,
      "onUpdate:defaultSortingSettings": _cache[1] || (_cache[1] = ($event: any) => ((sortingSettings).value = $event)),
      translateHeaders: translateHeaders,
      pdfRoute: 'repurpose-overview',
      hasProducts: quantities.value > 0
    }, null, 8, ["filterTitle", "filterText", "filterQuery", "defaultFilter", "defaultSortingSettings", "hasProducts"]),
    _createVNode(ProductTable, {
      actionStatuses: ['CP', 'FT', 'CA'],
      noRowsText: _unref(t)('label.noRowsPositive'),
      filter: filter.value,
      "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((filter).value = $event)),
      tableType: "recondition",
      sortingSettings: sortingSettings.value,
      translateHeaders: translateHeaders,
      presetFilter: presetFilter
    }, null, 8, ["noRowsText", "filter", "sortingSettings"])
  ], 64))
}
}

})