import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = { class: "row row-gap-3" }

import { ref } from "vue";
import GreenifiedImage from "@/components/common/GreenifiedImage.vue";
import {
  FormImagesObject,
  ProductImages,
} from "@/library/types/product/productImage";

type FormImagesProps = {
  formImages: FormImagesObject;
  productImages: ProductImages;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageField',
  props: {
    formImages: { default: () => ({
    bild_1: {
      fieldKey: "",
      imageHash: "",
    },
    bild_2: {
      fieldKey: "",
      imageHash: "",
    },
  }) },
    productImages: { default: () => ({
    bild_1: {
      url: "",
      hash: "",
    },
    bild_2: {
      url: "",
      hash: "",
    },
    bild_3: {
      url: "",
      hash: "",
    },
    bild_4: {
      url: "",
      hash: "",
    },
  }) }
  },
  emits: ["update:formImages", "update:productImages"],
  setup(__props: any, { emit: __emit }) {

const formImagesProps = __props;

const emit = __emit;
const currentProductImages = ref(formImagesProps.productImages);
const currentFormImages = ref(formImagesProps.formImages);
const requiredImageIndex = [0, 1];

const handleUpdatedImages = (imageKey: keyof ProductImages) => {
  emit("update:formImages", currentFormImages);
  if (
    !currentFormImages.value[imageKey]?.imageHash &&
    imageKey in currentProductImages.value
  ) {
    delete currentProductImages.value[imageKey];
    return emit("update:productImages", currentProductImages.value);
  }

  currentProductImages.value[imageKey] = {
    url: "",
    hash: currentFormImages.value[imageKey]?.imageHash ?? "",
  };
  return emit("update:productImages", currentProductImages.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-4" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentFormImages.value, (imageValue, imageKey, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-6",
            key: index
          }, [
            (currentFormImages.value[imageKey])
              ? (_openBlock(), _createBlock(GreenifiedImage, {
                  key: 0,
                  imageUrl: currentFormImages.value[imageKey].imageHash,
                  "onUpdate:imageUrl": [($event: any) => ((currentFormImages.value[imageKey].imageHash) = $event), ($event: any) => (handleUpdatedImages(imageKey))],
                  imageLabel: currentFormImages.value[imageKey].fieldKey,
                  imageIndex: index + 1,
                  imageIsRequired: requiredImageIndex.includes(index)
                }, null, 8, ["imageUrl", "onUpdate:imageUrl", "imageLabel", "imageIndex", "imageIsRequired"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})