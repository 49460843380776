<template>
  <MainFilter
    :filterTitle="`${t('title.repurposeOverview')} - ${quantities} ${t(
      'title.products'
    )}`"
    :filterText="t('paragraph.repurposeOverview')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="filter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :pdfRoute="'repurpose-overview'"
    :hasProducts="quantities > 0"
  />

  <ProductTable
    :actionStatuses="['CP', 'FT', 'CA']"
    :noRowsText="t('label.noRowsPositive')"
    v-model:filter="filter"
    tableType="recondition"
    :sortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :presetFilter="presetFilter"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, defineProps, withDefaults } from "vue";
import { I18nMessage } from "../library/types/locales/i18nMessage";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import ProductTable from "@/components/table/ProductTable.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

type ProjectRepurposeOverviewProps = {
  filterQuery?: string;
};

withDefaults(defineProps<ProjectRepurposeOverviewProps>(), {
  filterQuery: ""
});

const { t, messages, locale } = useI18n();
const moveList: Ref<{ label: string; url: string }> = ref({
  label: "",
  url: ""
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];

const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});

const presetFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "besk",
    "kategori_id",
    "levkod",
    "recondition",
    "kommentar",
    "status",
    "green_hash"
  ],
  status: ["M"]
};

onMounted(() => {
  moveList.value = localeMoveLists.value[0];
});

const localeMoveLists = computed(() => {
  const moveLists = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).moveLists;
  return Object.keys(moveLists).map(
    localeKey => moveLists[localeKey as keyof I18nMessage["list"]["moveLists"]]
  );
});

const quantities = computed(() => {
  return getQuantitiesByProductStatus("M") + getQuantitiesByProductStatus("F");
});
</script>
