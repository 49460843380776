import { useIndexStore } from "@/store";
import salesApi, {
  Advertisement,
  InternalSaleOrder,
  OldOrdersAsAdvertisement,
  OrderDataWithProducts,
  OrderForUpdate,
  PurchaseOrder
} from "@/services/api/salesApi";
import { ProductItem } from "../types/product/productItem";
import { AxiosResponse } from "axios";
import { FormFields } from "../types/structure/formStructure";
import {
  CustomError,
  handleApiRequest,
  Result
} from "../helpers/handleApiRequests";
import { InternalProductHeaders } from "@/services/api/productsApi";

export const postGreenifiedSale = async (
  productIds: ProductItem["id"][]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.postGreenifiedSale(indexStore.currentProjectNumber, productIds),
    "sales - postGreenifiedSale"
  );
};

export const getInternalAdvertisements = async (): Promise<
  Result<InternalProductHeaders, CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.getInternalAdvertisements(indexStore.currentProjectNumber),
    "sales - getInternalAdvertisements"
  );
};

export const getInternalAdvertisement = async (
  advertisementId: number
): Promise<Result<Advertisement, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.getInternalAdvertisement(
      indexStore.currentProjectNumber,
      advertisementId
    ),
    "sales - getInternalAdvertisement"
  );
};

export const getInternalOrdersByAdvertisementId = async (
  advertisementId: number
): Promise<Result<InternalSaleOrder[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.getInternalOrdersByAdvertisementId(
      indexStore.currentProjectNumber,
      advertisementId
    ),
    "sales - getInternalOrdersByAdvertisementId"
  );
};

export const getInternalOrders = async (
  orderStatuses: string[]
): Promise<Result<InternalSaleOrder[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.getInternalOrders(indexStore.currentProjectNumber, orderStatuses),
    "sales - getInternalOrders"
  );
};

export const postInternalAdvertisement = async (
  productIds: ProductItem["id"][],
  internalSaleInformation: FormFields
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.postInternalAdvertisement(
      indexStore.currentProjectNumber,
      productIds,
      internalSaleInformation
    ),
    "sales - postInternalAdvertisement"
  );
};

export const updateInternalAdvertisement = async (
  productIds: ProductItem["id"][],
  internalSaleInformation: FormFields,
  advertisementId: number
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.updateInternalAdvertisement(
      indexStore.currentProjectNumber,
      productIds,
      internalSaleInformation,
      advertisementId
    ),
    "sales - updateInternalAdvertisement"
  );
};

export const postOldOrderAsAdvertisement = async (
  oldOrders: OldOrdersAsAdvertisement[]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.postOldOrderAsAdvertisement(
      indexStore.currentProjectNumber,
      oldOrders
    ),
    "sales - postOldOrderAsAdvertisement"
  );
};

export const postInternalOrder = async (
  purchaseOrder: PurchaseOrder
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.postInternalOrder(indexStore.currentProjectNumber, purchaseOrder),
    "sales - postInternalOrder"
  );
};

export const postGuestOrder = async (
  guestToken: string,
  purchaseOrder: PurchaseOrder
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    salesApi.postGuestOrder(guestToken, purchaseOrder),
    "sales - postGuestOrder"
  );
};

export const updateInternalOrder = async (
  internalSaleInformation: OrderDataWithProducts,
  productIds: string[]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.updateInternalOrder(
      indexStore.currentProjectNumber,
      internalSaleInformation,
      productIds
    ),
    "sales - updateInternalOrder"
  );
};

export const denyInternalOrders = async (
  orders: OrderForUpdate[]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    salesApi.denyInternalOrders(indexStore.currentProjectNumber, orders),
    "sales - denyInternalOrders"
  );
};
