import api from "../api";
import {
  ProductItemInformation,
  ProductItemSale
} from "../../library/types/product/productItem";
import {
  ProductGroupSale,
  ProductInformation
} from "../../library/types/product/productGroup";
import { AxiosResponse } from "axios";
import {
  AdvertisementProductHeader,
  OrderProductHeader,
  ProductHeaderSale,
  ProductStatuses
} from "@/library/types/product/productHeader";

export type ReconditionUpdate = {
  comment: string | null;
  to_room: string | null;
  recondition: { [key: string]: { text: string; key: string } };
};

export type InternalProductHeaders = {
  advertisements: AdvertisementProductHeader;
  ongoingOrders: OrderProductHeader;
  completedOrders: OrderProductHeader;
  oldOrders: ProductHeaderSale;
};

export type HistoryLog = {
  fromText: string | null;
  toText: string;
  type: string;
  who: string;
  when: string;
  log_id: string;
  type_name: string;
};

export default {
  getProductHeadersApi(
    projectNr: string,
    filter: {
      filter: { key: string; operator: string; value: string[] }[];
      group: string[];
      status: string[];
      intervals?: object;
    }
  ): Promise<AxiosResponse<ProductHeaderSale>> {
    return api().post(
      "/api/project/" + projectNr + "/item",
      JSON.stringify(filter)
    );
  },

  getProductItemsApi(
    projectNr: string,
    filter: {
      filter: { key: string; operator: string; value: string[] }[];
      group: string[];
      status: string[];
      intervals?: object;
    }
  ): Promise<AxiosResponse<ProductItemSale[]>> {
    return api().post(
      "/api/project/" + projectNr + "/item",
      JSON.stringify(filter)
    );
  },

  getGuestAdvertisements(
    guestToken: string
  ): Promise<AxiosResponse<AdvertisementProductHeader>> {
    return api().get(
      "/api/guest/" + guestToken + "/guest-advertisements?guestToken"
    );
  },

  getItemLogHistory(
    projectNr: string,
    itemId: string
  ): Promise<AxiosResponse<HistoryLog[]>> {
    return api().get(`/api/project/${projectNr}/product/${itemId}/history`);
  },

  getPublishedItemApi(
    projectNr: string,
    greenifiedId: string
  ): Promise<AxiosResponse<ProductGroupSale>> {
    return api().get(
      `/api/project/${projectNr}/greenified/product?greenified_id=${greenifiedId}`
    );
  },

  postProductItemsAsSold(
    projectNr: string,
    productsToUpdate: { [key: string]: string | number }
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNr}/greenified/product/update`,
      JSON.stringify({ productsToUpdate: productsToUpdate })
    );
  },

  updateStatus(
    projectNumber: string,
    ids: string[],
    status: ProductStatuses
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/status`,
      JSON.stringify({ ids: ids, status: status })
    );
  },

  updateRecondition(
    projectNumber: string,
    ids: string[],
    data: ReconditionUpdate
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/action`,
      JSON.stringify({ ids: ids, data: data })
    );
  },

  getProductInformationApi(id: string): Promise<AxiosResponse> {
    return api().get(`/api/item/${id}`);
  },

  updateProductItemStructure(
    id: string,
    productGroup: ProductInformation
  ): Promise<AxiosResponse> {
    return api().put(`/api/item/${id}`, JSON.stringify(productGroup));
  },

  postCombineProducts(
    projectnr: string,
    productHeaderHash: string[],
    productIds: string[],
    productInformation: ProductInformation
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectnr}/combine`,
      JSON.stringify({
        productHeaderHash: productHeaderHash,
        productIds: productIds,
        data: productInformation
      })
    );
  },

  updateProductItemSpecifics(
    id: string,
    itemData: ProductItemInformation
  ): Promise<AxiosResponse> {
    return api().post(`/api/item/${id}`, JSON.stringify(itemData));
  },

  getProductItemsInRoom(
    projectNr: string,
    roomNr: string
  ): Promise<AxiosResponse<ProductHeaderSale>> {
    return api().post(
      "/api/project/" + projectNr + "/item",
      JSON.stringify({
        filter: [{ key: "rum", operator: "=", value: roomNr }],
        group: [
          "comp_id",
          "benamn",
          "besk",
          "kategori_id",
          "levkod",
          "rum",
          "status"
        ]
      })
    );
  },

  updateProductsSoldStatus(
    projectNr: string,
    productsToUpdate: { [key: string]: "0" | "1" }
  ) {
    return api().post(
      `/api/project/${projectNr}/greenified/product/update`,
      JSON.stringify({ productsToUpdate: productsToUpdate })
    );
  }
};
