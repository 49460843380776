<template>
  <GrLayoutSimple>
    <template v-slot:top-navbar>
      <div class="col-12 d-flex justify-content-end">
        <SimpleSelect />
      </div>
    </template>

    <template v-slot:container> <slot></slot></template>
    <template v-slot:footer> <footer-contact></footer-contact></template>
  </GrLayoutSimple>
</template>

<script setup lang="ts">
import GrLayoutSimple from "@/layouts/GrLayoutSimple.vue";
import FooterContact from "@/components/FooterContact.vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import SimpleSelect from "@/components/common/SimpleSelect.vue";
import { I18nLocale } from "@/library/types/locales/locales";
import { useI18n } from "vue-i18n";
const indexStore = useIndexStore();
const { messages, locale } = useI18n();

const { currentRoute } = useRouter();

const currentLanguage = ref(indexStore.currentProjectLanguage);

const selectLanguage = (selectedLanguage: I18nLocale) => {
  indexStore.changeProjectLanguage(selectedLanguage, locale);
  if (currentRoute.value.params.token) {
    indexStore.getGuestLocaleTranslations(
      indexStore.currentProject,
      currentRoute.value.params.token as string,
      messages.value[locale.value]
    );
  }
};

onMounted(() => {
  if (currentLanguage.value === null) {
    selectLanguage("sv");
  }
});
</script>

<style scoped>
.lang-selector {
  width: 3rem !important;
}

p.show + * {
  opacity: 1;
}

.gr-dropdown-menu {
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  transition: opacity 0.3s;
}
</style>
