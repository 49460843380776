<template>
  <v-select
    required
    :placeholder="getPlaceholder()"
    :options="getItemRooms()"
    multiple
    :closeOnSelect="false"
    :calculate-position="withPopper"
    append-to-body
    :clearable="false"
    label="comp_id"
    :deselectFromDropdown="true"
    :reduce="(option: ProductItemSale)  => option.id"
  >
    <template #selected-option-container>
      <div class="vs__search vs__hidden_selected_options">
        {{
          t("placeholders.selectedProducts", {
            count: productIds.length,
            of: maxQuantity
          })
        }}
      </div>
    </template>

    <template #option="{ id, comp_id, rum }">
      <div
        class="form-label-paragraph fw-bold mt-2 mb-2 header-label"
        style="color: black"
        v-if="!id"
      >
        {{ t("label.room") + " " + t("rum." + rum) }}
      </div>
      <template v-if="id">
        <div
          class="d-flex w-100 align-items-center"
          @click="handleSelectProductId(id)"
          v-if="!reservedProductIds.includes(id)"
        >
          <template
            v-if="!productIds.includes(id) && productIds.length === maxQuantity"
          >
            <InformationTooltip
              :infoText="t('paragraph.noMoreProductsCanBeAdded')"
              class="w-100"
            >
              <template #icon>
                <div class="d-flex w-100 align-items-center">
                  <img
                    src="@/assets/img/square-small.svg"
                    class="small-checkbox"
                  />
                  <div class="ms-2 fw-normal text-muted">
                    {{ comp_id }}
                  </div>
                </div></template
              >
            </InformationTooltip></template
          >
          <template v-else>
            <img
              src="@/assets/img/check-small.svg"
              class="small-checkbox"
              v-if="productIds.includes(id)"
            />
            <img
              src="@/assets/img/square-small.svg"
              class="small-checkbox"
              v-else
            />
            <div class="ms-2 fw-normal">
              {{ comp_id }}
            </div>
          </template>
        </div>

        <template v-else>
          <div
            class="d-flex w-100 align-items-center justify-content-between ps-1 fw-normal text-muted"
          >
            <div class="d-flex">
              {{ comp_id }}
            </div>
            <p>{{ t("placeholders.reserved") }}</p>
          </div>
        </template>
      </template>
    </template>
    <template
      #open-indicator="{ attributes }: { attributes: VueSelectSlotScope }"
    >
      <span v-bind="attributes">
        <img src="@/assets/img/drop-arrow.svg" />
      </span>
    </template>
  </v-select>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ProductItemSale } from "@/library/types/product/productItem";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import { withPopper } from "@/modules/popperModule";
import { withDefaults, defineProps, ref, Ref, defineEmits, watch } from "vue";
import { useI18n } from "vue-i18n";
import InformationTooltip from "./InformationTooltip.vue";

type RoomWithProductItems = [{ rum: string }, ...ProductItemSale[]];

type ProductsSelectProps = {
  productItems: ProductItemSale[];
  reservedProductIds: string[];
  soldProductIds: string[];
  maxQuantity: number;
  selectedProductIds: string[];
  selectableIds?: string[];
  requiredQuantity: boolean;
};
const productsSelectProps = withDefaults(defineProps<ProductsSelectProps>(), {
  productItems: () => [],
  reservedProductIds: () => [],
  soldProductIds: () => [],
  maxQuantity: 1,
  selectedProductIds: () => [],
  selectableIds: () => [],
  requiredQuantity: true
});

const { t } = useI18n();
const emit = defineEmits(["update:selectedProductIds"]);
const productIds: Ref<string[]> = ref(productsSelectProps.selectedProductIds);

const getPlaceholder = () => {
  if (productsSelectProps.requiredQuantity) {
    return productIds.value.length !== productsSelectProps.maxQuantity
      ? t("placeholders.selectProducts", {
          count: productsSelectProps.maxQuantity
        })
      : t("placeholders.selectedProducts", {
          count: productIds.value.length,
          of: productsSelectProps.maxQuantity
        });
  }
  return t("label.selectProducts");
};

const getItemRooms = () => {
  let currProductItems = productsSelectProps.productItems;

  let availableProductItems = [...currProductItems];

  if (productsSelectProps.soldProductIds.length) {
    availableProductItems = currProductItems.filter(
      productItem =>
        !productsSelectProps.soldProductIds.includes(productItem.id)
    );
  }

  if (productsSelectProps.selectableIds.length) {
    availableProductItems = availableProductItems.filter(productItem =>
      productsSelectProps.selectableIds.includes(productItem.id)
    );
  }

  const distinctRooms = new Set(availableProductItems.map(item => item.rum));

  const distinctRoomsToArray = Array.from(distinctRooms);
  const rooms = distinctRoomsToArray.map(item => {
    return { rum: item };
  });

  availableProductItems.sort((a, b) => {
    const aIsReserved = productsSelectProps.reservedProductIds.includes(a.id);
    const bIsReserved = productsSelectProps.reservedProductIds.includes(b.id);

    if (aIsReserved === bIsReserved) return 0;

    return aIsReserved ? 1 : -1;
  });

  const concatinatedRooms = [
    ...rooms,
    ...availableProductItems
  ] as RoomWithProductItems;

  const sortedRooms = concatinatedRooms.sort((a, b) => {
    return Number(a.rum) - Number(b.rum);
  }) as RoomWithProductItems;

  return sortedRooms;
};

const handleSelectProductId = (productId: string) => {
  const productIdIndex = productIds.value.findIndex(
    existingProductId => existingProductId === productId
  );

  if (productIdIndex !== -1) {
    productIds.value.splice(productIdIndex, 1);
    emit("update:selectedProductIds", productIds.value);
    return;
  }

  if (productIds.value.length === productsSelectProps.maxQuantity) return;

  productIds.value.push(productId);
  emit("update:selectedProductIds", productIds.value);
};

watch(
  () => productsSelectProps.selectedProductIds,
  updatedIds => {
    productIds.value = updatedIds;
  }
);
</script>
