import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-1 text-tiny fw-bolder"
}
const _hoisted_2 = ["disabled", "readonly", "rows", "max", "min", "type", "value", "required", "id", "placeholder"]
const _hoisted_3 = ["readonly", "disabled", "rows", "max", "min", "type", "required", "id", "placeholder"]
const _hoisted_4 = {
  key: 3,
  class: "input-helpers"
}
const _hoisted_5 = {
  key: 0,
  class: "error-text me-1"
}
const _hoisted_6 = { key: 1 }

import { watch } from "vue";
import {
  ref,
  onMounted,
  Ref
} from "vue";

type InputTextFieldProps = {
  label?: string;
  id?: string;
  type?: string;
  width?: string;
  modelValue: string | number | null;
  value?: string | number;
  showError?: boolean;
  showUnit?: boolean;
  disabled?: boolean;
  errorText?: string;
  unitText?: string | number;
  placeHolder?: string;
  setFocus?: boolean;
  min?: number | Date | null;
  max?: number | Date | null;
  isRequired?: boolean;
  notEditable?: boolean;
  rows?: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextField',
  props: {
    label: { default: "" },
    id: { default: "Input field" },
    type: { default: "text" },
    width: { default: "" },
    modelValue: { default: "" },
    value: { default: "" },
    showError: { type: Boolean, default: false },
    showUnit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorText: { default: "Error" },
    unitText: { default: "mm" },
    placeHolder: { default: "" },
    setFocus: { type: Boolean, default: false },
    min: { default: null },
    max: { default: null },
    isRequired: { type: Boolean, default: false },
    notEditable: { type: Boolean, default: false },
    rows: { default: 0 }
  },
  emits: [
  "update:modelValue",
  "hasError",
  "update:hasError",
  "blur",
  "click"
],
  setup(__props: any, { emit: __emit }) {

const inputTextFieldProps = __props;

const emit = __emit;
const inputValue = ref(inputTextFieldProps.modelValue);
let showPassword = ref(false);
const inputField: Ref<HTMLInputElement | null> = ref(null);

const inputFocus = () => {
  if (inputField.value) {
    emit("click");
    inputField.value.focus();
  }
};

onMounted(() => {
  if (inputTextFieldProps.setFocus) {
    inputFocus();
  }
});

watch(
  () => inputTextFieldProps.showError,
  hasError => {
    emit("update:hasError", hasError);
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "input-field-wrapper",
    onClick: _cache[5] || (_cache[5] = ($event: any) => (inputFocus()))
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (emit('blur'))),
          class: _normalizeClass(["greenified-input", [_ctx.width, _ctx.showError && 'error', _ctx.notEditable && 'not-editable']]),
          disabled: _ctx.disabled,
          readonly: _ctx.notEditable,
          rows: _ctx.rows,
          ref_key: "inputField",
          ref: inputField,
          max: _ctx.max,
          min: _ctx.min,
          type: _unref(showPassword) ? 'text' : _ctx.type,
          value: _ctx.value,
          required: _ctx.isRequired,
          id: _ctx.id,
          placeholder: _ctx.placeHolder,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (emit('update:modelValue', inputValue.value)))
        }, null, 42, _hoisted_2))
      : _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 2,
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (emit('blur'))),
          class: _normalizeClass(["greenified-input", [_ctx.width, _ctx.showError && 'error']]),
          readonly: _ctx.notEditable,
          disabled: _ctx.disabled,
          rows: _ctx.rows,
          ref_key: "inputField",
          ref: inputField,
          max: _ctx.max,
          min: _ctx.min,
          type: _unref(showPassword) ? 'text' : _ctx.type,
          required: _ctx.isRequired,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((inputValue).value = $event)),
          id: _ctx.id,
          placeholder: _ctx.placeHolder,
          onInput: _cache[4] || (_cache[4] = ($event: any) => (emit('update:modelValue', inputValue.value)))
        }, null, 42, _hoisted_3)), [
          [_vModelText, inputValue.value]
        ]),
    (_ctx.showError || _ctx.showUnit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.showError)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.errorText), 1))
            : _createCommentVNode("", true),
          (_ctx.showUnit)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.unitText), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})