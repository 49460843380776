<template>
  <LoadingOverlay
    v-if="store.updatingProject"
    :overlay="true"
    :isMainLoader="currentRoute.meta.middleware === 'auth'"
  />
  <main-layout v-if="authenticated && currentRoute.meta.middleware === 'auth'">
    <template v-if="store.cookiesAreVerified">
      <router-view
        v-slot="{ Component, route }"
        :key="store.currentProjectNumber"
        v-if="
          store.currentProjectNumber.length &&
          store.currentProject.active === '1'
        "
      >
        <component :is="Component" :key="route.path" />
      </router-view>
    </template>
    <ProjectModal v-if="showProjectModal" />
  </main-layout>
  <simple-layout v-else>
    <template v-if="store.cookiesAreVerified">
      <RouterView :key="currentRoute.fullPath"> </RouterView>
    </template>
  </simple-layout>
  <CookieInformation v-if="cookiesModal" @close="cookiesModal = false" />
</template>

<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import CookieInformation from "@/components/modal/CookieInformation.vue";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
import "@lbgm/phone-number-input/style";
import { computed, onMounted, ref } from "vue";
import { RouterView, useRouter } from "vue-router";
import { useIndexStore } from "./store";
import { useAuthStore } from "./store/auth";
import ProjectModal from "./views/ProjectModal.vue";
import LoadingOverlay from "./components/common/LoadingOverlay.vue";
const store = useIndexStore();
const authStore = useAuthStore();
const { currentRoute } = useRouter();
const cookiesModal = ref(false);
const showProjectModal = computed(() => {
  return !store.currentProjectNumber.length && authStore.isAuthenticated;
});

onMounted(() => {
  store.setUpdatingProjectStatus(false);
  if (!store.cookiesAreVerified) {
    cookiesModal.value = true;
    return;
  }
});

const authenticated = computed(() => {
  return (
    authStore.isAuthenticated && currentRoute.value.meta.middleware !== "guest"
  );
});
</script>

<style></style>
