<template>
  <GreenifiedModal
    :title="t('title.PostProductOnInternally')"
    @close="emit('close')"
    ref="reconModal"
    :type="'smaller'"
    :isLoading="isLoading"
  >
    <template #container>
      <form id="form-id" ref="complementForm" v-if="productInformation">
        <div class="row">
          <div class="col-12">
            <FieldLabel
              :fieldLabel="t('label.location')"
              :showTooltip="true"
              :tooltipInstructions="t('msg.infoOfChangingRoomForInternalSell')"
              :placing="'top'"
            />
          </div>
          <div class="col-12 position-relative mt-2">
            <SelectRoom
              :isLoadingRoom="isLoading"
              rightMenu
              isReduced
              v-model="productInformation.location"
              :append="true"
              :placeholder="
                uniqueRooms.length > 1 ? t('placeholders.differentRooms') : ''
              "
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <FieldLabel
              :fieldLabel="t('label.whenIsProductAvailable')"
              :placing="'top'"
              :requiredField="1"
              :presetFieldValue="
                productSaleInformation['nar_ar_produkten_tillganglig']
              "
              :form-is-submitted="formIsSubmitted"
            />
          </div>
          <div class="col-12 mt-2">
            <InputField
              type="date"
              maxValue="9999-12-31"
              v-model:fieldValue="
                productSaleInformation.nar_ar_produkten_tillganglig
              "
              :required="true"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <FieldLabel
              :fieldLabel="t('label.sellInGroupOrEach')"
              :placing="'top'"
              :requiredField="1"
              :presetFieldValue="
                productSaleInformation['saljs_styckvis_eller_i_grupp']
              "
              :form-is-submitted="formIsSubmitted"
            />
          </div>
          <div
            class="col-5 mt-2"
            v-for="(option, key) in {
              single: t('label.single'),
              group: t('label.group')
            }"
            :key="option"
          >
            <GreenifiedButton
              inputType="radio"
              :disabled="selectedProductIds.length === 1 && key === 'group'"
              :textAlign="
                selectedProductIds.length === 1 && key === 'group'
                  ? 'left'
                  : 'center'
              "
              :input-required="true"
              inputName="saljs_styckvis_eller_i_grupp"
              :type="'button'"
              @click="
                () => {
                  if (key === 'single') {
                    delete productSaleInformation['ange-antal'];
                  }
                  productSaleInformation.saljs_styckvis_eller_i_grupp = key;
                }
              "
              :text="option"
              :inputValue="option"
              :inputId="option + '-' + key"
              :btnStyle="
                productSaleInformation.saljs_styckvis_eller_i_grupp === key
                  ? 'black'
                  : 'normal'
              "
            />
          </div>
        </div>

        <div
          class="row mt-3"
          v-if="productSaleInformation.saljs_styckvis_eller_i_grupp === 'group'"
        >
          <div class="col-12">
            <FieldLabel
              :fieldLabel="t('label.soldInGroupOf')"
              :placing="'top'"
              :requiredField="1"
              :presetFieldValue="productSaleInformation['ange-antal']"
              :form-is-submitted="formIsSubmitted"
            />
          </div>

          <div class="col-12 mt-2">
            <InputField
              type="number"
              v-model:fieldValue="productSaleInformation['ange-antal']"
              minValue="2"
              :maxValue="selectedProductIds.length"
              required
              @update:field-value="currentProductIds = []"
            />
          </div>
        </div>

        <div
          class="row mt-3 mb-2"
          v-if="
            typeof productSaleInformation['ange-antal'] === 'number' &&
            productsForSaleDoesNotMatchGroupAmount
          "
        >
          <div class="col-12">
            <FieldLabel
              :fieldLabel="t('label.selectQuantity')"
              :placing="'top'"
              :requiredField="1"
            />

            <RequiredWarningText
              v-if="
                currentProductIds.length < 2 ||
                currentProductIds.length %
                  productSaleInformation['ange-antal'] !==
                  0
              "
              :text="t('msg.fieldRequired', [t('label.selectQuantity')])"
              textClass="form-label"
            />
          </div>

          <div class="col-12 mt-2">
            <ProductsSelect
              v-if="selectedProduct"
              :maxQuantity="countMaxGroupQuantity"
              :product-items="selectedProduct.items"
              :selectableIds="selectedProductIds"
              :reservedProductIds="[]"
              :soldProductIds="[]"
              :requiredQuantity="false"
              v-model:selectedProductIds="currentProductIds"
            />
          </div>
        </div>

        <div class="row mt-3 mb-2">
          <div class="col-12">
            <FieldLabel
              :fieldLabel="t('label.price')"
              :placing="'top'"
              :requiredField="1"
              :presetFieldValue="productSaleInformation['pris']"
              :form-is-submitted="formIsSubmitted"
            />
          </div>

          <div class="col-12 mt-2">
            <InputField
              type="number"
              minValue="0"
              v-model:fieldValue="productSaleInformation.pris"
              required
            />
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="col-12 d-flex">
        <GreenifiedButton
          :disabled="isLoading"
          btnStyle="black"
          :text="t('button.close')"
          size="thin"
          @click="emit('close')"
          class="me-3"
        />
        <GreenifiedButton
          :disabled="isLoading"
          btnStyle="green"
          size="thin"
          :text="
            isLoading ? t('button.pleaseWait') : t('button.postInternally')
          "
          @click="postAdvertisement()"
        />
      </div>
    </template>
  </GreenifiedModal>

  <GreenifiedModal
    v-if="publishSuccess"
    type="response"
    :closable="false"
    :title="
      type === 'internalSale'
        ? t('responseTitle.productIsPublished')
        : t('paragraph.advertisementHasBeenUpdated')
    "
    :text="getResponseText()"
    @close="
      emit('refresh');
      emit('close');
    "
  />
</template>

<script setup lang="ts">
import SelectRoom from "../SelectRoom.vue";
import {
  withDefaults,
  defineProps,
  ref,
  Ref,
  onMounted,
  defineEmits,
  computed
} from "vue";
import { bolderFont } from "../../library/helpers/typography";
import { ProductInformation } from "../../library/types/product/productGroup";
import { AddressInformation } from "../../library/types/room/rooms";
import { keysExistInObject } from "../../library/helpers/handleObjects";
import { getAddressesFromIds } from "@/library/api/address";
import { getProductInformationApi } from "@/library/api/products";

import { FormFields } from "@/library/types/structure/formStructure";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import { ProductRoomInformation } from "@/library/types/product/productRoom";
import { postNewProductLocation } from "@/library/api/moveProducts";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import FieldLabel from "./fields/FieldLabel.vue";
import InputField from "../common/InputField.vue";
import { useI18n } from "vue-i18n";
import {
  getInternalAdvertisement,
  postInternalAdvertisement,
  updateInternalAdvertisement
} from "@/library/api/sales";
import {
  AdvertisementProductHeader,
  ProductHeaderSale
} from "@/library/types/product/productHeader";
import ProductsSelect from "../common/ProductsSelect.vue";
import { ProductItemSale } from "@/library/types/product/productItem";
import RequiredWarningText from "../common/RequiredWarningText.vue";
import { CustomError, Result } from "@/library/helpers/handleApiRequests";
import { AxiosResponse } from "axios";

type InternalSaleFormProps = {
  selectedProduct:
    | ProductHeaderSale["key"]
    | AdvertisementProductHeader["key"]
    | null;
  selectedProductIds: ProductItemSale["id"][];
  type: "internalSale" | "editInternalSale";
};
const internalSaleFormProps = withDefaults(
  defineProps<InternalSaleFormProps>(),
  {
    selectedProduct: null,
    selectedProductIds: () => [],
    type: "internalSale"
  }
);

const { t } = useI18n();
const formIsSubmitted = ref(false);
const productSaleInformation: Ref<FormFields> = ref({});
const currentProductIds: Ref<string[]> = ref([]);
const isLoading = ref(true);
const greenifiedSaleStructure: Ref<FormFields> = ref({});
const productInformation: Ref<ProductInformation | null> = ref(null);
const uniqueRooms: Ref<string[]> = ref([]);
const addresses: Ref<AddressInformation[]> = ref([]);
const currentLocation: Ref<ProductRoomInformation | null> = ref(null);
const publishSuccess = ref(false);
const complementForm: Ref<HTMLFormElement | null> = ref(null);

const countMaxGroupQuantity = computed(() => {
  const productQuantity = internalSaleFormProps.selectedProductIds.length;
  if (!productQuantity) return 0;

  return (
    productQuantity -
    (productQuantity % Number(productSaleInformation.value["ange-antal"]))
  );
});

const productsForSaleDoesNotMatchGroupAmount = computed(() => {
  const soldInGroupOfAmount = Number(
    productSaleInformation.value["ange-antal"]
  );

  if (!internalSaleFormProps.selectedProduct) return true;

  return (
    productSaleInformation.value.saljs_styckvis_eller_i_grupp === "group" &&
    soldInGroupOfAmount &&
    soldInGroupOfAmount % internalSaleFormProps.selectedProduct.group.antal !==
      0 &&
    soldInGroupOfAmount !== 1
  );
});

const getResponseText = () => {
  const bolderString = bolderFont(t("button.otherSales"));
  if (internalSaleFormProps.type === "internalSale")
    return t("paragraph.publishedForSale", [bolderString]);
  return t("paragraph.advertisementHasBeenUpdated");
};

const moveProducts = async () => {
  if (!productInformation.value) return;

  const result = await postNewProductLocation(
    currentProductIds.value,
    productInformation.value.location
  );

  isLoading.value = false;

  if (!result.success) return;

  publishSuccess.value = true;
};

const postAdvertisement = async () => {
  formIsSubmitted.value = true;

  if (complementForm.value && !complementForm.value.reportValidity()) {
    return;
  }

  if (
    !productSaleInformation.value.saljs_styckvis_eller_i_grupp ||
    !internalSaleFormProps.selectedProduct
  )
    return;

  if (
    productsForSaleDoesNotMatchGroupAmount.value &&
    currentProductIds.value.length %
      Number(productSaleInformation.value["ange-antal"]) !==
      0
  ) {
    return;
  }

  if (
    productSaleInformation.value.saljs_styckvis_eller_i_grupp === "single" &&
    "ange-antal" in productSaleInformation.value
  ) {
    delete productSaleInformation.value["ange-antal"];
  }
  if (!currentProductIds.value.length) {
    currentProductIds.value = internalSaleFormProps.selectedProductIds;
  }

  isLoading.value = true;

  let result: Result<AxiosResponse, CustomError> | null = null;
  if (
    internalSaleFormProps.type === "editInternalSale" &&
    "advertisement_id" in internalSaleFormProps.selectedProduct.group
  ) {
    result = await updateInternalAdvertisement(
      currentProductIds.value,
      productSaleInformation.value,
      internalSaleFormProps.selectedProduct.group.advertisement_id
    );
  } else {
    result = await postInternalAdvertisement(
      currentProductIds.value,
      productSaleInformation.value
    );
  }

  if (!result || !result.success) {
    isLoading.value = false;
    return;
  }

  if (
    productInformation.value &&
    currentLocation.value !== productInformation.value.room
  ) {
    moveProducts();
  } else {
    isLoading.value = false;

    publishSuccess.value = true;
  }
};

const getProductInformation = async () => {
  const result = await getProductInformationApi(
    internalSaleFormProps.selectedProductIds[0]
  );

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  productInformation.value = result.result;

  greenifiedSaleStructure.value = result.result.green_struc;

  if (uniqueRooms.value.length > 1) {
    productInformation.value.room = {
      roomName: "",
      building: "",
      floor: "",
      roomCode: ""
    };
  }

  if (internalSaleFormProps.selectedProductIds.length === 1) {
    productSaleInformation.value["ange-antal"] = "1";
    productSaleInformation.value.saljs_styckvis_eller_i_grupp = "single";
  }

  currentLocation.value = productInformation.value.room;

  getProductAddresses();
};

const getProductAddresses = async () => {
  const result = await getAddressesFromIds(
    internalSaleFormProps.selectedProductIds
  );

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  addresses.value = result.result;
  if (
    addresses.value.length > 0 &&
    !keysExistInObject(
      ["adress", "postnummer", "ort", "land"],
      greenifiedSaleStructure.value
    )
  ) {
    greenifiedSaleStructure.value = Object.assign(
      greenifiedSaleStructure.value,
      addresses.value[0].address
    );
  }

  if (
    internalSaleFormProps.selectedProduct &&
    "advertisement_id" in internalSaleFormProps.selectedProduct.group &&
    internalSaleFormProps.type === "editInternalSale"
  ) {
    const advertisementResult = await getInternalAdvertisement(
      internalSaleFormProps.selectedProduct.group.advertisement_id
    );

    if (!advertisementResult.success) {
      isLoading.value = false;
      return;
    }

    productSaleInformation.value = advertisementResult.result.sale_info;
  }

  isLoading.value = false;
};

onMounted(() => {
  if (!internalSaleFormProps.selectedProduct) {
    emit("close");
    return;
  }

  uniqueRooms.value = internalSaleFormProps.selectedProduct.items.map(
    item => item.rum
  );

  productSaleInformation.value["ange-antal"] =
    internalSaleFormProps.selectedProduct.group.sell_antal ?? null;
  productSaleInformation.value["saljs_styckvis_eller_i_grupp"] =
    internalSaleFormProps.selectedProduct.group.sell_grupp ?? null;
  productSaleInformation.value["pris"] =
    internalSaleFormProps.selectedProduct.group.sell_pris ?? null;
  productSaleInformation.value["nar_ar_produkten_tillganglig"] =
    internalSaleFormProps.selectedProduct.items[0].sell_tillganglig ?? null;

  getProductInformation();
});

const emit = defineEmits(["refresh", "close"]);
</script>

<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
}
</style>
