<template>
  <GreenifiedModal
    @close="emit('close')"
    type="smaller"
    :title="t('title.addresses')"
    :isLoading="isLoading"
    :header-spacing="false"
    :key="String(addresses)"
  >
    <template #modalHeaderContent>
      <p class="text-small">
        {{ t("paragraph.addresses") }}
      </p>
      <TableHeaderRow
        v-if="addresses.length"
        :tableHeaders="tableColumns(addresses[0])"
      />
    </template>
    <template #container>
      <div class="border-bottom" v-for="address in addresses" :key="address.id">
        <MainTableRow
          :table-columns="tableColumns(address)"
          type="other"
          @delete="
            () => {
              addressToDelete = address.id;
              address.rooms.length
                ? (addressRooms = address.rooms)
                : (verifyDeleteAddress = true);
            }
          "
          @editAddress="
            addressToEdit = {
              address: address.address,
              project: address.project,
              last_use: address.last_use,
              building: address.building,
              id: address.id
            };
            showAddAddress = true;
          "
        />
      </div>
    </template>
    <template #footer>
      <div class="row p-0">
        <div class="col-5">
          <GreenifiedButton
            btn-style="green"
            :text="t('button.add')"
            @click="
              addressToEdit = {
                address: {
                  adress: '',
                  postnummer: '',
                  ort: '',
                  land: useIndexStore().currentProject.land,
                  [getProjectStructureFieldKey('land')]:
                    useIndexStore().currentProject.land
                },
                project: '',
                last_use: '',
                building: '',
                id: ''
              };
              showAddAddress = true;
            "
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>

  <GreenifiedModal
    type="verification"
    :closable="false"
    v-if="verifyDeleteAddress"
    :title="t('title.removeAddress')"
    :text="
      t('msg.verifyRemove', [getAddressName(addressToDelete)?.address.adress])
    "
    @close="
      verifyDeleteAddress = false;
      addressToDelete = '';
    "
    @confirm="
      removeAddress(addressToDelete);
      verifyDeleteAddress = false;
    "
  />
  <ProjectAddressForm
    v-if="showAddAddress && addressToEdit"
    @close="showAddAddress = false"
    :title="
      addressToEdit.id.length ? t('title.editAddress') : t('title.addAddress')
    "
    @refresh="
      showAddAddress = false;
      getProjectAddresses();
    "
    :existingAddressInformation="addressToEdit"
  />

  <GreenifiedModal
    type="verification"
    :closable="false"
    v-if="verifyDeleteAddress"
    :title="t('title.removeAddress')"
    :text="
      t('msg.verifyRemove', [getAddressName(addressToDelete)?.address.adress])
    "
    @close="
      verifyDeleteAddress = false;
      addressToDelete = '';
    "
    @confirm="
      removeAddress(addressToDelete);
      verifyDeleteAddress = false;
    "
  />

  <RemoveAddressModal
    v-if="addressRooms.length"
    :addressRooms="addressRooms"
    :addresses="addresses"
    :addressToDelete="addressToDelete"
    @close="addressRooms = []"
    @removeAddress="address => removeAddress(address)"
  />
</template>

<script lang="ts" setup>
import { defineEmits, onMounted, Ref, ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { deleteAddress, getAddresses } from "@/library/api/address";
import { AddressInformation, RoomObject } from "@/library/types/room/rooms";
import RemoveAddressModal from "./RemoveAddressModal.vue";
import ProjectAddressForm from "../ProjectAddressForm.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
import { useIndexStore } from "@/store";

const emit = defineEmits(["close"]);
const { t } = useI18n();
const addresses: Ref<ProjectSettingsAdress[]> = ref([]);
const tableColumns = (
  adress: ProjectSettingsAdress
): TableColumns["columns"] => {
  return {
    building: {
      value: adress.building,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      selectOptions: [],
      isEditable: false,
      translate: false,
      headerText: "tableTitle.building",
      inputSettings: null
    },
    address: {
      value: adress.address.adress,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      inputSettings: null,
      selectOptions: [],
      isEditable: false,
      translate: false,
      isSortable: false,
      headerText: "tableTitle.address"
    },
    edit: {
      value: "",
      columnStyling: "col-4",
      actions: ["edit"],
      selectOptions: [],
      isEditable: false,
      translate: false,
      tooltip: null,
      inputSettings: null,
      isSortable: false,
      headerText: ""
    }
  };
};
const isLoading = ref(false);
const addressToDelete: Ref<AddressInformation["id"]> = ref("");
const addressRooms: Ref<RoomObject[]> = ref([]);
const verifyDeleteAddress = ref(false);
const addressToEdit: Ref<AddressInformation> = ref({
  address: {},
  project: "",
  last_use: "",
  building: "",
  id: ""
});
const showAddAddress = ref(false);

const getProjectAddresses = async () => {
  isLoading.value = true;
  addressToDelete.value = "";
  addressRooms.value = [];

  const result = await getAddresses();

  isLoading.value = false;
  if (!result.success) return;

  addresses.value = result.result;
};

const getAddressName = (addressId: string) => {
  return addresses.value.find(address => address.id === addressId);
};

const removeAddress = async (id: string) => {
  isLoading.value = true;
  const result = await deleteAddress(id);

  if (!result.success) {
    isLoading.value = true;
    return;
  }

  getProjectAddresses();
};

onMounted(() => {
  getProjectAddresses();
});
</script>
