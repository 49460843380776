import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "supplier-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col d-flex justify-content-end" }

import debounce from "debounce";
import { Ref, ref } from "vue";
import { SearchedSupplier } from "../../services/api/suppliersApi";
import { getSuppliers, postNewSupplier } from "@/library/api/suppliers";
import GreenifiedModal from "./GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import InputField from "../common/InputField.vue";
import { useI18n } from "vue-i18n";

export default /*@__PURE__*/_defineComponent({
  __name: 'AddProjectSupplierModal',
  emits: ["close", "added"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { t } = useI18n();

const searchValue = ref("");
const searchResult: Ref<SearchedSupplier[]> = ref([]);
const isLoading = ref(false);

const addSupplier = async (supplier: SearchedSupplier) => {
  isLoading.value = true;
  supplier["status"] = 1;
  const result = await postNewSupplier(supplier.levkod);

  if (!result.success) {
    isLoading.value = false;
  }

  supplier["status"] = 2;
  emit("added", supplier.levkod);
  emit("close");
};

const debounceInput = debounce(async () => {
  if (searchValue.value.length) {
    isLoading.value = true;

    const result = await getSuppliers(searchValue.value);
    isLoading.value = false;

    if (!result.success) return;

    searchResult.value = result.result;
  }
}, 300);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    title: _unref(t)('title.addSupplier'),
    onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
    isLoading: isLoading.value,
    type: 'small',
    fullHeight: true
  }, {
    container: _withCtx(() => [
      _createVNode(InputField, {
        fieldValue: searchValue.value,
        "onUpdate:fieldValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((searchValue).value = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_unref(debounceInput)()))
        ],
        placeHolder: _unref(t)('placeholders.search'),
        isBlurable: false
      }, null, 8, ["fieldValue", "placeHolder"]),
      _createElementVNode("div", _hoisted_1, [
        (!searchValue.value.length && !searchResult.value.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(t)("msg.startSearching")), 1))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(searchResult.value, (supplier) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row supplier-row",
                key: supplier.varumarke
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(supplier.levkod.startsWith("ex")
                  ? _unref(t)("label.unkownSupplier")
                  : supplier.varumarke), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(GreenifiedButton, {
                    disabled: supplier.status === 2,
                    btnStyle: 'green',
                    text: 
                  supplier.status === 1
                    ? _unref(t)('button.loading')
                    : supplier.status === 2
                    ? _unref(t)('button.added')
                    : _unref(t)('button.add')
                ,
                    onClick: ($event: any) => (addSupplier(supplier))
                  }, null, 8, ["disabled", "text", "onClick"])
                ])
              ]))
            }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "isLoading"]))
}
}

})