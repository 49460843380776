import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, Ref, ref } from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import ProductTable from "@/components/table/ProductTable.vue";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

type ProjectReconditionProps = {
  filterQuery?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectRecondition',
  props: {
    filterQuery: { default: "" }
  },
  setup(__props: any) {


const { t } = useI18n();

const quantities = computed(() => {
  return getQuantitiesByProductStatus("R");
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];

const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const presetFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "besk",
    "kategori_id",
    "recondition",
    "kommentar",
    "status",
    "green_hash"
  ],
  status: ["R"]
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      filterTitle: `${_unref(t)('title.recondition')} - ${quantities.value} ${_unref(t)(
      'title.products'
    )}`,
      filterText: _unref(t)('paragraph.recondition'),
      filterQuery: _ctx.filterQuery,
      defaultFilter: filter.value,
      "onUpdate:defaultFilter": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event)),
      defaultSortingSettings: sortingSettings.value,
      "onUpdate:defaultSortingSettings": _cache[1] || (_cache[1] = ($event: any) => ((sortingSettings).value = $event)),
      translateHeaders: translateHeaders,
      productStatus: presetFilter.status,
      pdfRoute: 'recondition',
      hasProducts: quantities.value > 0
    }, null, 8, ["filterTitle", "filterText", "filterQuery", "defaultFilter", "defaultSortingSettings", "productStatus", "hasProducts"]),
    _createVNode(ProductTable, {
      actionStatuses: ['CP', 'F', 'CR', 'KC', 'CA'],
      noRowsText: _unref(t)('label.noRowsPositive'),
      filter: filter.value,
      "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((filter).value = $event)),
      sortingSettings: sortingSettings.value,
      translateHeaders: translateHeaders,
      presetFilter: presetFilter,
      tableType: 'recondition'
    }, null, 8, ["noRowsText", "filter", "sortingSettings"])
  ], 64))
}
}

})