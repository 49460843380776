import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid ps-0" }
const _hoisted_2 = {
  key: 0,
  class: "row align-items-center mb-3"
}
const _hoisted_3 = { class: "col-8 pe-0" }
const _hoisted_4 = { class: "container-fluid ps-0" }
const _hoisted_5 = { class: "row mb-3" }
const _hoisted_6 = { class: "col-8" }

import GreenifiedForm from "@/components/form/GreenifiedForm.vue";

import {
  ref,
  Ref,
  onBeforeMount,
} from "vue";
import { ProductInformation } from "../../../library/types/product/productGroup";
import { useIndexStore } from "@/store";
import { defaultExcludedFields } from "@/library/helpers/excludedFormFields";
import SelectRoom from "@/components/SelectRoom.vue";
import FieldLabel from "../fields/FieldLabel.vue";
import InputTextField from "@/components/common/InputTextField.vue";
import { FormImagesObject } from "@/library/types/product/productImage";
import { useI18n } from "vue-i18n";
import AddProjectSupplierModal from "@/components/modal/AddProjectSupplierModal.vue";

type GeneralProductInformationProps = {
  productInformation: ProductInformation | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralProductInformation',
  props: {
    productInformation: { default: null }
  },
  setup(__props: any, { expose: __expose }) {

const { t } = useI18n();

const generalProductInformationProps = __props;

const productForm: Ref<HTMLFormElement | null> = ref(null);
const store = useIndexStore();
const currentProductInformation: Ref<ProductInformation | null> = ref(
  generalProductInformationProps.productInformation,
);
const greenifiedForm = ref<InstanceType<typeof GreenifiedForm>>();
const showAddSupplier = ref(false);
const isLoading = ref(true);
const formIsSubmitted = ref(false);

const validateForm = (): boolean => {
  formIsSubmitted.value = true;
  if (!productForm.value) return false;
  productForm.value.reportValidity();
  if (!greenifiedForm.value?.validateGreenifiedForm()) return false;

  return true;
};

const productImages = () => {
  const currentProjectStructure = { ...store.projectDetails.struc };
  const currentProductImages: FormImagesObject = {
    bild_1: {
      fieldKey: "",
      imageHash: "",
    },
    bild_2: {
      fieldKey: "",
      imageHash: "",
    },
  };

  for (const structureKey in currentProjectStructure) {
    const structureField = currentProjectStructure[structureKey];
    const subFields = structureField.sub_fields;
    for (const fieldKey in subFields) {
      const field = subFields[fieldKey];
      const fieldValue =
        currentProductInformation.value?.green_struc[field.key];

      if (field.type === "image" && field.name !== "bild_5") {
        const fieldValueAsString = fieldValue ? String(fieldValue) : "";
        currentProductImages[field.name as keyof FormImagesObject] = {
          fieldKey: field.key,
          imageHash: fieldValueAsString,
        };
      }
    }
  }

  return currentProductImages;
};

onBeforeMount(() => {
  if (
    currentProductInformation.value &&
    !("other_information" in currentProductInformation.value.green_struc)
  )
    currentProductInformation.value.green_struc["other_information"] = "";

  isLoading.value = false;
});

__expose({ validateForm });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (currentProductInformation.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(FieldLabel, {
              fieldLabel: _unref(t)('label.location'),
              showLabel: true,
              requiredField: 1,
              presetFieldValue: currentProductInformation.value.location,
              formIsSubmitted: formIsSubmitted.value
            }, null, 8, ["fieldLabel", "presetFieldValue", "formIsSubmitted"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(SelectRoom, {
                modelValue: currentProductInformation.value.location,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentProductInformation.value.location) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("form", {
      action: "",
      ref_key: "productForm",
      ref: productForm
    }, [
      (currentProductInformation.value)
        ? (_openBlock(), _createBlock(GreenifiedForm, {
            key: 0,
            ref_key: "greenifiedForm",
            ref: greenifiedForm,
            formValues: currentProductInformation.value.green_struc,
            "onUpdate:formValues": _cache[1] || (_cache[1] = ($event: any) => ((currentProductInformation.value.green_struc) = $event)),
            excludedFields: _unref(defaultExcludedFields),
            formFields: _unref(store).projectDetails.struc,
            supplierCode: currentProductInformation.value.supplier_code,
            "onUpdate:supplierCode": _cache[2] || (_cache[2] = ($event: any) => ((currentProductInformation.value.supplier_code) = $event)),
            onShowAddSupplier: _cache[3] || (_cache[3] = ($event: any) => (showAddSupplier.value = true)),
            productImages: currentProductInformation.value.images,
            "onUpdate:productImages": _cache[4] || (_cache[4] = ($event: any) => ((currentProductInformation.value.images) = $event)),
            formIsSubmitted: formIsSubmitted.value,
            "onUpdate:formIsSubmitted": _cache[5] || (_cache[5] = ($event: any) => ((formIsSubmitted).value = $event)),
            "onUpdate:productCategory": _cache[6] || (_cache[6] = (category) =>{ 
        if (!currentProductInformation.value) return;
        currentProductInformation.value.category = category}),
            formImages: productImages()
          }, null, 8, ["formValues", "excludedFields", "formFields", "supplierCode", "productImages", "formIsSubmitted", "formImages"]))
        : _createCommentVNode("", true)
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(FieldLabel, {
          fieldLabel: _unref(t)('label.otherInformation'),
          showLabel: true
        }, null, 8, ["fieldLabel"]),
        _createElementVNode("div", _hoisted_6, [
          (
            typeof currentProductInformation.value?.green_struc.other_information ===
            'string'
          )
            ? (_openBlock(), _createBlock(InputTextField, {
                key: 0,
                modelValue: currentProductInformation.value.green_struc.other_information,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((currentProductInformation.value.green_struc.other_information) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (showAddSupplier.value && currentProductInformation.value)
      ? (_openBlock(), _createBlock(AddProjectSupplierModal, {
          key: 0,
          onAdded: _cache[8] || (_cache[8] = 
        (supplier) => {
          if (!currentProductInformation.value) return;
          currentProductInformation.value.supplier_code = supplier
        }
      ),
          onClose: _cache[9] || (_cache[9] = ($event: any) => (showAddSupplier.value = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})