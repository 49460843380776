import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/img/eye_icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "mb-1 text-small"
}
const _hoisted_2 = ["disabled", "readonly", "maxlength", "autocomplete", "max", "min", "type", "name", "required", "id", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "input-helpers text-tiny"
}
const _hoisted_4 = {
  key: 1,
  class: "error-text ms-2"
}
const _hoisted_5 = {
  key: 2,
  class: "ms-2"
}

import { PhoneInput } from "@lbgm/phone-number-input";
import {
  onMounted,
  Ref,
  ref,
  watch,
  InputHTMLAttributes,
  computed
} from "vue";
type InputFieldProps = {
  label?: string;
  id?: InputHTMLAttributes["id"];
  name?: InputHTMLAttributes["name"];
  type?: InputHTMLAttributes["type"];
  width?: string | number;
  fieldValue: InputHTMLAttributes["value"] | null;
  errorWithInputValue?: boolean;
  showUnit?: boolean;
  errorText?: string;
  unitText?: string | number;
  placeHolder?: InputHTMLAttributes["placeholder"];
  maxlength?: InputHTMLAttributes["maxlength"];
  autocomplete?: "off" | "on";
  setFocus?: boolean;
  passwordHasToggle?: boolean;
  maxValue?: InputHTMLAttributes["max"];
  minValue?: InputHTMLAttributes["min"];
  value?: InputHTMLAttributes["value"];
  disabled?: boolean;
  notEditable?: boolean;
  bold?: boolean;
  flashGreen?: boolean;
  required?: boolean;
  isBlurable?: boolean;
  rounded?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InputField',
  props: {
    label: { default: "" },
    id: { default: "Input field" },
    name: { default: "Input field" },
    type: { default: "text" },
    width: { default: "" },
    fieldValue: { default: null },
    errorWithInputValue: { type: Boolean, default: false },
    showUnit: { type: Boolean, default: false },
    errorText: { default: "Error" },
    unitText: { default: "mm" },
    placeHolder: { default: "" },
    maxlength: { default: "60" },
    autocomplete: { default: "off" },
    setFocus: { type: Boolean, default: false },
    passwordHasToggle: { type: Boolean, default: false },
    maxValue: { default: "" },
    minValue: { default: "" },
    value: { default: "" },
    disabled: { type: Boolean, default: false },
    notEditable: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    flashGreen: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    isBlurable: { type: Boolean, default: true },
    rounded: { type: Boolean, default: false }
  },
  emits: [
  "update:fieldValue",
  "hasError",
  "update:hasError",
  "blur",
  "input"
],
  setup(__props: any, { emit: __emit }) {

const inputFieldProps = __props;

const emit = __emit;

const currentCountry = computed(() => {
  return navigator.language.slice(-2);
});

const inputValue: Ref<InputHTMLAttributes["value"]> = ref(
  inputFieldProps.fieldValue ?? inputFieldProps.value
);
const showPassword = ref(false);
const inputField: Ref<HTMLInputElement | null> = ref(null);

onMounted(() => {
  if (inputFieldProps.setFocus) {
    setFocusOnInputField();
  }
});

const setFocusOnInputField = () => {
  if (!inputField.value) return;
  inputField.value.focus();
};

watch(
  () => inputFieldProps.errorWithInputValue,
  inputValueError => {
    emit("update:hasError", inputValueError);
  }
);

watch(
  () => inputFieldProps.fieldValue,
  newValue => (inputValue.value = newValue)
);

return (_ctx: any,_cache: any) => {
  return (_ctx.type === 'phone')
    ? (_openBlock(), _createBlock(_unref(PhoneInput), {
        key: 0,
        onPhone: _cache[0] || (_cache[0] = 
          (phoneNumber) => {
            emit('update:fieldValue', phoneNumber)
          }
        ),
        placeholder: _ctx.placeHolder,
        class: "phone-input",
        defaultCountry: currentCountry.value,
        value: inputValue.value,
        required: _ctx.required
      }, null, 8, ["placeholder", "defaultCountry", "value", "required"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["input-field-wrapper position-relative", !_ctx.rounded && 'w-100']),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (setFocusOnInputField()))
      }, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["position-relative", _ctx.rounded && 'd-flex justify-content-center w-100'])
        }, [
          _withDirectives(_createElementVNode("input", {
            disabled: _ctx.disabled,
            readonly: _ctx.notEditable,
            maxlength: _ctx.maxlength,
            autocomplete: _ctx.autocomplete,
            max: _ctx.maxValue,
            min: _ctx.minValue,
            class: _normalizeClass(["gr-input", [
          `input-${_ctx.width}`,
          _ctx.errorWithInputValue && 'error',
          _ctx.flashGreen && 'flash-green-animation',
          _ctx.bold && 'input-bold',
          _ctx.rounded && 'rounded'
        ]]),
            ref_key: "inputField",
            ref: inputField,
            onBlur: _cache[1] || (_cache[1] = 
          () => {
            if (!_ctx.isBlurable) return;

            if (_ctx.type === 'number' && _ctx.minValue) {
              !inputValue.value || inputValue.value < _ctx.minValue
                ? (inputValue.value = _ctx.minValue)
                : '';
            }
            emit('update:fieldValue', inputValue.value);
            emit('blur');
          }
        ),
            type: showPassword.value ? 'text' : _ctx.type,
            name: _ctx.name,
            required: _ctx.required,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((inputValue).value = $event)),
            id: _ctx.id,
            placeholder: _ctx.placeHolder,
            onInput: _cache[3] || (_cache[3] = 
          () => {
            if (_ctx.type === 'number') {
              if (_ctx.maxValue && inputValue.value > _ctx.maxValue) {
                inputValue.value = _ctx.maxValue;
              }

              if (_ctx.minValue && inputValue.value === 0) {
                inputValue.value = _ctx.minValue;
              }
            }
            emit('update:fieldValue', inputValue.value);
          }
        )
          }, null, 42, _hoisted_2), [
            [_vModelDynamic, inputValue.value]
          ]),
          (_ctx.errorWithInputValue || _ctx.showUnit || _ctx.passwordHasToggle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.passwordHasToggle)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _imports_0,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (showPassword.value = !showPassword.value)),
                      class: "eye-icon cursor-pointer"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.errorWithInputValue)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorText), 1))
                  : _createCommentVNode("", true),
                (_ctx.showUnit)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.unitText), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2))
}
}

})