<template>
  <div :class="placing === 'left' ? 'col-4' : 'col-12'">
    <template v-if="showLabel">
      <div
        class="d-flex align-items-center w-100"
        :class="placing === 'left' && 'justify-content-between'"
      >
        <div class="d-flex align-items-center">
          <label class="form-label" :for="fieldKey"
            >{{ fieldLabel }}
            <span class="ms-1" v-if="requiredField === 1 && fieldLabel.length"
              >*</span
            ></label
          >
        </div>
        <InformationTooltip
          :class="placing === 'top' && 'ms-1'"
          :formTooltip="true"
          v-if="showTooltip"
          :infoText="tooltipInstructions"
          :justify="'right'"
        />
      </div>
      <RequiredWarningText
        v-if="showRequiredText && !checkFieldValidation()"
        :text="t('msg.fieldRequired', [fieldLabel])"
        textClass="form-label"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import InformationTooltip from "@/components/common/InformationTooltip.vue";
import RequiredWarningText from "@/components/common/RequiredWarningText.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { withDefaults, defineProps, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type RequiredFieldProps = {
  placing?: "top" | "left";
  fieldKey?: string;
  fieldLabel: string;
  requiredField?: 0 | 1;
  presetFieldValue?: FormFields["key"] | null;
  showTooltip?: boolean;
  showLabel?: boolean;
  tooltipInstructions?: string;
  formIsSubmitted?: boolean;
};

const requiredFieldProps = withDefaults(defineProps<RequiredFieldProps>(), {
  placing: "left",
  fieldKey: "",
  fieldLabel: "",
  requiredField: 0,
  presetFieldValue: null,
  showTooltip: false,
  showLabel: true,
  tooltipInstructions: "",
  formIsSubmitted: false,
});
const { t } = useI18n();

const showRequiredText = ref(false);

const checkFieldValidation = () => {
  if (requiredFieldProps.requiredField === 0) return true;
  return requiredFieldProps.presetFieldValue &&
    String(requiredFieldProps.presetFieldValue).length > 0
    ? true
    : false;
};

watch(
  () => requiredFieldProps.formIsSubmitted,
  isSubmitted => {
    if (isSubmitted) showRequiredText.value = !checkFieldValidation();
  },
);
</script>
