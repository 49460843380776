import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow } from "vue"
import _imports_0 from '@/assets/img/search-icon.svg'


const _hoisted_1 = { class: "search-bar gr-color d-flex align-items-center px-2 position-relative" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "search-result-wrapper" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "px-3 py-2 row cursor-pointer"
}
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "fst-italic" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "col-6 cursor-pointer" }
const _hoisted_12 = { class: "col-6 d-flex justify-content-end cursor-pointer" }
const _hoisted_13 = { class: "spacer no-wrap" }

import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { debounce } from "debounce";
import { TranslatedFilter } from "@/services/api/searchApi";
import { getGuestSearchValues, getSearchValues } from "@/library/api/search";
import { useRouter } from "vue-router";
import { FilterOptions } from "@/library/types/filter/filter";
import { useIndexStore } from "@/store";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { getStatusText } from "@/library/helpers/statusTexts";

type SearchBarProps = {
  defaultFilter: FilterOptions;
  productStatus?: ProductStatuses;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchBar',
  props: {
    defaultFilter: { default: () => ({
    categories: {},
    intervals: {}
  }) },
    productStatus: { default: "J" }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const searchBarProps = __props;

const invalidSearch = ref(false);
const searchBar: Ref<HTMLInputElement | null> = ref(null);
const { t } = useI18n();
const currentSearchValue = ref("");
const searchBarIsExpanded = ref(false);
const selectedFilterOptionIndex: Ref<number | null> = ref(null);
const searchIsLoading = ref(false);
const searchFieldHasFocus = ref(false);
const searchResults: Ref<{ [key: string]: TranslatedFilter[] }> = ref({});
const { currentRoute } = useRouter();
const indexStore = useIndexStore();
const emit = __emit;
const currentFilter = ref(searchBarProps.defaultFilter);

const getTranslatedLabel = (searchLabel: TranslatedFilter) => {
  const translatedColumns = ["main_category", "kategori_id", "skick2"];

  if (translatedColumns.includes(searchLabel.kol))
    return t(`${searchLabel.kol}.${searchLabel.text}`);

  return searchLabel.text;
};

const increaseMarked = (event: Event) => {
  event.preventDefault();
  if (searchResults.value[currentSearchValue.value]) {
    if (selectedFilterOptionIndex.value !== null) {
      if (
        selectedFilterOptionIndex.value >=
        searchResults.value[currentSearchValue.value].length
      ) {
        selectedFilterOptionIndex.value = 0;
      } else {
        selectedFilterOptionIndex.value += 1;
      }
    } else {
      selectedFilterOptionIndex.value = 0;
    }
  }
};

const getSearchValuesWithRows = debounce(async (event: Event) => {
  searchIsLoading.value = true;
  invalidSearch.value = false;
  selectedFilterOptionIndex.value = null;
  const eventTarget = event.target as HTMLInputElement;
  currentSearchValue.value = eventTarget.value;

  let result;

  if (eventTarget.value in searchResults.value || eventTarget.value === "") {
    searchIsLoading.value = false;
    return;
  }

  if (currentRoute.value.params.token) {
    result = await getGuestSearchValues(
      eventTarget.value,
      currentRoute.value.params.token as string
    );
  } else {
    result = await getSearchValues(eventTarget.value);
  }

  searchIsLoading.value = false;

  if (!result.success) return (invalidSearch.value = true);

  if (!result.result.length) return (invalidSearch.value = true);
  searchResults.value[eventTarget.value] = result.result;
}, 500);

const decreaseMarked = (event: Event) => {
  event.preventDefault();
  if (searchResults.value[currentSearchValue.value]) {
    if (selectedFilterOptionIndex.value !== null) {
      if (selectedFilterOptionIndex.value <= 0) {
        selectedFilterOptionIndex.value =
          searchResults.value[currentSearchValue.value].length;
      } else {
        selectedFilterOptionIndex.value -= 1;
      }
    } else {
      selectedFilterOptionIndex.value =
        searchResults.value[currentSearchValue.value].length;
    }
  }
};

const enterSelect = (filterOptionIndex: number | null) => {
  if (!currentSearchValue.value) return;

  if (!filterOptionIndex || filterOptionIndex === -1)
    return addSearchValueToFilter(currentSearchValue.value);

  const markedObject =
    searchResults.value[currentSearchValue.value][filterOptionIndex];
  const searchObject = {
    group: markedObject.group,
    key: markedObject.kol,
    value: markedObject.nyckel,
    text: markedObject.text,
    sokvarde: "",
    nyckel: "",
    sort: "",
    kol: "",
    projekt: indexStore.currentProjectNumber
  };
  addSearchDropdownOptionToFilter(searchObject);
  return;
};

const addSearchValueToFilter = (searchValueForFilter: string) => {
  const filterHasSearchKey = Object.keys(
    currentFilter.value.categories
  ).includes("search");
  if (!filterHasSearchKey) {
    currentFilter.value.categories["search"] = [];
  }

  currentFilter.value.categories["search"].push({
    key: "search",
    value: searchValueForFilter
  });

  emit("update:modelValue", currentFilter.value);

  currentSearchValue.value = "";
};

const addSearchDropdownOptionToFilter = (searchObject: TranslatedFilter) => {
  const filterHasKey = Object.keys(currentFilter.value.categories).includes(
    searchObject.key
  );

  if (filterHasKey) {
    currentFilter.value.categories[searchObject.key].push({
      key: searchObject.key,
      value: searchObject.value
    });
  } else {
    currentFilter.value.categories[searchObject.key] = [
      { key: searchObject.key, value: searchObject.value }
    ];
  }

  emit("update:modelValue", currentFilter.value);

  currentSearchValue.value = "";
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "search icon",
      class: "search-icon cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
        () => {
          searchBarIsExpanded.value = !searchBarIsExpanded.value;
          if (!searchBarIsExpanded.value) {
            currentSearchValue.value = '';
            return;
          }
          searchBar.value?.focus();
        }
      )
    }),
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["search-input text-tiny", searchBarIsExpanded.value ? 'expanded-field' : 'collapsed-field']),
      ref_key: "searchBar",
      ref: searchBar,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentSearchValue).value = $event)),
      type: "text",
      placeholder: _unref(t)('placeholders.searchProduct'),
      onKeydown: _cache[2] || (_cache[2] = _withKeys((event) => increaseMarked(event), ["down"])),
      onKeyup: [
        _cache[3] || (_cache[3] = _withKeys((event) => decreaseMarked(event), ["up"])),
        _cache[4] || (_cache[4] = _withKeys(($event: any) => (enterSelect(selectedFilterOptionIndex.value)), ["enter"]))
      ],
      onInput: _cache[5] || (_cache[5] = (event) => {
                  searchIsLoading.value = true;
                  _unref(getSearchValuesWithRows)(event);
                }),
      onFocus: _cache[6] || (_cache[6] = ($event: any) => (searchFieldHasFocus.value = true))
    }, null, 42, _hoisted_2), [
      [_vModelText, currentSearchValue.value]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["search-result px-3 py-2 row cursor-pointer", selectedFilterOptionIndex.value === 0 && 'marked'])
      }, [
        _createElementVNode("div", {
          class: "col-12",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (addSearchValueToFilter(currentSearchValue.value)))
        }, [
          _createElementVNode("p", {
            class: "cursor-pointer",
            innerHTML: 
              _unref(t)('label.searchAfter', [
                '<strong>' + currentSearchValue.value + '</strong>'
              ])
            
          }, null, 8, _hoisted_4)
        ])
      ], 2),
      (searchIsLoading.value || invalidSearch.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (searchIsLoading.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_unref(t)("paragraph.searchingForProducts")) + " ", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)(_unref(getStatusText)(_ctx.productStatus))), 1),
                    _cache[8] || (_cache[8] = _createTextVNode(" ... "))
                  ]))
                : _createCommentVNode("", true),
              (invalidSearch.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_unref(t)("paragraph.noProductsMatchYourSearch")), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchResults.value[currentSearchValue.value], (searchResult, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: searchResult.key,
          class: _normalizeClass(["search-result cursor-pointer align-items-center px-3 py-2 row", selectedFilterOptionIndex.value === i + 1 && 'marked']),
          onClick: ($event: any) => (
          addSearchDropdownOptionToFilter({
            group: searchResult.group,
            key: searchResult.kol,
            value: searchResult.nyckel,
            text: searchResult.text,
            sokvarde: '',
            nyckel: '',
            sort: '',
            kol: '',
            projekt: ''
          })
        )
        }, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(getTranslatedLabel(searchResult)), 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(t)("label." + searchResult.kol)), 1)
          ])
        ], 10, _hoisted_10))
      }), 128))
    ], 512), [
      [_vShow, searchFieldHasFocus.value && currentSearchValue.value.length > 0]
    ])
  ]))
}
}

})