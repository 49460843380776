<template>
  <MainFilter
    :filterTitle="`${t('title.recycle')} - ${quantities} ${t(
      'title.products'
    )}`"
    :filterText="t('paragraph.recycle')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="filter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :productStatus="presetFilter.status"
    :pdfRoute="'recycle'"
    :hasProducts="quantities > 0"
  />
  <ProductTable
    :actionStatuses="['CP', 'F', 'C', 'CA']"
    :noRowsText="t('label.noRowsPositive')"
    v-model:filter="filter"
    :sortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :presetFilter="presetFilter"
  />
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, Ref, ref, defineProps, withDefaults } from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";
import ProductTable from "@/components/table/ProductTable.vue";

type ProjectRecycleProps = {
  filterQuery?: string;
};

withDefaults(defineProps<ProjectRecycleProps>(), {
  filterQuery: ""
});

const { t } = useI18n();

const quantities = computed(() => {
  return getQuantitiesByProductStatus("K");
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const presetFilter: PresetFilter = {
  filter: [],
  group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
  status: ["K"]
};
</script>
