<template>
  <div class="container-fluid table-footer py-4 border-top mt-5 ps-0 pe-0">
    <div class="row align-items-center">
      <div class="col-3">
        <p class="subheading-small">{{ footerText }}</p>
      </div>
      <div class="col-9 d-flex justify-content-end">
        <template v-for="(button, index) in tableFooterButtons" :key="index">
          <div class="ms-3" v-if="actionStatuses.includes(button.status)">
            <InformationTooltip
              v-if="button.tooltipText && toggleDisabledButtons"
              :infoText="t(button.tooltipText)"
              :tooltipWidth="'small'"
            >
              <template #icon>
                <GreenifiedButton
                  :btnStyle="button.color"
                  :disabled="button.toggleDisabled && toggleDisabledButtons"
                  :text="
                    button.buttonText.translate
                      ? t(button.buttonText.text)
                      : button.buttonText.text
                  "
                  :size="'thin'"
                  @click="emit('action', button)"
                />
              </template>
            </InformationTooltip>
            <template v-else>
              <template v-if="button.action === 'combineProducts'">
                <GreenifiedButton
                  :btnStyle="button.color"
                  :disabled="
                    button.toggleDisabled && toggleDisableCombineProductsButton
                  "
                  :text="t(button.buttonText.text).replace(/^.*?:\s*/, '')"
                  :size="'thin'"
                  @click="emit('action', button)"
                />
              </template>
              <template
                v-else-if="
                  button.action === 'availableInternal' ||
                  button.action === 'soldInternal'
                "
              >
                <GreenifiedButton
                  :btnStyle="button.color"
                  :disabled="button.toggleDisabled && toggleDisabledButtons"
                  :text="t(button.buttonText.text).replace(/^.*?:\s*/, '')"
                  :size="'thin'"
                  @click="emit('action', button)"
                />
              </template>
              <template v-else>
                <GreenifiedButton
                  :btnStyle="button.color"
                  :disabled="button.toggleDisabled && toggleDisabledButtons"
                  :text="
                    button.buttonText.translate
                      ? t(button.buttonText.text)
                      : button.buttonText.text
                  "
                  :size="'thin'"
                  :type="button.action === 'saveRoom' ? 'submit' : 'button'"
                  :form="button.action === 'saveRoom' ? 'room-id' : ''"
                  @click="emit('action', button)"
                />
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import InformationTooltip from "@/components/common/InformationTooltip.vue";
import { tableFooterButtons } from "@/library/helpers/tableFooterButtons";
import { ActionStatus } from "@/library/types/actions/actions";
import { withDefaults, defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

type TableFooterProps = {
  footerText?: string;
  actionStatuses?: ActionStatus[];
  toggleDisabledButtons?: boolean;
  toggleDisableCombineProductsButton?: boolean;
};

withDefaults(defineProps<TableFooterProps>(), {
  footerText: "",
  actionStatuses: () => [],
  toggleDisabledButtons: false,
  toggleDisableCombineProductsButton: false
});

const emit = defineEmits(["action"]);
const { t } = useI18n();
</script>

<style scoped>
.border-top {
  border-top: 1px solid hsla(0, 0%, 50%, 0.4) !important;
}

.table-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 8;
}
</style>
