<template>
  <div
    class="row info-row me-0 ms-0"
    :class="[showTopBorder && 'top-border', !showMore && 'align-items-center']"
  >
    <div
      class="col-2 ps-0 d-flex align-items-center"
      :style="{ height: `${textHeight}px !important` }"
      @click="hideOverflow = !hideOverflow"
    >
      <slot name="label">
        <p :class="[subheaderLabel ? 'subheading-small' : 'text-small']">
          {{ label }}
        </p>
      </slot>
    </div>
    <div
      class="pe-0 d-flex justify-content-between align-items-center"
      @click="hideOverflow = !hideOverflow"
      ref="textContainer"
      :class="showMore ? 'col-7' : 'col-9'"
    >
      <div
        class="d-flex align-items-center flex-wrap"
        :class="[hideOverflow ? 'hide-overflow' : 'sliding-text']"
      >
        <template
          v-for="informationsRow in informationRows"
          :key="informationsRow.rowValue"
        >
          <p class="d-flex me-3" v-if="informationsRow.rowValue">
            <span
              v-if="informationsRow.rowLabel"
              class="text-small fw-bolder me-1"
            >
              {{ informationsRow.rowLabel }}
            </span>
            <span class="text-small">
              {{ informationsRow.rowValue }}
            </span>
          </p>
        </template>
      </div>

      <RotateArrow
        v-if="accordionRows.length"
        :rotate="hideOverflow"
        size="small"
      />
    </div>
    <div
      v-if="showMore"
      @click="hideOverflow = !hideOverflow"
      class="col-2 d-flex align-items-center justify-content-end pe-0"
      :style="{ height: `${textHeight}px !important` }"
    >
      <IconButton
        :iconImage="hideOverflow ? currentAddIcon : currentMinusIcon"
        :width="'22'"
        :height="'22'"
        :alt-text="'show more icon'"
        noWrapper
      />
    </div>
    <div class="col-12 ps-0 pe-0" v-if="accordionRows">
      <AccordionContainer :toggleAccordion="hideOverflow">
        <template #content>
          <slot name="accordionContent">
            <div class="row mt-3" v-for="(row, i) in accordionRows" :key="i">
              <div class="col-4">
                <p class="text-small">
                  {{
                    accordionLabelText
                      ? row[accordionKey]
                      : t(row[accordionKey])
                  }}
                  {{ accordionLabelText }}
                </p>
              </div>
              <div class="col-6 ps-3">
                <p class="text-small" v-if="!links">
                  {{ row[accordionValue] }}
                </p>

                <a class="text-small link" :href="row[accordionValue]" v-else>
                  {{ row[accordionValue] }}
                </a>
              </div>
            </div>
          </slot>
        </template>
      </AccordionContainer>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconButton from "./common/IconButton.vue";
import RotateArrow from "./common/RotateArrow.vue";
import AccordionContainer from "./common/AccordionContainer.vue";
import { withDefaults, defineProps, ref, Ref, onMounted, onUpdated } from "vue";
import { InformationsRows } from "./ProductGroupInformation.vue";
import { useI18n } from "vue-i18n";
import addIcon from "@/assets/img/add_icon.svg";
import minusIcon from "@/assets/img/minus_icon.svg";

const { t } = useI18n();

type InformationRowProps = {
  label?: string;
  showTopBorder?: boolean;
  subheaderLabel?: boolean;
  accordionRows?: [];
  accordionKey?: string;
  accordionValue?: string;
  accordionLabelText?: string;
  links?: boolean;
  informationRows: InformationsRows["rows"];
};

withDefaults(defineProps<InformationRowProps>(), {
  label: "",
  showTopBorder: true,
  subheaderLabel: true,
  accordionRows: () => [],
  accordionKey: "",
  accordionValue: "",
  accordionLabelText: "",
  links: false,
  informationRows: () => []
});

const hideOverflow = ref(false);
const textHeight = ref(0);
const showMore = ref(false);
const textContainer: Ref<HTMLElement | null> = ref(null);
const currentAddIcon = addIcon;
const currentMinusIcon = minusIcon;

const containerHeight = () => {
  if (textContainer.value) {
    let containerHeight = textContainer.value.clientHeight;
    hideOverflow.value = containerHeight && containerHeight > 50 ? true : false;
    showMore.value = hideOverflow.value;
  }
};

onMounted(() => {
  containerHeight();
});

onUpdated(() => {
  if (hideOverflow.value && textContainer.value) {
    let containerHeight = textContainer.value.clientHeight;
    if (containerHeight) {
      textHeight.value = containerHeight;
    }
  }
});
</script>

<style scoped>
.sliding-text {
  transition: 0.2s;
}

.top-border {
  border-top: 1px solid #d1d1d1;
}
.info-row {
  border-bottom: 1px solid #d1d1d1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hide-overflow {
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s;
}
</style>
