<template>
  <GreenifiedModal
    :title="t('title.PostProductOnGreenified')"
    @close="emit('close')"
    ref="reconModal"
    :isLoading="isLoading"
    :autoHeight="true"
    :type="'auto'"
  >
    <template #container>
      <form id="form-id" class="h-100" :key="String(isLoading)">
        <div class="action-form" ref="test">
          <GreenifiedForm
            v-model:formValues="saleStructure"
            :priceSuggestion="priceSuggestion"
            ref="greenifiedForm"
            @priceChange="getCalculatedPriceDebounce()"
            :includedFields="[
              'adress',
              'postnummer',
              'ort',
              'land',
              'intro',
              'pris',
              'information',
              'lest_og_godkjent',
              'produktionsar',
              'nar_ar_produkten_tillganglig',
              'saljs_styckvis_eller_i_grupp',
              'ange-antal',
              'info'
            ]"
            :addressSuggestion="addresses"
            v-model:formIsSubmitted="formIsSubmitted"
            :formFields="indexStore.projectDetails.struc"
            @input="(address: NewAddress) => updateAddressFields(address)"
          />
        </div>
      </form>
      <div
        class="row align-items-center mt-2"
        v-if="
          parseCalculatedPrice !== '0' &&
          saleStructure[getProjectStructureFieldKey('pris')]
        "
      >
        <div class="col-4">
          <p class="form-label">
            {{ t("label.salesPriceOnGreenified") }}
          </p>
        </div>
        <div class="col-8">
          <p class="form-label">
            {{
              loadingPrice ? `${t("button.loading")}...` : parseCalculatedPrice
            }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row p-0">
        <div class="col-4 d-flex align-items-center">
          <GreenifiedButton
            :form="'form-id'"
            size="thin"
            :type="'submit'"
            :disabled="loadingPost"
            @click.prevent="handleFormValidation()"
            btnStyle="green"
            :text="
              loadingPost
                ? t('button.pleaseWait')
                : t('button.postOnGreenified')
            "
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-if="publishSuccess"
    type="response"
    :closable="false"
    :title="t('responseTitle.productIsPublished')"
    :text="getResponseText()"
    @close="emitRefresh()"
  />
</template>

<script setup lang="ts">
import GreenifiedForm from "@/components/form/GreenifiedForm.vue";
import debounce from "debounce";
import {
  withDefaults,
  defineProps,
  ref,
  Ref,
  defineEmits,
  computed,
  onMounted
} from "vue";
import { AddressInformation, NewAddress } from "../../library/types/room/rooms";
import { bolderFont } from "../../library/helpers/typography";
import { ProductInformation } from "../../library/types/product/productGroup";
import { keysExistInObject } from "../../library/helpers/handleObjects";
import { useIndexStore } from "@/store";
import { getAddressesFromIds } from "@/library/api/address";
import { completeGreenifiedStructure } from "@/library/api/greenifiedProducts";
import { getCalculatedPrice, getPriceEvaluation } from "@/library/api/helpers";
import {
  getProductInformationApi,
  getProductItemsApi
} from "@/library/api/products";
import { postGreenifiedSale } from "@/library/api/sales";
import { PriceSuggestion } from "@/services/api/helpersApi";
import { FormFields } from "@/library/types/structure/formStructure";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
import { useI18n } from "vue-i18n";
import { convertCurrencyCodeToSymbol } from "@/library/helpers/convertCurrencyCodeToSymbol";

type GreenifiedSaleFormProps = {
  productIds: string[];
};

const greenifiedSaleFormProps = withDefaults(
  defineProps<GreenifiedSaleFormProps>(),
  {
    productIds: () => []
  }
);

const { t, locale } = useI18n();
const indexStore = useIndexStore();
const isLoading = ref(false);
const loadingPost = ref(false);
const showValidation = ref(false);
const saleStructure: Ref<FormFields> = ref({});
const formIsSubmitted = ref(false);
const currentAddressInformation: Ref<AddressInformation["address"]> = ref({
  adress: "",
  postnummer: "",
  ort: "",
  land: ""
});
const productGroup: Ref<ProductInformation | null> = ref(null);
const uniqueRooms: Ref<string[]> = ref([]);
const addresses: Ref<AddressInformation[]> = ref([]);
const calculatedPrice = ref("");
const category = ref("");
const priceSuggestion: Ref<PriceSuggestion> = ref([]);
const publishSuccess = ref(false);
const test: Ref<HTMLElement | null> = ref(null);
const currentLocation: Ref<ProductInformation["location"]> = ref("");
const greenifiedForm = ref<InstanceType<typeof GreenifiedForm>>();
const loadingPrice = ref(false);

const emit = defineEmits(["refresh", "close"]);

const parseCalculatedPrice = computed(() => {
  const parsedPrice = parseInt(
    calculatedPrice.value?.substring(
      calculatedPrice.value?.indexOf(indexStore.projectDetails.land + ":") + 3
    )
  );

  if (!parsedPrice) return "0";
  return `${parsedPrice} ${convertCurrencyCodeToSymbol(
    indexStore.projectDetails.valuta,
    locale.value
  )}`;
});

const handleFormValidation = () => {
  formIsSubmitted.value = true;
  if (!greenifiedForm.value?.validateGreenifiedForm()) return false;

  completeGreenStruc();
};

const getResponseText = () => {
  const bolderString = bolderFont("button.onGreenified");
  return t("paragraph.publishedForSale", [bolderString]);
};

const updateAddressFields = (newAddress: NewAddress) => {
  currentAddressInformation.value[
    newAddress.key as keyof AddressInformation["address"]
  ] = newAddress.value;
  currentAddressInformation.value[
    newAddress.name as keyof AddressInformation["address"]
  ] = newAddress.value;
};

const completeGreenStruc = async () => {
  showValidation.value = false;
  loadingPost.value = true;

  saleStructure.value["pris"] =
    saleStructure.value[getProjectStructureFieldKey("pris")];
  saleStructure.value["saljs_styckvis_eller_i_grupp"] =
    saleStructure.value[
      getProjectStructureFieldKey("saljs_styckvis_eller_i_grupp")
    ];
  saleStructure.value["ange-antal"] =
    saleStructure.value[getProjectStructureFieldKey("ange-antal")];

  const result = await completeGreenifiedStructure(
    greenifiedSaleFormProps.productIds,
    "D",
    saleStructure.value
  );

  if (!result.success) return;

  postSellOnGreenified();
};

const postSellOnGreenified = async () => {
  showValidation.value = false;

  const result = await postGreenifiedSale(greenifiedSaleFormProps.productIds);

  loadingPost.value = false;
  if (!result.success) return;

  publishSuccess.value = true;
};

const getCalculatedPriceDebounce = debounce(() => {
  loadingPrice.value = true;
  getProductPrice();
}, 1000);

const emitRefresh = () => {
  emit("refresh");
  emit("close");
};

const getProductHeaders = async () => {
  isLoading.value = true;
  const result = await getProductItemsApi({
    filter: [
      {
        key: "id",
        operator: "in",
        value: greenifiedSaleFormProps.productIds
      }
    ],
    group: [],
    status: ["S"]
  });

  if (!result.success) {
    return (isLoading.value = false);
  }
  uniqueRooms.value = result.result.map(productItem => {
    return productItem.rum;
  });
  getProductInformation();
};

const getProductInformation = async () => {
  const result = await getProductInformationApi(
    greenifiedSaleFormProps.productIds[0]
  );

  if (!result.success) {
    return (isLoading.value = false);
  }

  productGroup.value = result.result;

  if (uniqueRooms.value.length > 1) {
    productGroup.value.location = "";
  }

  currentLocation.value = productGroup.value.location;
  saleStructure.value = result.result.sale_info;
  saleStructure.value[getProjectStructureFieldKey("produktionsar")] = String(
    productGroup.value.green_struc[getProjectStructureFieldKey("produktionsar")]
  );
  category.value = result.result.category;
  getProjectAddresses();
};
const getProjectAddresses = async () => {
  const result = await getAddressesFromIds(greenifiedSaleFormProps.productIds);

  if (!result.success) {
    return (isLoading.value = false);
  }

  addresses.value = result.result;

  if (
    addresses.value.length > 0 &&
    !keysExistInObject(["adress", "postnummer", "ort"], saleStructure.value)
  ) {
    const currentAddress = { ...addresses.value[0].address };
    Object.keys(currentAddress).forEach(addressKey => {
      const updatedFieldKey = getProjectStructureFieldKey(addressKey);
      const address =
        currentAddress[addressKey as keyof AddressInformation["address"]];

      saleStructure.value = Object.assign(saleStructure.value, {
        [updatedFieldKey]: address
      });
    });
  }
  getProductPrice();
};

const getProductPrice = async () => {
  const productPrice =
    saleStructure.value[getProjectStructureFieldKey("pris")] ?? 0;
  const productQuantity =
    saleStructure.value[
      getProjectStructureFieldKey("saljs_styckvis_eller_i_grupp")
    ] === "group"
      ? saleStructure.value[getProjectStructureFieldKey("ange-antal")] ?? 1
      : 1;

  const result = await getCalculatedPrice(
    Number(productPrice),
    category.value,
    Number(productQuantity)
  );
  loadingPrice.value = false;
  isLoading.value = false;
  if (!result.success) {
    return;
  }

  calculatedPrice.value = result.result.calcPrice;
};
const getEvaluation = async () => {
  const result = await getPriceEvaluation(greenifiedSaleFormProps.productIds);
  if (!result.success) return;

  priceSuggestion.value = result.result;
};

onMounted(() => {
  getProductHeaders();
  getEvaluation();
});
</script>

<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
}
</style>
