import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-label-paragraph mb-2" }
const _hoisted_2 = { class: "col-5" }

import { computed, onMounted, Ref, ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { useIndexStore } from "@/store";
import { getLabelOrdersApi } from "@/library/api/labels";
import OrderLabelsForm from "../form/OrderLabelsForm.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import { LabelOrder } from "@/library/types/labels/labels";


export default /*@__PURE__*/_defineComponent({
  __name: 'LabelOrderModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { t, locale } = useI18n();
const indexStore = useIndexStore();
const labelOrders: Ref<LabelOrder[]> = ref([]);
const showOrderMore = ref(false);
const isLoading = ref(false);

const userRole = computed(() => {
  return indexStore.currentProject.role;
});

const getLabelOrders = async () => {
  isLoading.value = true;
  const result = await getLabelOrdersApi();
  isLoading.value = false;

  if (!result.success) return;

  labelOrders.value = result.result;
};

const getLocaleTime = (date: string) => {
  const utcDate = new Date(date);
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const userDate = new Date(utcDate.getTime() + userTimezoneOffset * 60000);
  const formattedDate = userDate.toLocaleString(
    locale.value.toLocaleLowerCase()
  );
  return formattedDate;
};

const tableColumns = (labelOrder: LabelOrder): TableColumns["columns"] => {
  return {
    createdAt: {
      value: getLocaleTime(labelOrder.created_at),
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.orderDate",
      inputSettings: null,
      selectOptions: []
    },
    address: {
      value: `${labelOrder.adress} <br />
              ${labelOrder.postnr}, ${labelOrder.postadr}, ${labelOrder.land}`,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.address",
      inputSettings: null,
      selectOptions: []
    },
    action: {
      value: labelOrder.antal,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.numberOfLabels",
      inputSettings: null,
      selectOptions: []
    }
  };
};

onMounted(() => {
  getLabelOrders();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(GreenifiedModal, {
      onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
      type: "smaller",
      title: _unref(t)('title.orderedLabels'),
      isLoading: isLoading.value,
      headerSpacing: false,
      key: String(labelOrders.value)
    }, {
      modalHeaderContent: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.orderedLabels")), 1),
        (labelOrders.value.length)
          ? (_openBlock(), _createBlock(TableHeaderRow, {
              key: 0,
              tableHeaders: tableColumns(labelOrders.value[0])
            }, null, 8, ["tableHeaders"]))
          : _createCommentVNode("", true)
      ]),
      container: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(labelOrders.value, (order) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "border-bottom",
            key: order.land
          }, [
            _createVNode(MainTableRow, {
              type: "other",
              tableColumns: tableColumns(order)
            }, null, 8, ["tableColumns"])
          ]))
        }), 128))
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(GreenifiedButton, {
            btnStyle: "green",
            text: _unref(t)('button.orderLabels'),
            disabled: userRole.value !== 'P',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showOrderMore.value = true))
          }, null, 8, ["text", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"])),
    (showOrderMore.value)
      ? (_openBlock(), _createBlock(OrderLabelsForm, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (showOrderMore.value = false)),
          onRefresh: _cache[3] || (_cache[3] = ($event: any) => (getLabelOrders()))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})