import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/minus.svg'
import _imports_1 from '@/assets/img/plus.svg'


const _hoisted_1 = { class: "py-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { style: {} }
const _hoisted_5 = { class: "view-title" }
const _hoisted_6 = { class: "header-paragraf" }
const _hoisted_7 = { style: {} }
const _hoisted_8 = {
  class: "view-title mt-5",
  style: {"letter-spacing":"0.64px","font-size":"16px"}
}
const _hoisted_9 = { class: "header-paragraf" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  class: "row mt-5",
  style: {"display":"grid","grid-template-columns":"repeat(auto-fill, minmax(170px, auto))","grid-template-areas":"'a b b b b'\n          'c d d d d'\n          'c d d d d'","gap":"10px 50px"}
}
const _hoisted_13 = {
  class: "view-title",
  style: {"letter-spacing":"0.56px","font-size":"14px","grid-area":"a"}
}
const _hoisted_14 = {
  class: "gr-color w-100 dashboard-card",
  style: {"height":"146px","grid-area":"c"}
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  class: "view-title",
  style: {"letter-spacing":"0.56px","font-size":"14px","grid-area":"b"}
}
const _hoisted_17 = { style: {"display":"grid","grid-area":"d","gap":"50px 50px","grid-template-columns":"repeat(auto-fill, minmax(170px, auto))"} }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "row mt-5" }
const _hoisted_23 = { class: "col-6" }
const _hoisted_24 = { class: "row accordian align-items-center" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = {
  key: 0,
  src: _imports_0
}
const _hoisted_27 = {
  key: 1,
  src: _imports_1
}
const _hoisted_28 = { class: "accordian-text-wrapper" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "col-6" }
const _hoisted_31 = ["src"]

import LoaderSpinner from "@/components/common/LoaderSpinner.vue";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { I18nMessage } from "../library/types/locales/i18nMessage";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import { getProjectInformation } from "@/library/api/project";
import { useAuthStore } from "@/store/auth";
import { ProjectProductQuantities } from "@/services/api/projectApi";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const { t, locale, messages } = useI18n();
const store = useIndexStore();
const authStore = useAuthStore();
const router = useRouter();

const isLoading = ref(true);
const openAccordion: Ref<number | null> = ref(null);
const totalRepurposes = ref(0);
const totalForSale = ref(0);
const totalRecycles = ref(0);
const totalUnhandled = ref(0);
const projectInformation: Ref<{
  tot_actions: ProjectProductQuantities["tot_actions"];
  tot_prod: ProjectProductQuantities["tot_prod"];
} | null> = ref(null);
const currentProjectNumber = store.currentProjectNumber;

onMounted(() => {
  getProjectInfo();
});

const content = computed(() => {
  return process.env.VUE_APP_LARAVEL_API;
});

const faq = computed(() => {
  return (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).FAQ;
});

const getTotalAmount = (
  totalActions: ProjectProductQuantities["tot_actions"],
  productStatuses: ProductStatuses[]
) => {
  let totalAmount = 0;
  productStatuses.forEach(productStatusKey => {
    if (!totalActions[productStatusKey]) {
      totalAmount += 0;
    } else {
      totalAmount += Number(totalActions[productStatusKey].total);
    }
  });

  return totalAmount;
};

const getProjectInfo = async () => {
  isLoading.value = true;
  const result = await getProjectInformation();
  isLoading.value = false;
  if (!result.success) return;

  projectInformation.value = {
    tot_actions: result.result.data.tot_actions,
    tot_prod: result.result.data.tot_prod
  };

  totalRepurposes.value = getTotalAmount(projectInformation.value.tot_actions, [
    "Å",
    "R",
    "M",
    "F"
  ]);
  totalForSale.value = getTotalAmount(projectInformation.value.tot_actions, [
    "S",
    "I",
    "G"
  ]);
  totalRecycles.value = getTotalAmount(projectInformation.value.tot_actions, [
    "K",
    "C"
  ]);
  totalUnhandled.value = getTotalAmount(projectInformation.value.tot_actions, [
    "J"
  ]);
  isLoading.value = false;
};

watch(
  () => currentProjectNumber,
  () => {
    getProjectInfo();
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(`${_unref(t)("title.welcome")} ${
                _unref(authStore).currentUser.name ? _unref(authStore).currentUser.name : ""
              }`), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)("paragraph.aboutInventory")), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h2", _hoisted_8, _toDisplayString(_unref(t)("title.project") + ": " + _unref(store).currentProject.namn), 1),
          _createElementVNode("p", _hoisted_9, [
            _createTextVNode(_toDisplayString(_unref(store).currentProject.projektbesk
                ? _unref(store).currentProject.projektbesk
                : _unref(t)("label.missingDesc")), 1),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("img", {
          style: {"padding-top":"23px","margin-top":"13p","width":"100%","padding-left":"48px"},
          src: content.value + '/api/application-image/dashboard1'
        }, null, 8, _hoisted_11)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("h2", _hoisted_13, _toDisplayString(_unref(t)("label.overview")), 1),
      _createElementVNode("div", _hoisted_14, [
        (isLoading.value)
          ? (_openBlock(), _createBlock(LoaderSpinner, { key: 0 }))
          : _createCommentVNode("", true),
        (!isLoading.value && projectInformation.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(projectInformation.value.tot_prod), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString(_unref(t)("label.products")), 1)
      ]),
      _createElementVNode("h2", _hoisted_16, _toDisplayString(_unref(t)("label.proportionProducts")), 1),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", {
          class: "gr-color w-100 dashboard-card pointer",
          style: {"height":"146px"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (totalRepurposes.value && _unref(router).push({ name: 'projectRepurpose' })))
        }, [
          (isLoading.value)
            ? (_openBlock(), _createBlock(LoaderSpinner, { key: 0 }))
            : _createCommentVNode("", true),
          (!isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(totalRepurposes.value), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_unref(t)("title.repurpose")), 1)
        ]),
        _createElementVNode("div", {
          class: "gr-color w-100 dashboard-card pointer",
          style: {"height":"146px"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (totalForSale.value && _unref(router).push({ name: 'projectSell' })))
        }, [
          (isLoading.value)
            ? (_openBlock(), _createBlock(LoaderSpinner, { key: 0 }))
            : _createCommentVNode("", true),
          (!isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(totalForSale.value), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_unref(t)("label.sell")), 1)
        ]),
        _createElementVNode("div", {
          class: "gr-color w-100 dashboard-card pointer",
          style: {"height":"146px"},
          onClick: _cache[2] || (_cache[2] = ($event: any) => (totalRecycles.value > 0 && _unref(router).push({ name: 'projectRecycle' })))
        }, [
          (isLoading.value)
            ? (_openBlock(), _createBlock(LoaderSpinner, { key: 0 }))
            : _createCommentVNode("", true),
          (!isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(totalRecycles.value), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_unref(t)("label.recycle")), 1)
        ]),
        _createElementVNode("div", {
          class: "gr-color w-100 dashboard-card pointer",
          style: {"height":"146px"},
          onClick: _cache[3] || (_cache[3] = ($event: any) => (totalUnhandled.value > 0 && _unref(router).push({ name: 'projectAction' })))
        }, [
          (isLoading.value)
            ? (_openBlock(), _createBlock(LoaderSpinner, { key: 0 }))
            : _createCommentVNode("", true),
          (!isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(totalUnhandled.value), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_unref(t)("label.unhandled")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "row table-item align-items-center",
          style: {"min-height":"0px"}
        }, [
          _createElementVNode("h2", {
            class: "view-title",
            style: {"letter-spacing":"0.64px","font-size":"16px","padding-top":"0px"}
          }, " FAQ ")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(faq.value, (question, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", {
                class: "col d-flex justify-content-between",
                onClick: ($event: any) => (openAccordion.value = openAccordion.value === key ? null : key)
              }, [
                _createElementVNode("strong", null, _toDisplayString(question.name), 1),
                (openAccordion.value === key)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                  : (_openBlock(), _createElementBlock("img", _hoisted_27))
              ], 8, _hoisted_25)
            ]),
            _createElementVNode("div", _hoisted_28, [
              (openAccordion.value === key)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "pb-3 accordian-text",
                    innerHTML: question.text
                  }, null, 8, _hoisted_29))
                : _createCommentVNode("", true)
            ])
          ], 64))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("img", {
          style: {"padding-top":"23px","margin-top":"13p","width":"100%","padding-left":"48px"},
          src: content.value + '/api/application-image/dashboard2'
        }, null, 8, _hoisted_31)
      ])
    ])
  ]))
}
}

})