import { AxiosResponse } from "axios";
import api from "../api";

export default {
  postPasswordReset(email: string): Promise<AxiosResponse> {
    return api().post(`/api/password/reset/` + email);
  },

  postTokenReset(token: string): Promise<AxiosResponse> {
    return api().post(`/api/password/reset-token/` + token);
  },

  postPassword(
    token: string,
    password: string,
    name?: string,
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/password/new-password/` + token,
      JSON.stringify({ password: password, name: name }),
    );
  },

  postTokenVerification(token: string): Promise<AxiosResponse> {
    return api().get(`/api/password/verify-token/` + token);
  },

  updateUserRole(
    projectNr: string,
    user: string,
    role: string,
  ): Promise<AxiosResponse> {
    return api().post(
      "/api/project/" + projectNr + "/user/change-role",
      JSON.stringify({ user: user, role: role }),
    );
  },

  postUserInvite(
    projectNr: string,
    user: string,
    role: string,
  ): Promise<AxiosResponse> {
    return api().post(
      "/api/project/" + projectNr + "/user/invite-user",
      JSON.stringify({ user: user, role: role }),
    );
  },

  deleteUser(projectNr: string, user: string): Promise<AxiosResponse> {
    return api().delete("/api/project/" + projectNr + "/user/" + user);
  },
};
