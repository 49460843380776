import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



type CounterIconProps = {
  counter: number;
  positionX: "left" | "center" | "right";
  positionY: "top" | "middle" | "bottom";
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CounterIcon',
  props: {
    counter: { default: 0 },
    positionX: { default: "right" },
    positionY: { default: "top" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["counter-wrapper d-flex align-items-center justify-content-center text-min emphasis", [_ctx.positionX, _ctx.positionY]])
  }, _toDisplayString(_ctx.counter), 3))
}
}

})