import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/cookie-icon.svg'



import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useIndexStore } from "@/store";

export default /*@__PURE__*/_defineComponent({
  __name: 'CookieInformation',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const store = useIndexStore();

const emit = __emit;

const verifyCookies = () => {
  store.changeCookies(true);
  emit("close");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (verifyCookies())),
    type: "response",
    "decline-text": _unref(t)('button.accept'),
    text: `${_unref(t)('msg.cookiesInformation')} <br /><br />${_unref(t)(
      'msg.consentToCookies'
    )}`,
    "no-outer-close": true,
    closable: false
  }, {
    modalHeader: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "w-100 d-flex justify-content-center" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "cookie icon",
          class: "res-image"
        })
      ], -1)
    ])),
    _: 1
  }, 8, ["decline-text", "text"]))
}
}

})