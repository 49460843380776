import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "form-label mb-2" }
const _hoisted_3 = { class: "form-label mb-2" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-5" }

import GreenifiedForm from "@/components/form/GreenifiedForm.vue";
import { computed, ref } from "vue";
import { AddressInformation, NewAddress } from "../library/types/room/rooms";
import { useIndexStore } from "@/store";
import GreenifiedModal from "./modal/GreenifiedModal.vue";
import GreenifiedButton from "./common/GreenifiedButton.vue";
import InputField from "./common/InputField.vue";
import { useI18n } from "vue-i18n";
import { postNewAddress, updateAddress } from "@/library/api/address";

type ProjectAddressFormProps = {
  title: string;
  existingAddressInformation?: AddressInformation;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectAddressForm',
  props: {
    title: { default: "" },
    existingAddressInformation: { default: () => ({
      address: {
        adress: "",
        postnummer: "",
        ort: "",
        land: ""
      },
      project: "",
      last_use: "",
      building: "",
      id: ""
    }) }
  },
  emits: ["refresh", "close"],
  setup(__props: any, { emit: __emit }) {

const projectAddressFormProps = __props;

const indexStore = useIndexStore();

const { t } = useI18n();
const isLoading = ref(false);
const showValidation = ref(false);
const currentAddressInformation = ref(
  projectAddressFormProps.existingAddressInformation
);

const greenifiedStructure = computed(() => {
  return indexStore.projectDetails.struc;
});

const addressForm = ref<HTMLFormElement | null>(null);

const updateAddressFields = (newAddress: NewAddress) => {
  if (!currentAddressInformation.value) return;

  currentAddressInformation.value.address[
    newAddress.key as keyof AddressInformation["address"]
  ] = newAddress.value;
  currentAddressInformation.value.address[
    newAddress.name as keyof AddressInformation["address"]
  ] = newAddress.value;
};

const updateExistingAddress = async () => {
  if (!currentAddressInformation.value) return;
  const result = await updateAddress(
    {
      building: currentAddressInformation.value.building,
      address: currentAddressInformation.value.address
    },
    currentAddressInformation.value.id
  );
  isLoading.value = false;
  if (!result.success) return;

  emit("refresh");
  emit("close");
};

const postAddress = async () => {
  const result = await postNewAddress({
    building: currentAddressInformation.value.building,
    address: currentAddressInformation.value.address
  });

  isLoading.value = false;

  if (!result.success) return;

  emit("refresh");
  emit("close");
};

const checkFormValidation = () => {
  if (addressForm.value && !addressForm.value.reportValidity()) {
    showValidation.value = true;
    return;
  }

  showValidation.value = false;
  isLoading.value = true;
  if (projectAddressFormProps.existingAddressInformation.id.length) {
    updateExistingAddress();
    return;
  }
  postAddress();
};

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    type: "x-small",
    title: _ctx.title,
    onClose: _cache[4] || (_cache[4] = ($event: any) => (emit('close')))
  }, {
    container: _withCtx(() => [
      (currentAddressInformation.value)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            id: "form-id",
            class: "h-100 action-form",
            ref_key: "addressForm",
            ref: addressForm
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("label", _hoisted_2, _toDisplayString(_unref(t)("label.buildingName")) + " *", 1),
              _createVNode(InputField, {
                required: "",
                fieldValue: currentAddressInformation.value.building,
                "onUpdate:fieldValue": _cache[0] || (_cache[0] = ($event: any) => ((currentAddressInformation.value.building) = $event))
              }, null, 8, ["fieldValue"])
            ]),
            _createElementVNode("label", _hoisted_3, _toDisplayString(_unref(t)("label.address")), 1),
            _createVNode(GreenifiedForm, {
              formValues: currentAddressInformation.value.address,
              "onUpdate:formValues": _cache[1] || (_cache[1] = ($event: any) => ((currentAddressInformation.value.address) = $event)),
              formFields: greenifiedStructure.value,
              hideTitle: true,
              ref: "mainItemDesc",
              includedFields: ['adress', 'postnummer', 'ort', 'land', 'intro'],
              onInput: _cache[2] || (_cache[2] = (newAddress) => updateAddressFields(newAddress))
            }, null, 8, ["formValues", "formFields"])
          ], 512))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(GreenifiedButton, {
            disabled: isLoading.value,
            form: "form-id",
            type: "submit",
            btnStyle: "green",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (checkFormValidation())),
            text: isLoading.value ? _unref(t)('button.pleaseWait') : _unref(t)('button.save')
          }, null, 8, ["disabled", "text"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})