import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row mb-4 p-0" }
const _hoisted_5 = { class: "col-sm-7" }
const _hoisted_6 = { class: "col-sm-5" }

import { bolderFont } from "../../library/helpers/typography";
import SelectRoom from "../SelectRoom.vue";
import { ref } from "vue";
import { RoomObject } from "@/library/types/room/rooms";
import { ProductStatuses } from "@/library/types/product/productHeader";
import {
  postNewProductLocation,
  postNewProductRoom
} from "@/library/api/moveProducts";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";

type MoveProductsModalPropType = {
  currentRoom?: RoomObject | null;
  productItemIds: string[];
  changeLocation: boolean;
  statuses: ProductStatuses[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MoveProductsModal',
  props: {
    currentRoom: { default: null },
    productItemIds: { default: () => [] },
    changeLocation: { type: Boolean, default: false },
    statuses: { default: () => [] }
  },
  emits: ["close", "moved"],
  setup(__props: any, { emit: __emit }) {

const moveProductsModalProps = __props;
const { t } = useI18n();

const selectedRoom = ref(moveProductsModalProps.currentRoom);
const showSuccess = ref(false);
const isLoading = ref(false);

const emit = __emit;

const moveProducts = async () => {
  if (!selectedRoom.value) return;

  isLoading.value = true;
  const result = await postNewProductRoom(
    moveProductsModalProps.productItemIds,
    selectedRoom.value.rum,
    moveProductsModalProps.statuses
  );
  isLoading.value = false;

  if (!result.success) return;

  showSuccess.value = true;
};
const changeProductLocation = async () => {
  if (!selectedRoom.value) return;

  isLoading.value = true;

  const result = await postNewProductLocation(
    moveProductsModalProps.productItemIds,
    selectedRoom.value.rum
  );
  isLoading.value = false;

  if (!result.success) return;

  showSuccess.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      type: "verification",
      title: 
      _ctx.changeLocation ? _unref(t)('button.changeLocation') : _unref(t)('label.moveProduct')
    ,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
    }, {
      container: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.changeLocation
                ? _unref(t)("paragraph.changeLocationInfo")
                : _unref(t)("paragraph.moveProductForm")), 1)
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(SelectRoom, {
              modelValue: selectedRoom.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedRoom).value = $event)),
              isReduced: false,
              append: true
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(GreenifiedButton, {
              btnStyle: "black",
              disabled: selectedRoom.value === null,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLocation ? changeProductLocation() : moveProducts())),
              text: _unref(t)('placeholders.selectRoom')
            }, null, 8, ["disabled", "text"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    (showSuccess.value && selectedRoom.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.productMoved'),
          text: 
      _ctx.changeLocation
        ? _unref(t)('paragraph.productHasBeenMoved', [
            _unref(bolderFont)(selectedRoom.value.rumsnamn)
          ])
        : _unref(t)('paragraph.productWillBeMovedTo', [
            _unref(bolderFont)(selectedRoom.value.rumsnamn)
          ])
    ,
          onClose: _cache[3] || (_cache[3] = ($event: any) => {
      emit('moved');
      emit('close');
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})