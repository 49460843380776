<template>
  <GreenifiedModal
    :type="'x-small'"
    :title="t('title.repurposeLists')"
    @close="emit('close')"
    showCloseIcon
  >
    <template #container>
      <p class="form-label-paragraph mb-2">
        {{ t("paragraph.repurposeLists") }}
      </p>
      <CustomSelect
        :key="currentList.label"
        v-model="currentList"
        label="label"
        :clearable="false"
        :options="currentListOptions"
      />

      <label class="form-label">{{ t("label.possibleComment") }}</label>

      <InputTextField :rows="2" v-model="pdfComment" />
    </template>
    <template #footer>
      <div class="row ps-0">
        <div class="col-5">
          <GreenifiedButton
            :btnStyle="'black'"
            @click="emit('close')"
            :text="t('button.close')"
          />
        </div>
        <div class="col-5">
          <GreenifiedButton
            :btnStyle="'green'"
            @click="downloadPdfFromUrl()"
            :disabled="pdfIsLoading"
            :text="!pdfIsLoading ? t('button.download') : t('button.loading')"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import {
  defineEmits,
  defineProps,
  onMounted,
  Ref,
  ref,
  withDefaults
} from "vue";
import { I18nMessage, PdfList } from "@/library/types/locales/i18nMessage";
import InputTextField from "../common/InputTextField.vue";
import CustomSelect from "../common/CustomSelect.vue";
import { getPdfFromUrl, getReconditionListsApi } from "@/library/api/media";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { useIndexStore } from "@/store";

export type PdfRoute =
  | "to-repurpose"
  | "recondition"
  | "repurpose-overview"
  | "to-sale"
  | "recycle"
  | "recycle-overview"
  | "internal-order";

type PdfModalProps = {
  pdfRoute?: PdfRoute;
};

const pdfModalProps = withDefaults(defineProps<PdfModalProps>(), {
  pdfRoute: "recondition"
});

const { t, messages, locale } = useI18n();
const emit = defineEmits(["close"]);
const indexStore = useIndexStore();
const pdfComment = ref(" ");
const pdfIsLoading = ref(false);
const currentListOptions: Ref<PdfList[]> = ref([]);
const currentList: Ref<PdfList> = ref({ label: "", url: "" });

const localeMoveLists = (): PdfList[] => {
  const moveLists = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).moveLists;
  return Object.keys(moveLists).map(
    localeKey => moveLists[localeKey as keyof I18nMessage["list"]["moveLists"]]
  );
};

const localeReconditionLists = (reconditionList: string[]): PdfList[] => {
  const localeReconditionList = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).repurposeLists;
  return Object.keys(localeReconditionList)
    .filter(listKey => {
      return reconditionList.includes(listKey) || listKey === "ALLList";
    })
    .map(
      reconditionKey =>
        localeReconditionList[
          reconditionKey as keyof I18nMessage["list"]["repurposeLists"]
        ]
    );
};

const getReconditionLists = async () => {
  const result = await getReconditionListsApi();

  if (!result.success) return;
  currentList.value = localeReconditionLists(result.result)[0];
  currentListOptions.value = localeReconditionLists(result.result);
};

const downloadPdfFromUrl = async () => {
  pdfIsLoading.value = true;
  const pdfUrl = currentList.value.url
    .replace(/\{projectNr\}/g, indexStore.currentProjectNumber)
    .replace(/\{note\}/g, pdfComment.value);

  const result = await getPdfFromUrl(pdfUrl);

  if (!result.success) return;

  const blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);

  window.open(url);

  emit("close");
};

onMounted(() => {
  if (pdfModalProps.pdfRoute === "recondition") {
    return getReconditionLists();
  }
  currentList.value = localeMoveLists()[0];
  currentListOptions.value = localeMoveLists();
  return;
});
</script>
