import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-small mb-4" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = { class: "row p-0" }
const _hoisted_4 = { class: "col-7" }

import GreenifiedModal from "./GreenifiedModal.vue";
import {
  onMounted,
  Ref,
  ref
} from "vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import { getInternalOrders } from "@/library/api/sales";
import CustomSelect from "../common/CustomSelect.vue";
import { getPackingList } from "@/library/api/media";
import { InternalSaleOrder, OrderStatuses } from "@/services/api/salesApi";

type PackingListModalProps = {
  orderStatus: OrderStatuses;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'PackingListModal',
  props: {
    orderStatus: { default: "A" }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const packingListModalProps = __props;

const { t } = useI18n();
const emit = __emit;
const orderNumber: Ref<string | null> = ref(null);
const pdfIsLoading = ref(false);
const isLoading = ref(false);
const ordersWithOrderNumber: Ref<InternalSaleOrder[]> = ref([]);
const noConfirmedOrders = ref(false);

const fetchInternalOrders = async () => {
  isLoading.value = true;

  const result = await getInternalOrders([packingListModalProps.orderStatus]);

  if (!result.success) {
    isLoading.value = false;
    return;
  }
  if (!result.result.length) {
    isLoading.value = false;
    noConfirmedOrders.value = true;
    return;
  }
  const seenOrderNumbers = new Set<number | string>();

  ordersWithOrderNumber.value = result.result.filter(order => {
    if (order.order_number !== null) {
      if (!seenOrderNumbers.has(order.order_number)) {
        seenOrderNumbers.add(order.order_number);
        return true;
      }
    }
    return false;
  });

  if (!ordersWithOrderNumber.value.length) {
    noConfirmedOrders.value = true;
  }

  isLoading.value = false;
};

const fetchPackingList = async () => {
  pdfIsLoading.value = true;
  const result = await getPackingList(
    orderNumber.value,
    packingListModalProps.orderStatus
  );
  if (!result.success) {
    pdfIsLoading.value = false;
    return;
  }

  let blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);
  window.open(url);

  pdfIsLoading.value = false;
};

onMounted(() => {
  fetchInternalOrders();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    title: _unref(t)('title.packingList'),
    type: "response",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
    "is-loading": isLoading.value
  }, {
    container: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.generatePackinglistInfo")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(CustomSelect, {
          options: ordersWithOrderNumber.value,
          label: "order_number",
          placeholder: 
            ordersWithOrderNumber.value.length
              ? _unref(t)('label.orderNumber')
              : _unref(t)('placeholders.noConfirmedOrdersExist')
          ,
          "reduced-key": "order_number",
          modelValue: orderNumber.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((orderNumber).value = $event)),
          clearable: true
        }, null, 8, ["options", "placeholder", "modelValue"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (noConfirmedOrders.value)
            ? (_openBlock(), _createBlock(GreenifiedButton, {
                key: 0,
                btnStyle: "green",
                text: _unref(t)('button.noApprovedOrders'),
                disabled: true
              }, null, 8, ["text"]))
            : (_openBlock(), _createBlock(GreenifiedButton, {
                key: 1,
                btnStyle: "green",
                text: !pdfIsLoading.value ? _unref(t)('button.download') : _unref(t)('button.loading'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (fetchPackingList()))
              }, null, 8, ["text"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "is-loading"]))
}
}

})