<template>
  <b-sidebar :open="openSidebar" :bg="'white'" hideHeader>
    <div class="project-list-wrapper" v-if="!isLoading">
      <project-list
        v-if="openSidebar"
        :projects="activeProjects"
        :projectType="0"
        :title="t('label.activeProjects')"
        :collapse="expandedField === 1"
        @expandedField="(value: number) => (expandedField = value)"
      />

      <project-list
        v-if="openSidebar"
        :projectType="1"
        :projects="closedProjects"
        :title="t('label.completedProjects')"
        :collapse="expandedField === 0"
        @expandedField="(value: number) => (expandedField = value)"
      />
    </div>
  </b-sidebar>
</template>

<script setup lang="ts">
import BSidebar from "@/components/BSidebar.vue";
import ProjectList from "@/components/project/ProjectList.vue";
import {
  onMounted,
  Ref,
  ref,
  withDefaults,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import { SelectableProject } from "../../library/types/project/project";
import { getClientProjects } from "@/library/api/project";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/store/auth";

const { t } = useI18n();
const authStore = useAuthStore();

type SidebarProjectsProps = {
  openSidebar: boolean;
};

const sidebarProjectsProps = withDefaults(defineProps<SidebarProjectsProps>(), {
  openSidebar: false,
});

const activeProjects: Ref<SelectableProject[]> = ref([]);
const closedProjects: Ref<SelectableProject[]> = ref([]);
const expandedField = ref(0);
const isLoading = ref(false);

onMounted(() => {
  getProjects();
});

const getProjects = async () => {
  if (!authStore.isAuthenticated) return;
  isLoading.value = true;
  const result = await getClientProjects();
  isLoading.value = false;

  if (!result.success) return;

  result.result.forEach((project: SelectableProject) => {
    if (project.active === "1") {
      activeProjects.value.push(project);
    } else {
      closedProjects.value.push(project);
    }
  });
};

const emit = defineEmits(["update:openSidebar"]);

watch(
  () => sidebarProjectsProps.openSidebar,
  toggleOpen => {
    emit("update:openSidebar", toggleOpen);
  },
);
</script>

<style scoped>
.project-list-wrapper {
  max-height: 96%;
  overflow: auto;
  padding-left: 32px;
  padding-right: 16px;
}
</style>
