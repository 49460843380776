export const getLocaleFromLocationHost = (host: string): string => {
  if (
    host.includes("se") ||
    host.includes("localhost") ||
    host.includes("staging")
  ) {
    return "sv";
  }
  if (host.includes("dk")) {
    return "da";
  }
  if (host.includes("no")) {
    return "no";
  }
  if (host.includes("fi")) {
    return "fi";
  }
  return "en";
};
