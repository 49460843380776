import { LabelOrder, PostLabelOrder } from "@/library/types/labels/labels";
import api from "../api";
import { AxiosResponse } from "axios";

export default {
  getOrderVerification(token: string): Promise<AxiosResponse<LabelOrder>> {
    return api().get("/api/label-order/" + token);
  },

  postGrantedLabelOrder(token: string): Promise<AxiosResponse> {
    return api().post("/api/label-order/" + token);
  },

  postDeniedLabelOrder(token: string): Promise<AxiosResponse> {
    return api().post("/api/label-order/" + token + "/deny");
  },

  getLabelOrderFile(token: string): Promise<AxiosResponse<BlobPart>> {
    return api().post("/api/label-order/" + token + "/download");
  },

  postLabelOrderApi(
    projectNr: string,
    order: PostLabelOrder,
  ): Promise<AxiosResponse> {
    return api().post(
      "/api/project/" + projectNr + "/label-order",
      JSON.stringify(order),
    );
  },

  getLabelOrdersApi(projectNr: string): Promise<AxiosResponse<LabelOrder[]>> {
    return api().get("/api/project/" + projectNr + "/label-order");
  },
};
