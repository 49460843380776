<template>
  <GreenifiedButton
    inputType="radio"
    v-if="multipleRadioField"
    @click="inputRadio(String(optionKey))"
    :text="option"
    :inputId="multipleRadioField.key + '-' + optionKey"
    :inputName="multipleRadioField.key"
    :inputValue="option"
    :btnStyle="checked ? 'black' : 'normal'"
    :size="thinButton ? 'thin' : 'full'"
    :inputRequired="multipleRadioField.required === 0 ? false : true"
  />
</template>

<script lang="ts" setup>
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { RadioFieldType } from "@/library/types/structure/subfieldTypes";
import { ref, defineEmits, withDefaults, defineProps } from "vue";

type MultipleRadioFieldProps = {
  presetMultipleRadioFieldValues: FormFields["key"];
  multipleRadioField: RadioFieldType | null;
  optionKey: string | number;
  option: string;
  checked: boolean;
  thinButton: boolean;
};

const multipleRadioFieldProps = withDefaults(
  defineProps<MultipleRadioFieldProps>(),
  {
    presetMultipleRadioFieldValues: () => ({}),
    multipleRadioField: null,
    optionKey: "",
    option: "",
    checked: false,
    thinButton: false,
  },
);

const emit = defineEmits(["update:presetMultipleRadioFieldValues"]);

const localMultipleRadioFieldValues = ref(
  multipleRadioFieldProps.presetMultipleRadioFieldValues,
);

const inputRadio = (key: string) => {
  localMultipleRadioFieldValues.value = key;
  emit("update:presetMultipleRadioFieldValues", localMultipleRadioFieldValues);
};
</script>
