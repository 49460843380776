<template>
  <MainFilter
    :filterTitle="t('title.settings')"
    :filterText="t('paragraph.settings')"
    :hideFilterOptions="true"
  />
  <LoadingOverlay v-if="loadingPostAddress"></LoadingOverlay>
  <div class="d-flex gap-5 flex-wrap">
    <GrCard @click="showAddresses = true" :title="t('label.addresses')">
      <p
        class="card-paragraph mt-2"
        v-html="t('paragraph.settingsAddress')"
      ></p>
    </GrCard>
    <gr-card
      @click="
        router.push({
          name: 'projectRoom',
        })
      "
      :title="t('label.rooms')"
    >
      <p class="card-paragraph mt-2" v-html="t('paragraph.settingsRooms')"></p>
    </gr-card>
    <gr-card @click="showLabel = true" :title="t('label.orderLabels')">
      <p
        class="card-paragraph mt-2"
        v-html="
          t('paragraph.settingsLabelOrder', {
            tot: totalLabels,
            ordered: orderedLabels,
          })
        "
      ></p>
    </gr-card>
    <gr-card @click="showCurrency = true" :title="t('label.currency')">
      <p
        class="card-paragraph mt-2"
        v-html="
          t('paragraph.settingsCurrency', {
            currency: currencyName,
          })
        "
      ></p>
    </gr-card>
  </div>
  <CurrencyModal v-if="showCurrency" @close="showCurrency = false" />

  <LabelOrderModal v-if="showLabel" @close="showLabel = false" />

  <AddressModal v-if="showAddresses" @close="showAddresses = false" />
</template>

<script setup lang="ts">
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import MainFilter from "@/components/filter/MainFilter.vue";
import GrCard from "@/components/common/GrCard.vue";
import LabelOrderModal from "@/components/modal/LabelOrderModal.vue";
import CurrencyModal from "@/components/modal/CurrencyModal.vue";
import AddressModal from "@/components/modal/AddressModal.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const indexStore = useIndexStore();
const router = useRouter();
const showAddresses = ref(false);
const loadingPostAddress = ref(false);
const showCurrency = ref(false);
const showLabel = ref(false);

const currencyName = computed(() => {
  return t("list.currencies." + indexStore.currentProjectCurrency + ".name");
});

const totalLabels = computed(() => {
  return indexStore.quantities?.["E"];
});

const orderedLabels = computed(() => {
  return indexStore.quantities?.["EO"];
});
</script>

<style lang="scss" scoped>
.gr-contact-info {
  width: 100%;
  background-color: #f5f2eb;
}
.gr-contact-info:after {
  content: "";
  display: block;
}

.card-paragraph {
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.19px;
  line-height: 18px;
}
</style>
