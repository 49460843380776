<template>
  <div class="py-5">
    <div class="row">
      <div class="col">
        <div style="">
          <h2 class="view-title">
            {{
              `${t("title.welcome")} ${
                authStore.currentUser.name ? authStore.currentUser.name : ""
              }`
            }}
          </h2>
          <p class="header-paragraf">
            {{ t("paragraph.aboutInventory") }}
          </p>
        </div>
        <div style="">
          <h2
            class="view-title mt-5"
            style="letter-spacing: 0.64px; font-size: 16px"
          >
            {{ t("title.project") + ": " + store.currentProject.namn }}
          </h2>
          <p class="header-paragraf">
            {{
              store.currentProject.projektbesk
                ? store.currentProject.projektbesk
                : t("label.missingDesc")
            }}<br />
          </p>
        </div>
      </div>
      <div class="col">
        <img
          style="
            padding-top: 23px;
            margin-top: 13p;
            width: 100%;
            padding-left: 48px;
          "
          :src="content + '/api/application-image/dashboard1'"
        />
      </div>
    </div>

    <div
      class="row mt-5"
      style="
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(170px, auto));
        grid-template-areas:
          'a b b b b'
          'c d d d d'
          'c d d d d';
        gap: 10px 50px;
      "
    >
      <h2
        class="view-title"
        style="letter-spacing: 0.56px; font-size: 14px; grid-area: a"
      >
        {{ t("label.overview") }}
      </h2>
      <div
        class="gr-color w-100 dashboard-card"
        style="height: 146px; grid-area: c"
      >
        <LoaderSpinner v-if="isLoading"></LoaderSpinner>

        <div v-if="!isLoading && projectInformation">
          {{ projectInformation.tot_prod }}
        </div>
        <div>{{ t("label.products") }}</div>
      </div>
      <h2
        class="view-title"
        style="letter-spacing: 0.56px; font-size: 14px; grid-area: b"
      >
        {{ t("label.proportionProducts") }}
      </h2>
      <div
        style="
          display: grid;
          grid-area: d;
          gap: 50px 50px;

          grid-template-columns: repeat(auto-fill, minmax(170px, auto));
        "
      >
        <div
          class="gr-color w-100 dashboard-card pointer"
          style="height: 146px"
          @click="totalRepurposes && router.push({ name: 'projectRepurpose' })"
        >
          <LoaderSpinner v-if="isLoading"></LoaderSpinner>

          <div v-if="!isLoading">{{ totalRepurposes }}</div>
          <div>{{ t("title.repurpose") }}</div>
        </div>

        <div
          class="gr-color w-100 dashboard-card pointer"
          style="height: 146px"
          @click="totalForSale && router.push({ name: 'projectSell' })"
        >
          <LoaderSpinner v-if="isLoading"></LoaderSpinner>

          <div v-if="!isLoading">{{ totalForSale }}</div>
          <div>{{ t("label.sell") }}</div>
        </div>
        <div
          class="gr-color w-100 dashboard-card pointer"
          style="height: 146px"
          @click="totalRecycles > 0 && router.push({ name: 'projectRecycle' })"
        >
          <LoaderSpinner v-if="isLoading"></LoaderSpinner>

          <div v-if="!isLoading">{{ totalRecycles }}</div>
          <div>{{ t("label.recycle") }}</div>
        </div>
        <div
          class="gr-color w-100 dashboard-card pointer"
          style="height: 146px"
          @click="totalUnhandled > 0 && router.push({ name: 'projectAction' })"
        >
          <LoaderSpinner v-if="isLoading"></LoaderSpinner>

          <div v-if="!isLoading">{{ totalUnhandled }}</div>
          <div>{{ t("label.unhandled") }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <div class="row table-item align-items-center" style="min-height: 0px">
          <h2
            class="view-title"
            style="letter-spacing: 0.64px; font-size: 16px; padding-top: 0px"
          >
            FAQ
          </h2>
        </div>
        <template v-for="(question, key) in faq" :key="key">
          <div class="row accordian align-items-center">
            <div
              class="col d-flex justify-content-between"
              @click="openAccordion = openAccordion === key ? null : key"
            >
              <strong>
                {{ question.name }}
              </strong>
              <img v-if="openAccordion === key" src="@/assets/img/minus.svg" />
              <img v-else src="@/assets/img/plus.svg" />
            </div>
          </div>
          <div class="accordian-text-wrapper">
            <p
              class="pb-3 accordian-text"
              v-if="openAccordion === key"
              v-html="question.text"
            ></p>
          </div>
        </template>
      </div>
      <div class="col-6">
        <img
          style="
            padding-top: 23px;
            margin-top: 13p;
            width: 100%;
            padding-left: 48px;
          "
          :src="content + '/api/application-image/dashboard2'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LoaderSpinner from "@/components/common/LoaderSpinner.vue";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { I18nMessage } from "../library/types/locales/i18nMessage";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import { getProjectInformation } from "@/library/api/project";
import { useAuthStore } from "@/store/auth";
import { ProjectProductQuantities } from "@/services/api/projectApi";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";

const { t, locale, messages } = useI18n();
const store = useIndexStore();
const authStore = useAuthStore();
const router = useRouter();

const isLoading = ref(true);
const openAccordion: Ref<number | null> = ref(null);
const totalRepurposes = ref(0);
const totalForSale = ref(0);
const totalRecycles = ref(0);
const totalUnhandled = ref(0);
const projectInformation: Ref<{
  tot_actions: ProjectProductQuantities["tot_actions"];
  tot_prod: ProjectProductQuantities["tot_prod"];
} | null> = ref(null);
const currentProjectNumber = store.currentProjectNumber;

onMounted(() => {
  getProjectInfo();
});

const content = computed(() => {
  return process.env.VUE_APP_LARAVEL_API;
});

const faq = computed(() => {
  return (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).FAQ;
});

const getTotalAmount = (
  totalActions: ProjectProductQuantities["tot_actions"],
  productStatuses: ProductStatuses[]
) => {
  let totalAmount = 0;
  productStatuses.forEach(productStatusKey => {
    if (!totalActions[productStatusKey]) {
      totalAmount += 0;
    } else {
      totalAmount += Number(totalActions[productStatusKey].total);
    }
  });

  return totalAmount;
};

const getProjectInfo = async () => {
  isLoading.value = true;
  const result = await getProjectInformation();
  isLoading.value = false;
  if (!result.success) return;

  projectInformation.value = {
    tot_actions: result.result.data.tot_actions,
    tot_prod: result.result.data.tot_prod
  };

  totalRepurposes.value = getTotalAmount(projectInformation.value.tot_actions, [
    "Å",
    "R",
    "M",
    "F"
  ]);
  totalForSale.value = getTotalAmount(projectInformation.value.tot_actions, [
    "S",
    "I",
    "G"
  ]);
  totalRecycles.value = getTotalAmount(projectInformation.value.tot_actions, [
    "K",
    "C"
  ]);
  totalUnhandled.value = getTotalAmount(projectInformation.value.tot_actions, [
    "J"
  ]);
  isLoading.value = false;
};

watch(
  () => currentProjectNumber,
  () => {
    getProjectInfo();
  }
);
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.row {
  --bs-gutter-x: 0rem;
}
.table-item {
  border: none;

  & [class*="col"] {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    min-height: 3.4rem;
    display: flex;
    align-items: center;
  }
}
.dashboard-card {
  display: flex;
  width: 216px;
  padding: 47px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 9px;
  margin-right: 26px;
  align-items: center;
  flex: 0 auto;
  & *:first-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
  }
  & *:nth-first-child(2) {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 26px;
  margin-right: 26px;
}
.card-wrapper-large {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 26px;
  margin-right: 26px;
}
.accordian-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.accordian-text-wrapper {
  border-bottom: 1.5px solid black;
}

.accordian {
  min-height: 4.625rem;
  & *:hover {
    cursor: pointer;
  }
  & * *:first-child {
    color: var(--color-grey-10, #1a1a1a);
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
  }
  &-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  }
}

.header-paragraf {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.28px;
}
</style>
