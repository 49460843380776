import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, Ref, ref, watch } from "vue";
import { I18nMessage } from "../library/types/locales/i18nMessage";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { getProjectUsersApi, User } from "@/library/api/project";
import { deleteUser, updateUserRole } from "@/library/api/user";
import MainFilter from "@/components/filter/MainFilter.vue";
import TableHeaderRow from "@/components/table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import MainTableRow from "@/components/table/tableRows/MainTableRow.vue";
import { sortedUserRows } from "@/library/helpers/sortTableRows";
import { SortingSettings } from "@/library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import avatarIcon from "@/assets/img/avatar-icon.svg";
import TableFooter from "@/components/table/tableFooter/TableFooter.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import InviteUserModal, {
  UserRoleOption
} from "@/components/modal/InviteUserModal.vue";
import { SelectableAction } from "@/library/helpers/structureTableColumns";


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersView',
  setup(__props) {

const authStore = useAuthStore();
const { t, locale, messages } = useI18n();
const indexStore = useIndexStore();
const defaultImageSrc = avatarIcon;
const isLoading = ref(true);
const sortingSettings: Ref<SortingSettings> = ref({ col: "usr", asc: false });
const openInvite = ref(false);
const selectedUser: Ref<{
  role: string;
  user: string;
  old_role: UserRoleOption;
} | null> = ref(null);
const users: Ref<User[]> = ref([]);
const userToRemove: Ref<User | null> = ref(null);
const invitedUser: Ref<{ email: string | null; role: "S" | "P" }> = ref({
  email: null,
  role: "S"
});

onMounted(() => {
  getProjectUsers();
});

const currentProject = computed(() => {
  return indexStore.currentProjectNumber;
});

const currentProjectRole = computed(() => {
  return indexStore.currentProject.role;
});

const currentUser = computed(() => {
  return authStore.currentUser;
});

const tableColumns = (userColumn: User): TableColumns["columns"] => {
  const hasPermissionToEditUsers =
    currentProjectRole.value === "P" &&
    userColumn.usr !== currentUser.value.email;

  if (hasPermissionToEditUsers) {
    return {
      usr: {
        value: userColumn.usr,
        columnStyling: "col-4",
        tooltip: null,
        actions: [],
        isSortable: true,
        headerText: "tableTitle.email",
        isEditable: false,
        translate: false,
        inputSettings: null,
        selectOptions: []
      },
      name: {
        value: userColumn.name,
        columnStyling: "col-4",
        actions: [],
        tooltip: null,
        isSortable: true,
        isEditable: false,
        translate: false,
        headerText: "tableTitle.name",
        inputSettings: null,
        selectOptions: []
      },
      actions: {
        value: "",
        columnStyling: "col-4 justify-content-between pe-1",
        tooltip: null,
        actions: ["select", "delete"],
        isSortable: false,
        isEditable: false,
        translate: false,
        headerText: "tableTitle.role",
        inputSettings: null,
        selectOptions: roleOptions.value
      }
    };
  }

  return {
    usr: {
      value: userColumn.usr,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: true,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.email",
      inputSettings: null,
      selectOptions: []
    },
    name: {
      value: userColumn.name,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      isSortable: true,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.name",
      inputSettings: null,
      selectOptions: []
    },
    typ: {
      value: t(`list.role.${userColumn.typ}`),
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "tableTitle.role",
      inputSettings: null,
      selectOptions: []
    }
  };
};

const roleOptions = computed((): SelectableAction[] => {
  const localeRole = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).role;

  const roleOption = Object.keys(localeRole).map(key => ({
    value: key,
    label: localeRole[key as keyof I18nMessage["list"]["role"]],
    selectable: true
  }));

  return roleOption;
});

const removeUserClick = async () => {
  isLoading.value = true;
  if (userToRemove.value?.usr) {
    const result = await deleteUser(userToRemove.value.usr);

    userToRemove.value = null;
    isLoading.value = false;

    if (!result.success) return;

    getProjectUsers();
  }
};

const resetRole = () => {
  getProjectUsers();
  selectedUser.value = null;
};

const changeRole = async () => {
  isLoading.value = true;
  if (selectedUser.value) {
    const result = await updateUserRole(
      selectedUser.value.user,
      selectedUser.value.role
    );

    isLoading.value = false;
    if (!result.success) return;

    selectedUser.value = null;
  }
};

const getProjectUsers = async () => {
  isLoading.value = true;
  const result = await getProjectUsersApi();
  isLoading.value = false;

  if (!result.success) return;

  users.value = result.result;
  invitedUser.value = { email: null, role: "S" };
  openInvite.value = false;
};

watch(
  () => currentProject.value,
  () => {
    getProjectUsers();
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      "filter-title": _unref(t)('title.users'),
      "filter-text": _unref(t)('paragraph.users')
    }, null, 8, ["filter-title", "filter-text"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(TableHeaderRow, {
            "table-headers": tableColumns(users.value[0]),
            "sorting-options": sortingSettings.value,
            "onUpdate:sortingOptions": _cache[0] || (_cache[0] = ($event: any) => ((sortingSettings).value = $event)),
            isSticky: ""
          }, null, 8, ["table-headers", "sorting-options"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedUserRows)(users.value, sortingSettings.value), (user) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "border-bottom",
              key: user.name
            }, [
              _createVNode(MainTableRow, {
                type: "other",
                onImageError: ($event: any) => (user.avatar_icon = _unref(defaultImageSrc)),
                tableColumns: tableColumns(user),
                rowImage: user.avatar_icon ?? _unref(defaultImageSrc),
                defaultSelectedAction: user.typ,
                onDelete: ($event: any) => (userToRemove.value = user)
              }, null, 8, ["onImageError", "tableColumns", "rowImage", "defaultSelectedAction", "onDelete"])
            ]))
          }), 128)),
          (currentProjectRole.value === 'P')
            ? (_openBlock(), _createBlock(TableFooter, {
                key: 0,
                "action-statuses": ['IU'],
                onAction: _cache[1] || (_cache[1] = ($event: any) => (openInvite.value = true))
              }))
            : _createCommentVNode("", true)
        ], 64)),
    (userToRemove.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 2,
          type: "verification",
          closable: false,
          title: _unref(t)('title.areYouSureRemoveUser'),
          text: 
      _unref(t)('msg.areYouSureRemoveUser', {
        name:
          '<strong>' + (userToRemove.value?.name ?? userToRemove.value?.usr) + '</strong>'
      })
    ,
          onConfirm: _cache[2] || (_cache[2] = ($event: any) => (removeUserClick())),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (userToRemove.value = null))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (selectedUser.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 3,
          type: "verification",
          closable: false,
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => (changeRole())),
          text: 
      _unref(t)('msg.areYouSureChangeRole', {
        role:
          '<strong>' +
          _unref(t)('list.role.' + selectedUser.value?.role).toLowerCase() +
          '</strong>',
        user: '<strong>' + selectedUser.value?.user + '</strong>'
      })
    ,
          title: _unref(t)('title.areYouSureChangeRole'),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (resetRole()))
        }, null, 8, ["text", "title"]))
      : _createCommentVNode("", true),
    (openInvite.value)
      ? (_openBlock(), _createBlock(InviteUserModal, {
          key: 4,
          roleOptions: roleOptions.value,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (openInvite.value = false)),
          onRefresh: _cache[7] || (_cache[7] = ($event: any) => {
      getProjectUsers();
      openInvite.value = false;
    })
        }, null, 8, ["roleOptions"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})