<template>
  <LoadingOverlay v-if="isLoading" />
  <div v-else class="container-fluid p-0">
    <MainFilter
      :filterTitle="
        !showTokenError ? t('title.resetPassword') : t('title.urlHasExpired')
      "
      :filterText="
        !showTokenError
          ? t('msg.passwordRequirements')
          : t('paragraph.urlExpiredInfo')
      "
      hideFilterOptions
    />
    <form
      id="resetPasswordForm"
      v-if="!showTokenError"
      @submit.prevent="postNewPassword()"
    >
      <div class="row align-items-center">
        <div class="col-sm-3 d-flex align-items-center">
          <p class="fw-bolder">{{ t("label.newPassword") + " *" }}</p>
          <RequiredWarning
            :warningText="t('msg.readAbovePasswordRequirements')"
            v-if="invalidPassword && password2 === password1"
          />
        </div>
        <div class="col-3">
          <InputField
            type="password"
            v-model:field-value="password1"
            :placeHolder="t('placeholders.password')"
            required
            minlength="8"
            @update:field-value="invalidPassword = false"
            :password-has-toggle="true"
          />
        </div>
      </div>
      <div class="row mt-4 align-items-center">
        <div class="col-sm-3 d-flex align-items-center">
          <p class="fw-bolder me-2">
            {{ t("label.repeatNewPassword") + " *" }}
          </p>
          <RequiredWarning
            :warningText="t('msg.passwordNotTheSame')"
            v-if="invalidPassword && password2 !== password1"
          />
        </div>
        <div class="col-3">
          <InputField
            type="password"
            v-model:field-value="password2"
            :placeHolder="t('placeholders.password')"
            required
            minlength="8"
            :password-has-toggle="true"
          />
        </div>
      </div>
      <div class="row mt-5 align-items-center">
        <div class="col-4">
          <GreenifiedButton
            btn-style="black"
            :text="t('button.savePassword')"
            type="submit"
            size="thin"
            form="resetPasswordForm"
          />
        </div>
      </div>
    </form>
    <div class="row mt-5 align-items-center" v-else>
      <div class="col-4">
        <GreenifiedButton
          size="thin"
          :btnStyle="'black'"
          @click="getNewReset"
          :text="t('button.sendNewLink')"
        />
      </div>
    </div>
  </div>

  <GreenifiedModal
    v-if="showError"
    type="response"
    :closable="false"
    :title="t('responseTitle.passwordResetError')"
    :text="t('msg.passwordError')"
    @close="showError = false"
  />

  <GreenifiedModal
    v-if="showSuccess"
    type="response"
    :closable="false"
    :title="t('responseTitle.passwordReset')"
    :text="t('msg.passwordSuccess')"
    @close="showSuccess = false"
  />
</template>

<script setup lang="ts">
import RequiredWarning from "@/components/common/RequiredWarning.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  postPassword,
  postTokenReset,
  postTokenVerification
} from "@/library/api/user";
import { useI18n } from "vue-i18n";
import MainFilter from "@/components/filter/MainFilter.vue";
import InputField from "@/components/common/InputField.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";

const router = useRouter();

const { t } = useI18n();

const route = useRoute();

const password1 = ref("");
const password2 = ref("");
const invalidPassword = ref(false);
const showError = ref(false);
const showSuccess = ref(false);
const showTokenError = ref(false);
const isLoading = ref(true);

const getNewReset = async () => {
  const result = await postTokenReset(route.query.token as string);
  isLoading.value = false;

  if (!result.success) {
    showTokenError.value = true;
  }
};

const verifyToken = async () => {
  const result = await postTokenVerification(route.query.token as string);
  isLoading.value = false;

  if (!result.success) {
    if (result.error.status !== 410) return router.push({ name: "notFound" });
    showTokenError.value = true;
  }

  showTokenError.value = false;
};

const verifyPasswordRequirements = (firstPw: string, secondPw: string) => {
  const digitsInPassword = firstPw.replace(/\D/g, "");
  return (
    firstPw.length >= 8 &&
    firstPw === secondPw &&
    /[A-Z]/.test(firstPw) === true &&
    /[a-z]/.test(firstPw) === true &&
    digitsInPassword.length >= 2
  );
};

const postNewPassword = async () => {
  const correctPassword = verifyPasswordRequirements(
    password1.value,
    password2.value
  );

  if (!correctPassword) {
    invalidPassword.value = true;
    return;
  }

  invalidPassword.value = false;

  const result = await postPassword(
    route.query.token as string,
    password1.value
  );

  if (!result.success) {
    showError.value = true;
    return;
  }

  showSuccess.value = true;
};

onMounted(() => {
  verifyToken();
});
</script>
