<template>
  <div class="row">
    <div class="col-3">
      <div class="button-container">
        <GreenifiedButton
          v-if="!extendedSearchResult.length || isSearching"
          disabled
          :text="
            isSearching
              ? t('button.searchingForMoreProducts')
              : t('button.couldNotFindMoreProducts')
          "
        />
        <GreenifiedButton
          v-if="extendedSearchResult.length && !isSearching"
          @click="showSearchDropdown = !showSearchDropdown"
          :text="
            !showSearchDropdown
              ? t('button.showOtherSearchHits', [extendedSearchResult.length])
              : t('button.hideOtherSearchHits', [extendedSearchResult.length])
          "
        />
      </div>
    </div>
  </div>

  <template v-if="showSearchDropdown">
    <div class="row table-header ms-0 me-0 d-flex align-items-center">
      <div class="col-4 ps-0">{{ t("tableTitle.state") }}</div>
      <div class="col-4 d-flex align-items-center">
        {{ t("tableTitle.searchHit") }}
      </div>
      <div class="col-4 d-flex align-items-center">
        {{ t("tableTitle.quantity") }}
      </div>
    </div>

    <template v-for="(searchResult, i) in extendedSearchResult" :key="i">
      <div class="row table-item ms-0 me-0">
        <div class="col-4 ps-0 d-flex align-items-center">
          {{ getStatusTextsAndRoutes(searchResult.status, searchResult).text }}
        </div>
        <div class="col-4 d-flex align-items-center flex-wrap">
          <div
            v-for="field in findFieldKey(searchResult).slice().reverse()"
            :key="field.group"
          >
            <div class="d-flex me-2" v-if="searchKeys.includes(field.group)">
              <span class="fst-italic me-2">{{
                t(`tableTitle.${field.value}`)
              }}</span>
              <p
                v-html="
                  formattedText(searchResult.hit_on, searchResult.search_hit)
                "
              ></p>
            </div>
            <div class="d-flex" v-else>
              <span class="fst-italic me-2">{{ field.group }}</span>
              <p class="me-2">{{ field.value }}</p>
            </div>
          </div>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-between">
          {{ searchResult.quantity }}
          <GreenifiedButton
            @click="
              router.push(
                getStatusTextsAndRoutes(searchResult.status, searchResult).route
              )
            "
            :text="t('button.goToTheProduct')"
            :btnStyle="'green'"
            size="auto"
          />
        </div>
      </div>
    </template>
  </template>
</template>

<script setup lang="ts">
import { ref, withDefaults, defineProps, onMounted, Ref, watch } from "vue";
import { ExtendedSearch } from "../library/types/search/search";
import {
  RouteLocationAsPathGeneric,
  RouteLocationAsRelativeGeneric,
  useRouter
} from "vue-router";
import { ExtendedSearchFilters, searchAllProducts } from "@/library/api/search";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
import GreenifiedButton from "./common/GreenifiedButton.vue";
const { t, locale } = useI18n();
const router = useRouter();

type FilteredProduct = {
  group: string;
  value: string | null;
};

type ExtendedFilteredProductsProps = {
  extendedSearchFilters: ExtendedSearchFilters;
  productStatus?: ProductStatuses[];
};
const extendedFilteredProductsProps = withDefaults(
  defineProps<ExtendedFilteredProductsProps>(),
  {
    extendedSearchFilters: () => ({}),
    productStatus: () => ["J"]
  }
);

const searchKeys = ["hit_on", "search_hit", "hit_field", "status", "quantity"];
const showSearchDropdown = ref(false);
const extendedSearchResult: Ref<ExtendedSearch[]> = ref([]);
const isSearching = ref(true);

onMounted(() => {
  getExtendedSearchResults();
});

const getExtendedSearchResults = async () => {
  isSearching.value = true;
  const result = await searchAllProducts(
    extendedFilteredProductsProps.extendedSearchFilters,
    extendedFilteredProductsProps.productStatus,
    locale.value.toLocaleLowerCase()
  );
  isSearching.value = false;

  if (!result.success) return;

  extendedSearchResult.value = result.result;
};

const findFieldKey = (searchResult: ExtendedSearch): FilteredProduct[] => {
  let filteredProducts: FilteredProduct[] = [];

  for (const key in searchResult) {
    if (key !== null && searchResult[key as keyof ExtendedSearch] !== null) {
      const productField = getProductField(
        key,
        searchResult[key as keyof ExtendedSearch]
      );
      if (!searchKeys.includes(key)) {
        filteredProducts.push({
          group: productField.group,
          value: productField.value
        });
      } else if (key === "hit_field") {
        filteredProducts.push({
          group: key,
          value: searchResult[key as keyof ExtendedSearch]
        });
      }
    }
  }
  return filteredProducts;
};

const getProductField = (
  key: string,
  searchResult: string | null
): FilteredProduct => {
  if (
    key === "levkod" ||
    key === "kategori_id" ||
    key === "rum" ||
    key === "main_category"
  ) {
    return {
      group: t(`label.${key}`),
      value: t(`${key}.${searchResult}`)
    };
  }
  return { group: t(`label.${key}`), value: searchResult };
};

const formattedText = (
  valueMatchedBySearch: ExtendedSearch["hit_on"],
  searchHit: ExtendedSearch["search_hit"]
): string => {
  const index = valueMatchedBySearch
    .toLowerCase()
    .indexOf(searchHit.toLowerCase());

  if (index !== -1) {
    const matchedText = valueMatchedBySearch.substr(index, searchHit.length);
    const boldText = `<b>${matchedText}</b>`;
    return valueMatchedBySearch.replace(new RegExp(matchedText, "i"), boldText);
  } else {
    return valueMatchedBySearch;
  }
};

const stringifyObjectQuery = (searchResult: ExtendedSearch): string => {
  const currentSearchResult: { [key: string]: string | null } = {};
  for (const key in searchResult) {
    if (
      searchResult[key as keyof ExtendedSearch] &&
      key !== "quantity" &&
      key !== "status" &&
      key !== "search_hit" &&
      key !== "hit_field"
    ) {
      currentSearchResult[key] = searchResult[key as keyof ExtendedSearch];
    }
  }
  return JSON.stringify(currentSearchResult);
};

const getStatusTextsAndRoutes = (
  status: ExtendedSearch["status"],
  searchResult: ExtendedSearch
): {
  text: string;
  route: string | RouteLocationAsRelativeGeneric | RouteLocationAsPathGeneric;
} => {
  const queryProduct = stringifyObjectQuery(searchResult);
  switch (status) {
    case "B":
      return {
        text: `${t("button.otherSales")} - ${t("title.sold")}`,
        route: {
          name: "internalSaleOverview",
          params: { setTab: 2, filterQuery: queryProduct }
        }
      };
    case "I":
      return {
        text: `${t("button.otherSales")} - ${t("button.otherSales")}`,
        route: {
          name: "internalSaleOverview",
          params: { setTab: 1, filterQuery: queryProduct }
        }
      };
    case "O":
    case "A":
      return {
        text: `${t("button.otherSales")} - ${t("button.otherSales")}`,
        route: {
          name: "internalSaleOverview",
          params: { setTab: 2, filterQuery: queryProduct }
        }
      };

    case "G":
      return {
        text: `Greenified Web`,
        route: {
          name: "projectSellOverview",
          params: { setTab: 1, filterQuery: queryProduct }
        }
      };
    case "C":
      return {
        text: t("label.overviewRecycle"),
        route: {
          name: "projectRecycleOverview",
          params: { filterQuery: queryProduct }
        }
      };
    case "J":
      return {
        text: t("title.handleProducts"),
        route: {
          name: "projectAction",
          params: { filterQuery: queryProduct }
        }
      };
    case "Å":
      return {
        text: t("button.repurpose"),
        route: {
          name: "projectRepurpose",
          params: { filterQuery: queryProduct }
        }
      };
    case "S":
      return {
        text: t("label.sell"),
        route: {
          name: "projectSell",
          params: { filterQuery: queryProduct }
        }
      };
    case "K":
      return {
        text: t("label.recycle"),
        route: {
          name: "projectRecycle",
          params: { filterQuery: queryProduct }
        }
      };
    case "R":
      return {
        text: t("title.recondition"),
        route: {
          name: "projectRecondition",
          params: { filterQuery: queryProduct }
        }
      };
    case "M":
      return {
        text: t("label.overviewRepurpose"),
        route: {
          name: "projectRepurposeOverview",
          params: { filterQuery: queryProduct }
        }
      };
  }

  return {
    text: "",
    route: {}
  };
};
watch(
  () => extendedFilteredProductsProps.extendedSearchFilters,
  () => {
    getExtendedSearchResults();
  }
);
</script>

<style scoped>
.button-container {
  width: 14rem;
}
</style>
