import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hamb-menu-header"
}
const _hoisted_2 = {
  key: 1,
  class: "hamb-menu-content w-100"
}
const _hoisted_3 = {
  key: 2,
  class: "hamb-menu-footer"
}

import {
  ref,
  onMounted,
  watch,
} from "vue";

type BSidebarProps = {
  open: boolean;
  bg: string;
  hideHeader: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BSidebar',
  props: {
    open: { type: Boolean, default: false },
    bg: { default: "#f5f2eb" },
    hideHeader: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "update:open"],
  setup(__props: any, { emit: __emit }) {

const bSidebarProps = __props;

const emit = __emit;

let isExpanded = ref(false);
let open2 = ref(false);

const closeMenu = () => {
  isExpanded.value = false;
  emit("update:open", false);
  window.setTimeout(() => {
    open2.value = false;
  }, 200);
};

const openMenu = () => {
  open2.value = true;
  window.setTimeout(() => {
    isExpanded.value = true;
  }, 1);
};

onMounted(() => {
  open2.value = bSidebarProps.open;
});

watch(
  () => bSidebarProps.open,
  (sideBarIsOpen: boolean) => {
    if (!sideBarIsOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_unref(open2))
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: "hamb-menu-wrapper",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (closeMenu()))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_unref(isExpanded) ? 'expanded-container' : 'collapsed-container', "sidebar-container"]),
          style: _normalizeStyle('background-color:' + _ctx.bg),
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          (!_ctx.hideHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "header")
              ]))
            : _createCommentVNode("", true),
          (_unref(isExpanded))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("", true),
          (_unref(isExpanded))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true)
        ], 6)
      ]))
    : _createCommentVNode("", true)
}
}

})