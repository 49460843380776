import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "container-fluid p-0"
}
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col-sm-3 d-flex align-items-center" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "row mt-4 align-items-center" }
const _hoisted_7 = { class: "col-sm-3 d-flex align-items-center" }
const _hoisted_8 = { class: "fw-bolder me-2" }
const _hoisted_9 = { class: "col-3" }
const _hoisted_10 = { class: "row mt-5 align-items-center" }
const _hoisted_11 = { class: "col-4" }
const _hoisted_12 = {
  key: 1,
  class: "row mt-5 align-items-center"
}
const _hoisted_13 = { class: "col-4" }

import RequiredWarning from "@/components/common/RequiredWarning.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  postPassword,
  postTokenReset,
  postTokenVerification
} from "@/library/api/user";
import { useI18n } from "vue-i18n";
import MainFilter from "@/components/filter/MainFilter.vue";
import InputField from "@/components/common/InputField.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPassword',
  setup(__props) {

const router = useRouter();

const { t } = useI18n();

const route = useRoute();

const password1 = ref("");
const password2 = ref("");
const invalidPassword = ref(false);
const showError = ref(false);
const showSuccess = ref(false);
const showTokenError = ref(false);
const isLoading = ref(true);

const getNewReset = async () => {
  const result = await postTokenReset(route.query.token as string);
  isLoading.value = false;

  if (!result.success) {
    showTokenError.value = true;
  }
};

const verifyToken = async () => {
  const result = await postTokenVerification(route.query.token as string);
  isLoading.value = false;

  if (!result.success) {
    if (result.error.status !== 410) return router.push({ name: "notFound" });
    showTokenError.value = true;
  }

  showTokenError.value = false;
};

const verifyPasswordRequirements = (firstPw: string, secondPw: string) => {
  const digitsInPassword = firstPw.replace(/\D/g, "");
  return (
    firstPw.length >= 8 &&
    firstPw === secondPw &&
    /[A-Z]/.test(firstPw) === true &&
    /[a-z]/.test(firstPw) === true &&
    digitsInPassword.length >= 2
  );
};

const postNewPassword = async () => {
  const correctPassword = verifyPasswordRequirements(
    password1.value,
    password2.value
  );

  if (!correctPassword) {
    invalidPassword.value = true;
    return;
  }

  invalidPassword.value = false;

  const result = await postPassword(
    route.query.token as string,
    password1.value
  );

  if (!result.success) {
    showError.value = true;
    return;
  }

  showSuccess.value = true;
};

onMounted(() => {
  verifyToken();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(MainFilter, {
            filterTitle: 
        !showTokenError.value ? _unref(t)('title.resetPassword') : _unref(t)('title.urlHasExpired')
      ,
            filterText: 
        !showTokenError.value
          ? _unref(t)('msg.passwordRequirements')
          : _unref(t)('paragraph.urlExpiredInfo')
      ,
            hideFilterOptions: ""
          }, null, 8, ["filterTitle", "filterText"]),
          (!showTokenError.value)
            ? (_openBlock(), _createElementBlock("form", {
                key: 0,
                id: "resetPasswordForm",
                onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (postNewPassword()), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)("label.newPassword") + " *"), 1),
                    (invalidPassword.value && password2.value === password1.value)
                      ? (_openBlock(), _createBlock(RequiredWarning, {
                          key: 0,
                          warningText: _unref(t)('msg.readAbovePasswordRequirements')
                        }, null, 8, ["warningText"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(InputField, {
                      type: "password",
                      "field-value": password1.value,
                      "onUpdate:fieldValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((password1).value = $event)),
                        _cache[1] || (_cache[1] = ($event: any) => (invalidPassword.value = false))
                      ],
                      placeHolder: _unref(t)('placeholders.password'),
                      required: "",
                      minlength: "8",
                      "password-has-toggle": true
                    }, null, 8, ["field-value", "placeHolder"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)("label.repeatNewPassword") + " *"), 1),
                    (invalidPassword.value && password2.value !== password1.value)
                      ? (_openBlock(), _createBlock(RequiredWarning, {
                          key: 0,
                          warningText: _unref(t)('msg.passwordNotTheSame')
                        }, null, 8, ["warningText"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(InputField, {
                      type: "password",
                      "field-value": password2.value,
                      "onUpdate:fieldValue": _cache[2] || (_cache[2] = ($event: any) => ((password2).value = $event)),
                      placeHolder: _unref(t)('placeholders.password'),
                      required: "",
                      minlength: "8",
                      "password-has-toggle": true
                    }, null, 8, ["field-value", "placeHolder"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(GreenifiedButton, {
                      "btn-style": "black",
                      text: _unref(t)('button.savePassword'),
                      type: "submit",
                      size: "thin",
                      form: "resetPasswordForm"
                    }, null, 8, ["text"])
                  ])
                ])
              ], 32))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(GreenifiedButton, {
                    size: "thin",
                    btnStyle: 'black',
                    onClick: getNewReset,
                    text: _unref(t)('button.sendNewLink')
                  }, null, 8, ["text"])
                ])
              ]))
        ])),
    (showError.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 2,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.passwordResetError'),
          text: _unref(t)('msg.passwordError'),
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showError.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (showSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 3,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.passwordReset'),
          text: _unref(t)('msg.passwordSuccess'),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (showSuccess.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})