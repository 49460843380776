import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "subheading-tiny mt-1" }
const _hoisted_4 = { class: "row mt-2" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "text-tiny" }
const _hoisted_7 = { class: "row mt-4" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "text-tiny fw-bolder mb-1" }
const _hoisted_11 = { class: "row mt-3" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "text-tiny fw-bolder" }
const _hoisted_14 = { class: "row mt-1 align-items-end" }
const _hoisted_15 = { class: "col-3" }
const _hoisted_16 = { class: "col-3" }

import { ref } from "vue";
import { ProductItemInformation } from "@/library/types/product/productItem";
import InputTextField from "@/components/common/InputTextField.vue";
import { useIndexStore } from "@/store";
import GreenifiedForm from "../GreenifiedForm.vue";
import GreenifiedImage from "@/components/common/GreenifiedImage.vue";
import { useI18n } from "vue-i18n";

type SpecificProductInformationProps = {
  productSpecifics: ProductItemInformation;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SpecificProductInformation',
  props: {
    productSpecifics: { default: () => ({
      comp_id: 0,
      damages: "",
      id: "",
      location: "",
      productSpecificImages: [],
      productSpecifics: {},
      room: {
        roomName: "",
        roomCode: "",
        floor: "",
        building: "",
      },
      status: "",
    }) }
  },
  emits: ["update:damages", "update"],
  setup(__props: any, { emit: __emit }) {

const indexStore = useIndexStore();
const { t } = useI18n();

const specificProductInformationProps = __props;

const emit = __emit;

const projectStructure = indexStore.projectDetails.struc;
const currentProductSpecifics = ref(
  specificProductInformationProps.productSpecifics,
);
const productDamages = ref(
  specificProductInformationProps.productSpecifics.damages,
);
const productImages = ref(
  specificProductInformationProps.productSpecifics.productSpecificImages,
);

const image1 =
  specificProductInformationProps.productSpecifics?.productSpecificImages?.[0]
    ?.hash ?? null;
const image2 =
  specificProductInformationProps.productSpecifics?.productSpecificImages?.[1]
    ?.hash ?? null;

const removeProductImage = (imageHash: string) => {
  const currentIndex = productImages.value.findIndex(
    image => image.hash === imageHash,
  );

  productImages.value.splice(currentIndex, 1);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)("title.productSpecification")) + " " + _toDisplayString(_ctx.productSpecifics?.comp_id), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)("paragraph.specifiedProductInformation")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(InputTextField, {
          label: _unref(t)('label.productspecificComment'),
          modelValue: currentProductSpecifics.value.damages,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((currentProductSpecifics.value.damages) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (emit('update:damages', productDamages.value)))
          ],
          placeHolder: _unref(t)('placeholders.dentDescription')
        }, null, 8, ["label", "modelValue", "placeHolder"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)("placeholders.condition")), 1),
        _createVNode(GreenifiedForm, {
          formValues: currentProductSpecifics.value.productSpecifics,
          "onUpdate:formValues": _cache[2] || (_cache[2] = ($event: any) => ((currentProductSpecifics.value.productSpecifics) = $event)),
          hideTitle: "",
          ref: "mainItemDesc",
          formFields: _unref(projectStructure),
          includedFields: ['info', 'skick']
        }, null, 8, ["formValues", "formFields"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)("title.specifiedImages")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        (_openBlock(), _createBlock(GreenifiedImage, {
          imageIndex: 1,
          imageLabel: 'image1',
          key: _unref(image1),
          imageUrl: _unref(image1),
          "onUpdate:imageUrl": [
            _cache[3] || (_cache[3] = ($event: any) => (_isRef(image1) ? (image1).value = $event : null)),
            _cache[4] || (_cache[4] = 
          imageHash => {
            if (!imageHash) {
              removeProductImage(_unref(image1));
              return;
            }
            productImages.value[0] = { hash: _unref(image1), url: '' };
          }
        )
          ]
        }, null, 8, ["imageUrl"]))
      ]),
      _createElementVNode("div", _hoisted_16, [
        (_openBlock(), _createBlock(GreenifiedImage, {
          selectable: productImages.value.length ? true : false,
          imageIndex: 2,
          imageLabel: 'image2',
          key: _unref(image2),
          imageUrl: _unref(image2),
          "onUpdate:imageUrl": [
            _cache[5] || (_cache[5] = ($event: any) => (_isRef(image2) ? (image2).value = $event : null)),
            _cache[6] || (_cache[6] = 
          imageHash => {
            if (!imageHash) {
              removeProductImage(_unref(image2));
              return;
            }
            productImages.value[1] = { hash: _unref(image2), url: '' };
          }
        )
          ]
        }, null, 8, ["selectable", "imageUrl"]))
      ])
    ])
  ], 64))
}
}

})