import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import api from "../api";
import { AxiosResponse } from "axios";
import { ProductStatuses } from "@/library/types/product/productHeader";
import {
  FormFields,
  GroupFields
} from "@/library/types/structure/formStructure";

export type GreenifiedProductHeaders = {
  productsForSale: GreenifiedProductHeader[];
  soldProducts: GreenifiedProductHeader[];
};

export default {
  getSaleProductGroups(
    projectNr: string
  ): Promise<AxiosResponse<GreenifiedProductHeaders>> {
    return api().get("/api/project/" + projectNr + "/greenified/product");
  },

  getGreenifiedData(
    projectNr: string,
    greenifiedProduct: GreenifiedProductHeader
  ): Promise<AxiosResponse<GreenifiedProductHeader>> {
    return api().post(
      "/api/project/" + projectNr + "/greenified/product/greenified-data",
      JSON.stringify({ item: greenifiedProduct })
    );
  },

  updateProductsWithSameGreenifiedHash(
    projectnr: string,
    productGroupHashes: string[],
    structure: object,
    supplier_code: string,
    category: string,
    status: string,
    productIds: string[]
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectnr}/updateProducts`,
      JSON.stringify({
        productGroupHashes: productGroupHashes,
        structure: structure,
        supplier_code: supplier_code,
        category: category,
        status: status,
        productIds: productIds
      })
    );
  },

  completeGreenifiedStructure(
    projectNumber: string,
    ids: string[],
    status: string,
    results: FormFields
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/green-struk/complete`,
      JSON.stringify({ ids: ids, results: results, status: status })
    );
  },

  removeProductGreenified(
    projectNumber: string,
    id: string,
    status: ProductStatuses
  ): Promise<AxiosResponse> {
    return api().delete(
      `/api/project/${projectNumber}/greenified/product/${id}?status=${status}`
    );
  },

  getGreenifiedStructure(
    projectNr: string
  ): Promise<AxiosResponse<GroupFields[]>> {
    return api().get(`/api/project/${projectNr}/greenified/structure`);
  }
};
