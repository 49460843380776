import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-7" }
const _hoisted_2 = { class: "col-5" }
const _hoisted_3 = { class: "col-12" }

import GrLayoutTemplate from "./GrLayoutTemplate.vue";


type GrLayoutProps = {
  openSideMenu: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GrLayout',
  props: {
    openSideMenu: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GrLayoutTemplate, {
    openSideMenu: _ctx.openSideMenu,
    wideContainer: false
  }, {
    "top-navbar": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "top-navbar-left")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "top-navbar-right")
      ])
    ]),
    "side-bar": _withCtx(() => [
      _renderSlot(_ctx.$slots, "side-bar")
    ]),
    container: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "container")
      ])
    ]),
    _: 3
  }, 8, ["openSideMenu"]))
}
}

})