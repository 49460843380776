import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-v-4bd10b26": "",
  class: "col-sm-12 d-flex justify-content-between"
}
const _hoisted_2 = {
  "data-v-4bd10b26": "",
  class: "col-sm-5"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  "data-v-4bd10b26": "",
  class: "col-sm-4 d-flex justify-content-end"
}
const _hoisted_6 = {
  "data-v-2439e6cf": "",
  class: "greenified-info"
}
const _hoisted_7 = {
  "data-v-2439e6cf": "",
  class: "fw-bolder"
}

import { computed } from "vue";
import { useI18n } from "vue-i18n";

export default /*@__PURE__*/_defineComponent({
  __name: 'FooterContact',
  setup(__props) {

const { t } = useI18n();
const apiDomain = computed((): string => {
  return process.env.VUE_APP_LARAVEL_API;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        height: "19.19",
        "data-v-4bd10b26": "",
        src: apiDomain.value + '/api/logo',
        class: "greenified-footer-logo"
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        "data-v-2439e6cf": "",
        class: "mt-2 greenified-info pe-4",
        innerHTML: _unref(t)('msg.footerInfo')
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)("title.contact")), 1),
        _cache[0] || (_cache[0] = _createElementVNode("p", {
          "data-v-2439e6cf": "",
          class: "mt-2"
        }, "+46 (0)200 77 00 60", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("p", { "data-v-2439e6cf": "" }, "inventory@greenified.se", -1))
      ])
    ])
  ]))
}
}

})