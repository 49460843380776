<template>
  <GreenifiedModal
    :title="t('title.currency')"
    :type="'verification'"
    @close="emit('close')"
    :confirmText="t('button.save')"
    @confirm="postCurrency(currentCurrency)"
    :isLoading="isLoading"
  >
    <template #modalHeaderContent>
      <p class="form-label-paragraph">
        {{ t("paragraph.currency") }}
      </p>
    </template>
    <template #container>
      <CustomSelect
        class="mt-3"
        :options="currencies"
        v-model="currentCurrency"
        :label="'label'"
        :reducedKey="'value'"
        :clearable="false"
      />
    </template>
  </GreenifiedModal>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import { computed, defineEmits, ref } from "vue";
import { I18nMessage } from "@/library/types/locales/i18nMessage";
import { useIndexStore } from "@/store";
import { postLocaleCurrency } from "@/library/api/helpers";
import { ProjectDetails } from "@/library/types/project/project";
import CustomSelect from "../common/CustomSelect.vue";

type CurrencyOption = {
  value: ProjectDetails["valuta"];
  label: string;
};

const emit = defineEmits(["close"]);
const { t, messages, locale } = useI18n();
const indexStore = useIndexStore();
const currentCurrency = ref(indexStore.currentProjectCurrency);
const isLoading = ref(false);

const currencies = computed(() => {
  const localeCurrencies = (
    messages.value[locale.value.toLocaleLowerCase()] as I18nMessage
  ).list.currencies;
  return Object.keys(localeCurrencies).map(currency => ({
    value: currency,
    label:
      localeCurrencies[currency as keyof I18nMessage["list"]["currencies"]].name
  }));
});

const postCurrency = async (currency: CurrencyOption["value"]) => {
  isLoading.value = true;
  const result = await postLocaleCurrency(currency);
  isLoading.value = false;

  if (!result.success) return;
  indexStore.changeCurrency(currency);
  emit("close");
};
</script>
