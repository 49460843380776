<template>
  <GreenifiedModal
    @close="verifyCookies()"
    type="response"
    :decline-text="t('button.accept')"
    :text="`${t('msg.cookiesInformation')} <br /><br />${t(
      'msg.consentToCookies'
    )}`"
    :no-outer-close="true"
    :closable="false"
  >
    <template #modalHeader>
      <div class="w-100 d-flex justify-content-center">
        <img
          src="@/assets/img/cookie-icon.svg"
          alt="cookie icon"
          class="res-image"
        />
      </div>
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useIndexStore } from "@/store";
const { t } = useI18n();
const store = useIndexStore();

const emit = defineEmits(["close"]);

const verifyCookies = () => {
  store.changeCookies(true);
  emit("close");
};
</script>

<style scoped>
.v-text {
  font-size: 13px;
}
.res-image {
  width: 2.2rem;
}
</style>
