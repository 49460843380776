<template>
  <div v-if="!isLoading">
    <div class="container-fluid">
      <MainFilter
        :filterTitle="t('title.labelOrder')"
        :filterText="t('paragraph.acceptLabelOrder')"
        hideFilterOptions
      />

      <div class="row">
        <div class="col-2 subheading-small">{{ t("label.who") }}</div>
        <div class="col text-small">
          {{ labelOrder.vem }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2 subheading-small">
          {{ t("label.phoneNumber") }}
        </div>
        <div class="col text-small">
          {{ labelOrder.telefonnummer }}
        </div>
      </div>
      <div class="row align-items-start mt-2">
        <div class="col-2 subheading-small">{{ t("label.address") }}</div>
        <div class="col text-small">
          {{ labelOrder.adress }}
          <br />
          {{
            (
              labelOrder.postnr +
              ", " +
              labelOrder.postadr +
              ", " +
              labelOrder.land
            )
              .replace(",,", ",")
              .trim()
          }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2 subheading-small">{{ t("label.orderDate") }}</div>
        <div class="col text-small">
          {{ getLocaleTime(labelOrder.created_at) }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2 subheading-small">
          {{ t("label.quantityOrdered") }}
        </div>
        <div class="col text-small">
          {{ t("measure.label", [labelOrder.antal]) }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <GreenifiedButton
            size="thin"
            @click="downloadFile"
            :text="t('button.download')"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 d-flex">
          <GreenifiedButton
            @click="acceptLabelOrder()"
            :text="t('button.acceptLabelOrder')"
            size="thin"
            btnStyle="green"
            class="me-3"
          />
          <GreenifiedButton
            @click="denyLabelOrder()"
            :text="t('button.denyLabelOrder')"
            size="thin"
            btnStyle="black"
          />
        </div>
      </div>
    </div>
  </div>

  <GreenifiedModal
    type="response"
    :closable="false"
    v-if="showError"
    :title="t('responseTitle.labelOrderError')"
    :text="t('msg.labelOrderError')"
    @close="showError = false"
  />

  <GreenifiedModal
    type="response"
    :closable="false"
    v-if="showSuccess"
    :title="t('responseTitle.orderAccepted')"
    :text="t('msg.labelOrderAccepted')"
    @close="router.push({ name: 'notFound' })"
  />
  <GreenifiedModal
    type="response"
    :closable="false"
    v-if="showDenySuccess"
    :title="t('responseTitle.orderRejected')"
    :text="t('msg.labelOrderDenied')"
    @close="router.push({ name: 'notFound' })"
  />
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  getLabelOrderFile,
  getOrderVerification,
  postDeniedLabelOrder,
  postGrantedLabelOrder
} from "@/library/api/labels";
import { useI18n } from "vue-i18n";
import { LabelOrder } from "@/library/types/labels/labels";
import MainFilter from "@/components/filter/MainFilter.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";

const { t, locale } = useI18n();
const router = useRouter();

const route = useRoute();
const showError = ref(false);
const showSuccess = ref(false);
const showDenySuccess = ref(false);
const labelOrder: Ref<LabelOrder> = ref({
  vem: "",
  telefonnummer: "",
  adress: "",
  postnr: "",
  postadr: "",
  land: "",
  created_at: "",
  antal: ""
});
const isLoading = ref(true);

const verifyLabelOrder = async () => {
  const result = await getOrderVerification(route.params.token as string);

  isLoading.value = false;
  if (!result.success) return router.push({ name: "notFound" });

  labelOrder.value = result.result;
};

const downloadFile = async () => {
  const result = await getLabelOrderFile(route.params.token as string);

  if (!result.success) return;

  let blob = new Blob([result.result], {
    type: "text/tab-separated-values'"
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "Labels.txt";
  link.click();
};

const denyLabelOrder = async () => {
  const result = await postDeniedLabelOrder(route.params.token as string);

  if (!result.success) {
    showDenySuccess.value = false;
    return;
  }

  showDenySuccess.value = true;
};

const acceptLabelOrder = async () => {
  const result = await postGrantedLabelOrder(route.params.token as string);

  if (!result.success) {
    showError.value = true;
    return;
  }

  showSuccess.value = true;
};
const getLocaleTime = (createdAtDate: string) => {
  const utcDate = new Date(createdAtDate);

  const userTimezoneOffset = new Date().getTimezoneOffset();

  const userDate = new Date(utcDate.getTime() + userTimezoneOffset * 60000);

  const formattedDate = userDate.toLocaleString(locale.value.toLocaleLowerCase());

  return formattedDate;
};

onMounted(() => {
  verifyLabelOrder();
});
</script>

<style scoped>
.info-list > * {
  margin-bottom: 0.5rem;
}
</style>
