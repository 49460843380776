import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pb-4 position-relative" }
const _hoisted_2 = {
  key: 1,
  class: "border-bottom"
}
const _hoisted_3 = {
  key: 0,
  class: "gr-color subtable position-relative mb-4"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "text-small" }
const _hoisted_7 = { class: "row mt-3" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "fw-bolder" }

import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import {
  ref,
  Ref,
  watch,
  computed
} from "vue";
import { GreenifiedItem } from "@/library/types/product/productItem";
import SubTableRow from "./tableRows/SubTableRow.vue";
import TableHeaderRow from "./tableHeader/TableHeaderRow.vue";
import MainTableRow from "./tableRows/MainTableRow.vue";
import {
  getSaleTableColumns,
  getSubSaleColumns,
  SelectableAction
} from "@/library/helpers/structureTableColumns";
import {
  sortedGreenifiedHeaderRows,
  sortedGreenifiedItemRows
} from "@/library/helpers/sortTableRows";
import HistoryModal from "../modal/HistoryModal.vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/library/types/actions/actions";
import { FilterOptions } from "@/library/types/filter/filter";
import { SortingSettings } from "@/library/types/sorting/sorting";
import ProductGroupInformation from "../ProductGroupInformation.vue";

import GreenifiedModal from "../modal/GreenifiedModal.vue";
import RemoveGreenifiedProductModal from "../modal/RemoveGreenifiedProductModal.vue";
import TableFooter from "./tableFooter/TableFooter.vue";
import { updateProductsSoldStatus } from "@/library/api/products";
import { useIndexStore } from "@/store";

type GreenifiedProductTableProps = {
  tableType?: "sale" | "sold";
  filter: FilterOptions;
  sortingSettings: SortingSettings;
  translateHeaders: string[];
  noRowsText?: string;
  productHeaders: GreenifiedProductHeader[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedProductTable',
  props: {
    tableType: { default: "sale" },
    filter: { default: () => ({
      categories: {},
      intervals: {}
    }) },
    sortingSettings: { default: () => ({
      col: "updated_at",
      asc: false
    }) },
    translateHeaders: { default: () => [] },
    noRowsText: { default: "" },
    productHeaders: { default: () => [] }
  },
  emits: ["getProducts"],
  setup(__props: any, { emit: __emit }) {

const greenifiedProductTableProps = __props;

const { t } = useI18n();
const emit = __emit;
const indexStore = useIndexStore();
const selectedProductForShowingInformation: Ref<GreenifiedProductHeader | null> =
  ref(null);
const showUpdateSuccess = ref(false);
const verifyUpdateSoldProductStatus = ref(false);
const showTableFooter = ref(false);
const productToDelete: Ref<GreenifiedProductHeader | null> = ref(null);
const productsForUpdatingSoldStatus: Ref<{ [key: string]: "0" | "1" }> = ref(
  {}
);
const totalAmountOfSoldProducts = ref(0);
const mainTableSortingOptions = ref(
  greenifiedProductTableProps.sortingSettings
);
const subTableSortingOptions = ref(greenifiedProductTableProps.sortingSettings);
const showSubTable = ref(false);
const productHistoryId = ref("");

const modalType: Ref<Actions | null> = ref(null);

const selectedProductGroupForShowingItems: Ref<
  GreenifiedProductHeader["id"] | null
> = ref(null);

const filteredProductHeaders = computed(() => {
  const productHeaders = [...greenifiedProductTableProps.productHeaders];
  const currFilteredProductHeaders: GreenifiedProductHeader[] = [];

  productHeaders.forEach(productHeader => {
    const isGroupMatching = checkFilter(null, productHeader);
    const matchingItems = filteredProductItems(productHeader);
    const hasMatchingItems = matchingItems.length > 0;

    if (isGroupMatching || hasMatchingItems) {
      currFilteredProductHeaders.push({
        ...productHeader,
        items: matchingItems
      });
    }
  });

  if (Object.keys(greenifiedProductTableProps.filter.categories).length) {
    indexStore.calculateMaxQuantity(currFilteredProductHeaders);
  } else {
    indexStore.calculateMaxQuantity(productHeaders);
  }

  return currFilteredProductHeaders;
});

const filteredProductItems = (productHeader: GreenifiedProductHeader) => {
  return productHeader.items.filter(productItem =>
    checkFilter(productItem, productHeader)
  );
};

const productsMarkedAsSold = computed(() => {
  let soldProductsAmount = 0;

  for (const key in productsForUpdatingSoldStatus.value) {
    if (productsForUpdatingSoldStatus.value[key] === "1") {
      soldProductsAmount += 1;
    }
  }

  return soldProductsAmount;
});

const updateSoldProducts = async () => {
  const result = await updateProductsSoldStatus(
    productsForUpdatingSoldStatus.value
  );

  if (!result.success) return;
  showUpdateSuccess.value = true;
};

const handleSelectSubRow = (
  productItem: GreenifiedItem,
  amountOfSoldProducts: number
) => {
  if (
    amountOfSoldProducts ===
    Object.keys(productsForUpdatingSoldStatus.value).length
  )
    return;

  if (
    productItem.id in productsForUpdatingSoldStatus.value &&
    productsForUpdatingSoldStatus.value[productItem.id] === "1"
  )
    return;

  productsForUpdatingSoldStatus.value[productItem.id] = "1";
  showTableFooter.value = true;

  totalAmountOfSoldProducts.value = amountOfSoldProducts;

  return;
};

const handleDeselectSubRow = (
  productItem: GreenifiedItem,
  amountOfSoldProducts: number
) => {
  const productItemIsSavedAsSold = productItem.sold === "1";

  if (productItemIsSavedAsSold) {
    productsForUpdatingSoldStatus.value[productItem.id] = "0";
  } else {
    delete productsForUpdatingSoldStatus.value[productItem.id];
  }

  totalAmountOfSoldProducts.value = amountOfSoldProducts;
  showTableFooter.value = true;

  return;
};

const markAllProductsAsSold = (productHeader: GreenifiedProductHeader) => {
  if (productHeader.salda === productsMarkedAsSold.value) return;

  for (let i = 0; i < productHeader.items.length; i++) {
    const productItem = productHeader.items[i];
    productsForUpdatingSoldStatus.value[productItem.id] = "1";

    if (productHeader.salda === productsMarkedAsSold.value) {
      break;
    }
  }

  totalAmountOfSoldProducts.value = productHeader.salda;
  showTableFooter.value = true;
  return;
};

const unmarkAllProductsAsSold = (productHeader: GreenifiedProductHeader) => {
  productHeader.items.forEach(productItem => {
    if (productItem.id in productsForUpdatingSoldStatus.value) {
      const productItemIsSavedAsSold = productItem.sold === "1";
      if (!productItemIsSavedAsSold) {
        delete productsForUpdatingSoldStatus.value[productItem.id];
      }
    }
  });
  totalAmountOfSoldProducts.value = productHeader.salda;
  showTableFooter.value = true;

  return;
};

const handleSelectedAction = (
  action: SelectableAction["value"],
  productGroup: GreenifiedProductHeader
) => {
  if (action === 1) {
    window.open(productGroup.permalink, "_blank", "noreferrer");
  } else if (action === 2) {
    window.open(productGroup.editUrl, "_blank", "noreferrer");
  } else if (action === 3) {
    if (productGroup.reserved.length > 0) {
      modalType.value = "productIsReserved";
      return;
    }
    productToDelete.value = productGroup;
  }
};

const getRowStatus = (productHeader: GreenifiedProductHeader) => {
  if (!productHeader.loading && !("loadingStatus" in productHeader))
    return null;
  if (productHeader.loading) return t("label.publishing");
  return productHeader.loadingStatus
    ? t("paragraph.fetchingGreenifiedData")
    : t("paragraph.removedFromGreenified");
};

const checkFilter = (
  productItem: GreenifiedItem | null,
  productGroup: GreenifiedProductHeader
) => {
  const filterCategories = { ...greenifiedProductTableProps.filter.categories };

  if (
    !Object.keys(filterCategories).length &&
    !Object.keys(greenifiedProductTableProps.filter.intervals).length
  ) {
    return true;
  }

  if (!productGroupMatchesIntervalAmount(productGroup)) {
    return false;
  }

  for (const key in filterCategories) {
    const categories = filterCategories[key];
    const filterType = key;

    if (
      filterType === "search" &&
      !productMatchesSearchValue(categories, productGroup, productItem)
    ) {
      return false;
    }

    if (filterType !== "search") {
      if (
        productItem &&
        !productItemMatchesFilterType(filterType, categories, productItem)
      ) {
        return false;
      }

      if (
        !productGroupMatchesFilterType(filterType, categories, productGroup)
      ) {
        return false;
      }
    }
  }

  return true;
};

const productMatchesSearchValue = (
  categories: FilterOptions["categories"]["key"],
  productGroup: GreenifiedProductHeader,
  productItem: GreenifiedItem | null
) => {
  for (const category of categories) {
    const searchValue = String(category.value).toLowerCase();

    const combined = { ...productGroup, ...(productItem || {}) };

    for (const key in combined) {
      const value = combined[key as keyof typeof combined];
      if (typeof value === "string") {
        if (value.toLowerCase().includes(searchValue)) {
          return true;
        }
      }

      if (greenifiedProductTableProps.translateHeaders.includes(key)) {
        const translatedValue = t(`${key}.${value}`);
        if (
          translatedValue &&
          translatedValue.toLowerCase().includes(searchValue)
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const productItemMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productItem: GreenifiedItem
) => {
  if (filterType in productItem) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productItem[filterType].substring(0, 2) === "ex";
      return (
        String(productItem[filterType as keyof GreenifiedItem]) ===
        String(category.value)
      );
    });
  }

  return true;
};

const productGroupMatchesIntervalAmount = (
  productGroup: GreenifiedProductHeader
) => {
  for (const key in greenifiedProductTableProps.filter.intervals) {
    const interval = greenifiedProductTableProps.filter.intervals[key];

    if (key in productGroup) {
      if (
        "from" in interval &&
        !(
          interval.from <=
          Number(productGroup[key as keyof GreenifiedProductHeader])
        )
      ) {
        return false;
      }
      if (
        "to" in interval &&
        !(
          interval.to >=
          Number(productGroup[key as keyof GreenifiedProductHeader])
        )
      ) {
        return false;
      }
    }
  }
  return true;
};

const productGroupMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productGroup: GreenifiedProductHeader
) => {
  if (filterType in productGroup) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productGroup[filterType].substring(0, 2) === "ex";
      return (
        String(productGroup[filterType as keyof GreenifiedProductHeader]) ===
        category.value
      );
    });
  }

  return true;
};

const toggleSubTable = (productGroup: GreenifiedProductHeader) => {
  showTableFooter.value = false;

  if (selectedProductGroupForShowingItems.value === productGroup.id) {
    showSubTable.value = !showSubTable.value;
  } else {
    productsForUpdatingSoldStatus.value = {};
    showSubTable.value = true;
    selectedProductGroupForShowingItems.value = productGroup.id;
    if (productGroup.salda === 0) return;

    productGroup.items.forEach(productItem => {
      if (productItem.sold === "1") {
        productsForUpdatingSoldStatus.value[productItem.id] = "1";
      }
    });
  }
};

watch(
  () => greenifiedProductTableProps.sortingSettings,
  newSortingSettings => {
    mainTableSortingOptions.value = newSortingSettings;
    subTableSortingOptions.value = newSortingSettings;
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.productHeaders.length)
        ? (_openBlock(), _createBlock(TableHeaderRow, {
            key: 0,
            class: "mt-3",
            tableHeaders: null,
            noRowsText: _ctx.noRowsText
          }, null, 8, ["noRowsText"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedGreenifiedHeaderRows)(
        filteredProductHeaders.value,
        mainTableSortingOptions.value
      ), (productHeader, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (index === 0)
            ? (_openBlock(), _createBlock(TableHeaderRow, {
                key: 0,
                tableHeaders: _unref(getSaleTableColumns)(productHeader, _ctx.tableType).columns,
                sortingOptions: mainTableSortingOptions.value,
                "onUpdate:sortingOptions": _cache[0] || (_cache[0] = ($event: any) => ((mainTableSortingOptions).value = $event)),
                type: "main",
                hideHeaderCheckbox: true,
                isSticky: ""
              }, null, 8, ["tableHeaders", "sortingOptions"]))
            : _createCommentVNode("", true),
          (productHeader.items.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(MainTableRow, {
                  tableColumns: _unref(getSaleTableColumns)(productHeader, _ctx.tableType).columns,
                  rowImage: productHeader.images?.bild_1?.url ?? '',
                  toggleSubTable: 
            showSubTable.value &&
            selectedProductGroupForShowingItems.value === productHeader.id
          ,
                  showCheckbox: false,
                  onShowSubTable: ($event: any) => (toggleSubTable(productHeader)),
                  onShowProductInformation: ($event: any) => (
            selectedProductForShowingInformation.value = productHeader
          ),
                  type: _ctx.tableType,
                  rowAction: 
            productHeader.missingOnGreenified &&
            !productHeader.loadingStatus &&
            !productHeader.loading
              ? ['tooltip']
              : ['handleVariant']
          ,
                  buttonText: _unref(t)('button.seeProducts'),
                  disabled: productHeader.missingOnGreenified,
                  rowStatus: getRowStatus(productHeader),
                  onSelectAction: action => handleSelectedAction(action, productHeader),
                  filteredAmount: 
            productHeader.quantity !== productHeader.items.length
              ? productHeader.items.length
              : null
          
                }, null, 8, ["tableColumns", "rowImage", "toggleSubTable", "onShowSubTable", "onShowProductInformation", "type", "rowAction", "buttonText", "disabled", "rowStatus", "onSelectAction", "filteredAmount"]),
                (
            selectedProductGroupForShowingItems.value === productHeader.id &&
            showSubTable.value
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "left-frame gr-color" }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedGreenifiedItemRows)(
              productHeader.items,
              subTableSortingOptions.value
            ), (productItem, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (index === 0)
                            ? (_openBlock(), _createBlock(TableHeaderRow, {
                                key: 0,
                                tableHeaders: _unref(getSubSaleColumns)(productItem, _ctx.tableType).columns,
                                sortingOptions: subTableSortingOptions.value,
                                "onUpdate:sortingOptions": _cache[1] || (_cache[1] = ($event: any) => ((subTableSortingOptions).value = $event)),
                                type: "sub",
                                hideHeaderCheckbox: true,
                                soldProducts: productsMarkedAsSold.value,
                                productsForSale: productHeader.salda,
                                "toggle-checkbox": productHeader.salda === productsMarkedAsSold.value,
                                "onUpdate:toggleCheckbox": 
                isChecked => {
                  if (isChecked) return markAllProductsAsSold(productHeader);
                  return unmarkAllProductsAsSold(productHeader);
                }
              
                              }, null, 8, ["tableHeaders", "sortingOptions", "soldProducts", "productsForSale", "toggle-checkbox", "onUpdate:toggleCheckbox"]))
                            : _createCommentVNode("", true),
                          (checkFilter(productItem, productHeader))
                            ? (_openBlock(), _createBlock(SubTableRow, {
                                key: 1,
                                subTableColumns: _unref(getSubSaleColumns)(productItem, _ctx.tableType),
                                tableType: _ctx.tableType,
                                hideCheckbox: "",
                                onShowHistory: ($event: any) => {
                  modalType.value = 'history';
                  productHistoryId.value = productItem.id;
                },
                                rowIsChecked: 
                  productsForUpdatingSoldStatus.value[productItem.id] === '1'
                ,
                                onSelectSubRow: (checked) => {
                  if (checked) return handleSelectSubRow(productItem, productHeader.salda)
                  return handleDeselectSubRow(productItem, productHeader.salda)
              },
                                disableCheckbox: productHeader.salda === productsMarkedAsSold.value
                              }, null, 8, ["subTableColumns", "tableType", "onShowHistory", "rowIsChecked", "onSelectSubRow", "disableCheckbox"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128)),
                      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "right-frame gr-color" }, null, -1))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    (showTableFooter.value)
      ? (_openBlock(), _createBlock(TableFooter, {
          key: 0,
          footerText: `${_unref(t)(
      'label.markedAsSold'
    )} ${productsMarkedAsSold.value}/${totalAmountOfSoldProducts.value}`,
          actionStatuses: ['US'],
          toggleDisabledButtons: 
      Object.keys(productsForUpdatingSoldStatus.value).length === 0
    ,
          onAction: _cache[2] || (_cache[2] = ($event: any) => (verifyUpdateSoldProductStatus.value = true))
        }, null, 8, ["footerText", "toggleDisabledButtons"]))
      : _createCommentVNode("", true),
    (verifyUpdateSoldProductStatus.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: "verification",
          onClose: _cache[3] || (_cache[3] = ($event: any) => (verifyUpdateSoldProductStatus.value = false)),
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => {
      updateSoldProducts();
      showTableFooter.value = false;
    }),
          title: _unref(t)('title.updateSoldProducts'),
          text: _unref(t)('paragraph.verifyUpdateSoldStatus')
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (showUpdateSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 2,
          type: "response",
          title: _unref(t)('title.updateSuccessful'),
          text: _unref(t)('paragraph.soldStatusIsUpdated'),
          onClose: _cache[5] || (_cache[5] = ($event: any) => {
      showUpdateSuccess.value = false;
      emit('getProducts');
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (modalType.value === 'history')
      ? (_openBlock(), _createBlock(HistoryModal, {
          key: 3,
          productId: productHistoryId.value,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (modalType.value = null))
        }, null, 8, ["productId"]))
      : _createCommentVNode("", true),
    (selectedProductForShowingInformation.value)
      ? (_openBlock(), _createBlock(ProductGroupInformation, {
          key: 4,
          onUpdate: _cache[7] || (_cache[7] = ($event: any) => {
      selectedProductForShowingInformation.value = null;
      emit('getProducts');
    }),
          onClose: _cache[8] || (_cache[8] = ($event: any) => (selectedProductForShowingInformation.value = null)),
          productId: selectedProductForShowingInformation.value.items[0].id,
          productName: selectedProductForShowingInformation.value.benamn,
          supplierCode: selectedProductForShowingInformation.value.levkod
        }, null, 8, ["productId", "productName", "supplierCode"]))
      : _createCommentVNode("", true),
    (modalType.value === 'productIsReserved')
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 5,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (modalType.value = null)),
          type: 'response',
          closable: false,
          title: _unref(t)('title.productIsReservedOnGreenified')
        }, {
          container: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)("paragraph.productIsReservedOnGreenified")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)("title.contact")), 1)
              ])
            ]),
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "row mt-2" }, [
              _createElementVNode("div", { class: "col-12 d-flex align-items-center" }, [
                _createElementVNode("p", { class: "me-3" }, "+46 (0)200 77 00 60"),
                _createElementVNode("p", null, "inventory@greenified.se")
              ])
            ], -1))
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (productToDelete.value)
      ? (_openBlock(), _createBlock(RemoveGreenifiedProductModal, {
          key: 6,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (productToDelete.value = null)),
          onRefresh: _cache[11] || (_cache[11] = ($event: any) => {
      productToDelete.value = null;
      emit('getProducts');
    }),
          greenifiedProductHeaderToRemove: productToDelete.value
        }, null, 8, ["greenifiedProductHeaderToRemove"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})