<template>
  <div class="d-flex align-items-center">
    <InformationTooltip
      v-if="actions.includes('history')"
      class="me-1"
      :tooltipIcon="historyIcon"
      altText="History icon"
      :size="'large'"
      :infoText="t('title.productHistory')"
      offset="0"
      @click="emit('showHistory')"
    />
    <div class="me-1 d-flex" v-if="actions.includes('checkbox')">
      <p
        class="fst-italic me-2"
        v-if="typeof columnValue === 'string' && columnValue.length"
      >
        {{ t(columnValue) }}
      </p>
      <GreenifiedCheckbox
        :trueValue="true"
        :disabled="disableCheckbox && !isChecked"
        :falseValue="false"
        v-model="currIsChecked"
        @update:model-value="emit('update:isChecked', currIsChecked)"
      />
    </div>

    <InformationTooltip
      v-if="actions.includes('edit')"
      :tooltipIcon="penIcon"
      altText="Pen icon"
      :size="'small'"
      :infoText="t('title.editProduct')"
      offset="0"
      class="me-2"
      @click="emit('showEdit')"
    />
  </div>
</template>

<script setup lang="ts">
import historyIcon from "@/assets/img/history-thin.svg";
import penIcon from "@/assets/img/pen.svg";
import InformationTooltip from "@/components/common/InformationTooltip.vue";
import { useI18n } from "vue-i18n";
import { defineEmits, withDefaults, defineProps, ref, watch } from "vue";
import { ColumnActions } from "@/library/types/tables/columns";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import { Dimensions } from "@/library/types/product/productItem";

type StandardSubActionsProps = {
  actions: ColumnActions[];
  isChecked?: boolean;
  columnValue?: string | number | Dimensions;
  disableCheckbox?: boolean;
};

const standardSubActionsProps = withDefaults(
  defineProps<StandardSubActionsProps>(),
  {
    actions: () => [],
    isChecked: false,
    columnValue: "",
    disableCheckbox: false,
  },
);

const { t } = useI18n();
const currIsChecked = ref(standardSubActionsProps.isChecked);
const emit = defineEmits(["showEdit", "showHistory", "update:isChecked"]);

watch(
  () => standardSubActionsProps.isChecked,
  () => {
    currIsChecked.value = standardSubActionsProps.isChecked;
  },
);
</script>
