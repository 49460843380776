import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from "vue";
import dropArrow from "../../assets/img/drop-arrow.svg";

type RotateArrowProps = {
  rotate: boolean;
  size: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RotateArrow',
  props: {
    rotate: { type: Boolean, default: false },
    size: { default: "full" }
  },
  setup(__props: any) {

const rotateArrowProps = __props;

const arrowDirection = computed(() => {
  return rotateArrowProps.rotate
    ? "ascending-direction"
    : "descending-direction";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    src: _unref(dropArrow),
    alt: "dropdown arrow",
    class: _normalizeClass([arrowDirection.value, _ctx.size])
  }, null, 10, _hoisted_1))
}
}

})