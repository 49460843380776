import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import CustomSelect from "@/components/common/CustomSelect.vue";
import { FormFields } from "@/library/types/structure/formStructure";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SelectFieldType } from "@/library/types/structure/subfieldTypes";
import {
  ref,
  onMounted,
  Ref
} from "vue";

export type SelectOptions = {
  key: string;
  value: string;
};

type SelectFieldProps = {
  selectField: SelectFieldType | null;
  selectFieldValues: FormFields["key"];
  showError?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectField',
  props: {
    selectField: { default: null },
    selectFieldValues: { default: () => ({}) },
    showError: { type: Boolean, default: false }
  },
  emits: ["update:selectFieldValues"],
  setup(__props: any, { emit: __emit }) {

const selectFieldProps = __props;

const emit = __emit;

const localSelectFieldValues: Ref<string | number | null> = ref(null);

const sortedOptions = (fieldChoices: {
  [key: string]: string;
}): SelectOptions[] => {
  const sortedEntries = Object.entries(fieldChoices).sort(([a], [b]) =>
    a.localeCompare(b)
  );
  const sortedArray = sortedEntries.map(([key, value]) => ({ key, value }));
  return sortedArray;
};

onMounted(() => {
  if (selectFieldProps.selectField?.default_value) {
    localSelectFieldValues.value = String(
      selectFieldProps.selectField.default_value
    );

    return;
  }

  if (
    typeof selectFieldProps.selectFieldValues === "string" ||
    typeof selectFieldProps.selectFieldValues === "number"
  ) {
    localSelectFieldValues.value = selectFieldProps.selectFieldValues;
    return;
  }

  localSelectFieldValues.value = null;
  return;
});

return (_ctx: any,_cache: any) => {
  return (_ctx.selectField)
    ? (_openBlock(), _createBlock(CustomSelect, {
        key: String(localSelectFieldValues.value),
        withComponents: true,
        required: _ctx.selectField.required === 1 ? true : false,
        id: _ctx.selectField.key,
        name: _ctx.selectField.key,
        options: sortedOptions(_ctx.selectField.choices),
        reducedKey: 'key',
        label: "value",
        placeholder: 
      _ctx.selectField.placeholder.length
        ? _ctx.selectField.placeholder
        : _ctx.selectField.default_value
        ? String(_ctx.selectField.default_value)
        : _ctx.selectField.label
        ? _ctx.selectField.label
        : ''
    ,
        modelValue: localSelectFieldValues.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((localSelectFieldValues).value = $event)),
          _cache[1] || (_cache[1] = (option) => {
      emit('update:selectFieldValues', option)})
        ],
        appendToBody: true,
        clearable: _ctx.selectField.required === 1 ? false : true,
        showError: _ctx.showError
      }, null, 8, ["required", "id", "name", "options", "placeholder", "modelValue", "clearable", "showError"]))
    : _createCommentVNode("", true)
}
}

})