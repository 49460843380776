import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



type RequiredWarningTextProps = {
  text: string;
  textClass?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RequiredWarningText',
  props: {
    text: { default: "This field is mandatory" },
    textClass: { default: "text-tiny" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["text-danger", _ctx.textClass])
  }, _toDisplayString(_ctx.text), 3))
}
}

})