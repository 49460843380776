import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "col-12 d-flex flex-row-reverse" }

import { FilterOptions } from "@/library/types/filter/filter";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import BreadCrumb from "./BreadCrumb.vue";

type FilterBreadcrumbsProps = {
  filterOptions: FilterOptions;
  translateHeaders?: string[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterBreadcrumbs',
  props: {
    filterOptions: { default: () => ({
      categories: {},
      intervals: {},
    }) },
    translateHeaders: { default: () => [] }
  },
  emits: ["update:filterOptions"],
  setup(__props: any, { emit: __emit }) {

const filterBreadcrumbsProps = __props;

const { t } = useI18n();
const currentFilterOptions = ref(filterBreadcrumbsProps.filterOptions);
const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        Object.keys(currentFilterOptions.value.categories).length ||
        Object.keys(currentFilterOptions.value.intervals).length
      )
      ? (_openBlock(), _createBlock(BreadCrumb, {
          key: 0,
          text: 'label.clearFilter',
          class: "ms-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
        currentFilterOptions.value.categories = {};
        currentFilterOptions.value.intervals = {};
        emit('update:filterOptions', currentFilterOptions.value);
      })
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentFilterOptions.value.categories, (categoryValues, filterKey) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: filterKey }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoryValues, (categoryValue, index) => {
          return (_openBlock(), _createBlock(BreadCrumb, {
            class: "ms-2",
            key: index,
            translate: _ctx.translateHeaders.includes(String(filterKey)),
            text: 
          _ctx.translateHeaders.includes(String(filterKey))
            ? `${filterKey}.${categoryValue.value}`
            : categoryValue.value
        ,
            onClick: 
          () => {
            categoryValues.splice(index, 1);
            if (!categoryValues.length) {
              delete currentFilterOptions.value.categories[filterKey];
            }
            emit('update:filterOptions', currentFilterOptions.value);
          }
        
          }, null, 8, ["translate", "text", "onClick"]))
        }), 128))
      ], 64))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentFilterOptions.value.intervals, (interval, key) => {
      return (_openBlock(), _createBlock(BreadCrumb, {
        key: interval.to,
        text: `${_unref(t)(`label.${key}`)} ${interval.from ?? ''} - ${
          interval.to ?? ''
        }`,
        translate: false,
        onClick: _cache[1] || (_cache[1] = ($event: any) => {
          currentFilterOptions.value.intervals = {};
          emit('update:filterOptions', currentFilterOptions.value);
        })
      }, null, 8, ["text"]))
    }), 128))
  ]))
}
}

})