<template>
  <div
    class="icon-wrapper d-flex justify-content-center align-items-center"
    :class="!noWrapper && 'wrapper-size'"
    @click="!disabled && emit('clicked')"
  >
    <img
      :width="width"
      :height="height"
      :class="[disabled ?? 'disabled']"
      :src="iconImage"
      :style="{
        width: width + 'px',
        height: height + 'px',
      }"
      :alt="altText"
    />
  </div>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from "vue";

type IconButtonProps = {
  width?: string;
  height: string;
  disabled?: boolean;
  iconImage: string;
  altText: string;
  noWrapper: boolean;
};

withDefaults(defineProps<IconButtonProps>(), {
  width: "48",
  height: "48",
  disabled: false,
  iconImage: "",
  altText: "Button icon",
  noWrapper: false,
});

const emit = defineEmits(["clicked"]);
</script>

<style scoped>
.wrapper-size {
  min-height: 48px;
  min-width: 48px;
}

.icon-wrapper {
  max-width: max-content;
  max-height: max-content;
  cursor: pointer;
  padding: 0px;
}

.wide {
  width: 12rem;
}

.medium {
  width: 8rem;
}
</style>
