import { AxiosResponse } from "axios";
import api from "../api";
import { FormFields } from "@/library/types/structure/formStructure";
import { InternalProductHeaders } from "./productsApi";
import {
  OrderGroupSale,
  ProductGroupSale,
  SaleInformation
} from "@/library/types/product/productGroup";
import { ProductItemSale } from "@/library/types/product/productItem";

export type OrderStatuses = "O" | "A" | "D" | "C";

export type Advertisement = {
  advertisement_id: number;
  project: string;
  sale_info: SaleInformation;
};

export type OrderProducts = {
  article_id: string;
  order_id: number;
};

export type OldOrdersAsAdvertisement = {
  productIds: string[];
  saleInfo: SaleInformation;
};

export type OrderData = {
  advertisement_id: number | null;
  order_id: number;
  delivery_address: string | null;
  delivery_room: string | null;
  order_comment: string | null;
  order_number: string | null;
  pick_up_date: string | null;
  email: string;
  status: OrderStatuses;
  requested_quantity: string | null;
};

export interface OrderDataWithProducts extends OrderData {
  products: OrderProducts[];
}

export type AdvertisementProduct = {
  advertisement_id: string;
  article_id: string;
  product_data: ProductItemSale;
};

export interface InternalSaleOrder extends PurchaseOrder {
  status: OrderStatuses;
  order_id: number;
  products: OrderProducts[];
  advertisement_products: AdvertisementProduct[];
  delivery_address: string | null;
  delivery_room: string | null;
}

export type PurchaseOrder = {
  requested_quantity: string | null;
  email: string;
  order_comment: string | null;
  pick_up_date: string;
  order_number: string | null;
  advertisement_id: number | null;
  greenifiedHash: string | null;
};

export type OrderForUpdate = {
  orderId: OrderGroupSale["order_id"];
  greenifiedHash: ProductGroupSale["green_hash"];
};

export default {
  postGreenifiedSale(
    projectNumber: string,
    ids: string[]
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/greenified/product`,
      JSON.stringify({ ids: ids })
    );
  },

  getInternalOrders(
    projectNumber: string,
    orderStatuses: string[]
  ): Promise<AxiosResponse<InternalSaleOrder[]>> {
    return api().post(
      `/api/project/${projectNumber}/internal-orders`,
      JSON.stringify({
        orderStatuses: orderStatuses
      })
    );
  },

  getInternalOrdersByAdvertisementId(
    projectNr: string,
    advertisementId: number
  ): Promise<AxiosResponse<InternalSaleOrder[]>> {
    return api().get(
      `/api/project/${projectNr}/internal-order/${advertisementId}`
    );
  },

  getInternalAdvertisements(
    projectNr: string
  ): Promise<AxiosResponse<InternalProductHeaders>> {
    return api().get("/api/project/" + projectNr + "/internal-advertisements");
  },

  getInternalAdvertisement(
    projectNr: string,
    advertisementId: number
  ): Promise<AxiosResponse<Advertisement>> {
    return api().get(
      "/api/project/" +
        projectNr +
        "/internal-advertisements/" +
        advertisementId
    );
  },

  postInternalAdvertisement(
    projectNumber: string,
    productIds: string[],
    internalSaleInformation: FormFields
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/post-advertisement`,
      JSON.stringify({ ids: productIds, data: internalSaleInformation })
    );
  },

  updateInternalAdvertisement(
    projectNumber: string,
    productIds: string[],
    internalSaleInformation: FormFields,
    advertisementId: number
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/update-advertisement`,
      JSON.stringify({
        ids: productIds,
        data: internalSaleInformation,
        advertisementId: advertisementId
      })
    );
  },

  postOldOrderAsAdvertisement(
    projectNumber: string,
    oldOrders: OldOrdersAsAdvertisement[]
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/post-advertisement/convert`,
      JSON.stringify({ orders: oldOrders })
    );
  },

  postInternalOrder(
    projectNr: string,
    purchaseOrder: PurchaseOrder
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNr}/internal-order`,
      JSON.stringify(purchaseOrder)
    );
  },

  postGuestOrder(
    guestToken: string | null,
    purchaseOrder: PurchaseOrder
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/guest/${guestToken}/internal-order`,
      JSON.stringify(purchaseOrder)
    );
  },

  updateInternalOrder(
    projectNumber: string,
    internalSaleInformation: OrderDataWithProducts,
    productIds: string[]
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/update-internal-order`,
      JSON.stringify({
        saleInformation: internalSaleInformation,
        productIds: productIds
      })
    );
  },

  denyInternalOrders(projectNumber: string, orders: OrderForUpdate[]) {
    return api().post(
      `/api/project/${projectNumber}/deny-internal-orders`,
      JSON.stringify({ orders: orders })
    );
  }
};
