import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/style.scss";
import "@/assets/scss/typography.scss";
import "vue3-toastify/dist/index.css";
import VueLazyload from "vue-lazyload";
import "bootstrap/dist/js/bootstrap.js";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { toastPlugin } from "./modules/errorToastModule";
import { initializeApiService } from "./library/helpers/handleApiRequests";
import { loadI18n } from "./i18n";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify } from "vuetify";

const token = localStorage.getItem("token");
const pinia = createPinia();

const vuetify = createVuetify({
  components,
  directives
});
pinia.use(piniaPluginPersistedstate);

// Only enable Flare in production
if (process.env.NODE_ENV === "production") {
  flare.light("tTFdx4TeRGwOOyFILUNHOC0EVJV3VV3a");
}

axios.defaults.baseURL = process.env.VUE_APP_LARAVEL_API;
axios.defaults.withCredentials = false;

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
const app = createApp(App);
app.use(vuetify);
app.use(flareVue);
app.use(pinia);
const i18n = await loadI18n(pinia); // Pass Pinia instance

app.use(i18n);
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
});
app.use(router);
app.use(toastPlugin, { i18n });
initializeApiService(app._context.provides["toastError"]);

app.mount("#app");
