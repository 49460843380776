import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gr-card col-md-3 form-label d-flex p-3" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center" }
const _hoisted_3 = {
  class: "card-title mt-3",
  style: {"font-weight":"700"}
}



type GrCardProps = {
  title: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GrCard',
  props: {
    title: { default: "" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})