import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { MessageFieldType } from "@/library/types/structure/subfieldTypes";


type MessageFieldProps = {
  messageField: MessageFieldType | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageField',
  props: {
    messageField: { default: null }
  },
  setup(__props: any) {



const getMessage = (messageField: MessageFieldType) => {
  return messageField.message.replace(
    'href="https://vimeo.com/',
    'target="_blank" href="https://player.vimeo.com/video/',
  );
};

return (_ctx: any,_cache: any) => {
  return (_ctx.messageField)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "w-100",
        innerHTML: getMessage(_ctx.messageField)
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})