import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { CheckboxFieldType } from "@/library/types/structure/subfieldTypes";
import { ref } from "vue";

type MultipleCheckboxFieldProps = {
  option: string;
  presetMultipleCheckboxValues: FormFields["key"];
  multipleCheckboxField: CheckboxFieldType | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleCheckboxField',
  props: {
    option: { default: "" },
    presetMultipleCheckboxValues: { default: () => ({}) },
    multipleCheckboxField: { default: null }
  },
  emits: ["delete", "update:presetMultipleCheckboxValues"],
  setup(__props: any, { emit: __emit }) {

const multipleCheckboxFieldProps = __props;

const localMultipleCheckboxValues = ref(
  multipleCheckboxFieldProps.presetMultipleCheckboxValues as string
);

const isChecked = ref(false);

const emit = __emit;

const addOrRemoveOption = (option: string) => {
  if (!Array.isArray(localMultipleCheckboxValues.value)) return;

  if (!localMultipleCheckboxValues.value) {
    return;
  }

  const descriptionIndex = localMultipleCheckboxValues.value.indexOf(option);
  if (descriptionIndex === -1) {
    localMultipleCheckboxValues.value.push(option);
    isChecked.value = true;
  } else {
    localMultipleCheckboxValues.value.splice(descriptionIndex, 1);
    isChecked.value = false;
  }
  emit(
    "update:presetMultipleCheckboxValues",
    localMultipleCheckboxValues.value
  );

  if (localMultipleCheckboxValues.value.length === 0) {
    emit("delete");
  }
};

return (_ctx: any,_cache: any) => {
  return (_ctx.multipleCheckboxField)
    ? (_openBlock(), _createBlock(GreenifiedButton, {
        key: 0,
        inputType: "checkbox",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (addOrRemoveOption(_ctx.option))),
        inputRequired: _ctx.multipleCheckboxField.required === 0 ? false : true,
        id: "name-id",
        name: _ctx.multipleCheckboxField.key,
        value: localMultipleCheckboxValues.value,
        text: _ctx.option,
        btnStyle: isChecked.value ? 'black' : 'normal'
      }, null, 8, ["inputRequired", "name", "value", "text", "btnStyle"]))
    : _createCommentVNode("", true)
}
}

})