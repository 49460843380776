import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row row-gap-2 ps-0 pe-0" }
const _hoisted_2 = { class: "col-4" }


import GreenifiedModal from "./GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
type ChangeActionModalProps = {
  actions: ProductStatuses[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeActionModal',
  props: {
    actions: { default: () => [] }
  },
  emits: ["openAction", "close"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const changeActionModalProps = __props;

const allActions: { type: ProductStatuses; text: string }[] = [
  {
    type: "S",
    text: t("button.sell")
  },
  {
    type: "K",
    text: t("button.recycle")
  },
  {
    type: "Å",
    text: t("button.repurpose")
  }
];

const currentActions = changeActionModalProps.actions
  ? allActions.filter(
      action => !changeActionModalProps.actions.includes(action.type)
    )
  : allActions;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
    title: _unref(t)('title.changeAction'),
    type: 'verification',
    text: _unref(t)('msg.selectDifferentAction')
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentActions), (action) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-4 pe-0",
            key: action.type
          }, [
            _createVNode(GreenifiedButton, {
              onClick: ($event: any) => (emit('openAction', action.type)),
              text: action.text,
              btnStyle: 'green',
              smallerPadding: ""
            }, null, 8, ["onClick", "text"])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(GreenifiedButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('openAction', 'J'))),
            text: _unref(t)('button.reset')
          }, null, 8, ["text"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "text"]))
}
}

})