import { FormFields } from "../types/structure/formStructure";

export const keysExistInObject = (
  objectKeys: string[],
  structure: FormFields,
) => {
  for (let i = 0; i < objectKeys.length; i++) {
    if (objectKeys[i] in structure) {
      return true;
    }
  }
  return false;
};
