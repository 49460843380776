import { useIndexStore } from "@/store";
import roomsApi, { RoomsToPost } from "@/services/api/roomsApi";
import { AddressInformation, RoomObject } from "../types/room/rooms";
import { AxiosResponse } from "axios";
import {
  CustomError,
  handleApiRequest,
  Result,
} from "../helpers/handleApiRequests";

export const getProjectRooms = async (): Promise<
  Result<RoomObject[], CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    roomsApi.getProjectRooms(indexStore.currentProjectNumber),
    "rooms - getProjectRooms",
  );
};

export const updateRooms = async (
  rooms: RoomsToPost,
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();
  return handleApiRequest(
    roomsApi.updateRooms(indexStore.currentProjectNumber, rooms),
    "rooms - updateRooms",
  );
};

export const moveRoomsFromAddress = async (
  addressToDelete: AddressInformation["id"],
  newAddress: AddressInformation["id"],
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    roomsApi.moveRoomsFromAddress(
      indexStore.currentProjectNumber,
      addressToDelete,
      newAddress,
    ),
    "rooms - moveRoomsFromAddress",
  );
};
