<template>
  <GreenifiedModal
    :title="t('title.orderLabels')"
    @close="emit('close')"
    type="small"
    :isLoading="isLoading"
  >
    <template #modalHeaderContent>
      <p class="form-label-paragraph">
        {{ t("paragraph.orderLabels") }}
      </p>
    </template>
    <template #container>
      <form
        id="label-order"
        ref="labelOrderForm"
        @submit.prevent="postLabelOrder"
      >
        <div
          class="mb-2"
          v-for="(orderField, orderFieldValue, index) in labelOrderFields(
            defaultLabelOrder
          )"
          :key="index"
        >
          <label class="form-label mb-1"
            >{{ t(`label.${orderFieldValue}`) }}
            {{ orderField.fields.find(field => field.required) && "*" }}</label
          >
          <p class="form-label-paragraph mb-2" v-if="orderField.paragraph">
            {{ t(`${orderField.paragraph}`) }}
          </p>
          <div class="row row-gap-2">
            <div
              class="col-12"
              v-for="(field, fieldIndex) in orderField.fields"
              :key="fieldIndex"
            >
              <InputField
                v-if="field.type === 'number'"
                v-model:fieldValue="field.fieldValue"
                @update:fieldValue="
                  defaultLabelOrder[field.labelOrderKey] = field.fieldValue
                "
                :placeHolder="t(field.placeholder)"
                :type="field.type"
                :required="field.required && field.fieldValue > 0"
                :errorWithInputValue="field.fieldValue <= 0 && formIsPosted"
                :maxlength="field.maxlength"
              />
              <InputField
                v-if="field.type === 'text' || field.type === 'phone'"
                v-model:fieldValue="field.fieldValue"
                @update:fieldValue="
                  defaultLabelOrder[field.labelOrderKey] = field.fieldValue
                "
                :placeHolder="t(field.placeholder)"
                :type="field.type"
                :required="field.required"
                :errorWithInputValue="
                  field.fieldValue.length === 0 && formIsPosted
                "
                :maxlength="field.maxlength"
              />

              <InputTextField
                v-if="field.type === 'textarea'"
                v-model="field.fieldValue"
                @update:modelValue="
                  defaultLabelOrder[field.labelOrderKey] = field.fieldValue
                "
                :rows="2"
              />
              <CustomSelect
                v-if="field.type === 'select'"
                v-model="field.fieldValue"
                @update:model-value="
                  defaultLabelOrder[field.labelOrderKey] = field.fieldValue
                "
                :options="allCountries"
                :clearable="false"
                label="label"
                reducedKey="value"
              />
            </div>
          </div>
        </div></form
    ></template>
    <template #footer>
      <div class="col-4">
        <GreenifiedButton
          @click="formIsPosted = true"
          :text="t('button.send')"
          :type="'submit'"
          :form="'label-order'"
          btn-style="green"
        />
      </div>
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import { computed, Ref, ref, defineEmits, onBeforeMount } from "vue";
import { I18nMessage } from "../../library/types/locales/i18nMessage";
import { useI18n } from "vue-i18n";
import { postLabelOrderApi } from "@/library/api/labels";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import InputField from "../common/InputField.vue";
import CustomSelect from "../common/CustomSelect.vue";
import InputTextField from "../common/InputTextField.vue";
import { LabelGroup, PostLabelOrder } from "@/library/types/labels/labels";

type Country = {
  value: string;
  label: string;
};

const { t, locale, messages } = useI18n();
const formIsPosted = ref(false);

const defaultLabelOrder: Ref<PostLabelOrder> = ref({
  phone: "",
  country: "",
  address: "",
  zip: "",
  city: "",
  who: "",
  comment: "",
  quantity: 0
});

const labelOrderFields = (labelOrder: PostLabelOrder): LabelGroup => {
  return {
    address: {
      paragraph: "paragraph.addressToSendLabels",
      fields: [
        {
          labelOrderKey: "address",
          fieldValue: labelOrder.address,
          placeholder: "placeholders.address",
          type: "text",
          maxlength: "200",
          required: true
        },
        {
          labelOrderKey: "zip",
          fieldValue: labelOrder.zip,
          placeholder: "placeholders.zip",
          type: "text",
          maxlength: "20",
          required: true
        },
        {
          labelOrderKey: "city",
          fieldValue: labelOrder.city,
          placeholder: "placeholders.city",
          type: "text",
          maxlength: "100",
          required: true
        },
        {
          labelOrderKey: "country",
          fieldValue: labelOrder.country,
          placeholder: "",
          type: "select",
          maxlength: "",
          required: false
        }
      ]
    },
    who: {
      paragraph: "paragraph.sendToWho",
      fields: [
        {
          labelOrderKey: "who",
          fieldValue: labelOrder.who,
          placeholder: "placeholders.name",
          type: "text",
          maxlength: "100",
          required: true
        },
        {
          labelOrderKey: "phone",
          fieldValue: labelOrder.phone,
          placeholder: "placeholders.phoneNumber",
          type: "phone",
          maxlength: "",
          required: false
        }
      ]
    },
    quantityLabels: {
      paragraph: null,
      fields: [
        {
          labelOrderKey: "quantity",
          fieldValue: labelOrder.quantity,
          placeholder: "",
          type: "number",
          maxlength: "",
          required: true
        }
      ]
    },
    note: {
      paragraph: null,
      fields: [
        {
          labelOrderKey: "comment",
          fieldValue: labelOrder.comment,
          placeholder: "",
          type: "textarea",
          maxlength: "",
          required: false
        }
      ]
    }
  };
};

const isLoading = ref(false);
const labelOrderForm: Ref<HTMLFormElement | null> = ref(null);

const currentCountry = computed(() => {
  return navigator.language.slice(-2);
});

const allCountries = computed((): Country[] => {
  const currentCountries = (messages.value[locale.value.toLocaleLowerCase()] as I18nMessage).list
    .countries;

  return Object.keys(currentCountries).map(country => ({
    value: country,
    label:
      currentCountries[country as keyof I18nMessage["list"]["countries"]].name
  }));
});

const postLabelOrder = async () => {
  formIsPosted.value = true;

  if (defaultLabelOrder.value.quantity <= 0) return;

  if (labelOrderForm.value && !labelOrderForm.value.reportValidity()) return;

  isLoading.value = true;

  const result = await postLabelOrderApi(defaultLabelOrder.value);
  isLoading.value = false;

  if (!result.success) return;

  emit("close");
  emit("refresh");
};

const emit = defineEmits(["refresh", "close"]);

onBeforeMount(() => {
  defaultLabelOrder.value.country = currentCountry.value.toLocaleUpperCase();
});
</script>
