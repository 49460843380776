<template>
  <div
    v-if="roomColumns && currentRoomToUpdate"
    class="row ms-0 me-0 table-row"
  >
    <template v-for="(columnValue, columnKey) in roomColumns" :key="columnKey">
      <div
        class="d-flex align-items-center ps-0"
        :class="[columnValue.columnStyling]"
      >
        <v-select
          v-if="columnKey === 'hus'"
          :key="String(showAddAddress)"
          :clearable="false"
          @option:selecting="emit('update', currentRoomToUpdate)"
          :options="projectAddresses"
          class="w-75"
          label="building"
          :placeholder="
            columnValue.inputSettings?.placeholder &&
            t(columnValue.inputSettings.placeholder)
          "
          :reduce="(option: AddressInformation) => option.id"
          v-model="currentRoomToUpdate.hus"
          :calculate-position="withPopper"
          append-to-body
        >
          <template
            #open-indicator="{ attributes }: { attributes: VueSelectSlotScope }"
          >
            <span v-bind="attributes">
              <img src="@/assets/img/drop-arrow.svg" />
            </span>
          </template>
          <template
            #option="{
              building,
              address
            }: {
              building: AddressInformation['building'],
              address: AddressInformation['address']
            }"
          >
            <div>
              {{ building }}
              <p style="font-weight: 400">
                {{ address.adress }}<br />{{ address.postnummer }},{{
                  address.ort
                }},{{ address.land }}
              </p>
            </div>
          </template>
          <template #list-footer>
            <div class="d-flex align-items-center px-3 py-2">
              <GreenifiedButton
                @click="showAddAddress = true"
                btnStyle="black"
                :text="t('button.addBuildingAddress')"
                size="thin"
              />
            </div> </template
        ></v-select>
        <div
          v-if="columnKey === 'new_room'"
          class="d-flex align-items-center justify-content-between w-100"
        >
          <GreenifiedCheckbox
            :trueValue="'1'"
            :falseValue="'0'"
            v-model="currentRoomToUpdate.new_room"
            @update:modelValue="emit('update', currentRoomToUpdate)"
            :size="'large'"
          />

          <GreenifiedButton
            btn-style="black"
            @click.prevent="emit('delete')"
            :text="t('button.remove')"
            size="thin"
          />
        </div>
        <InputField
          v-if="columnKey !== 'new_room' && columnKey !== 'hus'"
          v-model:field-value="currentRoomToUpdate[columnKey as keyof RoomObject]"
          @update:field-value="emit('update', currentRoomToUpdate)"
          :placeHolder="
            columnValue.inputSettings?.placeholder &&
            t(columnValue.inputSettings.placeholder)
          "
          :type="columnValue.inputSettings?.type"
          :maxlength="columnValue.inputSettings?.maxlength"
          :required="columnValue.inputSettings?.required"
        />
      </div>
    </template>
  </div>

  <ProjectAddressForm
    v-if="showAddAddress"
    @close="showAddAddress = false"
    :title="t('title.addAddress')"
    @refresh="
      showAddAddress = false;
      emit('refreshAddress');
    "
  />
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import { defineEmits, defineProps, ref, withDefaults } from "vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AddressInformation, RoomObject } from "../library/types/room/rooms";
import { withPopper } from "../modules/popperModule";
import { TableColumns } from "@/library/types/tables/columns";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { useI18n } from "vue-i18n";
import InputField from "./common/InputField.vue";
import GreenifiedCheckbox from "./common/GreenifiedCheckbox.vue";
import GreenifiedButton from "./common/GreenifiedButton.vue";
import ProjectAddressForm from "./ProjectAddressForm.vue";

type RoomHandlerLineProps = {
  roomToUpdate: RoomObject | null;
  roomColumns: TableColumns["columns"];
  projectAddresses: ProjectSettingsAdress[];
};

const { t } = useI18n();
const emit = defineEmits(["update", "refreshAddress", "delete"]);

const roomHandlerLineProps = withDefaults(defineProps<RoomHandlerLineProps>(), {
  roomToUpdate: null,

  projectAddresses: () => []
});

const currentRoomToUpdate = ref(roomHandlerLineProps.roomToUpdate);
const showAddAddress = ref(false);
</script>
