<template>
  <GreenifiedModal
    :title="t('title.shareURL')"
    type="response"
    @close="emit('close')"
  >
    <template #container>
      <p class="form-label-paragraph mb-3">
        {{ t("msg.shareURL") }}
      </p>
      <div style="position: relative" class="mb-3">
        <input
          disabled
          type="text"
          class="w-100 mt-2 pe-4"
          :value="getShareUrl"
        />
        <div>
          <img
            src="@/assets/img/copy.svg"
            height="20"
            @click="copy(getShareUrl)"
            style="
              cursor: pointer;
              position: absolute;
              right: 6px;
              top: calc(0.5rem + 7px);
            "
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import { computed, defineEmits } from "vue";
import { toast } from "vue3-toastify";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";

const { t } = useI18n();
const router = useRouter();
const store = useIndexStore();
const emit = defineEmits(["close"]);

const copy = (url: string) => {
  navigator.clipboard.writeText(url);
  toast(t("label.copied"), {
    autoClose: 2000,
    theme: "light",
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    closeButton: false
  });
};

const getShareUrl = computed(() => {
  const routeName = "guestInternalSell";
  const resolvedRoute = router.resolve({
    name: routeName,
    params: { token: store.currentProject.guest_token }
  });
  const routePath = resolvedRoute.href;
  return window.location.origin + routePath;
});
</script>
