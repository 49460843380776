import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-2"
}
const _hoisted_2 = { class: "d-flex w-100 justify-content-between text-box" }
const _hoisted_3 = { class: "cursor-pointer" }
const _hoisted_4 = { class: "cursor-pointer" }
const _hoisted_5 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_6 = { class: "d-flex cursor-pointer" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "dotted-vertical-line"
}

import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import {
  productAmountInCurrentStatus,
  SubRoute,
} from "@/library/helpers/sidebarButtons";
import { Quantities } from "@/services/api/helpersApi";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

type SidebarButtonProps = {
  routeName: string;
  buttonText: string;
  mainQuantities: Array<keyof Quantities>;
  isCompleted: boolean;
  mainHeader: number;
  isMainButton: boolean;
  subRoutes: SubRoute[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarButton',
  props: {
    routeName: { default: "" },
    buttonText: { default: "" },
    mainQuantities: { default: () => [] },
    isCompleted: { type: Boolean, default: false },
    mainHeader: { default: 0 },
    isMainButton: { type: Boolean, default: false },
    subRoutes: { default: () => [] }
  },
  setup(__props: any) {



const { t } = useI18n();
const { currentRoute } = useRouter();
const router = useRouter();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isMainButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "menu-tree-branch" }, [
            _createElementVNode("div")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex justify-content-between", _ctx.isMainButton ? 'col-12' : 'col-10 ps-0'])
    }, [
      _createVNode(GreenifiedButton, {
        btnStyle: 
        _unref(currentRoute).meta.mainHeader === _ctx.mainHeader ? 'black' : 'normal'
      ,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: _ctx.routeName })))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(_ctx.buttonText)), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(productAmountInCurrentStatus)(_ctx.mainQuantities)), 1)
          ])
        ]),
        _: 1
      }, 8, ["btnStyle"]),
      _createElementVNode("div", {
        class: _normalizeClass(["ms-2", _ctx.isCompleted ? 'done' : 'not-done'])
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "21",
          height: "15",
          viewBox: "0 0 21 15",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M1 6L8 13L20 1.5",
            stroke: "white",
            "stroke-width": "1.5"
          })
        ], -1)
      ]), 2)
    ], 2),
    (_unref(currentRoute).meta.mainHeader === _ctx.mainHeader && !_ctx.isMainButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subRoutes, (subRoute, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createElementVNode("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-25" }, null, -1)),
                _createElementVNode("div", {
                  class: "w-75 d-flex",
                  onClick: ($event: any) => (_unref(router).push({ name: subRoute.subRouteName }))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["me-3", 
              _unref(productAmountInCurrentStatus)(subRoute.subQuantities) === 0
                ? 'done-marker'
                : _unref(currentRoute).meta.subHeader === subRoute.subHeader
                ? 'checked-marker'
                : 'unchecked-marker'
            ])
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("div", null, null, -1)
                  ]), 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["align-items-center d-flex", 
              _unref(currentRoute).meta.subHeader === subRoute.subHeader
                ? 'fw-bold'
                : ''
            ])
                  }, _toDisplayString(_unref(t)(subRoute.subButtonText)), 3)
                ], 8, _hoisted_7)
              ]),
              (index !== _ctx.subRoutes.length - 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})