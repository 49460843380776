<template>
  <CustomSelect
    v-if="selectField"
    :key="String(localSelectFieldValues)"
    :withComponents="true"
    :required="selectField.required === 1 ? true : false"
    :id="selectField.key"
    :name="selectField.key"
    :options="sortedOptions(selectField.choices)"
    :reducedKey="'key'"
    label="value"
    :placeholder="
      selectField.placeholder.length
        ? selectField.placeholder
        : selectField.default_value
        ? String(selectField.default_value)
        : selectField.label
        ? selectField.label
        : ''
    "
    v-model="localSelectFieldValues"
    :appendToBody="true"
    @update:modelValue="(option: SelectOptions) => {
      emit('update:selectFieldValues', option)}"
    :clearable="selectField.required === 1 ? false : true"
    :showError="showError"
  />
</template>

<script lang="ts" setup>
import CustomSelect from "@/components/common/CustomSelect.vue";
import { FormFields } from "@/library/types/structure/formStructure";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SelectFieldType } from "@/library/types/structure/subfieldTypes";
import {
  withDefaults,
  defineProps,
  ref,
  defineEmits,
  onMounted,
  Ref
} from "vue";

export type SelectOptions = {
  key: string;
  value: string;
};

type SelectFieldProps = {
  selectField: SelectFieldType | null;
  selectFieldValues: FormFields["key"];
  showError?: boolean;
};

const selectFieldProps = withDefaults(defineProps<SelectFieldProps>(), {
  selectField: null,
  selectFieldValues: () => ({}),
  showError: false
});

const emit = defineEmits(["update:selectFieldValues"]);

const localSelectFieldValues: Ref<string | number | null> = ref(null);

const sortedOptions = (fieldChoices: {
  [key: string]: string;
}): SelectOptions[] => {
  const sortedEntries = Object.entries(fieldChoices).sort(([a], [b]) =>
    a.localeCompare(b)
  );
  const sortedArray = sortedEntries.map(([key, value]) => ({ key, value }));
  return sortedArray;
};

onMounted(() => {
  if (selectFieldProps.selectField?.default_value) {
    localSelectFieldValues.value = String(
      selectFieldProps.selectField.default_value
    );

    return;
  }

  if (
    typeof selectFieldProps.selectFieldValues === "string" ||
    typeof selectFieldProps.selectFieldValues === "number"
  ) {
    localSelectFieldValues.value = selectFieldProps.selectFieldValues;
    return;
  }

  localSelectFieldValues.value = null;
  return;
});
</script>
