<template>
  <div class="col-12 d-flex flex-row-reverse">
    <BreadCrumb
      v-if="
        Object.keys(currentFilterOptions.categories).length ||
        Object.keys(currentFilterOptions.intervals).length
      "
      :text="'label.clearFilter'"
      class="ms-3"
      @click="
        currentFilterOptions.categories = {};
        currentFilterOptions.intervals = {};
        emit('update:filterOptions', currentFilterOptions);
      "
    />

    <template
      v-for="(categoryValues, filterKey) in currentFilterOptions.categories"
      :key="filterKey"
    >
      <BreadCrumb
        class="ms-2"
        v-for="(categoryValue, index) in categoryValues"
        :key="index"
        :translate="translateHeaders.includes(String(filterKey))"
        :text="
          translateHeaders.includes(String(filterKey))
            ? `${filterKey}.${categoryValue.value}`
            : categoryValue.value
        "
        @click="
          () => {
            categoryValues.splice(index, 1);
            if (!categoryValues.length) {
              delete currentFilterOptions.categories[filterKey];
            }
            emit('update:filterOptions', currentFilterOptions);
          }
        "
      />
    </template>
    <template
      v-for="(interval, key) in currentFilterOptions.intervals"
      :key="interval.to"
    >
      <BreadCrumb
        :text="`${t(`label.${key}`)} ${interval.from ?? ''} - ${
          interval.to ?? ''
        }`"
        :translate="false"
        @click="
          currentFilterOptions.intervals = {};
          emit('update:filterOptions', currentFilterOptions);
        "
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { FilterOptions } from "@/library/types/filter/filter";
import { withDefaults, defineProps, ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import BreadCrumb from "./BreadCrumb.vue";

type FilterBreadcrumbsProps = {
  filterOptions: FilterOptions;
  translateHeaders?: string[];
};

const filterBreadcrumbsProps = withDefaults(
  defineProps<FilterBreadcrumbsProps>(),
  {
    translateHeaders: () => [],
    filterOptions: () => ({
      categories: {},
      intervals: {},
    }),
  },
);

const { t } = useI18n();
const currentFilterOptions = ref(filterBreadcrumbsProps.filterOptions);
const emit = defineEmits(["update:filterOptions"]);
</script>
