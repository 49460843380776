import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { RadioFieldType } from "@/library/types/structure/subfieldTypes";
import { ref } from "vue";

type MultipleRadioFieldProps = {
  presetMultipleRadioFieldValues: FormFields["key"];
  multipleRadioField: RadioFieldType | null;
  optionKey: string | number;
  option: string;
  checked: boolean;
  thinButton: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleRadioField',
  props: {
    presetMultipleRadioFieldValues: { default: () => ({}) },
    multipleRadioField: { default: null },
    optionKey: { default: "" },
    option: { default: "" },
    checked: { type: Boolean, default: false },
    thinButton: { type: Boolean, default: false }
  },
  emits: ["update:presetMultipleRadioFieldValues"],
  setup(__props: any, { emit: __emit }) {

const multipleRadioFieldProps = __props;

const emit = __emit;

const localMultipleRadioFieldValues = ref(
  multipleRadioFieldProps.presetMultipleRadioFieldValues,
);

const inputRadio = (key: string) => {
  localMultipleRadioFieldValues.value = key;
  emit("update:presetMultipleRadioFieldValues", localMultipleRadioFieldValues);
};

return (_ctx: any,_cache: any) => {
  return (_ctx.multipleRadioField)
    ? (_openBlock(), _createBlock(GreenifiedButton, {
        key: 0,
        inputType: "radio",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (inputRadio(String(_ctx.optionKey)))),
        text: _ctx.option,
        inputId: _ctx.multipleRadioField.key + '-' + _ctx.optionKey,
        inputName: _ctx.multipleRadioField.key,
        inputValue: _ctx.option,
        btnStyle: _ctx.checked ? 'black' : 'normal',
        size: _ctx.thinButton ? 'thin' : 'full',
        inputRequired: _ctx.multipleRadioField.required === 0 ? false : true
      }, null, 8, ["text", "inputId", "inputName", "inputValue", "btnStyle", "size", "inputRequired"]))
    : _createCommentVNode("", true)
}
}

})