<template>
  <GreenifiedModal type="x-small" :title="title" @close="emit('close')">
    <template #container>
      <form
        id="form-id"
        class="h-100 action-form"
        ref="addressForm"
        v-if="currentAddressInformation"
      >
        <div class="mb-3">
          <label class="form-label mb-2">
            {{ t("label.buildingName") }} *</label
          >
          <InputField
            required
            v-model:fieldValue="currentAddressInformation.building"
          />
        </div>
        <label class="form-label mb-2"> {{ t("label.address") }}</label>
        <GreenifiedForm
          v-model:formValues="currentAddressInformation.address"
          :formFields="greenifiedStructure"
          :hideTitle="true"
          ref="mainItemDesc"
          :includedFields="['adress', 'postnummer', 'ort', 'land', 'intro']"
          @input="(newAddress: NewAddress) => updateAddressFields(newAddress)"
        ></GreenifiedForm>
      </form>
    </template>
    <template #footer>
      <div class="row">
        <div class="col-5">
          <GreenifiedButton
            :disabled="isLoading"
            form="form-id"
            type="submit"
            btnStyle="green"
            @click="checkFormValidation()"
            :text="isLoading ? t('button.pleaseWait') : t('button.save')"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import GreenifiedForm from "@/components/form/GreenifiedForm.vue";
import { computed, withDefaults, ref, defineProps, defineEmits } from "vue";
import { AddressInformation, NewAddress } from "../library/types/room/rooms";
import { useIndexStore } from "@/store";
import GreenifiedModal from "./modal/GreenifiedModal.vue";
import GreenifiedButton from "./common/GreenifiedButton.vue";
import InputField from "./common/InputField.vue";
import { useI18n } from "vue-i18n";
import { postNewAddress, updateAddress } from "@/library/api/address";

type ProjectAddressFormProps = {
  title: string;
  existingAddressInformation?: AddressInformation;
};

const projectAddressFormProps = withDefaults(
  defineProps<ProjectAddressFormProps>(),
  {
    title: "",
    existingAddressInformation: () => ({
      address: {
        adress: "",
        postnummer: "",
        ort: "",
        land: ""
      },
      project: "",
      last_use: "",
      building: "",
      id: ""
    })
  }
);

const indexStore = useIndexStore();

const { t } = useI18n();
const isLoading = ref(false);
const showValidation = ref(false);
const currentAddressInformation = ref(
  projectAddressFormProps.existingAddressInformation
);

const greenifiedStructure = computed(() => {
  return indexStore.projectDetails.struc;
});

const addressForm = ref<HTMLFormElement | null>(null);

const updateAddressFields = (newAddress: NewAddress) => {
  if (!currentAddressInformation.value) return;

  currentAddressInformation.value.address[
    newAddress.key as keyof AddressInformation["address"]
  ] = newAddress.value;
  currentAddressInformation.value.address[
    newAddress.name as keyof AddressInformation["address"]
  ] = newAddress.value;
};

const updateExistingAddress = async () => {
  if (!currentAddressInformation.value) return;
  const result = await updateAddress(
    {
      building: currentAddressInformation.value.building,
      address: currentAddressInformation.value.address
    },
    currentAddressInformation.value.id
  );
  isLoading.value = false;
  if (!result.success) return;

  emit("refresh");
  emit("close");
};

const postAddress = async () => {
  const result = await postNewAddress({
    building: currentAddressInformation.value.building,
    address: currentAddressInformation.value.address
  });

  isLoading.value = false;

  if (!result.success) return;

  emit("refresh");
  emit("close");
};

const checkFormValidation = () => {
  if (addressForm.value && !addressForm.value.reportValidity()) {
    showValidation.value = true;
    return;
  }

  showValidation.value = false;
  isLoading.value = true;
  if (projectAddressFormProps.existingAddressInformation.id.length) {
    updateExistingAddress();
    return;
  }
  postAddress();
};

const emit = defineEmits(["refresh", "close"]);
</script>

<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
}
</style>
