import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "project-list-wrapper"
}

import BSidebar from "@/components/BSidebar.vue";
import ProjectList from "@/components/project/ProjectList.vue";
import {
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { SelectableProject } from "../../library/types/project/project";
import { getClientProjects } from "@/library/api/project";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/store/auth";

type SidebarProjectsProps = {
  openSidebar: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarProjects',
  props: {
    openSidebar: { type: Boolean, default: false }
  },
  emits: ["update:openSidebar"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const authStore = useAuthStore();

const sidebarProjectsProps = __props;

const activeProjects: Ref<SelectableProject[]> = ref([]);
const closedProjects: Ref<SelectableProject[]> = ref([]);
const expandedField = ref(0);
const isLoading = ref(false);

onMounted(() => {
  getProjects();
});

const getProjects = async () => {
  if (!authStore.isAuthenticated) return;
  isLoading.value = true;
  const result = await getClientProjects();
  isLoading.value = false;

  if (!result.success) return;

  result.result.forEach((project: SelectableProject) => {
    if (project.active === "1") {
      activeProjects.value.push(project);
    } else {
      closedProjects.value.push(project);
    }
  });
};

const emit = __emit;

watch(
  () => sidebarProjectsProps.openSidebar,
  toggleOpen => {
    emit("update:openSidebar", toggleOpen);
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BSidebar, {
    open: _ctx.openSidebar,
    bg: 'white',
    hideHeader: ""
  }, {
    default: _withCtx(() => [
      (!isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.openSidebar)
              ? (_openBlock(), _createBlock(ProjectList, {
                  key: 0,
                  projects: activeProjects.value,
                  projectType: 0,
                  title: _unref(t)('label.activeProjects'),
                  collapse: expandedField.value === 1,
                  onExpandedField: _cache[0] || (_cache[0] = (value) => (expandedField.value = value))
                }, null, 8, ["projects", "title", "collapse"]))
              : _createCommentVNode("", true),
            (_ctx.openSidebar)
              ? (_openBlock(), _createBlock(ProjectList, {
                  key: 1,
                  projectType: 1,
                  projects: closedProjects.value,
                  title: _unref(t)('label.completedProjects'),
                  collapse: expandedField.value === 0,
                  onExpandedField: _cache[1] || (_cache[1] = (value) => (expandedField.value = value))
                }, null, 8, ["projects", "title", "collapse"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})