import { useIndexStore } from "@/store";
import { ProductStatuses } from "../types/product/productHeader";
import { Quantities } from "@/services/api/helpersApi";

export const getQuantitiesByProductStatus = (
  productStatus: ProductStatuses,
): number => {
  const indexStore = useIndexStore();

  if (!indexStore.quantities) return 0;

  return indexStore.quantities[productStatus as keyof Quantities];
};
