import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-label-paragraph" }
const _hoisted_2 = { class: "row justify-content-end ms-0 me-0" }
const _hoisted_3 = { class: "col-4 ps-0 pe-0" }

import ProjectList from "@/components/project/ProjectList.vue";
import { onMounted, Ref, ref, watch } from "vue";
import { bolderFont } from "../library/helpers/typography";
import { useRouter } from "vue-router";
import { SelectableProject } from "@/library/types/project/project";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { getClientProjects } from "@/library/api/project";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectModal',
  setup(__props) {

const authStore = useAuthStore();
const store = useIndexStore();
const router = useRouter();

const { t } = useI18n();

onMounted(() => {
  getProjects();
});

const showInactiveModal = ref(
  store.currentProjectNumber.length && store.currentProject.active === "0"
);

const isLoading = ref(false);
const showProjectsModal = ref(true);
const userIsMissingActiveProjects = ref(false);
const activeProjects: Ref<SelectableProject[]> = ref([]);
const closedProjects: Ref<SelectableProject[]> = ref([]);
const expandedField = ref(0);

const navigateToOverview = () => {
  showProjectsModal.value = false;
  router.push({ name: "projectOverview" });
};

const getProjects = async () => {
  isLoading.value = true;

  const result = await getClientProjects();

  isLoading.value = false;
  if (!result.success) return;

  result.result.forEach((project: SelectableProject) => {
    if (project.active === "1") {
      activeProjects.value.push(project);
    } else {
      closedProjects.value.push(project);
    }
  });

  if (!activeProjects.value.length) {
    userIsMissingActiveProjects.value = true;
  }
};

const logout = async () => {
  authStore.logout();
};

watch(
  () => showProjectsModal.value,
  () => {
    setInterval(() => {
      showProjectsModal.value = true;
    });
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      title: _unref(t)('title.project'),
      type: 'small',
      noOuterClose: "",
      closable: false,
      isLoading: isLoading.value
    }, {
      modalHeaderContent: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.selectProject")), 1)
      ]),
      container: _withCtx(() => [
        _createVNode(ProjectList, {
          onProjectIsSelected: navigateToOverview,
          isExpandable: closedProjects.value.length ? true : false,
          projects: activeProjects.value,
          projectType: 0,
          title: _unref(t)('label.activeProjects'),
          accordionBg: '#f5f2eb',
          collapse: expandedField.value === 1,
          onExpandedField: _cache[0] || (_cache[0] = (fieldIndex) => (expandedField.value = fieldIndex)),
          onIsLoading: _cache[1] || (_cache[1] = (loading) => (isLoading.value = loading))
        }, null, 8, ["isExpandable", "projects", "title", "collapse"]),
        (closedProjects.value.length)
          ? (_openBlock(), _createBlock(ProjectList, {
              key: 0,
              projects: closedProjects.value,
              projectType: 1,
              title: _unref(t)('label.completedProjects'),
              accordionBg: '#f5f2eb',
              collapse: expandedField.value === 0,
              onExpandedField: _cache[2] || (_cache[2] = (fieldIndex) => (expandedField.value = fieldIndex))
            }, null, 8, ["projects", "title", "collapse"]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(GreenifiedButton, {
              text: _unref(t)('menu.signOut'),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (logout()))
            }, null, 8, ["text"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"]),
    (showInactiveModal.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: 'response',
          closable: false,
          title: _unref(t)('responseTitle.completedProject'),
          text: 
      _unref(t)('paragraph.completedProjectInfo', [
        _unref(bolderFont)(_unref(store).currentProject.namn)
      ])
    ,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showInactiveModal.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (userIsMissingActiveProjects.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: 'response',
          closable: false,
          title: _unref(t)('responseTitle.completedProject'),
          text: _unref(t)('paragraph.missingActiveProjectInfo'),
          declineText: _unref(t)('menu.signOut'),
          onConfirm: _cache[5] || (_cache[5] = ($event: any) => (logout()))
        }, null, 8, ["title", "text", "declineText"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})