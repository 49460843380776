import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "sorting-container"
}
const _hoisted_3 = { class: "sorting-option ellipsis cursor-pointer" }
const _hoisted_4 = { class: "sorting-option ellipsis cursor-pointer" }

import { FilterSortOption } from "../../library/types/filter/filter";
import {
  ref,
  computed,
  Ref,
} from "vue";
import { OnClickOutside } from "@vueuse/components";
import GreenifiedButton from "./GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import { SortingSettings } from "@/library/types/sorting/sorting";

export type SortingDropdownOption = {
  col: string;
  asc: boolean;
};

type SortingDropdownProps = {
  sortOptions: FilterSortOption[];
  sortingSettings: SortingSettings;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SortingDropdown',
  props: {
    sortOptions: { default: () => [] },
    sortingSettings: { default: () => ({ col: "", asc: true }) }
  },
  emits: ["setSort"],
  setup(__props: any, { emit: __emit }) {

const sortingDropdownProps = __props;

const { t } = useI18n();
const showSort = ref(false);
const currentSortingOptions = ref(sortingDropdownProps.sortOptions);
const currentSortingSettings = ref(sortingDropdownProps.sortingSettings);
const sortingHeader: Ref<HTMLElement | null> = ref(null);

const emit = __emit;

const getMaxWidth = computed(() => {
  if (!sortingHeader.value) return "100%";
  return sortingHeader.value.getBoundingClientRect().width;
});

const translatedOrderText = computed(() => {
  const currentSortOption = currentSortingOptions.value.find(
    option => currentSortingSettings.value.col === option.colValue.col,
  );
  if (currentSortOption) {
    return (
      t(currentSortOption.name) +
      " " +
      t(
        currentSortingSettings.value.asc
          ? currentSortOption.ascendingLocale
          : currentSortOption.descendingLocale,
      )
    );
  }
  return "";
});

const handleSortOption = (column: string, isAscending: boolean) => {
  showSort.value = false;
  currentSortingSettings.value = {
    col: column,
    asc: isAscending,
  };
  emit("setSort", currentSortingSettings.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(OnClickOutside), {
    onTrigger: _cache[1] || (_cache[1] = ($event: any) => (showSort.value = false)),
    key: currentSortingSettings.value.col
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "sortingHeader",
        ref: sortingHeader
      }, [
        _createVNode(GreenifiedButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showSort.value = !showSort.value)),
          btnStyle: 'yellow',
          textAlign: 'left',
          overflow: 'ellipsis',
          smallerPadding: ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("button.orderedBy")) + ": " + _toDisplayString(translatedOrderText.value ?? _unref(t)(`label.${currentSortingSettings.value.col}`)), 1)
          ]),
          _: 1
        })
      ], 512),
      (showSort.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentSortingOptions.value, (sortingOption) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sortingOption.name,
                class: "d-flex flex-column",
                style: _normalizeStyle(`width: ${getMaxWidth.value}px`)
              }, [
                _createVNode(GreenifiedButton, {
                  textAlign: 'left',
                  btnStyle: 'yellow',
                  size: 'auto',
                  smallerPadding: "",
                  onClick: ($event: any) => (handleSortOption(sortingOption.colValue.col, true))
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(`${_unref(t)(sortingOption.name)} ${_unref(t)(sortingOption.ascendingLocale)}`), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(GreenifiedButton, {
                  btnStyle: 'yellow',
                  textAlign: 'left',
                  size: 'auto',
                  smallerPadding: "",
                  onClick: ($event: any) => (handleSortOption(sortingOption.colValue.col, false))
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(`${_unref(t)(sortingOption.name)} ${_unref(t)(sortingOption.descendingLocale)}`), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ], 4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})