import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["type", "disabled", "form"]
const _hoisted_2 = { class: "text-start" }
const _hoisted_3 = ["for", "disabled"]
const _hoisted_4 = ["type", "true-value", "false-value", "required", "checked", "id", "name", "value"]

import { ButtonColor } from "@/library/types/tables/footer";
import { ButtonHTMLAttributes, ref } from "vue";

type GreenifiedButtonProps = {
  type?: ButtonHTMLAttributes["type"];
  btnStyle?: ButtonColor;
  size?: "full" | "wider" | "wide" | "medium" | "small" | "thin" | "auto";
  text?: string;
  disabled?: boolean;
  noWrap?: boolean;
  form?: string;
  smallerPadding?: boolean;
  inputType?: "button" | "checkbox" | "radio";
  inputTrueValue?: boolean | string | number;
  inputFalseValue?: boolean | string | number;
  inputRequired?: boolean;
  inputId?: string;
  inputName?: string;
  inputValue?: string;
  overflow?: "ellipsis" | null;
  textAlign?: "left" | "center";
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedButton',
  props: {
    type: { default: "button" },
    btnStyle: { default: "normal" },
    size: { default: "full" },
    text: { default: "Click here" },
    disabled: { type: Boolean, default: false },
    noWrap: { type: Boolean, default: false },
    form: { default: "" },
    smallerPadding: { type: Boolean, default: false },
    inputType: { default: "button" },
    inputTrueValue: { type: [Boolean, String, Number], default: true },
    inputFalseValue: { type: [Boolean, String, Number], default: false },
    inputRequired: { type: Boolean, default: false },
    inputId: { default: "" },
    inputName: { default: "" },
    inputValue: { default: "" },
    overflow: { default: null },
    textAlign: { default: "center" }
  },
  setup(__props: any) {

const greenifiedButtonProps = __props;

const selectedValue = ref("");

const selectOption = () => {
  selectedValue.value = greenifiedButtonProps.inputValue;
};

return (_ctx: any,_cache: any) => {
  return (_ctx.inputType === 'button' || _ctx.disabled)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["greenified-button text-tiny fw-bold", [
      _ctx.btnStyle,
      _ctx.size,
      _ctx.disabled && 'disabled',
      _ctx.noWrap && 'no-wrap',
      _ctx.smallerPadding && 'smaller-padding',
      _ctx.overflow && _ctx.overflow,
      _ctx.textAlign,
      !_ctx.disabled && 'cursor-pointer'
    ]]),
        type: _ctx.type,
        disabled: _ctx.disabled,
        form: _ctx.form
      }, [
        (_ctx.inputType !== 'button')
          ? _renderSlot(_ctx.$slots, "content", { key: 0 }, () => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1)
            ])
          : _renderSlot(_ctx.$slots, "content", { key: 1 }, () => [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ])
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["greenified-button d-flex align-items-center position-relative text-tiny fw-bold", [
      _ctx.btnStyle,
      _ctx.size,
      _ctx.disabled && 'disabled',
      _ctx.noWrap && 'no-wrap',
      _ctx.smallerPadding && 'smaller-padding',
      _ctx.overflow && _ctx.overflow,
      !_ctx.disabled && 'cursor-pointer'
    ]]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (selectOption()))
      }, [
        _createElementVNode("label", {
          for: _ctx.inputId,
          class: _normalizeClass(["greenified-label", !_ctx.disabled && 'cursor-pointer']),
          disabled: _ctx.disabled
        }, _toDisplayString(_ctx.text), 11, _hoisted_3),
        (!_ctx.disabled)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              class: "visually-hidden",
              type: _ctx.inputType,
              "true-value": _ctx.inputTrueValue,
              "false-value": _ctx.inputFalseValue,
              required: _ctx.inputRequired,
              checked: _ctx.btnStyle === 'black',
              id: _ctx.inputId,
              name: _ctx.inputName,
              value: _ctx.inputValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event))
            }, null, 8, _hoisted_4)), [
              [_vModelDynamic, selectedValue.value]
            ])
          : _createCommentVNode("", true)
      ], 2))
}
}

})