<template>
  <GreenifiedModal
    @close="emit('close')"
    :title="t('title.changeAction')"
    :type="'verification'"
    :text="t('msg.selectDifferentAction')"
  >
    <template #footer>
      <div class="row row-gap-2 ps-0 pe-0">
        <div
          class="col-4 pe-0"
          v-for="action in currentActions"
          :key="action.type"
        >
          <GreenifiedButton
            @click="emit('openAction', action.type)"
            :text="action.text"
            :btnStyle="'green'"
            smallerPadding
          />
        </div>

        <div class="col-4">
          <GreenifiedButton
            @click="emit('openAction', 'J')"
            :text="t('button.reset')"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

type ChangeActionModalProps = {
  actions: ProductStatuses[];
};

const changeActionModalProps = withDefaults(
  defineProps<ChangeActionModalProps>(),
  {
    actions: () => []
  }
);

const allActions: { type: ProductStatuses; text: string }[] = [
  {
    type: "S",
    text: t("button.sell")
  },
  {
    type: "K",
    text: t("button.recycle")
  },
  {
    type: "Å",
    text: t("button.repurpose")
  }
];

const currentActions = changeActionModalProps.actions
  ? allActions.filter(
      action => !changeActionModalProps.actions.includes(action.type)
    )
  : allActions;

const emit = defineEmits(["openAction", "close"]);
</script>

<style scoped>
.action-btn {
  width: 122px;
}
</style>
