import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-2 subheading-small" }
const _hoisted_5 = { class: "col text-small" }
const _hoisted_6 = { class: "row mt-2" }
const _hoisted_7 = { class: "col-2 subheading-small" }
const _hoisted_8 = { class: "col text-small" }
const _hoisted_9 = { class: "row align-items-start mt-2" }
const _hoisted_10 = { class: "col-2 subheading-small" }
const _hoisted_11 = { class: "col text-small" }
const _hoisted_12 = { class: "row mt-2" }
const _hoisted_13 = { class: "col-2 subheading-small" }
const _hoisted_14 = { class: "col text-small" }
const _hoisted_15 = { class: "row mt-2" }
const _hoisted_16 = { class: "col-2 subheading-small" }
const _hoisted_17 = { class: "col text-small" }
const _hoisted_18 = { class: "row mt-4" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "row mt-5" }
const _hoisted_21 = { class: "col-12 d-flex" }

import { onMounted, Ref, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  getLabelOrderFile,
  getOrderVerification,
  postDeniedLabelOrder,
  postGrantedLabelOrder
} from "@/library/api/labels";
import { useI18n } from "vue-i18n";
import { LabelOrder } from "@/library/types/labels/labels";
import MainFilter from "@/components/filter/MainFilter.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminTokenLabelOrderView',
  setup(__props) {

const { t, locale } = useI18n();
const router = useRouter();

const route = useRoute();
const showError = ref(false);
const showSuccess = ref(false);
const showDenySuccess = ref(false);
const labelOrder: Ref<LabelOrder> = ref({
  vem: "",
  telefonnummer: "",
  adress: "",
  postnr: "",
  postadr: "",
  land: "",
  created_at: "",
  antal: ""
});
const isLoading = ref(true);

const verifyLabelOrder = async () => {
  const result = await getOrderVerification(route.params.token as string);

  isLoading.value = false;
  if (!result.success) return router.push({ name: "notFound" });

  labelOrder.value = result.result;
};

const downloadFile = async () => {
  const result = await getLabelOrderFile(route.params.token as string);

  if (!result.success) return;

  let blob = new Blob([result.result], {
    type: "text/tab-separated-values'"
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "Labels.txt";
  link.click();
};

const denyLabelOrder = async () => {
  const result = await postDeniedLabelOrder(route.params.token as string);

  if (!result.success) {
    showDenySuccess.value = false;
    return;
  }

  showDenySuccess.value = true;
};

const acceptLabelOrder = async () => {
  const result = await postGrantedLabelOrder(route.params.token as string);

  if (!result.success) {
    showError.value = true;
    return;
  }

  showSuccess.value = true;
};
const getLocaleTime = (createdAtDate: string) => {
  const utcDate = new Date(createdAtDate);

  const userTimezoneOffset = new Date().getTimezoneOffset();

  const userDate = new Date(utcDate.getTime() + userTimezoneOffset * 60000);

  const formattedDate = userDate.toLocaleString(locale.value.toLocaleLowerCase());

  return formattedDate;
};

onMounted(() => {
  verifyLabelOrder();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(MainFilter, {
              filterTitle: _unref(t)('title.labelOrder'),
              filterText: _unref(t)('paragraph.acceptLabelOrder'),
              hideFilterOptions: ""
            }, null, 8, ["filterTitle", "filterText"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)("label.who")), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(labelOrder.value.vem), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)("label.phoneNumber")), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(labelOrder.value.telefonnummer), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)("label.address")), 1),
              _createElementVNode("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(labelOrder.value.adress) + " ", 1),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString((
              labelOrder.value.postnr +
              ", " +
              labelOrder.value.postadr +
              ", " +
              labelOrder.value.land
            )
              .replace(",,", ",")
              .trim()), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(t)("label.orderDate")), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(getLocaleTime(labelOrder.value.created_at)), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)("label.quantityOrdered")), 1),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(t)("measure.label", [labelOrder.value.antal])), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(GreenifiedButton, {
                  size: "thin",
                  onClick: downloadFile,
                  text: _unref(t)('button.download')
                }, null, 8, ["text"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(GreenifiedButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (acceptLabelOrder())),
                  text: _unref(t)('button.acceptLabelOrder'),
                  size: "thin",
                  btnStyle: "green",
                  class: "me-3"
                }, null, 8, ["text"]),
                _createVNode(GreenifiedButton, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (denyLabelOrder())),
                  text: _unref(t)('button.denyLabelOrder'),
                  size: "thin",
                  btnStyle: "black"
                }, null, 8, ["text"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (showError.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.labelOrderError'),
          text: _unref(t)('msg.labelOrderError'),
          onClose: _cache[2] || (_cache[2] = ($event: any) => (showError.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (showSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 2,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.orderAccepted'),
          text: _unref(t)('msg.labelOrderAccepted'),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push({ name: 'notFound' })))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (showDenySuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 3,
          type: "response",
          closable: false,
          title: _unref(t)('responseTitle.orderRejected'),
          text: _unref(t)('msg.labelOrderDenied'),
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push({ name: 'notFound' })))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})