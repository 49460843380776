import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { FormFields } from "@/library/types/structure/formStructure";
import { RadioFieldType } from "@/library/types/structure/subfieldTypes";
import { ref } from "vue";

type RadioFieldProps = {
  option: string;
  optionKey: string | number;
  presetRadioFieldValues: FormFields["key"];
  radioField: RadioFieldType | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioField',
  props: {
    option: { default: "" },
    optionKey: { default: "" },
    presetRadioFieldValues: { default: () => ({}) },
    radioField: { default: null }
  },
  emits: ["update:presetRadioFieldValues"],
  setup(__props: any, { emit: __emit }) {

const radioFieldProps = __props;

const emit = __emit;

const localRadioFieldValues = ref(radioFieldProps.presetRadioFieldValues);

const inputRadio = (key: string) => {
  localRadioFieldValues.value = key;
  emit("update:presetRadioFieldValues", localRadioFieldValues);
};

return (_ctx: any,_cache: any) => {
  return (_ctx.radioField)
    ? (_openBlock(), _createBlock(GreenifiedButton, {
        key: 0,
        inputType: "radio",
        inputName: _ctx.radioField.key,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (inputRadio(String(_ctx.optionKey)))),
        text: 
      localRadioFieldValues.value && String(localRadioFieldValues.value).includes(_ctx.option)
        ? 'checked'
        : ''
    ,
        inputValue: _ctx.option,
        inputRequired: _ctx.radioField.required === 0 ? false : true,
        inputId: _ctx.radioField.key + '-' + _ctx.optionKey
      }, null, 8, ["inputName", "text", "inputValue", "inputRequired", "inputId"]))
    : _createCommentVNode("", true)
}
}

})