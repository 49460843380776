import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-small" }
const _hoisted_2 = { class: "row p-0" }
const _hoisted_3 = { class: "col-5" }

import { onMounted, Ref, ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { deleteAddress, getAddresses } from "@/library/api/address";
import { AddressInformation, RoomObject } from "@/library/types/room/rooms";
import RemoveAddressModal from "./RemoveAddressModal.vue";
import ProjectAddressForm from "../ProjectAddressForm.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
import { useIndexStore } from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { t } = useI18n();
const addresses: Ref<ProjectSettingsAdress[]> = ref([]);
const tableColumns = (
  adress: ProjectSettingsAdress
): TableColumns["columns"] => {
  return {
    building: {
      value: adress.building,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      selectOptions: [],
      isEditable: false,
      translate: false,
      headerText: "tableTitle.building",
      inputSettings: null
    },
    address: {
      value: adress.address.adress,
      columnStyling: "col-4",
      actions: [],
      tooltip: null,
      inputSettings: null,
      selectOptions: [],
      isEditable: false,
      translate: false,
      isSortable: false,
      headerText: "tableTitle.address"
    },
    edit: {
      value: "",
      columnStyling: "col-4",
      actions: ["edit"],
      selectOptions: [],
      isEditable: false,
      translate: false,
      tooltip: null,
      inputSettings: null,
      isSortable: false,
      headerText: ""
    }
  };
};
const isLoading = ref(false);
const addressToDelete: Ref<AddressInformation["id"]> = ref("");
const addressRooms: Ref<RoomObject[]> = ref([]);
const verifyDeleteAddress = ref(false);
const addressToEdit: Ref<AddressInformation> = ref({
  address: {},
  project: "",
  last_use: "",
  building: "",
  id: ""
});
const showAddAddress = ref(false);

const getProjectAddresses = async () => {
  isLoading.value = true;
  addressToDelete.value = "";
  addressRooms.value = [];

  const result = await getAddresses();

  isLoading.value = false;
  if (!result.success) return;

  addresses.value = result.result;
};

const getAddressName = (addressId: string) => {
  return addresses.value.find(address => address.id === addressId);
};

const removeAddress = async (id: string) => {
  isLoading.value = true;
  const result = await deleteAddress(id);

  if (!result.success) {
    isLoading.value = true;
    return;
  }

  getProjectAddresses();
};

onMounted(() => {
  getProjectAddresses();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(GreenifiedModal, {
      onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
      type: "smaller",
      title: _unref(t)('title.addresses'),
      isLoading: isLoading.value,
      "header-spacing": false,
      key: String(addresses.value)
    }, {
      modalHeaderContent: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.addresses")), 1),
        (addresses.value.length)
          ? (_openBlock(), _createBlock(TableHeaderRow, {
              key: 0,
              tableHeaders: tableColumns(addresses.value[0])
            }, null, 8, ["tableHeaders"]))
          : _createCommentVNode("", true)
      ]),
      container: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(addresses.value, (address) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "border-bottom",
            key: address.id
          }, [
            _createVNode(MainTableRow, {
              "table-columns": tableColumns(address),
              type: "other",
              onDelete: 
            () => {
              addressToDelete.value = address.id;
              address.rooms.length
                ? (addressRooms.value = address.rooms)
                : (verifyDeleteAddress.value = true);
            }
          ,
              onEditAddress: ($event: any) => {
            addressToEdit.value = {
              address: address.address,
              project: address.project,
              last_use: address.last_use,
              building: address.building,
              id: address.id
            };
            showAddAddress.value = true;
          }
            }, null, 8, ["table-columns", "onDelete", "onEditAddress"])
          ]))
        }), 128))
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(GreenifiedButton, {
              "btn-style": "green",
              text: _unref(t)('button.add'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => {
              addressToEdit.value = {
                address: {
                  adress: '',
                  postnummer: '',
                  ort: '',
                  land: _unref(useIndexStore)().currentProject.land,
                  [_unref(getProjectStructureFieldKey)('land')]:
                    _unref(useIndexStore)().currentProject.land
                },
                project: '',
                last_use: '',
                building: '',
                id: ''
              };
              showAddAddress.value = true;
            })
            }, null, 8, ["text"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"])),
    (verifyDeleteAddress.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "verification",
          closable: false,
          title: _unref(t)('title.removeAddress'),
          text: 
      _unref(t)('msg.verifyRemove', [getAddressName(addressToDelete.value)?.address.adress])
    ,
          onClose: _cache[2] || (_cache[2] = ($event: any) => {
      verifyDeleteAddress.value = false;
      addressToDelete.value = '';
    }),
          onConfirm: _cache[3] || (_cache[3] = ($event: any) => {
      removeAddress(addressToDelete.value);
      verifyDeleteAddress.value = false;
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (showAddAddress.value && addressToEdit.value)
      ? (_openBlock(), _createBlock(ProjectAddressForm, {
          key: 1,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showAddAddress.value = false)),
          title: 
      addressToEdit.value.id.length ? _unref(t)('title.editAddress') : _unref(t)('title.addAddress')
    ,
          onRefresh: _cache[5] || (_cache[5] = ($event: any) => {
      showAddAddress.value = false;
      getProjectAddresses();
    }),
          existingAddressInformation: addressToEdit.value
        }, null, 8, ["title", "existingAddressInformation"]))
      : _createCommentVNode("", true),
    (verifyDeleteAddress.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 2,
          type: "verification",
          closable: false,
          title: _unref(t)('title.removeAddress'),
          text: 
      _unref(t)('msg.verifyRemove', [getAddressName(addressToDelete.value)?.address.adress])
    ,
          onClose: _cache[6] || (_cache[6] = ($event: any) => {
      verifyDeleteAddress.value = false;
      addressToDelete.value = '';
    }),
          onConfirm: _cache[7] || (_cache[7] = ($event: any) => {
      removeAddress(addressToDelete.value);
      verifyDeleteAddress.value = false;
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (addressRooms.value.length)
      ? (_openBlock(), _createBlock(RemoveAddressModal, {
          key: 3,
          addressRooms: addressRooms.value,
          addresses: addresses.value,
          addressToDelete: addressToDelete.value,
          onClose: _cache[8] || (_cache[8] = ($event: any) => (addressRooms.value = [])),
          onRemoveAddress: _cache[9] || (_cache[9] = address => removeAddress(address))
        }, null, 8, ["addressRooms", "addresses", "addressToDelete"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})