<template>
  <CustomSelect
    v-if="taxonomyField"
    :required="taxonomyField.required === 1 ? true : false"
    :showError="showError"
    :id="taxonomyField.key"
    :name="taxonomyField.key"
    :options="
      indexStore.currentProject.product_cat.filter(option =>
        indexStore.currentProject.sprak !== 'fi'
          ? taxonomyField?.allow_terms.includes(option.id)
          : taxonomyField?.allow_terms.includes(option.sv_id) &&
            option.locale === 'fi',
      )
    "
    reducedKey="id"
    :withComponents="true"
    :appendToBody="true"
    label="namn"
    :placeholder="taxonomyField.placeholder"
    v-model="localTaxonomyFieldValues"
    :clearable="taxonomyField.required === 1 ? false : true"
    @update:modelValue="(option: ProjectCategory) => {
      emit('update:taxonomyFieldValues', option)}"
  />
</template>

<script lang="ts" setup>
import CustomSelect from "@/components/common/CustomSelect.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ProjectCategory } from "@/library/types/project/project";
import { FormFields } from "@/library/types/structure/formStructure";
import { TaxonomyTermsFieldType } from "@/library/types/structure/subfieldTypes";
import { useIndexStore } from "@/store";
import { withDefaults, defineProps, ref, defineEmits } from "vue";

const indexStore = useIndexStore();

type TaxonomyFieldProps = {
  taxonomyField: TaxonomyTermsFieldType | null;
  taxonomyFieldValues: FormFields["key"];
  showError?: boolean;
};

const taxonomyFieldProps = withDefaults(defineProps<TaxonomyFieldProps>(), {
  taxonomyField: null,
  taxonomyFieldValues: () => ({}),
  showError: false,
});

const emit = defineEmits(["update:taxonomyFieldValues"]);

const localTaxonomyFieldValues = ref(
  typeof taxonomyFieldProps.taxonomyFieldValues === "string"
    ? taxonomyFieldProps.taxonomyFieldValues
    : "",
);
</script>
