import { AxiosResponse } from "axios";
import api from "../api";

export type SearchedSupplier = {
  levkod: string;
  varumarke: string;
  status?: number;
};

export default {
  getSuppliers(search: string): Promise<AxiosResponse<SearchedSupplier[]>> {
    return api().get("/api/supplier?search=" + search);
  },

  postNewSupplier(levkod: string, projectnr: string): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectnr}/supplier`,
      JSON.stringify({ levkod, projectnr }),
    );
  },
};
