import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12 d-flex justify-content-end" }

import {
  ref,
  Ref,
  computed
} from "vue";
import {
  ProductItem,
  ProductItemInformation
} from "../../library/types/product/productItem";
import { updateStatus } from "@/library/api/products";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import { ProductInformation } from "@/library/types/product/productGroup";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { bolderFont } from "@/library/helpers/typography";

type RemoveProductsModalProps = {
  productGroup: ProductInformation | null;
  productName: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RemoveProductsModal',
  props: {
    productGroup: { default: null },
    productName: { default: "" }
  },
  emits: ["close", "delete"],
  setup(__props: any, { emit: __emit }) {

const removeProductsModalProps = __props;
const { t } = useI18n();

const showDeleteVerification = ref(false);
const productsToDelete: Ref<
  { id: ProductItem["id"]; comp_id: ProductItem["comp_id"] }[]
> = ref([]);
const currentProductGroup = ref(removeProductsModalProps.productGroup);
const showSuccess = ref(false);

const tableColumns = (
  productItem: ProductItemInformation,
  productGroup: ProductInformation
): TableColumns["columns"] => {
  return {
    comp_id: {
      value: productItem.comp_id,
      columnStyling: "col-4",
      tooltip: null,
      actions: ["checkbox"],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "ID",
      inputSettings: null,
      selectOptions: []
    },
    productName: {
      value: removeProductsModalProps.productName,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "label.benamn",
      inputSettings: null,
      selectOptions: []
    },
    category: {
      value:
        productGroup.category.substring(0, 2) === "ex"
          ? t("label.unkownSupplier")
          : t(`kategori_id.${productGroup.category}`),
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "label.kategori_id",
      inputSettings: null,
      selectOptions: []
    }
  };
};

const sortedItems = computed(() => {
  if (!currentProductGroup.value) return [];

  return currentProductGroup.value.same_products.slice().sort((a, b) => {
    return a.comp_id - b.comp_id;
  });
});

const selectProduct = (productItem: ProductItemInformation) => {
  const productItemIndex = productsToDelete.value.findIndex(
    product => product.comp_id === productItem.comp_id
  );
  if (productItemIndex !== -1) {
    productsToDelete.value.splice(productItemIndex, 1);
    return;
  }

  productsToDelete.value.push({
    comp_id: productItem.comp_id,
    id: productItem.id
  });
  return;
};

const selectAllProducts = (
  isToggled: boolean,
  productGroup: ProductInformation
) => {
  if (!isToggled) return [];

  return productGroup.same_products.map(product => {
    return {
      id: product.id,
      comp_id: product.comp_id
    };
  });
};

const removeProduct = async () => {
  if (!currentProductGroup.value) return;

  const productIds = [];
  for (let i = 0; i < productsToDelete.value.length; i++) {
    const productToDelete = productsToDelete.value[i];
    productIds.push(productToDelete.id);
    currentProductGroup.value.same_products =
      currentProductGroup.value.same_products.filter(
        product => product.id !== productToDelete.id
      );
  }

  const result = await updateStatus(productIds, "N");
  if (!result.success) return;
  showDeleteVerification.value = false;
  showSuccess.value = true;
};

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
      title: _unref(t)('title.removeProduct'),
      type: 'smaller'
    }, _createSlots({
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(GreenifiedButton, {
            disabled: !productsToDelete.value.length,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (showDeleteVerification.value = true)),
            text: _unref(t)('label.remove'),
            size: "thin"
          }, null, 8, ["disabled", "text"])
        ])
      ]),
      _: 2
    }, [
      (currentProductGroup.value)
        ? {
            name: "container",
            fn: _withCtx(() => [
              _createVNode(TableHeaderRow, {
                tableHeaders: tableColumns(sortedItems.value[0], currentProductGroup.value),
                "onUpdate:toggleCheckbox": _cache[0] || (_cache[0] = 
          isToggled => {
            if (!currentProductGroup.value) return;
            productsToDelete.value = selectAllProducts(
              isToggled,
              currentProductGroup.value
            );
          }
        ),
                toggleCheckbox: productsToDelete.value.length === sortedItems.value.length
              }, null, 8, ["tableHeaders", "toggleCheckbox"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedItems.value, (productItem, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "border-bottom",
                  key: index
                }, [
                  _createVNode(MainTableRow, {
                    tableColumns: tableColumns(productItem, currentProductGroup.value),
                    showCheckbox: true,
                    type: 'other',
                    onSelectRow: ($event: any) => (selectProduct(productItem)),
                    rowIsChecked: 
            productsToDelete.value.some(
              product => product.comp_id === productItem.comp_id
            )
          
                  }, null, 8, ["tableColumns", "onSelectRow", "rowIsChecked"])
                ]))
              }), 128))
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title"]),
    (showSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          closable: false,
          title: _unref(t)('title.removeProduct'),
          text: _unref(t)('paragraph.productHasBeenRemoved'),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (emit('delete')))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (showDeleteVerification.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: "verification",
          closable: false,
          title: _unref(t)('title.removeProduct'),
          text: 
      productsToDelete.value.length > 1
        ? _unref(t)('msg.verifyProductsDelete', [
            productsToDelete.value.length,
            _unref(bolderFont)(_ctx.productName)
          ])
        : _unref(t)('msg.verifyProductDelete', [
            _unref(bolderFont)(_ctx.productName),
            _unref(bolderFont)(String(productsToDelete.value[0].comp_id))
          ])
    ,
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => (removeProduct())),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (showDeleteVerification.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})