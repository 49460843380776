export const defaultExcludedFields = [
  "miljomarkningar",
  "intro",
  "skick_super",
  "antal",
  "saljs_styckvis_eller_i_grupp",
  "ange-antal",
  "pris",
  "internt",
  "butiksägare",
  "emballage",
  "packaging",
  "product_labels_for_admin",
  "notering",
  "bild_5",
  "bild_4",
  "bild_3",
  "bild_2",
  "bild_1",
];
