<template>
  <GreenifiedModal
    v-if="noRecondition"
    :title="t('label.moveKeepCondition')"
    :type="'x-small'"
    :text="t('paragraph.moveKeepConditionForm')"
    @decline="emit('closeModal')"
    @close="emit('closeModal')"
    @confirm="updateActions()"
    :confirmText="t('button.save')"
    :isLoading="isLoading"
  >
    <template #container>
      <div class="row pb-1">
        <div class="col text-small">
          <p>{{ t("paragraph.moveKeepConditionForm") }}</p>
          <div class="mt-3">
            <SelectRoom v-model="reconditionInformation.to_room" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="col-12 d-flex">
        <GreenifiedButton
          :text="t('button.save')"
          size="thin"
          @click="updateActions()"
        />
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-else
    @close="emit('closeModal')"
    :title="t('title.selectRecondition')"
    :type="'small'"
    :isLoading="isLoading"
  >
    <template #container>
      <div>
        <p class="form-label-paragraph mb-3">
          {{ t("paragraph.reconditionForm", { count: productIds.length }) }}
        </p>
        <form
          ref="reconditionForm"
          @submit.prevent
          class="action-form h-100 d-flex flex-column justify-content-between"
          v-if="!isLoading"
        >
          <div
            class="row mb-3"
            v-for="(rec, key) in translatedReconditions"
            :key="rec.name"
          >
            <div class="col-1">
              <input
                type="checkbox"
                :value="reconditionInformation.recondition[key]"
                @input="
                  () => {
                    if (reconditionInformation.recondition[key]) {
                      delete reconditionInformation.recondition[key];
                    } else {
                      reconditionInformation.recondition[key] = {
                        text: '',
                        key: key
                      };
                    }
                  }
                "
                class="small-checkbox mt-1"
              />
            </div>
            <div class="col-11">
              <label class="form-label">{{ rec.name }}</label>
              <p class="form-label-paragraph">{{ rec.desc }}</p>
              <InputField
                v-if="reconditionInformation.recondition[key]"
                type="text"
                class="mt-2"
                required
                maxlength="500"
                :placeHolder="t('placeholders.reconditionInfo')"
                v-model:fieldValue="
                  reconditionInformation.recondition[key].text
                "
              />
            </div>
          </div>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-11">
              <label class="form-label">{{ t("label.possibleComment") }}</label>
              <InputTextField
                v-model="reconditionInformation.comment"
                :rows="2"
              />
            </div>
          </div>

          <div class="container-fluid p-0">
            <div class="row">
              <div class="col">
                <label class="form-label mt-4">{{
                  moveTitle ?? t("label.moveProduct")
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="form-label-paragraph mb-3">
                  {{ moveText ?? t("paragraph.moveRecondtionForm") }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <SelectRoom v-model="reconditionInformation.to_room" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <template #footer>
      <div class="row justify-content-end">
        <div class="col-3">
          <GreenifiedButton :text="t('button.save')" @click="updateActions()" />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-if="showSuccess"
    type="response"
    :closable="false"
    :title="
      noRecondition
        ? t('responseTitle.keptInCurrentCondition')
        : t('responseTitle.reconditionRegistered')
    "
    :text="
      noRecondition
        ? `${t('paragraph.productsKeptInCurrentCondition2')} ${bolderFont(
            t('title.repurposeOverview')
          )}`
        : `${t('paragraph.reconditionIsRegistered2')} ${bolderFont(
            t('title.recondition')
          )}`
    "
    @close="
      emit('refresh');
      showSuccess = false;
    "
  />
</template>

<script setup lang="ts">
import SelectRoom from "../SelectRoom.vue";
import {
  withDefaults,
  defineProps,
  ref,
  Ref,
  defineEmits,
  computed
} from "vue";
import { ReconditionUpdate } from "../../services/api/productsApi";
import { ProductItem } from "../../library/types/product/productItem";
import { I18nMessage } from "../../library/types/locales/i18nMessage";
import { bolderFont } from "../../library/helpers/typography";
import { useI18n } from "vue-i18n";
import { updateRecondition } from "@/library/api/products";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import InputTextField from "../common/InputTextField.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import InputField from "../common/InputField.vue";

const { messages, locale } = useI18n();

const emit = defineEmits(["closeModal", "refresh"]);

type ProductRenovationFormProps = {
  isValidated: boolean;
  productIds: ProductItem["id"][];
  noRecondition: boolean;
  moveText: string;
  moveTitle: string;
};

const productRenovationFormProps = withDefaults(
  defineProps<ProductRenovationFormProps>(),
  {
    isValidated: false,
    productIds: () => [],
    noRecondition: false,
    moveText: "",
    moveTitle: ""
  }
);
const { t } = useI18n();
const showSuccess = ref(false);
const isLoading = ref(false);
const reconditionInformation: Ref<ReconditionUpdate> = ref({
  comment: null,
  to_room: null,
  recondition: {}
});
const reconditionForm: Ref<HTMLFormElement | null> = ref(null);

const translatedReconditions = computed(() => {
  return (messages.value[locale.value.toLocaleLowerCase()] as I18nMessage).list.reconditions;
});

const updateActions = async () => {
  if (
    productRenovationFormProps.isValidated &&
    reconditionForm.value &&
    !reconditionForm.value.reportValidity()
  ) {
    return;
  }
  isLoading.value = true;

  const result = await updateRecondition(
    productRenovationFormProps.productIds,
    reconditionInformation.value
  );

  isLoading.value = false;
  if (!result.success) return;
  showSuccess.value = true;
};
</script>

<style scoped>
.action-form-input {
  width: 100%;
}

.action-form {
  overflow-y: hidden;
  font-size: bold;
}
</style>
