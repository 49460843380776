<template>
  <GreenifiedModal
    :title="t('title.removeRoom')"
    @close="emit('close')"
    :type="'smaller'"
  >
    <template #container>
      <div>
        <p class="form-label-paragraph mb-2">
          {{ t("paragraph.removeRoomProductInformation") }}
        </p>
        <template
          v-for="(productHeader, value, index) in productsInDeletedRoom"
          :key="index"
        >
          <TableHeaderRow
            v-if="index === 0"
            :tableHeaders="
              getMainTableColumnsByTableType(productHeader.group, 'other')
                .columns
            "
          />
          <div class="border-bottom">
            <MainTableRow
              thinRows
              :tableColumns="
                getMainTableColumnsByTableType(productHeader.group, 'other')
                  .columns
              "
              :rowImage="productHeader.items[0].images?.bild_1?.url ?? ''"
              :type="'other'"
            />
          </div>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="col-8">
        <SelectRoom
          :isReduced="false"
          v-model="newLocation"
          :excludedRoom="roomToDelete ? roomToDelete.rum : ''"
          @update:modelValue="showMoveVerification = true"
          :placeholder="t('placeholders.selectRoomForProducts')"
        />
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    type="verification"
    :closable="false"
    v-if="showMoveVerification"
    :title="t('label.moveProductAndRemove')"
    :text="
      roomToDelete && newLocation
        ? t('msg.verifyMoveAndRemove', [
            roomToDelete.rumsnamn,
            newLocation.rumsnamn,
            roomToDelete.rumsnamn
          ])
        : ''
    "
    @close="
      showMoveVerification = false;
      newLocation = {
        rum: '',
        rumsnamn: '',
        rumskod: '',
        van: '',
        hus: '',
        enhet: null,
        new_room: '0'
      };
    "
    @confirm="
      () => {
        moveProductsOnDelete();
        showMoveVerification = false;
      }
    "
  />
</template>

<script lang="ts" setup>
import { withDefaults, defineProps, defineEmits, ref, Ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { ProductHeaderSale } from "@/library/types/product/productHeader";
import SelectRoom from "../SelectRoom.vue";
import { RoomObject } from "@/library/types/room/rooms";
import { postNewProductRoomOnDelete } from "@/library/api/moveProducts";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { getMainTableColumnsByTableType } from "@/library/helpers/structureTableColumns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";

type ProductsInDeletedRoomModalProps = {
  productsInDeletedRoom: ProductHeaderSale;
  roomToDelete: RoomObject | null;
};

const productsInDeletedRoomModalProps = withDefaults(
  defineProps<ProductsInDeletedRoomModalProps>(),
  {
    productsInDeletedRoom: () => ({}),
    roomToDelete: null
  }
);

const { t } = useI18n();
const emit = defineEmits(["close", "removeRoom"]);

const showMoveVerification = ref(false);
const newLocation: Ref<RoomObject> = ref({
  rum: "",
  rumsnamn: "",
  rumskod: "",
  van: "",
  hus: "",
  enhet: null,
  new_room: "0"
});

const moveProductsOnDelete = async () => {
  if (!productsInDeletedRoomModalProps.roomToDelete) return;
  if (!newLocation.value) return;

  const result = await postNewProductRoomOnDelete(
    productsInDeletedRoomModalProps.roomToDelete.rum,
    newLocation.value.rum
  );

  showMoveVerification.value = false;

  if (!result.success) return;
  emit("close");
  emit("removeRoom");
};
</script>
