<template>
  <section v-if="open2" class="hamb-menu-wrapper" @click="closeMenu()">
    <div
      :class="isExpanded ? 'expanded-container' : 'collapsed-container'"
      class="sidebar-container"
      :style="'background-color:' + bg"
      @click.stop
    >
      <div class="hamb-menu-header" v-if="!hideHeader">
        <slot name="header"></slot>
      </div>
      <div class="hamb-menu-content w-100" v-if="isExpanded">
        <slot></slot>
      </div>
      <div class="hamb-menu-footer" v-if="isExpanded">
        <slot name="footer"></slot>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  withDefaults,
  defineProps,
  defineEmits,
  ref,
  onMounted,
  watch,
} from "vue";

type BSidebarProps = {
  open: boolean;
  bg: string;
  hideHeader: boolean;
};

const bSidebarProps = withDefaults(defineProps<BSidebarProps>(), {
  open: false,
  bg: "#f5f2eb",
  hideHeader: false,
});

const emit = defineEmits(["update:modelValue", "update:open"]);

let isExpanded = ref(false);
let open2 = ref(false);

const closeMenu = () => {
  isExpanded.value = false;
  emit("update:open", false);
  window.setTimeout(() => {
    open2.value = false;
  }, 200);
};

const openMenu = () => {
  open2.value = true;
  window.setTimeout(() => {
    isExpanded.value = true;
  }, 1);
};

onMounted(() => {
  open2.value = bSidebarProps.open;
});

watch(
  () => bSidebarProps.open,
  (sideBarIsOpen: boolean) => {
    if (!sideBarIsOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  },
);
</script>

<style scoped>
.hamb-menu-footer {
  margin-top: 2rem;
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hamb-menu-content {
  width: 100%;
  height: 100%;
}
.hamb-menu-wrapper {
  position: fixed;
  top: 2.5rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
}
.expanded-container {
  background-color: #f5f2eb;
  height: 70%;
  width: 24.2rem;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
}
.collapsed-container {
  height: 0%;
  max-width: 0px;

  width: 0%;
  transition: 0.2s;
}
.hamb-menu-header {
  height: 6%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
