import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "src", "alt"]



type IconButtonProps = {
  width?: string;
  height: string;
  disabled?: boolean;
  iconImage: string;
  altText: string;
  noWrapper: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IconButton',
  props: {
    width: { default: "48" },
    height: { default: "48" },
    disabled: { type: Boolean, default: false },
    iconImage: { default: "" },
    altText: { default: "Button icon" },
    noWrapper: { type: Boolean, default: false }
  },
  emits: ["clicked"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["icon-wrapper d-flex justify-content-center align-items-center", !_ctx.noWrapper && 'wrapper-size']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled && emit('clicked')))
  }, [
    _createElementVNode("img", {
      width: _ctx.width,
      height: _ctx.height,
      class: _normalizeClass([_ctx.disabled ?? 'disabled']),
      src: _ctx.iconImage,
      style: _normalizeStyle({
        width: _ctx.width + 'px',
        height: _ctx.height + 'px',
      }),
      alt: _ctx.altText
    }, null, 14, _hoisted_1)
  ], 2))
}
}

})