import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row position-relative h-100"
}
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6 ps-5 info-wrapper d-flex flex-column justify-content-between" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "heading-small" }
const _hoisted_7 = { class: "col-12 mt-2" }
const _hoisted_8 = { class: "text-medium" }
const _hoisted_9 = { class: "mt-4 info-row-container" }
const _hoisted_10 = {
  key: 0,
  class: "row"
}
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }

import "vue-select/dist/vue-select.css";
import RemoveProductsModal from "./modal/RemoveProductsModal.vue";
import InformationRow from "./InformationRow.vue";
import ProductImageContainer from "./ProductImageContainer.vue";
import LoadingOverlay from "./common/LoadingOverlay.vue";
import {
  computed,
  ref,
  Ref,
  onMounted
} from "vue";
import { getProductInformationApi } from "@/library/api/products";
import { ProductInformation } from "@/library/types/product/productGroup";
import GreenifiedButton from "./common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./modal/GreenifiedModal.vue";
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
export type InformationsRows = {
  label?: string;
  rows: {
    rowLabel: string | null;
    rowValue: string | number;
  }[];
};

type ProductGroupInformationProps = {
  productId: string;
  productName: string;
  supplierCode?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductGroupInformation',
  props: {
    productId: { default: "" },
    productName: { default: "" },
    supplierCode: { default: "" }
  },
  emits: ["close", "update"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const productGroupInformationProps = __props;

const isLoading = ref(false);
const productGroupInformation: Ref<ProductInformation | null> = ref(null);
const informationRows: Ref<InformationsRows[]> = ref([]);
const showDeleteProduct = ref(false);

const getSupplierCode = computed(() => {
  if (!productGroupInformation.value) return;
  let supplierCode = null;
  const currentSupplier = productGroupInformationProps.supplierCode;
  if (currentSupplier) {
    supplierCode = currentSupplier.includes("ex")
      ? t("label.unkownSupplier")
      : t(`levkod.${currentSupplier}`);
  }
  return supplierCode;
});

const filteredRows = computed(() => {
  return informationRows.value.filter(infoRow => {
    const hasRowValues = infoRow.rows.find(
      rowValue => rowValue.rowValue && rowValue.rowValue !== "undefined"
    );
    if (hasRowValues) {
      return infoRow;
    }
  });
});

const getProductInformation = async () => {
  if (!productGroupInformationProps.productId) return;
  isLoading.value = true;
  const result = await getProductInformationApi(
    productGroupInformationProps.productId
  );
  isLoading.value = false;

  if (!result.success) return;

  productGroupInformation.value = result.result;
  const productGroup = productGroupInformation.value;
  informationRows.value = [
    {
      label: t("label.besk"),
      rows: [
        {
          rowLabel: null,
          rowValue: productGroup.description
        }
      ]
    },
    {
      label: t("tableTitle.quantity"),
      rows: [
        {
          rowLabel: null,
          rowValue: `${productGroup.same_products.length} ${t("label.pcs")}`
        }
      ]
    },
    {
      label: t("label.manufactured"),
      rows: [
        {
          rowLabel: null,
          rowValue: String(
            productGroup.green_struc[
              getProjectStructureFieldKey("produktionsar")
            ]
          )
        }
      ]
    },
    {
      label: t("label.skick2"),
      rows: [
        {
          rowLabel: null,
          rowValue: t(`skick2.${productGroup.condition}`)
        }
      ]
    },
    {
      label: t("label.designer"),
      rows: [
        {
          rowLabel: null,
          rowValue: String(
            productGroup.green_struc[getProjectStructureFieldKey("designer")]
          )
        }
      ]
    },
    {
      label: t("tableTitle.matt"),
      rows: [
        {
          rowLabel: t("label.length"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("langd")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("langd")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.height"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("hojd")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("hojd")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.width"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("bredd")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("bredd")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.depth"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("djup")
          ]
            ? `${
                productGroup.green_struc[getProjectStructureFieldKey("djup")]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.diameter"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("diameter")
          ]
            ? `${
                productGroup.green_struc[
                  getProjectStructureFieldKey("diameter")
                ]
              } mm`
            : ""
        },
        {
          rowLabel: t("label.seat_height"),
          rowValue: productGroup.green_struc[
            getProjectStructureFieldKey("sitthojd")
          ]
            ? `${
                productGroup.green_struc[
                  getProjectStructureFieldKey("sitthojd")
                ]
              } mm`
            : ""
        }
      ]
    },
    {
      label: t("label.other"),
      rows: [
        {
          rowLabel: null,
          rowValue: String(productGroup.green_struc.other_information)
        }
      ]
    }
  ];
};
onMounted(() => {
  getProductInformation();
});

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    onClose: _cache[3] || (_cache[3] = ($event: any) => (emit('close'))),
    type: 'large',
    title: ''
  }, {
    container: _withCtx(() => [
      (isLoading.value && !filteredRows.value.length)
        ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(ProductImageContainer, {
                mainImage: 
              productGroupInformation.value?.images.bild_1 ?? { url: '', hash: '' }
            ,
                itemImages: 
              productGroupInformation.value?.images ?? {
                bild_1: { url: '', hash: '' },
                bild_2: { url: '', hash: '' }
              }
            
              }, null, 8, ["mainImage", "itemImages"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.productName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(getSupplierCode.value), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredRows.value, (informationRow, i) => {
                    return (_openBlock(), _createBlock(InformationRow, {
                      key: i,
                      label: informationRow.label,
                      showTopBorder: i === 0 ? true : false,
                      informationRows: informationRow.rows
                    }, null, 8, ["label", "showTopBorder", "informationRows"]))
                  }), 128))
                ])
              ]),
              (productGroupInformation.value?.status !== 'B')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(GreenifiedButton, {
                        size: "thin",
                        "btn-style": "black",
                        text: _unref(t)('title.removeProduct'),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (showDeleteProduct.value = true))
                      }, null, 8, ["text"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])),
      (showDeleteProduct.value)
        ? (_openBlock(), _createBlock(RemoveProductsModal, {
            key: 2,
            onDelete: _cache[1] || (_cache[1] = ($event: any) => {
          showDeleteProduct.value = false;
          emit('update');
        }),
            onClose: _cache[2] || (_cache[2] = ($event: any) => (showDeleteProduct.value = false)),
            productGroup: productGroupInformation.value,
            productName: _ctx.productName
          }, null, 8, ["productGroup", "productName"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})