<template>
  <GreenifiedModal
    @close="emit('close')"
    :title="t('title.removeProduct')"
    :type="'smaller'"
  >
    <template #container v-if="currentProductGroup">
      <TableHeaderRow
        :tableHeaders="tableColumns(sortedItems[0], currentProductGroup)"
        @update:toggleCheckbox="
          isToggled => {
            if (!currentProductGroup) return;
            productsToDelete = selectAllProducts(
              isToggled,
              currentProductGroup
            );
          }
        "
        :toggleCheckbox="productsToDelete.length === sortedItems.length"
      />
      <div
        class="border-bottom"
        v-for="(productItem, index) in sortedItems"
        :key="index"
      >
        <MainTableRow
          :tableColumns="tableColumns(productItem, currentProductGroup)"
          :showCheckbox="true"
          :type="'other'"
          @selectRow="selectProduct(productItem)"
          :rowIsChecked="
            productsToDelete.some(
              product => product.comp_id === productItem.comp_id
            )
          "
        />
      </div>
    </template>
    <template #footer>
      <div class="col-12 d-flex justify-content-end">
        <GreenifiedButton
          :disabled="!productsToDelete.length"
          @click="showDeleteVerification = true"
          :text="t('label.remove')"
          size="thin"
        />
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    v-if="showSuccess"
    type="response"
    :closable="false"
    :title="t('title.removeProduct')"
    :text="t('paragraph.productHasBeenRemoved')"
    @close="emit('delete')"
  />
  <GreenifiedModal
    v-if="showDeleteVerification"
    type="verification"
    :closable="false"
    :title="t('title.removeProduct')"
    :text="
      productsToDelete.length > 1
        ? t('msg.verifyProductsDelete', [
            productsToDelete.length,
            bolderFont(productName)
          ])
        : t('msg.verifyProductDelete', [
            bolderFont(productName),
            bolderFont(String(productsToDelete[0].comp_id))
          ])
    "
    @confirm="removeProduct()"
    @close="showDeleteVerification = false"
  />
</template>

<script setup lang="ts">
import {
  withDefaults,
  defineProps,
  ref,
  Ref,
  computed,
  defineEmits
} from "vue";
import {
  ProductItem,
  ProductItemInformation
} from "../../library/types/product/productItem";
import { updateStatus } from "@/library/api/products";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { TableColumns } from "@/library/types/tables/columns";
import { ProductInformation } from "@/library/types/product/productGroup";
import MainTableRow from "../table/tableRows/MainTableRow.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { bolderFont } from "@/library/helpers/typography";

type RemoveProductsModalProps = {
  productGroup: ProductInformation | null;
  productName: string;
};

const removeProductsModalProps = withDefaults(
  defineProps<RemoveProductsModalProps>(),
  {
    productGroup: null,
    productName: ""
  }
);
const { t } = useI18n();

const showDeleteVerification = ref(false);
const productsToDelete: Ref<
  { id: ProductItem["id"]; comp_id: ProductItem["comp_id"] }[]
> = ref([]);
const currentProductGroup = ref(removeProductsModalProps.productGroup);
const showSuccess = ref(false);

const tableColumns = (
  productItem: ProductItemInformation,
  productGroup: ProductInformation
): TableColumns["columns"] => {
  return {
    comp_id: {
      value: productItem.comp_id,
      columnStyling: "col-4",
      tooltip: null,
      actions: ["checkbox"],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "ID",
      inputSettings: null,
      selectOptions: []
    },
    productName: {
      value: removeProductsModalProps.productName,
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "label.benamn",
      inputSettings: null,
      selectOptions: []
    },
    category: {
      value:
        productGroup.category.substring(0, 2) === "ex"
          ? t("label.unkownSupplier")
          : t(`kategori_id.${productGroup.category}`),
      columnStyling: "col-4",
      tooltip: null,
      actions: [],
      isSortable: false,
      isEditable: false,
      translate: false,
      headerText: "label.kategori_id",
      inputSettings: null,
      selectOptions: []
    }
  };
};

const sortedItems = computed(() => {
  if (!currentProductGroup.value) return [];

  return currentProductGroup.value.same_products.slice().sort((a, b) => {
    return a.comp_id - b.comp_id;
  });
});

const selectProduct = (productItem: ProductItemInformation) => {
  const productItemIndex = productsToDelete.value.findIndex(
    product => product.comp_id === productItem.comp_id
  );
  if (productItemIndex !== -1) {
    productsToDelete.value.splice(productItemIndex, 1);
    return;
  }

  productsToDelete.value.push({
    comp_id: productItem.comp_id,
    id: productItem.id
  });
  return;
};

const selectAllProducts = (
  isToggled: boolean,
  productGroup: ProductInformation
) => {
  if (!isToggled) return [];

  return productGroup.same_products.map(product => {
    return {
      id: product.id,
      comp_id: product.comp_id
    };
  });
};

const removeProduct = async () => {
  if (!currentProductGroup.value) return;

  const productIds = [];
  for (let i = 0; i < productsToDelete.value.length; i++) {
    const productToDelete = productsToDelete.value[i];
    productIds.push(productToDelete.id);
    currentProductGroup.value.same_products =
      currentProductGroup.value.same_products.filter(
        product => product.id !== productToDelete.id
      );
  }

  const result = await updateStatus(productIds, "N");
  if (!result.success) return;
  showDeleteVerification.value = false;
  showSuccess.value = true;
};

const emit = defineEmits(["close", "delete"]);
</script>
<style scoped>
.product-wrapper {
  max-height: 100%;
  min-height: 23rem;

  overflow: auto;
}

.button-footer {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
