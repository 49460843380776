<template>
  <GreenifiedModal
    :title="t('title.removeProduct')"
    @close="emit('close')"
    :isLoading="isLoading"
    :type="'verification'"
    :disabledConfirm="!selectedStatusOption"
    :confirmText="t('button.save')"
    @confirm="removeProduct(greenifiedProductHeaderToRemove)"
    v-if="greenifiedProductHeaderToRemove"
  >
    <template #container>
      <p
        v-if="selectedStatusOption === 'N'"
        class="form-label-paragraph mt-3 mb-4 text-danger fw-bolder"
      >
        {{
          greenifiedProductHeaderToRemove.items.length > 1
            ? t("msg.verifyProductsDelete", [
                greenifiedProductHeaderToRemove.items.length,
                greenifiedProductHeaderToRemove.benamn
              ])
            : t("msg.verifyProductDelete", [
                greenifiedProductHeaderToRemove.benamn,
                greenifiedProductHeaderToRemove.items[0].comp_id
              ])
        }}
      </p>
      <p class="form-label-paragraph mt-3 mb-4" v-else>
        {{ t("paragraph.moveAfterRemove") }}
      </p>
      <div class="row">
        <div class="col-12">
          <CustomSelect
            v-model="selectedStatusOption"
            :options="statusOptions"
            :placeholder="t('placeholders.selectCategoryToMoveTo')"
            :reducedKey="'value'"
            label="label"
            :clearable="false"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
</template>

<script lang="ts" setup>
import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import GreenifiedModal from "./GreenifiedModal.vue";
import { defineProps, withDefaults, defineEmits, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import CustomSelect from "../common/CustomSelect.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { removeProductGreenified } from "@/library/api/greenifiedProducts";

type StatusOption = {
  label: string;
  value: ProductStatuses;
};

type RemoveGreenifiedProductModal = {
  greenifiedProductHeaderToRemove: GreenifiedProductHeader | null;
};

withDefaults(defineProps<RemoveGreenifiedProductModal>(), {
  greenifiedProductHeaderToRemove: null
});
const { t } = useI18n();
const emit = defineEmits(["close", "refresh"]);

const statusOptions: StatusOption[] = [
  { label: t("label.moveToRepurpose"), value: "Å" },
  { label: t("label.moveToSell"), value: "S" },
  { label: t("label.moveToRecycle"), value: "K" },
  { label: t("label.productIsDiscarded"), value: "C" },
  { label: t("title.removeProduct"), value: "N" }
];
const selectedStatusOption: Ref<StatusOption["value"] | null> = ref(null);
const isLoading = ref(false);
const removeProduct = async (
  greenifiedProductHeader: GreenifiedProductHeader
) => {
  isLoading.value = true;
  if (selectedStatusOption.value) {
    const result = await removeProductGreenified(
      greenifiedProductHeader.id,
      selectedStatusOption.value
    );
    isLoading.value = false;

    if (!result.success) return;

    emit("refresh");
    emit("close");
  }
};
</script>
