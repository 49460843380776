import mediaApi from "@/services/api/mediaApi";
import axios from "axios";
import { useIndexStore } from "@/store";
import {
  CustomError,
  handleApiRequest,
  Result
} from "../helpers/handleApiRequests";
import { OrderStatuses } from "@/services/api/salesApi";
import { getLocaleFromLocationHost } from "../helpers/getLocaleFromLocationHost";

export const postImage = async (
  formData: FormData
): Promise<Result<string, CustomError>> => {
  return handleApiRequest(mediaApi.postImage(formData), "media - postImage");
};

export const getPdfFromUrl = async (
  pdfUrl: string
): Promise<Result<BlobPart, CustomError>> => {
  return handleApiRequest(
    mediaApi.getPdfFromUrl(pdfUrl),
    "media - getPdfFromUrl"
  );
};

export const getPdfFromRoute = async (
  route: string
): Promise<Result<BlobPart, CustomError>> => {
  const indexStore = useIndexStore();
  const currentLanguage =
    indexStore.currentProjectLanguage ??
    getLocaleFromLocationHost(window.location.host);
  return handleApiRequest(
    mediaApi.getPdfFromRoute(
      indexStore.currentProjectNumber,
      currentLanguage,
      route
    ),
    "media - getPdfFromRoute"
  );
};

export const getPackingList = async (
  orderNumber: string | null,
  orderStatus: OrderStatuses
): Promise<Result<BlobPart, CustomError>> => {
  const indexStore = useIndexStore();
  const currentLanguage =
    indexStore.currentProjectLanguage ??
    getLocaleFromLocationHost(window.location.host);

  return handleApiRequest(
    mediaApi.getPackingList(
      indexStore.currentProjectNumber,
      currentLanguage,
      orderNumber,
      orderStatus
    ),
    "media - getPackingList"
  );
};

export const getReconditionListsApi = async (): Promise<
  Result<string[], CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    mediaApi.getReconditionListsApi(indexStore.currentProjectNumber),
    "media - getReconditionListsApi"
  );
};

export const downloadImageApi = async (
  imageUrl: string
): Promise<Result<BlobPart, CustomError>> => {
  return handleApiRequest(
    axios.get(imageUrl, {
      responseType: "arraybuffer"
    }),
    "media - downloadImageApi"
  );
};
