<template>
  <div class="col-2" v-if="!isMainButton">
    <div class="menu-tree-branch">
      <div></div>
    </div>
  </div>

  <div
    class="d-flex justify-content-between"
    :class="isMainButton ? 'col-12' : 'col-10 ps-0'"
  >
    <GreenifiedButton
      :btnStyle="
        currentRoute.meta.mainHeader === mainHeader ? 'black' : 'normal'
      "
      @click="router.push({ name: routeName })"
      ><template #content>
        <div class="d-flex w-100 justify-content-between text-box">
          <p class="cursor-pointer">
            {{ t(buttonText) }}
          </p>

          <p class="cursor-pointer">
            {{ productAmountInCurrentStatus(mainQuantities) }}
          </p>
        </div></template
      ></GreenifiedButton
    >

    <div class="ms-2" :class="isCompleted ? 'done' : 'not-done'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
      >
        <path d="M1 6L8 13L20 1.5" stroke="white" stroke-width="1.5" />
      </svg>
    </div>
  </div>

  <div
    class="mt-3"
    v-if="currentRoute.meta.mainHeader === mainHeader && !isMainButton"
  >
    <template v-for="(subRoute, index) in subRoutes" :key="index">
      <div class="d-flex cursor-pointer">
        <div class="w-25"></div>

        <div
          class="w-75 d-flex"
          @click="router.push({ name: subRoute.subRouteName })"
        >
          <div
            class="me-3"
            :class="
              productAmountInCurrentStatus(subRoute.subQuantities) === 0
                ? 'done-marker'
                : currentRoute.meta.subHeader === subRoute.subHeader
                ? 'checked-marker'
                : 'unchecked-marker'
            "
          >
            <div></div>
          </div>
          <div
            class="align-items-center d-flex"
            :class="
              currentRoute.meta.subHeader === subRoute.subHeader
                ? 'fw-bold'
                : ''
            "
          >
            {{ t(subRoute.subButtonText) }}
          </div>
        </div>
      </div>
      <div
        v-if="index !== subRoutes.length - 1"
        class="dotted-vertical-line"
      ></div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import {
  productAmountInCurrentStatus,
  SubRoute,
} from "@/library/helpers/sidebarButtons";
import { Quantities } from "@/services/api/helpersApi";
import { withDefaults, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

type SidebarButtonProps = {
  routeName: string;
  buttonText: string;
  mainQuantities: Array<keyof Quantities>;
  isCompleted: boolean;
  mainHeader: number;
  isMainButton: boolean;
  subRoutes: SubRoute[];
};

withDefaults(defineProps<SidebarButtonProps>(), {
  routeName: "",
  buttonText: "",
  mainQuantities: () => [],
  isCompleted: false,
  mainHeader: 0,
  subRoutes: () => [],
  isMainButton: false,
});

const { t } = useI18n();
const { currentRoute } = useRouter();
const router = useRouter();
</script>

<style scoped>
.not-done {
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 19px;
  background: #d5d2cc;
  align-items: center;
  display: flex;
  justify-content: center;
  /* transition: all 0.5s ease; */
}
.done {
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 19px;
  background: #82ab72;
  align-items: center;
  display: flex;
  justify-content: center;
  /* transition: all 0.5s ease; */
}

.done-marker {
  background-image: url("@/assets/img/checkOnlyWhiteSmall.svg");
  background-color: #82ab72;
  border: none;
  &,
  .checked-marker + div {
    font-weight: 600;
  }
}

.checked-marker {
  background-color: #fff;
  border: solid 6px #000;
  & + div {
    font-weight: 600;
  }
}

.unchecked-marker {
  border: solid 1.5px rgba(0, 0, 0, 0.3);
  position: relative;
  & div {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5px 0 0 -3.5px;
  }
}

.checked-marker,
.unchecked-marker,
.done-marker {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  & + div {
    font-size: 11.5px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    margin-top: 3px;
  }
}

.menu-tree-branch {
  width: 20%;
  position: relative;
  & > div {
    border-left: 1.5px solid black;
    border-bottom: 1.5px solid black;
    position: absolute;
    width: 20px;
    height: 230px;
    top: -210px;
    left: 15px;
    border-radius: 0px 0px 0px 10px;
    z-index: 2;
  }
}

.dotted-vertical-line {
  width: 1.5px;
  height: 25px;
  border-left: dashed 1.5px #d4d4d4;
  margin-left: calc(25% + 10.5px);
}

.check-icon {
  height: 20px;
  width: 20px;
}
</style>
