import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid ps-0 pe-0 me-0 ms-0" }
const _hoisted_2 = { class: "row top-navbar align-items-center px-4" }
const _hoisted_3 = { class: "col-12 d-flex" }

import {
  onBeforeMount,
  onUnmounted,
  ref
} from "vue";
type GrLayoutTemplateProps = {
  openSideMenu: boolean;
  wideContainer?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GrLayoutTemplate',
  props: {
    openSideMenu: { type: Boolean, default: true },
    wideContainer: { type: Boolean, default: false }
  },
  setup(__props: any) {



const smallNavbar = ref(false);

const handleScroll = () => {
  if (document.documentElement.scrollTop >= 50) {
    smallNavbar.value = true;
  } else if (document.documentElement.scrollTop < 14.8) {
    smallNavbar.value = false;
  }
};

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "top-navbar")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["row h-100", _ctx.wideContainer ? 'wide-bg' : ''])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.openSideMenu ? 'open-menu px-4 ' : 'closed-menu', "side-menu h-100"])
        }, [
          _renderSlot(_ctx.$slots, "side-bar")
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["layout-container ps-4 pe-3 position-relative", _ctx.openSideMenu && 'ms-3'])
        }, [
          _renderSlot(_ctx.$slots, "container")
        ], 2)
      ])
    ], 2)
  ]))
}
}

})