import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-label-paragraph mb-2" }
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = { class: "row ps-0" }
const _hoisted_4 = { class: "col-5" }
const _hoisted_5 = { class: "col-5" }

import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import {
  onMounted,
  Ref,
  ref
} from "vue";
import { I18nMessage, PdfList } from "@/library/types/locales/i18nMessage";
import InputTextField from "../common/InputTextField.vue";
import CustomSelect from "../common/CustomSelect.vue";
import { getPdfFromUrl, getReconditionListsApi } from "@/library/api/media";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { useIndexStore } from "@/store";

export type PdfRoute =
  | "to-repurpose"
  | "recondition"
  | "repurpose-overview"
  | "to-sale"
  | "recycle"
  | "recycle-overview"
  | "internal-order";

type PdfModalProps = {
  pdfRoute?: PdfRoute;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PdfModal',
  props: {
    pdfRoute: { default: "recondition" }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const pdfModalProps = __props;

const { t, messages, locale } = useI18n();
const emit = __emit;
const indexStore = useIndexStore();
const pdfComment = ref(" ");
const pdfIsLoading = ref(false);
const currentListOptions: Ref<PdfList[]> = ref([]);
const currentList: Ref<PdfList> = ref({ label: "", url: "" });

const localeMoveLists = (): PdfList[] => {
  const moveLists = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).moveLists;
  return Object.keys(moveLists).map(
    localeKey => moveLists[localeKey as keyof I18nMessage["list"]["moveLists"]]
  );
};

const localeReconditionLists = (reconditionList: string[]): PdfList[] => {
  const localeReconditionList = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).repurposeLists;
  return Object.keys(localeReconditionList)
    .filter(listKey => {
      return reconditionList.includes(listKey) || listKey === "ALLList";
    })
    .map(
      reconditionKey =>
        localeReconditionList[
          reconditionKey as keyof I18nMessage["list"]["repurposeLists"]
        ]
    );
};

const getReconditionLists = async () => {
  const result = await getReconditionListsApi();

  if (!result.success) return;
  currentList.value = localeReconditionLists(result.result)[0];
  currentListOptions.value = localeReconditionLists(result.result);
};

const downloadPdfFromUrl = async () => {
  pdfIsLoading.value = true;
  const pdfUrl = currentList.value.url
    .replace(/\{projectNr\}/g, indexStore.currentProjectNumber)
    .replace(/\{note\}/g, pdfComment.value);

  const result = await getPdfFromUrl(pdfUrl);

  if (!result.success) return;

  const blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);

  window.open(url);

  emit("close");
};

onMounted(() => {
  if (pdfModalProps.pdfRoute === "recondition") {
    return getReconditionLists();
  }
  currentList.value = localeMoveLists()[0];
  currentListOptions.value = localeMoveLists();
  return;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    type: 'x-small',
    title: _unref(t)('title.repurposeLists'),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (emit('close'))),
    showCloseIcon: ""
  }, {
    container: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.repurposeLists")), 1),
      (_openBlock(), _createBlock(CustomSelect, {
        key: currentList.value.label,
        modelValue: currentList.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentList).value = $event)),
        label: "label",
        clearable: false,
        options: currentListOptions.value
      }, null, 8, ["modelValue", "options"])),
      _createElementVNode("label", _hoisted_2, _toDisplayString(_unref(t)("label.possibleComment")), 1),
      _createVNode(InputTextField, {
        rows: 2,
        modelValue: pdfComment.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((pdfComment).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(GreenifiedButton, {
            btnStyle: 'black',
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
            text: _unref(t)('button.close')
          }, null, 8, ["text"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(GreenifiedButton, {
            btnStyle: 'green',
            onClick: _cache[3] || (_cache[3] = ($event: any) => (downloadPdfFromUrl())),
            disabled: pdfIsLoading.value,
            text: !pdfIsLoading.value ? _unref(t)('button.download') : _unref(t)('button.loading')
          }, null, 8, ["disabled", "text"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})