<template>
  <div
    class="search-bar gr-color d-flex align-items-center px-2 position-relative"
  >
    <img
      src="@/assets/img/search-icon.svg"
      alt="search icon"
      class="search-icon cursor-pointer"
      @click="
        () => {
          searchBarIsExpanded = !searchBarIsExpanded;
          if (!searchBarIsExpanded) {
            currentSearchValue = '';
            return;
          }
          searchBar?.focus();
        }
      "
    />
    <input
      class="search-input text-tiny"
      ref="searchBar"
      :class="searchBarIsExpanded ? 'expanded-field' : 'collapsed-field'"
      v-model="currentSearchValue"
      type="text"
      :placeholder="t('placeholders.searchProduct')"
      @keydown.down="(event: KeyboardEvent) => increaseMarked(event)"
      @keyup.up="(event: KeyboardEvent) => decreaseMarked(event)"
      @keyup.enter="enterSelect(selectedFilterOptionIndex)"
      @input="(event: Event) => {
                  searchIsLoading = true;
                  getSearchValuesWithRows(event);
                }"
      @focus="searchFieldHasFocus = true"
    />
    <div
      class="search-result-wrapper"
      v-show="searchFieldHasFocus && currentSearchValue.length > 0"
    >
      <div
        class="search-result px-3 py-2 row cursor-pointer"
        :class="selectedFilterOptionIndex === 0 && 'marked'"
      >
        <div class="col-12" @click="addSearchValueToFilter(currentSearchValue)">
          <p
            class="cursor-pointer"
            v-html="
              t('label.searchAfter', [
                '<strong>' + currentSearchValue + '</strong>'
              ])
            "
          ></p>
        </div>
      </div>
      <div
        class="px-3 py-2 row cursor-pointer"
        v-if="searchIsLoading || invalidSearch"
      >
        <div class="col-12">
          <p v-if="searchIsLoading">
            {{ t("paragraph.searchingForProducts") }}
            <span class="fst-italic">{{
              t(getStatusText(productStatus))
            }}</span>
            ...
          </p>
          <p v-if="invalidSearch">
            {{ t("paragraph.noProductsMatchYourSearch") }}
          </p>
        </div>
      </div>
      <div
        v-for="(searchResult, i) in searchResults[currentSearchValue]"
        :key="searchResult.key"
        class="search-result cursor-pointer align-items-center px-3 py-2 row"
        @click="
          addSearchDropdownOptionToFilter({
            group: searchResult.group,
            key: searchResult.kol,
            value: searchResult.nyckel,
            text: searchResult.text,
            sokvarde: '',
            nyckel: '',
            sort: '',
            kol: '',
            projekt: ''
          })
        "
        :class="selectedFilterOptionIndex === i + 1 && 'marked'"
      >
        <div class="col-6 cursor-pointer">
          {{ getTranslatedLabel(searchResult) }}
        </div>
        <div class="col-6 d-flex justify-content-end cursor-pointer">
          <span class="spacer no-wrap">{{
            t("label." + searchResult.kol)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, withDefaults, defineProps, Ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { debounce } from "debounce";
import { TranslatedFilter } from "@/services/api/searchApi";
import { getGuestSearchValues, getSearchValues } from "@/library/api/search";
import { useRouter } from "vue-router";
import { FilterOptions } from "@/library/types/filter/filter";
import { useIndexStore } from "@/store";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { getStatusText } from "@/library/helpers/statusTexts";

type SearchBarProps = {
  defaultFilter: FilterOptions;
  productStatus?: ProductStatuses;
};

const searchBarProps = withDefaults(defineProps<SearchBarProps>(), {
  defaultFilter: () => ({
    categories: {},
    intervals: {}
  }),
  productStatus: "J"
});

const invalidSearch = ref(false);
const searchBar: Ref<HTMLInputElement | null> = ref(null);
const { t } = useI18n();
const currentSearchValue = ref("");
const searchBarIsExpanded = ref(false);
const selectedFilterOptionIndex: Ref<number | null> = ref(null);
const searchIsLoading = ref(false);
const searchFieldHasFocus = ref(false);
const searchResults: Ref<{ [key: string]: TranslatedFilter[] }> = ref({});
const { currentRoute } = useRouter();
const indexStore = useIndexStore();
const emit = defineEmits(["update:modelValue"]);
const currentFilter = ref(searchBarProps.defaultFilter);

const getTranslatedLabel = (searchLabel: TranslatedFilter) => {
  const translatedColumns = ["main_category", "kategori_id", "skick2"];

  if (translatedColumns.includes(searchLabel.kol))
    return t(`${searchLabel.kol}.${searchLabel.text}`);

  return searchLabel.text;
};

const increaseMarked = (event: Event) => {
  event.preventDefault();
  if (searchResults.value[currentSearchValue.value]) {
    if (selectedFilterOptionIndex.value !== null) {
      if (
        selectedFilterOptionIndex.value >=
        searchResults.value[currentSearchValue.value].length
      ) {
        selectedFilterOptionIndex.value = 0;
      } else {
        selectedFilterOptionIndex.value += 1;
      }
    } else {
      selectedFilterOptionIndex.value = 0;
    }
  }
};

const getSearchValuesWithRows = debounce(async (event: Event) => {
  searchIsLoading.value = true;
  invalidSearch.value = false;
  selectedFilterOptionIndex.value = null;
  const eventTarget = event.target as HTMLInputElement;
  currentSearchValue.value = eventTarget.value;

  let result;

  if (eventTarget.value in searchResults.value || eventTarget.value === "") {
    searchIsLoading.value = false;
    return;
  }

  if (currentRoute.value.params.token) {
    result = await getGuestSearchValues(
      eventTarget.value,
      currentRoute.value.params.token as string
    );
  } else {
    result = await getSearchValues(eventTarget.value);
  }

  searchIsLoading.value = false;

  if (!result.success) return (invalidSearch.value = true);

  if (!result.result.length) return (invalidSearch.value = true);
  searchResults.value[eventTarget.value] = result.result;
}, 500);

const decreaseMarked = (event: Event) => {
  event.preventDefault();
  if (searchResults.value[currentSearchValue.value]) {
    if (selectedFilterOptionIndex.value !== null) {
      if (selectedFilterOptionIndex.value <= 0) {
        selectedFilterOptionIndex.value =
          searchResults.value[currentSearchValue.value].length;
      } else {
        selectedFilterOptionIndex.value -= 1;
      }
    } else {
      selectedFilterOptionIndex.value =
        searchResults.value[currentSearchValue.value].length;
    }
  }
};

const enterSelect = (filterOptionIndex: number | null) => {
  if (!currentSearchValue.value) return;

  if (!filterOptionIndex || filterOptionIndex === -1)
    return addSearchValueToFilter(currentSearchValue.value);

  const markedObject =
    searchResults.value[currentSearchValue.value][filterOptionIndex];
  const searchObject = {
    group: markedObject.group,
    key: markedObject.kol,
    value: markedObject.nyckel,
    text: markedObject.text,
    sokvarde: "",
    nyckel: "",
    sort: "",
    kol: "",
    projekt: indexStore.currentProjectNumber
  };
  addSearchDropdownOptionToFilter(searchObject);
  return;
};

const addSearchValueToFilter = (searchValueForFilter: string) => {
  const filterHasSearchKey = Object.keys(
    currentFilter.value.categories
  ).includes("search");
  if (!filterHasSearchKey) {
    currentFilter.value.categories["search"] = [];
  }

  currentFilter.value.categories["search"].push({
    key: "search",
    value: searchValueForFilter
  });

  emit("update:modelValue", currentFilter.value);

  currentSearchValue.value = "";
};

const addSearchDropdownOptionToFilter = (searchObject: TranslatedFilter) => {
  const filterHasKey = Object.keys(currentFilter.value.categories).includes(
    searchObject.key
  );

  if (filterHasKey) {
    currentFilter.value.categories[searchObject.key].push({
      key: searchObject.key,
      value: searchObject.value
    });
  } else {
    currentFilter.value.categories[searchObject.key] = [
      { key: searchObject.key, value: searchObject.value }
    ];
  }

  emit("update:modelValue", currentFilter.value);

  currentSearchValue.value = "";
};
</script>

<style scoped>
.search-bar {
  border-radius: 25px;
  height: 32px;
  width: max-content;
}
.search-icon {
  width: 16px;
}
.search-input {
  background-color: transparent;
  border: none;
}

.expanded-field {
  width: 12rem;
  transition: 0.2s;
  padding-left: 10px;
}

.collapsed-field {
  width: 0px;
  padding: 0 !important;
  transition: 0.2s;
}

.search-result:hover {
  background-color: #f5f2eb;
}

.search-result-wrapper {
  background-color: #fff;
  border: 1.5px solid #000;
  top: 40px;
  left: 0;
  position: absolute;
  width: max-content;
  max-width: 400px;
  text-align: left;
  transition: all 0.15s ease-in-out;
  z-index: 5;
  overflow-y: hidden;
}

.marked {
  background: #f5f2eb;
}

.spacer {
  color: #989898;
  font-size: 11px;
  text-transform: uppercase;
  text-align: right;
  margin-left: 19px;
}
</style>
