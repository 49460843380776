<template>
  <div
    class="sidebar-container pt-5 d-flex flex-column justify-content-between h-100"
  >
    <div class="container-fluid ps-0 pe-0">
      <div class="row">
        <div class="col">
          <img
            src="@/assets/img/logo.svg"
            @click="router.push({ name: 'projectOverview' })"
          />
        </div>
      </div>
      <div v-if="!toggleShowContent" class="h-100"></div>

      <template v-if="toggleShowContent">
        <div class="row mt-5">
          <SidebarButton
            routeName="projectAction"
            :mainHeader="1"
            buttonText="button.handleProductsSidebar"
            :main-quantities="['J']"
            :isCompleted="quantities?.J === 0"
            isMainButton
            :subRoutes="[]"
          />
        </div>
        <div class="action-buttons-container">
          <template v-for="(button, index) in sidebarButtons()" :key="index">
            <div class="mt-3 row" v-if="button.showButton">
              <SidebarButton
                :buttonText="button.buttonText"
                :isCompleted="button.isCompleted"
                :mainHeader="button.mainHeader"
                :subRoutes="button.subRoutes"
                :mainQuantities="button.mainQuantities"
                :routeName="button.routeName"
                :isMainButton="button.isMainButton"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="toggleShowContent">
      <template v-if="(Number(store.currentProjectNumber) ?? 0) !== 0">
        <div class="row mt-2">
          <router-link :to="{ name: 'projectOverview' }">
            <GreenifiedButton
              :btnStyle="route.meta.mainHeader === 5 ? 'black' : 'normal'"
              :text="t('button.dashboard')"
              size="thin"
            />
          </router-link>
        </div>
        <div class="row mt-3">
          <router-link :to="{ name: 'projectUsers' }">
            <GreenifiedButton
              :btnStyle="route.meta.mainHeader === 6 ? 'black' : 'normal'"
              :text="t('button.user')"
              size="thin"
            />
          </router-link>
        </div>
        <div class="mt-3 row">
          <router-link :to="{ name: 'projectSettings' }">
            <GreenifiedButton
              :btnStyle="route.meta.mainHeader === 7 ? 'black' : 'normal'"
              :text="t('button.settings')"
              size="thin"
            />
          </router-link>
        </div>
      </template>
      <div class="row align-items-center mt-4">
        <div class="col-2">
          <img
            class="avatar-icon g-0"
            :src="imageSrc ?? defaultImageSrc"
            alt="Avatar"
            @error="imageSrc = defaultImageSrc"
          />
        </div>
        <div class="col-10 ps-4">
          <div class="yellow-text">{{ t("label.loggedInAs") }}</div>
          <div class="name-text">{{ userName ?? userEmail }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { sidebarButtons } from "@/library/helpers/sidebarButtons";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { computed, withDefaults, defineProps, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import SidebarButton from "./SidebarButton.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";

type SidebarContentProps = {
  showContent: boolean;
};

const sidebarContentProps = withDefaults(defineProps<SidebarContentProps>(), {
  showContent: false,
});

const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const store = useIndexStore();
const authStore = useAuthStore();
const toggleShowContent = ref(true);
const quantities = computed(() => {
  return store.quantities;
});

const imageSrc = authStore.user.avatarIcon;
const defaultImageSrc = computed(() => {
  return require("@/assets/img/avatar-icon.svg");
});

const userEmail = computed(() => {
  return authStore.currentUser.email;
});

const userName = computed(() => {
  return authStore.currentUser.name;
});

watch(
  () => sidebarContentProps.showContent,
  showContent => {
    if (showContent) {
      window.setTimeout(() => {
        toggleShowContent.value = true;
      }, 150);
    } else {
      toggleShowContent.value = false;
    }
  },
);
</script>

<style scoped lang="scss">
.action-buttons-container {
  overflow: hidden;
}

.text-align-start {
  text-align: start;
}

.yellow-text {
  color: #bbaf93;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.menu-tree-button {
  z-index: 3;
}
</style>
