import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Popper from "vue3-popper";


type GrTooltipProps = {
  showTooltip?: boolean;
  bgColor?: string;
  textClass?: string;
  justify?: string;
  width: "large" | "medium" | "small";
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GrTooltip',
  props: {
    showTooltip: { type: Boolean, default: true },
    bgColor: { default: "" },
    textClass: { default: "text-tiny" },
    justify: { default: "" },
    width: { default: "large" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popper), {
    hover: true,
    placement: 'top-start'
  }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 2
  }, [
    (_ctx.showTooltip)
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["req-text-container d-flex align-items-center p-2", [_ctx.bgColor, _ctx.justify, _ctx.width]])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(["req-text", _ctx.textClass])
              }, [
                _renderSlot(_ctx.$slots, "content")
              ], 2)
            ], 2)
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}
}

})