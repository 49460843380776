import { ProjectDetails } from "../types/project/project";

export const convertCurrencyCodeToSymbol = (
  currencyCode: ProjectDetails["valuta"],
  locale: string,
) => {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });

  const parts = numberFormat.formatToParts(1);
  let currencySymbol: string = currencyCode;

  for (const part of parts) {
    if (part.type === "currency") {
      currencySymbol = part.value;
      break;
    }
  }

  return currencySymbol;
};
