import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-label-paragraph mb-2" }
const _hoisted_2 = { class: "border-bottom" }
const _hoisted_3 = { class: "col-8" }

import { ref, Ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { ProductHeaderSale } from "@/library/types/product/productHeader";
import SelectRoom from "../SelectRoom.vue";
import { RoomObject } from "@/library/types/room/rooms";
import { postNewProductRoomOnDelete } from "@/library/api/moveProducts";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { getMainTableColumnsByTableType } from "@/library/helpers/structureTableColumns";
import MainTableRow from "../table/tableRows/MainTableRow.vue";

type ProductsInDeletedRoomModalProps = {
  productsInDeletedRoom: ProductHeaderSale;
  roomToDelete: RoomObject | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsInDeletedRoomModal',
  props: {
    productsInDeletedRoom: { default: () => ({}) },
    roomToDelete: { default: null }
  },
  emits: ["close", "removeRoom"],
  setup(__props: any, { emit: __emit }) {

const productsInDeletedRoomModalProps = __props;

const { t } = useI18n();
const emit = __emit;

const showMoveVerification = ref(false);
const newLocation: Ref<RoomObject> = ref({
  rum: "",
  rumsnamn: "",
  rumskod: "",
  van: "",
  hus: "",
  enhet: null,
  new_room: "0"
});

const moveProductsOnDelete = async () => {
  if (!productsInDeletedRoomModalProps.roomToDelete) return;
  if (!newLocation.value) return;

  const result = await postNewProductRoomOnDelete(
    productsInDeletedRoomModalProps.roomToDelete.rum,
    newLocation.value.rum
  );

  showMoveVerification.value = false;

  if (!result.success) return;
  emit("close");
  emit("removeRoom");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      title: _unref(t)('title.removeRoom'),
      onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
      type: 'smaller'
    }, {
      container: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.removeRoomProductInformation")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsInDeletedRoom, (productHeader, value, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (index === 0)
                ? (_openBlock(), _createBlock(TableHeaderRow, {
                    key: 0,
                    tableHeaders: 
              _unref(getMainTableColumnsByTableType)(productHeader.group, 'other')
                .columns
            
                  }, null, 8, ["tableHeaders"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(MainTableRow, {
                  thinRows: "",
                  tableColumns: 
                _unref(getMainTableColumnsByTableType)(productHeader.group, 'other')
                  .columns
              ,
                  rowImage: productHeader.items[0].images?.bild_1?.url ?? '',
                  type: 'other'
                }, null, 8, ["tableColumns", "rowImage"])
              ])
            ], 64))
          }), 128))
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(SelectRoom, {
            isReduced: false,
            modelValue: newLocation.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((newLocation).value = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (showMoveVerification.value = true))
            ],
            excludedRoom: _ctx.roomToDelete ? _ctx.roomToDelete.rum : '',
            placeholder: _unref(t)('placeholders.selectRoomForProducts')
          }, null, 8, ["modelValue", "excludedRoom", "placeholder"])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    (showMoveVerification.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "verification",
          closable: false,
          title: _unref(t)('label.moveProductAndRemove'),
          text: 
      _ctx.roomToDelete && newLocation.value
        ? _unref(t)('msg.verifyMoveAndRemove', [
            _ctx.roomToDelete.rumsnamn,
            newLocation.value.rumsnamn,
            _ctx.roomToDelete.rumsnamn
          ])
        : ''
    ,
          onClose: _cache[3] || (_cache[3] = ($event: any) => {
      showMoveVerification.value = false;
      newLocation.value = {
        rum: '',
        rumsnamn: '',
        rumskod: '',
        van: '',
        hus: '',
        enhet: null,
        new_room: '0'
      };
    }),
          onConfirm: _cache[4] || (_cache[4] = 
      () => {
        moveProductsOnDelete();
        showMoveVerification.value = false;
      }
    )
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})