import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-container"
}
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-6 d-flex form-label-paragraph align-items-center fw-bold" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6 form-label-paragraph fw-bold align-items-center d-flex" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6 form-label-paragraph fw-bold align-items-center d-flex" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-6 pt-2 form-label-paragraph fw-bold d-flex" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-6 pt-2 form-label-paragraph fw-bold d-flex" }
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "col-4" }

import {
  ref,
  computed,
  Ref,
  onMounted
} from "vue";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { AdvertisementProductHeader } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
import InputField from "../common/InputField.vue";
import InputTextField from "../common/InputTextField.vue";
import CustomSelect from "../common/CustomSelect.vue";
import { useRoute } from "vue-router";
import { CustomError, Result } from "@/library/helpers/handleApiRequests";
import { AxiosResponse } from "axios";
import { postGuestOrder, postInternalOrder } from "@/library/api/sales";
import { PurchaseOrder } from "@/services/api/salesApi";

type InternalBuyPropTypes = {
  productToPurchase: AdvertisementProductHeader["key"] | null;
};

type QuantityOption = {
  label: string;
  value: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InternalPurchaseOrder',
  props: {
    productToPurchase: { default: null }
  },
  emits: ["refresh", "close"],
  setup(__props: any, { emit: __emit }) {

const internalBuyProps = __props;

const productAmountOptions = computed(() => {
  let amountOptions: QuantityOption[] = [];
  if (!internalBuyProps.productToPurchase) return amountOptions;

  const soldInGroupOf = Number(
    internalBuyProps.productToPurchase.group.sale_info["ange-antal"]
  );

  const isSoldInGroup =
    internalBuyProps.productToPurchase.group.sale_info
      .saljs_styckvis_eller_i_grupp === "group";

  const productAmount = Number(
    internalBuyProps.productToPurchase.group.quantity
  );

  if (isSoldInGroup && soldInGroupOf && soldInGroupOf > 1) {
    amountOptions = Array.from(
      { length: productAmount },
      (_, number) => number + 1
    )
      .filter(value => value % soldInGroupOf === 0)
      .map(value => ({
        label: value === productAmount ? t("label.all") : String(value),
        value: value
      }));
  } else {
    amountOptions = Array.from({ length: productAmount }, (_, number) => ({
      label: number === productAmount - 1 ? t("label.all") : String(number + 1),
      value: number + 1
    }));
  }

  return amountOptions;
});
const route = useRoute();
const emit = __emit;

const { t } = useI18n();
const showPurchaseVerification = ref(false);
const isLoading = ref(false);
const productInformation = ref(internalBuyProps.productToPurchase);
const purchaseOrder: Ref<PurchaseOrder> = ref({
  requested_quantity: null,
  email: "",
  order_comment: null,
  pick_up_date: "",
  order_number: null,
  advertisement_id:
    internalBuyProps.productToPurchase?.group.advertisement_id ?? null,
  greenifiedHash: internalBuyProps.productToPurchase?.group.green_hash ?? null
});

const purchaseOrderForm = ref<HTMLFormElement | null>(null);

const requiredFieldsAreValid = computed((): boolean => {
  if (!purchaseOrder.value.requested_quantity) return false;

  return (
    Number(purchaseOrder.value.requested_quantity) > 0 &&
    purchaseOrder.value.email.length > 0
  );
});

const postPurchaseOrder = async () => {
  if (!internalBuyProps.productToPurchase) return;

  if (purchaseOrderForm.value && !purchaseOrderForm.value.reportValidity()) {
    return;
  }
  isLoading.value = true;

  const guestToken = route.params.token as string;
  let result: Result<AxiosResponse, CustomError>;

  if (guestToken) {
    result = await postGuestOrder(guestToken, purchaseOrder.value);
  } else {
    result = await postInternalOrder(purchaseOrder.value);
  }

  isLoading.value = false;
  if (!result.success) return;

  showPurchaseVerification.value = true;
};

onMounted(() => {
  if (productAmountOptions.value.length === 1) {
    purchaseOrder.value.requested_quantity = String(
      internalBuyProps.productToPurchase?.group.quantity
    );
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      title: _unref(t)('button.orderProduct'),
      onClose: _cache[5] || (_cache[5] = ($event: any) => (emit('close'))),
      type: 'smaller',
      isLoading: isLoading.value
    }, {
      container: _withCtx(() => [
        _createElementVNode("form", {
          ref_key: "purchaseOrderForm",
          ref: purchaseOrderForm,
          id: "purchaseOrderForm",
          onSubmit: _withModifiers(postPurchaseOrder, ["prevent"])
        }, [
          (productInformation.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (productAmountOptions.value.length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("label.selectQuantity")) + " " + _toDisplayString(productInformation.value.group.sell_grupp === "Grupp" ||
                  productInformation.value.group.sale_info
                    .saljs_styckvis_eller_i_grupp === "group"
                    ? `(${_unref(t)("label.inGroupOf")} ${
                        productInformation.value?.group.sell_antal ??
                        productInformation.value.group.sale_info["ange-antal"]
                      })`
                    : "") + " *", 1)
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(CustomSelect, {
                          options: productAmountOptions.value,
                          label: "label",
                          modelValue: purchaseOrder.value.requested_quantity,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((purchaseOrder.value.requested_quantity) = $event)),
                          placeholder: _unref(t)('label.selectQuantity'),
                          reducedKey: 'value',
                          required: true
                        }, null, 8, ["options", "modelValue", "placeholder"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("label", null, _toDisplayString(_unref(t)("label.whenToBeCollected")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    (productInformation.value)
                      ? (_openBlock(), _createBlock(InputField, {
                          key: 0,
                          "field-value": purchaseOrder.value.pick_up_date,
                          "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((purchaseOrder.value.pick_up_date) = $event)),
                          minValue: productInformation.value.group?.sell_tillganglig,
                          maxValue: "9999-12-31",
                          type: "date"
                        }, null, 8, ["field-value", "minValue"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("label", null, _toDisplayString(_unref(t)("label.email")) + " *", 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(InputField, {
                      required: "",
                      "field-value": purchaseOrder.value.email,
                      "onUpdate:fieldValue": _cache[2] || (_cache[2] = ($event: any) => ((purchaseOrder.value.email) = $event)),
                      placeHolder: _unref(t)('placeholders.yourEmail'),
                      type: "email"
                    }, null, 8, ["field-value", "placeHolder"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("label", null, _toDisplayString(_unref(t)("label.orderNumber")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(InputField, {
                      placeHolder: _unref(t)('label.orderNumber'),
                      type: "text",
                      "field-value": purchaseOrder.value.order_number,
                      "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((purchaseOrder.value.order_number) = $event))
                    }, null, 8, ["placeHolder", "field-value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("label", null, _toDisplayString(_unref(t)("label.comment")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(InputTextField, {
                      placeHolder: _unref(t)('placeholders.internalBuyComment'),
                      type: "text",
                      modelValue: purchaseOrder.value.order_comment,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((purchaseOrder.value.order_comment) = $event)),
                      rows: 2
                    }, null, 8, ["placeHolder", "modelValue"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 544)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(GreenifiedButton, {
              disabled: !requiredFieldsAreValid.value,
              text: _unref(t)('button.orderProduct'),
              "btn-style": 'green',
              form: 'purchaseOrderForm',
              type: 'submit'
            }, null, 8, ["disabled", "text"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"]),
    (showPurchaseVerification.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          closable: false,
          onClose: _cache[6] || (_cache[6] = ($event: any) => {
      emit('refresh');
      emit('close');
    }),
          title: _unref(t)('title.thanksForTheOrder'),
          text: _unref(t)('paragraph.thanksForTheOrder')
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})