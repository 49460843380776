import { RoomObject } from "@/library/types/room/rooms";
import api from "../api";
import { AxiosResponse } from "axios";
import { ProductItem } from "@/library/types/product/productItem";

export default {
  postNewProductRoomOnDelete(
    projectNr: string,
    roomToDelete: RoomObject["rum"],
    moveToRoom: RoomObject["rum"]
  ): Promise<AxiosResponse> {
    return api().post(
      "/api/project/" + projectNr + "/move-on-delete",
      JSON.stringify({ fromRoom: roomToDelete, toRoom: moveToRoom })
    );
  },

  postNewProductRoom(
    projectNumber: string,
    ids: ProductItem["id"][],
    toRoom: RoomObject["rum"],
    status?: string[]
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/move`,
      JSON.stringify({ ids: ids, to_room: toRoom, status: status })
    );
  },

  postNewProductLocation(
    projectNumber: string,
    ids: string[],
    room: string
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/move-location`,
      JSON.stringify({ ids: ids, room: room })
    );
  }
};
