<template>
  <div class="input-field-wrapper" @click="inputFocus()">
    <label v-if="label" class="mb-1 text-tiny fw-bolder">{{ label }}</label>
    <textarea
      @blur="emit('blur')"
      v-if="value"
      class="greenified-input"
      :disabled="disabled"
      :readonly="notEditable"
      :rows="rows"
      ref="inputField"
      :class="[width, showError && 'error', notEditable && 'not-editable']"
      :max="max"
      :min="min"
      :type="showPassword ? 'text' : type"
      :value="value"
      :required="isRequired"
      :id="id"
      :placeholder="placeHolder"
      @input="emit('update:modelValue', inputValue)"
    />
    <textarea
      @blur="emit('blur')"
      v-else
      class="greenified-input"
      :readonly="notEditable"
      :disabled="disabled"
      :rows="rows"
      ref="inputField"
      :class="[width, showError && 'error']"
      :max="max"
      :min="min"
      :type="showPassword ? 'text' : type"
      :required="isRequired"
      v-model="inputValue"
      :id="id"
      :placeholder="placeHolder"
      @input="emit('update:modelValue', inputValue)"
    />
    <div class="input-helpers" v-if="showError || showUnit">
      <p v-if="showError" class="error-text me-1">{{ errorText }}</p>
      <p v-if="showUnit">{{ unitText }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";
import {
  defineEmits,
  withDefaults,
  defineProps,
  ref,
  onMounted,
  Ref
} from "vue";

type InputTextFieldProps = {
  label?: string;
  id?: string;
  type?: string;
  width?: string;
  modelValue: string | number | null;
  value?: string | number;
  showError?: boolean;
  showUnit?: boolean;
  disabled?: boolean;
  errorText?: string;
  unitText?: string | number;
  placeHolder?: string;
  setFocus?: boolean;
  min?: number | Date | null;
  max?: number | Date | null;
  isRequired?: boolean;
  notEditable?: boolean;
  rows?: number;
};

const inputTextFieldProps = withDefaults(defineProps<InputTextFieldProps>(), {
  label: "",
  id: "Input field",
  name: "Input field",
  type: "text",
  width: "",
  modelValue: "",
  value: "",
  showError: false,
  showUnit: false,
  disabled: false,
  errorText: "Error",
  unitText: "mm",
  placeHolder: "",
  setFocus: false,
  min: null,
  max: null,
  isRequired: false,
  notEditable: false,
  rows: 0
});

const emit = defineEmits([
  "update:modelValue",
  "hasError",
  "update:hasError",
  "blur",
  "click"
]);
const inputValue = ref(inputTextFieldProps.modelValue);
let showPassword = ref(false);
const inputField: Ref<HTMLInputElement | null> = ref(null);

const inputFocus = () => {
  if (inputField.value) {
    emit("click");
    inputField.value.focus();
  }
};

onMounted(() => {
  if (inputTextFieldProps.setFocus) {
    inputFocus();
  }
});

watch(
  () => inputTextFieldProps.showError,
  hasError => {
    emit("update:hasError", hasError);
  }
);
</script>

<style scoped>
@import "@/assets/scss/inputs.scss";
.greenified-input {
  height: 100px;
  font-size: 12px !important;
}

.greenified-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.error-text {
  font-weight: bolder;
}
</style>
