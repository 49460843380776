import { toast, ToastContainerOptions } from "vue3-toastify";
import { App, WritableComputedRef } from "vue";
import { I18n } from "vue-i18n";
import api from "@/services/api";
import { AxiosError } from "axios";
import {
  I18nMessage,
  I18nMessageSchema,
} from "@/library/types/locales/i18nMessage";
import { useAuthStore } from "@/store/auth";

const toastOptions: ToastContainerOptions = {
  autoClose: 3000,
  theme: "dark",
  position: "bottom-right",
  hideProgressBar: true,
  closeButton: false,
  style: {
    fontSize: "15px",
  },
};

export type ToastError = (error: AxiosError | string, callee: string) => void;

export const toastPlugin = {
  install(
    app: App,
    {
      i18n,
    }: {
      i18n: I18n<
        I18nMessageSchema,
        Record<string, string>,
        Record<string, string>,
        string,
        false
      >;
    },
  ) {
    const authStore = useAuthStore();
    const user = authStore.currentUser?.email;

    const globalLocale = (i18n.global.locale as WritableComputedRef<string>)
      .value;
    const localeMessage: I18nMessage = (
      i18n.global.messages as WritableComputedRef<I18nMessageSchema>
    ).value[globalLocale as keyof I18nMessageSchema];

    const postError: ToastError = (error, callee) => {
      if (typeof error !== "string" && error.response?.status === 401) {
        authStore.logout();
        return;
      }

      toast.warn(
        localeMessage.msg.errorMessage || "An error occurred",
        toastOptions,
      );

      if (!window.location.href.includes("localhost")) {
        api().post(
          "/api/report-error",
          JSON.stringify({ error: error, callee: callee, user: user }),
        );
      }
    };

    app.provide("toastError", postError);
  },
};

export const unexpectedError = (errorText?: string) => {
  const currentErrorText = errorText
    ? errorText
    : "An unexpected error occured. Please try again.";

  toast.warn(currentErrorText, toastOptions);
};
