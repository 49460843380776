import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col d-flex align-items-center pe-0 pt-0 ps-0"
}
const _hoisted_2 = { class: "text-small" }
const _hoisted_3 = { class: "d-flex justify-content-between w-100" }

import { ref, watch } from "vue";

import TableHeader from "./TableHeader.vue";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import { SortingSettings } from "@/library/types/sorting/sorting";
import { TableColumns } from "@/library/types/tables/columns";

type TableHeaderProps = {
  sortingOptions?: SortingSettings;
  type?: "main" | "sub";
  hideHeaderCheckbox?: boolean;
  tableHeaders: TableColumns["columns"] | null;
  toggleCheckbox?: boolean;
  noRowsText?: string;
  isSticky?: boolean;
  soldProducts?: number;
  productsForSale?: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TableHeaderRow',
  props: {
    sortingOptions: { default: () => ({
    asc: false,
    col: "",
  }) },
    type: { default: "main" },
    hideHeaderCheckbox: { type: Boolean, default: false },
    tableHeaders: {},
    toggleCheckbox: { type: Boolean, default: false },
    noRowsText: { default: "" },
    isSticky: { type: Boolean, default: false },
    soldProducts: { default: 0 },
    productsForSale: { default: 0 }
  },
  emits: [
  "update:sortingOptions",
  "update:toggleCheckbox",
  "editAll",
],
  setup(__props: any, { emit: __emit }) {

const tableHeaderProps = __props;

const { t } = useI18n();
const localSortingOptions = ref(tableHeaderProps.sortingOptions);

const localToggleCheckbox = ref(tableHeaderProps.toggleCheckbox);

const currentTableHeaders = ref(tableHeaderProps.tableHeaders);

const emit = __emit;

watch(
  () => tableHeaderProps.toggleCheckbox,
  newCheckboxValue => {
    localToggleCheckbox.value = newCheckboxValue;
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row ms-0 me-0 border-bottom table-header-wrapper", [!currentTableHeaders.value && 'border-top', _ctx.isSticky && 'sticky-header']])
  }, [
    (!currentTableHeaders.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.noRowsText), 1)
        ]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(currentTableHeaders.value, (header, headerKey, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["d-flex align-items-center pe-0 pt-0 ps-0", header.columnStyling]),
            key: index
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["d-flex align-items-center w-100", _ctx.type === 'sub' && index === 0 && 'left-margin'])
            }, [
              (header.actions.includes('checkbox') && !_ctx.hideHeaderCheckbox)
                ? (_openBlock(), _createBlock(GreenifiedCheckbox, {
                    key: 0,
                    modelValue: localToggleCheckbox.value,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((localToggleCheckbox).value = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (
              emit('update:toggleCheckbox', localToggleCheckbox.value)
            ))
                    ],
                    tableMargin: _ctx.type
                  }, null, 8, ["modelValue", "tableMargin"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(TableHeader, {
                  headerText: header.headerText,
                  sortingColumn: headerKey,
                  type: _ctx.type,
                  sortingOptions: localSortingOptions.value,
                  "onUpdate:sortingOptions": [
                    _cache[2] || (_cache[2] = ($event: any) => ((localSortingOptions).value = $event)),
                    _cache[3] || (_cache[3] = ($event: any) => (
                emit('update:sortingOptions', localSortingOptions.value)
              ))
                  ],
                  isSortable: header.isSortable,
                  soldProducts: _ctx.soldProducts,
                  productsForSale: _ctx.productsForSale,
                  "onUpdate:isChecked": [
                    _cache[4] || (_cache[4] = 
                boolean => emit('update:toggleCheckbox', boolean)
              ),
                    _cache[5] || (_cache[5] = ($event: any) => ((localToggleCheckbox).value = $event))
                  ],
                  isChecked: localToggleCheckbox.value
                }, null, 8, ["headerText", "sortingColumn", "type", "sortingOptions", "isSortable", "soldProducts", "productsForSale", "isChecked"]),
                (
                header.actions.includes('edit') &&
                _ctx.type === 'sub' &&
                index === Object.keys(currentTableHeaders.value).length - 1
              )
                  ? (_openBlock(), _createBlock(GreenifiedButton, {
                      key: 0,
                      size: "auto",
                      noWrap: "",
                      text: _unref(t)('button.editAll'),
                      class: "right-margin",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (emit('editAll')))
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 2))
        }), 128))
  ], 2))
}
}

})