<template>
  <button
    v-if="inputType === 'button' || disabled"
    class="greenified-button text-tiny fw-bold"
    :type="type"
    :class="[
      btnStyle,
      size,
      disabled && 'disabled',
      noWrap && 'no-wrap',
      smallerPadding && 'smaller-padding',
      overflow && overflow,
      textAlign,
      !disabled && 'cursor-pointer'
    ]"
    :disabled="disabled"
    :form="form"
  >
    <slot name="content" v-if="inputType !== 'button'">
      <p class="text-start">{{ text }}</p>
    </slot>
    <slot name="content" v-else>
      {{ text }}
    </slot>
  </button>

  <div
    class="greenified-button d-flex align-items-center position-relative text-tiny fw-bold"
    :class="[
      btnStyle,
      size,
      disabled && 'disabled',
      noWrap && 'no-wrap',
      smallerPadding && 'smaller-padding',
      overflow && overflow,
      !disabled && 'cursor-pointer'
    ]"
    @click="selectOption()"
    v-else
  >
    <label
      :for="inputId"
      class="greenified-label"
      :class="!disabled && 'cursor-pointer'"
      :disabled="disabled"
    >
      {{ text }}
    </label>
    <input
      v-if="!disabled"
      class="visually-hidden"
      :type="inputType"
      :true-value="inputTrueValue"
      :false-value="inputFalseValue"
      :required="inputRequired"
      :checked="btnStyle === 'black'"
      :id="inputId"
      :name="inputName"
      :value="inputValue"
      v-model="selectedValue"
    />
  </div>
</template>

<script setup lang="ts">
import { ButtonColor } from "@/library/types/tables/footer";
import { withDefaults, defineProps, ButtonHTMLAttributes, ref } from "vue";

type GreenifiedButtonProps = {
  type?: ButtonHTMLAttributes["type"];
  btnStyle?: ButtonColor;
  size?: "full" | "wider" | "wide" | "medium" | "small" | "thin" | "auto";
  text?: string;
  disabled?: boolean;
  noWrap?: boolean;
  form?: string;
  smallerPadding?: boolean;
  inputType?: "button" | "checkbox" | "radio";
  inputTrueValue?: boolean | string | number;
  inputFalseValue?: boolean | string | number;
  inputRequired?: boolean;
  inputId?: string;
  inputName?: string;
  inputValue?: string;
  overflow?: "ellipsis" | null;
  textAlign?: "left" | "center";
};

const greenifiedButtonProps = withDefaults(
  defineProps<GreenifiedButtonProps>(),
  {
    type: "button",
    btnStyle: "normal",
    size: "full",
    text: "Click here",
    disabled: false,
    noWrap: false,
    form: "",
    smallerPadding: false,
    inputType: "button",
    inputTrueValue: true,
    inputFalseValue: false,
    inputRequired: false,
    inputId: "",
    inputName: "",
    inputValue: "",
    overflow: null,
    textAlign: "center"
  }
);

const selectedValue = ref("");

const selectOption = () => {
  selectedValue.value = greenifiedButtonProps.inputValue;
};
</script>

<style scoped>
.smaller-padding {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.greenified-button {
  border-radius: 25px;
  padding-left: 24px;
  padding-right: 24px;
  height: 2rem;
  cursor: pointer;
  border: 1.5px solid transparent;
  transition: 0.2s;
}

.greenified-button {
  text-decoration: none;
}

.normal {
  background-color: white;
  color: black;
  border: 1.5px solid black;
}

.black {
  background-color: black;
  color: white;
}

.green {
  background-color: #82ab72;
  color: white;
}

.yellow {
  background-color: #f5f2eb;
  color: black;
  border-radius: 0 !important;
  border: none !important;
}

.yellow-active {
  background-color: #e0d4b8;
  color: black;
  border-radius: 0 !important;
  border: none !important;
}

.full {
  width: 100%;
}

.wider {
  width: 16rem;
}

.wide {
  width: 12rem;
}

.btn-text-medium {
  font-size: 15px;
}

.medium {
  width: 10rem !important;
}

.small {
  width: 9rem !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.big {
  font-size: 16px;
}
.transparent {
  background-color: transparent;
  color: black;
}

.thin {
  width: max-content;
}

.auto {
  width: auto;
}

.disabled {
  background-color: rgba(174, 174, 174, 0.5) !important;
  color: rgba(0, 0, 0, 0.75);
  cursor: default;
  border: 1.5px solid transparent;
}

.greenified-label {
  text-wrap: nowrap;
  max-width: 100%;
}
</style>
