import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex justify-content-end w-100 right-margin pe-1"
}
const _hoisted_2 = { class: "text-tiny bolder pe-2" }

import { ref, watch } from "vue";
import RotateArrow from "../../../components/common/RotateArrow.vue";
import { useI18n } from "vue-i18n";
import { SortingSettings } from "@/library/types/sorting/sorting";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";

type TableHeaderProps = {
  headerText: string | number;
  sortingOptions: SortingSettings;
  isSortable: boolean;
  sortingColumn: string | number;
  soldProducts?: number;
  productsForSale?: number;
  isChecked?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TableHeader',
  props: {
    headerText: { default: "" },
    sortingOptions: { default: () => ({
    asc: false,
    col: "",
  }) },
    isSortable: { type: Boolean, default: true },
    sortingColumn: { default: "" },
    soldProducts: { default: 0 },
    productsForSale: { default: 0 },
    isChecked: { type: Boolean, default: false }
  },
  emits: ["update:sortingOptions", "update:isChecked"],
  setup(__props: any, { emit: __emit }) {

const tableHeaderProps = __props;

const emit = __emit;

const { t } = useI18n();
const currentIsChecked = ref(tableHeaderProps.isChecked);
const isAscending = ref(false);

const handleClick = (sortingColumn: string | number) => {
  isAscending.value = !isAscending.value;

  const currentSortingOptions: SortingSettings = {
    asc: isAscending.value,
    col: sortingColumn,
  };

  emit("update:sortingOptions", currentSortingOptions);
};

watch(
  () => tableHeaderProps.isChecked,
  () => {
    currentIsChecked.value = tableHeaderProps.isChecked;
  },
);

return (_ctx: any,_cache: any) => {
  return (_ctx.sortingColumn !== 'soldProducts')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["d-flex align-items-center", _ctx.isSortable ? 'cursor-pointer' : 'cursor-default']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSortable && handleClick(_ctx.sortingColumn)))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["me-1 text-tiny bolder", [
        _ctx.sortingOptions.col === _ctx.headerText && 'fw-bold',
        _ctx.isSortable ? 'cursor-pointer' : 'cursor-default',
      ]])
        }, _toDisplayString(_unref(t)(_ctx.headerText)), 3),
        (_ctx.isSortable)
          ? (_openBlock(), _createBlock(RotateArrow, {
              key: 0,
              size: "tiny",
              rotate: isAscending.value
            }, null, 8, ["rotate"]))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)(_ctx.headerText)) + " (" + _toDisplayString(_ctx.soldProducts) + "/" + _toDisplayString(_ctx.productsForSale) + ") ", 1),
        _createVNode(GreenifiedCheckbox, {
          modelValue: currentIsChecked.value,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((currentIsChecked).value = $event)),
            _cache[2] || (_cache[2] = ($event: any) => (emit('update:isChecked', currentIsChecked.value)))
          ],
          trueValue: true,
          falseValue: false
        }, null, 8, ["modelValue"])
      ]))
}
}

})