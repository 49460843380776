import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-tiny emphasis d-flex cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "delete-span me-1"
}


import { useI18n } from "vue-i18n";

type BreadcrumbProps = {
  deletable?: boolean;
  text: string;
  translate?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BreadCrumb',
  props: {
    deletable: { type: Boolean, default: true },
    text: { default: "" },
    translate: { type: Boolean, default: true }
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.deletable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "✕"))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.translate ? _unref(t)(_ctx.text) : _ctx.text), 1)
  ]))
}
}

})