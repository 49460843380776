<template>
  <div
    class="greenified-image position-relative cursor-pointer"
    :key="currentImageUrl"
  >
    <GreenifiedLoader v-if="loadingImage" :color="'black'" />
    <label
      class="w-100 h-100"
      :class="selectable ? 'cursor-pointer' : 'cursor-default'"
      :for="imageLabel"
    >
      <input
        v-if="selectable"
        type="file"
        accept="image/*"
        :id="imageLabel"
        maxlength="60"
        class="hidden-input"
        @change="uploadImageFile"
      />
      <img
        v-if="currentImageUrl"
        :src="`${contentUrl}${currentImageUrl}`"
        alt="form image"
        class="form-image cursor-pointer"
      />
      <p v-else class="text-tiny m-2">
        {{ `${t("label.image")} ${imageIndex}` }}
      </p>
    </label>
    <div
      class="delete-icon-wrapper"
      @click="deleteImageFile"
      v-if="!imageIsRequired && currentImageUrl"
    >
      <img
        src="@/assets/img/remove.svg"
        alt="delete image icon"
        class="delete-image-icon cursor-pointer"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { postImage } from "@/library/api/media";
import { withDefaults, defineProps, ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import GreenifiedLoader from "./GreenifiedLoader.vue";

type ImageFieldProps = {
  imageUrl: string | null;
  imageLabel: string;
  imageIsRequired?: boolean;
  imageIndex: number;
  selectable?: boolean;
};

const imageFieldProps = withDefaults(defineProps<ImageFieldProps>(), {
  imageUrl: "",
  imageLabel: "",
  imageIsRequired: false,
  imageIndex: 0,
  selectable: true
});

const { t } = useI18n();

const contentUrl = process.env.VUE_APP_CONTENT_URL;

const emit = defineEmits(["update:imageUrl"]);

const loadingImage = ref(false);

const currentImageUrl = ref(imageFieldProps.imageUrl ?? "");

const deleteImageFile = () => {
  currentImageUrl.value = "";
  emit("update:imageUrl", currentImageUrl.value);
};

const uploadImageFile = async (event: Event) => {
  loadingImage.value = true;
  const formData = new FormData();
  const eventTarget = event.target as HTMLInputElement;
  if (eventTarget.files?.length) {
    formData.append("file", eventTarget.files[0]);

    const result = await postImage(formData);
    loadingImage.value = false;

    if (!result.success) return;
    currentImageUrl.value = result.result;

    emit("update:imageUrl", currentImageUrl.value);
  }
};
</script>

<style scoped>
.greenified-image {
  border: 1.5px solid black;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: white;
}

.form-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.delete-icon-wrapper {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.delete-image-icon {
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease;
}

.delete-icon-wrapper:hover .delete-image-icon {
  filter: invert(100%) grayscale(100%) brightness(50%);
}
</style>
