import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.svg'


const _hoisted_1 = { class: "container-fluid ps-0 pe-0 d-flex flex-column simple-container" }
const _hoisted_2 = { class: "row top-navbar px-4 d-flex align-items-center justify-content-end" }
const _hoisted_3 = { class: "navbar-wrapper" }
const _hoisted_4 = { class: "gr-color sub-wrapper row px-4" }
const _hoisted_5 = { class: "layout-container row ps-4" }
const _hoisted_6 = { class: "main-footer gr-color row px-4" }
const _hoisted_7 = { class: "col-12" }

import {
  onBeforeMount,
  onUnmounted,
  ref
} from "vue";

type GrLayoutSimpleProps = {
  wideContainer?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GrLayoutSimple',
  props: {
    wideContainer: { type: Boolean, default: false }
  },
  setup(__props: any) {



const smallNavbar = ref(false);

const handleScroll = () => {
  if (document.documentElement.scrollTop >= 50) {
    smallNavbar.value = true;
  } else if (document.documentElement.scrollTop < 14.8) {
    smallNavbar.value = false;
  }
};

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "top-navbar")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass([smallNavbar.value ? 'small-logo-wrapper' : 'logo-wrapper', "px-4 row w-100 align-items-center"])
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "col-12" }, [
          _createElementVNode("img", {
            height: "30",
            src: _imports_0
          })
        ], -1)
      ]), 2),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "bottom-navbar")
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.wideContainer ? 'wide-bg' : '')
      }, [
        _renderSlot(_ctx.$slots, "container")
      ], 2)
    ]),
    _createElementVNode("footer", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _renderSlot(_ctx.$slots, "footer")
      ])
    ])
  ]))
}
}

})