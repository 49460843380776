import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tooltip-icon cursor-pointer fw-bolder text-tiny"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["innerHTML"]

import GrTooltip from "./GrTooltip.vue";

import infoIcon from "../../assets/img/info-icon.svg";

type InformationTooltipProps = {
  infoText: string | null;
  tooltipIcon?: string;
  altText?: string;
  size?: null | string;
  formTooltip?: boolean;
  tooltipWidth?: "large" | "medium" | "small";
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InformationTooltip',
  props: {
    infoText: { default: "" },
    tooltipIcon: { default: infoIcon },
    altText: { default: "Info icon" },
    size: { default: null },
    formTooltip: { type: Boolean, default: false },
    tooltipWidth: { default: "large" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GrTooltip, {
    width: _ctx.tooltipWidth,
    hideBox: _ctx.infoText === null
  }, {
    content: _withCtx(() => [
      _createElementVNode("p", {
        class: "text-small",
        innerHTML: _ctx.infoText
      }, null, 8, _hoisted_3)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        (_ctx.formTooltip)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, " i "))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.tooltipIcon,
              alt: _ctx.altText,
              class: _normalizeClass(["cursor-pointer", `${_ctx.size}-icon`])
            }, null, 10, _hoisted_2))
      ])
    ]),
    _: 3
  }, 8, ["width", "hideBox"]))
}
}

})