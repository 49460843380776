import { AxiosResponse } from "axios";
import { RoomObject } from "../../library/types/room/rooms";
import api from "../api";

export type RoomsToPost = {
  update: RoomObject[];
  delete: RoomObject[];
  insert: RoomObject[];
};

export default {
  getProjectRooms(projectNr: string): Promise<AxiosResponse<RoomObject[]>> {
    return api().get("/api/project/" + projectNr + "/room");
  },

  updateRooms(
    projectNr: string,
    updatedRooms: RoomsToPost,
  ): Promise<AxiosResponse> {
    return api().post(
      "/api/project/" + projectNr + "/room",
      JSON.stringify(updatedRooms),
    );
  },

  moveRoomsFromAddress(
    projectNumber: string,
    fromAddress: string,
    toAddress: string,
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/address/move`,
      JSON.stringify({ fromAddressId: fromAddress, toAddressId: toAddress }),
    );
  },
};
