import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-label-paragraph" }

import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import { computed, ref } from "vue";
import { I18nMessage } from "@/library/types/locales/i18nMessage";
import { useIndexStore } from "@/store";
import { postLocaleCurrency } from "@/library/api/helpers";
import { ProjectDetails } from "@/library/types/project/project";
import CustomSelect from "../common/CustomSelect.vue";

type CurrencyOption = {
  value: ProjectDetails["valuta"];
  label: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrencyModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { t, messages, locale } = useI18n();
const indexStore = useIndexStore();
const currentCurrency = ref(indexStore.currentProjectCurrency);
const isLoading = ref(false);

const currencies = computed(() => {
  const localeCurrencies = (
    messages.value[locale.value.toLocaleLowerCase()] as I18nMessage
  ).list.currencies;
  return Object.keys(localeCurrencies).map(currency => ({
    value: currency,
    label:
      localeCurrencies[currency as keyof I18nMessage["list"]["currencies"]].name
  }));
});

const postCurrency = async (currency: CurrencyOption["value"]) => {
  isLoading.value = true;
  const result = await postLocaleCurrency(currency);
  isLoading.value = false;

  if (!result.success) return;
  indexStore.changeCurrency(currency);
  emit("close");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    title: _unref(t)('title.currency'),
    type: 'verification',
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
    confirmText: _unref(t)('button.save'),
    onConfirm: _cache[2] || (_cache[2] = ($event: any) => (postCurrency(currentCurrency.value))),
    isLoading: isLoading.value
  }, {
    modalHeaderContent: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.currency")), 1)
    ]),
    container: _withCtx(() => [
      _createVNode(CustomSelect, {
        class: "mt-3",
        options: currencies.value,
        modelValue: currentCurrency.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentCurrency).value = $event)),
        label: 'label',
        reducedKey: 'value',
        clearable: false
      }, null, 8, ["options", "modelValue"])
    ]),
    _: 1
  }, 8, ["title", "confirmText", "isLoading"]))
}
}

})