<template>
  <div
    class="row ms-0 me-0 border-bottom table-header-wrapper"
    :class="[!currentTableHeaders && 'border-top', isSticky && 'sticky-header']"
  >
    <div
      class="col d-flex align-items-center pe-0 pt-0 ps-0"
      v-if="!currentTableHeaders"
    >
      <p class="text-small">{{ noRowsText }}</p>
    </div>
    <template v-else>
      <div
        class="d-flex align-items-center pe-0 pt-0 ps-0"
        v-for="(header, headerKey, index) in currentTableHeaders"
        :class="header.columnStyling"
        :key="index"
      >
        <div
          class="d-flex align-items-center w-100"
          :class="type === 'sub' && index === 0 && 'left-margin'"
        >
          <GreenifiedCheckbox
            v-if="header.actions.includes('checkbox') && !hideHeaderCheckbox"
            v-model="localToggleCheckbox"
            @update:modelValue="
              emit('update:toggleCheckbox', localToggleCheckbox)
            "
            :tableMargin="type"
          />

          <div class="d-flex justify-content-between w-100">
            <TableHeader
              :headerText="header.headerText"
              :sortingColumn="headerKey"
              :type="type"
              v-model:sortingOptions="localSortingOptions"
              @update:sortingOptions="
                emit('update:sortingOptions', localSortingOptions)
              "
              :isSortable="header.isSortable"
              :soldProducts="soldProducts"
              :productsForSale="productsForSale"
              @update:isChecked="
                boolean => emit('update:toggleCheckbox', boolean)
              "
              v-model:isChecked="localToggleCheckbox"
            />
            <GreenifiedButton
              v-if="
                header.actions.includes('edit') &&
                type === 'sub' &&
                index === Object.keys(currentTableHeaders).length - 1
              "
              size="auto"
              noWrap
              :text="t('button.editAll')"
              class="right-margin"
              @click="emit('editAll')"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, watch } from "vue";
import { withDefaults, defineProps } from "vue";
import TableHeader from "./TableHeader.vue";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import { SortingSettings } from "@/library/types/sorting/sorting";
import { TableColumns } from "@/library/types/tables/columns";

type TableHeaderProps = {
  sortingOptions?: SortingSettings;
  type?: "main" | "sub";
  hideHeaderCheckbox?: boolean;
  tableHeaders: TableColumns["columns"] | null;
  toggleCheckbox?: boolean;
  noRowsText?: string;
  isSticky?: boolean;
  soldProducts?: number;
  productsForSale?: number;
};

const tableHeaderProps = withDefaults(defineProps<TableHeaderProps>(), {
  sortingOptions: () => ({
    asc: false,
    col: "",
  }),
  selectedTableHeader: "",
  isAscending: false,
  type: "main",
  hideHeaderCheckbox: false,
  toggleCheckbox: false,
  noRowsText: "",
  isSticky: false,
  soldProducts: 0,
  productsForSale: 0,
});

const { t } = useI18n();
const localSortingOptions = ref(tableHeaderProps.sortingOptions);

const localToggleCheckbox = ref(tableHeaderProps.toggleCheckbox);

const currentTableHeaders = ref(tableHeaderProps.tableHeaders);

const emit = defineEmits([
  "update:sortingOptions",
  "update:toggleCheckbox",
  "editAll",
]);

watch(
  () => tableHeaderProps.toggleCheckbox,
  newCheckboxValue => {
    localToggleCheckbox.value = newCheckboxValue;
  },
);
</script>

<style scoped>
.table-header-wrapper {
  height: 44px;
}

.subtable-margin {
  margin-left: 28px;
}

.edit-button-wrapper {
  position: absolute;
  right: 80px;
  z-index: 4;
  top: 4px;
  width: 3rem;
}

.sticky-header {
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 4;
  background-color: white;
}
</style>
