<template>
  <div
    class="accordion-wrapper"
    :class="{ visible: showContent }"
    :style="{ backgroundColor: backgroundColor }"
  >
    <div
      class="accordion-content"
      :class="{ expanded: showContent }"
      ref="contentRef"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  withDefaults,
  defineProps,
  onMounted,
  ref,
  nextTick,
  watch,
} from "vue";

type AccordionContainerProps = {
  toggleAccordion: boolean;
  backgroundColor?: string;
};

const accordionContainerProps = withDefaults(
  defineProps<AccordionContainerProps>(),
  {
    toggleAccordion: false,
    backgroundColor: "white",
  },
);
let showContent = ref(false);
let contentRef = ref<HTMLElement | null>(null);

onMounted(() => {
  showContent.value = accordionContainerProps.toggleAccordion;
});

watch(
  () => accordionContainerProps.toggleAccordion,
  (expanded: boolean) => {
    if (expanded !== showContent.value) {
      nextTick(() => {
        const contentReference = contentRef.value;
        if (contentReference) {
          showContent.value = expanded;
        }
      });
    }
  },
);
</script>

<style scoped>
.accordion-wrapper {
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.accordion-content.expanded {
  max-height: 20000px;
  transition: max-height 1s;
}
</style>
