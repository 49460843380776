<template>
  <div class="container-fluid ps-0 pe-0 me-0 ms-0">
    <div class="row top-navbar align-items-center px-4">
      <slot name="top-navbar"></slot>
    </div>

    <div class="row h-100" :class="wideContainer ? 'wide-bg' : ''">
      <div class="col-12 d-flex">
        <div
          :class="openSideMenu ? 'open-menu px-4 ' : 'closed-menu'"
          class="side-menu h-100"
        >
          <slot name="side-bar"></slot>
        </div>
        <div
          class="layout-container ps-4 pe-3 position-relative"
          :class="openSideMenu && 'ms-3'"
        >
          <slot name="container"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  onBeforeMount,
  onUnmounted,
  ref,
  withDefaults,
  defineProps
} from "vue";
type GrLayoutTemplateProps = {
  openSideMenu: boolean;
  wideContainer?: boolean;
};

withDefaults(defineProps<GrLayoutTemplateProps>(), {
  openSideMenu: true,
  wideContainer: false
});

const smallNavbar = ref(false);

const handleScroll = () => {
  if (document.documentElement.scrollTop >= 50) {
    smallNavbar.value = true;
  } else if (document.documentElement.scrollTop < 14.8) {
    smallNavbar.value = false;
  }
};

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped lang="scss">
@import "../assets/scss/navbar.scss";

.side-menu {
  top: 2.5rem;
  left: 0;
  background-color: #f8f5ee;
  height: calc(100vh - 2.5rem);
  transition: all 0.4s ease;
  transition-delay: 0s;
  padding-bottom: 5rem;
  width: 300px;
}

.open-menu {
  width: 307px;
  min-width: 307px;
}

.closed-menu {
  width: 0px;
  min-width: 0px;
  transition: 0.2s;
}

.open-menu {
  transition: 0.2s;
  opacity: 1;
}
</style>
