<template>
  <div class="container-fluid ps-0">
    <div class="row align-items-center mb-3" v-if="currentProductInformation">
      <FieldLabel
        :fieldLabel="t('label.location')"
        :showLabel="true"
        :requiredField="1"
        :presetFieldValue="currentProductInformation.location"
        :formIsSubmitted="formIsSubmitted"
      />

      <div class="col-8 pe-0">
        <SelectRoom v-model="currentProductInformation.location" />
      </div>
    </div>
  </div>
  <form action="" ref="productForm">
    <GreenifiedForm
      v-if="currentProductInformation"
      ref="greenifiedForm"
      v-model:formValues="currentProductInformation.green_struc"
      :excludedFields="defaultExcludedFields"
      :formFields="store.projectDetails.struc"
      v-model:supplierCode="currentProductInformation.supplier_code"
      @showAddSupplier="showAddSupplier = true"
      v-model:productImages="currentProductInformation.images"
      v-model:formIsSubmitted="formIsSubmitted"
      @update:productCategory="(category: ProductInformation['category']) =>{ 
        if (!currentProductInformation) return;
        currentProductInformation.category = category}"
      :formImages="productImages()"
    />
  </form>
  <div class="container-fluid ps-0">
    <div class="row mb-3">
      <FieldLabel :fieldLabel="t('label.otherInformation')" :showLabel="true" />

      <div class="col-8">
        <InputTextField
          v-if="
            typeof currentProductInformation?.green_struc.other_information ===
            'string'
          "
          v-model="currentProductInformation.green_struc.other_information"
        />
      </div>
    </div>
  </div>

  <AddProjectSupplierModal
    v-if="showAddSupplier && currentProductInformation"
    @added="
        (supplier: ProductInformation['supplier_code']) => {
          if (!currentProductInformation) return;
          currentProductInformation.supplier_code = supplier
        }
      "
    @close="showAddSupplier = false"
  ></AddProjectSupplierModal>
</template>

<script setup lang="ts">
import GreenifiedForm from "@/components/form/GreenifiedForm.vue";

import {
  defineProps,
  withDefaults,
  ref,
  Ref,
  onBeforeMount,
  defineExpose,
} from "vue";
import { ProductInformation } from "../../../library/types/product/productGroup";
import { useIndexStore } from "@/store";
import { defaultExcludedFields } from "@/library/helpers/excludedFormFields";
import SelectRoom from "@/components/SelectRoom.vue";
import FieldLabel from "../fields/FieldLabel.vue";
import InputTextField from "@/components/common/InputTextField.vue";
import { FormImagesObject } from "@/library/types/product/productImage";
import { useI18n } from "vue-i18n";
import AddProjectSupplierModal from "@/components/modal/AddProjectSupplierModal.vue";

const { t } = useI18n();

type GeneralProductInformationProps = {
  productInformation: ProductInformation | null;
};

const generalProductInformationProps = withDefaults(
  defineProps<GeneralProductInformationProps>(),
  {
    productInformation: null,
  },
);

const productForm: Ref<HTMLFormElement | null> = ref(null);
const store = useIndexStore();
const currentProductInformation: Ref<ProductInformation | null> = ref(
  generalProductInformationProps.productInformation,
);
const greenifiedForm = ref<InstanceType<typeof GreenifiedForm>>();
const showAddSupplier = ref(false);
const isLoading = ref(true);
const formIsSubmitted = ref(false);

const validateForm = (): boolean => {
  formIsSubmitted.value = true;
  if (!productForm.value) return false;
  productForm.value.reportValidity();
  if (!greenifiedForm.value?.validateGreenifiedForm()) return false;

  return true;
};

const productImages = () => {
  const currentProjectStructure = { ...store.projectDetails.struc };
  const currentProductImages: FormImagesObject = {
    bild_1: {
      fieldKey: "",
      imageHash: "",
    },
    bild_2: {
      fieldKey: "",
      imageHash: "",
    },
  };

  for (const structureKey in currentProjectStructure) {
    const structureField = currentProjectStructure[structureKey];
    const subFields = structureField.sub_fields;
    for (const fieldKey in subFields) {
      const field = subFields[fieldKey];
      const fieldValue =
        currentProductInformation.value?.green_struc[field.key];

      if (field.type === "image" && field.name !== "bild_5") {
        const fieldValueAsString = fieldValue ? String(fieldValue) : "";
        currentProductImages[field.name as keyof FormImagesObject] = {
          fieldKey: field.key,
          imageHash: fieldValueAsString,
        };
      }
    }
  }

  return currentProductImages;
};

onBeforeMount(() => {
  if (
    currentProductInformation.value &&
    !("other_information" in currentProductInformation.value.green_struc)
  )
    currentProductInformation.value.green_struc["other_information"] = "";

  isLoading.value = false;
});

defineExpose({ validateForm });
</script>

<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
  font-size: bold;
}
</style>
