<template>
  <div class="container-fluid ps-0 pe-0 d-flex flex-column simple-container">
    <div
      class="row top-navbar px-4 d-flex align-items-center justify-content-end"
    >
      <slot name="top-navbar"></slot>
    </div>
    <div class="navbar-wrapper">
      <div
        :class="smallNavbar ? 'small-logo-wrapper' : 'logo-wrapper'"
        class="px-4 row w-100 align-items-center"
      >
        <div class="col-12">
          <img height="30" src="@/assets/img/logo.svg" />
        </div>
      </div>

      <div class="gr-color sub-wrapper row px-4">
        <slot name="bottom-navbar"></slot>
      </div>
    </div>

    <div class="layout-container row ps-4">
      <div :class="wideContainer ? 'wide-bg' : ''">
        <slot name="container"></slot>
      </div>
    </div>

    <footer class="main-footer gr-color row px-4">
      <div class="col-12">
        <slot name="footer"></slot>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import {
  onBeforeMount,
  onUnmounted,
  ref,
  withDefaults,
  defineProps
} from "vue";

type GrLayoutSimpleProps = {
  wideContainer?: boolean;
};

withDefaults(defineProps<GrLayoutSimpleProps>(), {
  wideContainer: false
});

const smallNavbar = ref(false);

const handleScroll = () => {
  if (document.documentElement.scrollTop >= 50) {
    smallNavbar.value = true;
  } else if (document.documentElement.scrollTop < 14.8) {
    smallNavbar.value = false;
  }
};

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
@import "../assets/scss/navbar.scss";

.main-footer {
  flex: 3;
  max-height: max-content;
  padding-top: 40px;
  padding-bottom: 40px;
}

.navbar-wrapper {
  position: sticky;
  z-index: 6;
  top: 0;
  left: 0;
  background-color: white;
}

.layout-container {
  flex: 2;
  margin-top: 1.5rem;
}

.sub-wrapper {
  font-weight: 600;
  height: 2.5rem;
  font-size: 12.5px !important;
}

.simple-container {
  min-height: 100vh;
}
</style>
