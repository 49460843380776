import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row mt-5 align-items-center" }
const _hoisted_3 = { class: "col-sm-3" }

import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFound',
  setup(__props) {

const router = useRouter();

const navToHome = () => {
  router.push({ name: "home" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"row\" data-v-15dbfd96><div class=\"col-sm-6\" data-v-15dbfd96><h5 data-v-15dbfd96>404 URL not found</h5></div><div class=\"col\" data-v-15dbfd96></div></div><div class=\"row mt-3\" data-v-15dbfd96><div class=\"col-sm-12\" data-v-15dbfd96><p data-v-15dbfd96> The URL that you are attempting to use does not exist. Click the button below to return to the home page. </p></div></div>", 2)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GreenifiedButton, {
          size: "thin",
          btnStyle: "black",
          text: 'Return',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (navToHome()))
        })
      ])
    ])
  ]))
}
}

})