<template>
  <MainFilter
    :filterTitle="`${t('title.handleProducts')} - ${t('label.unit', {
      count: quantities
    })}`"
    :filterText="t('paragraph.handleProducts')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="filter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :productStatus="['J']"
    :hasProducts="quantities > 0"
  />
  <ProductTable
    :actionStatuses="['CP', 'F', 'Å', 'S', 'K']"
    :noRowsText="t('label.noRowsPositive')"
    v-model:filter="filter"
    :sortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :presetFilter="actionFilter"
  />
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, Ref, ref, defineProps, withDefaults } from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "@/library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { useIndexStore } from "@/store";
import ProductTable from "@/components/table/ProductTable.vue";

type ProjectActionProps = {
  filterQuery?: string;
};

withDefaults(defineProps<ProjectActionProps>(), {
  filterQuery: ""
});

const indexStore = useIndexStore();
const actionFilter: PresetFilter = {
  filter: [],
  group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
  status: ["J"]
};

const { t } = useI18n();
const quantities = computed(() => {
  return indexStore.quantities?.J ?? 0;
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];
const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
</script>
