import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

import HomeView from "../views/HomeView.vue";
import UsersView from "../views/UsersView.vue";
import ProjectAction from "../views/ProjectAction.vue";
import ProjectRepurpose from "../views/ProjectRepurpose.vue";
import ProjectRecondition from "../views/ProjectRecondition.vue";
import ProjectRepurposeOverview from "../views/ProjectRepurposeOverview.vue";
import ProjectSettingsView from "../views/ProjectSettingsView.vue";
import ResetPassword from "../views/ResetPassword.vue";
import RegisterView from "../views/RegisterView.vue";
import ProjectRecycle from "../views/ProjectRecycle.vue";
import ProjectRecycleOverview from "../views/ProjectRecycleOverview.vue";
import AdminTokenLabelOrderView from "../views/AdminTokenLabelOrderView.vue";
import ProjectRoomView from "../views/ProjectRoomView.vue";
import NotFound from "../views/NotFound.vue";
import SalesView from "../views/SalesView.vue";
import SalesOverviewGuestView from "@/views/SalesOverviewGuestView.vue";
import LoginView from "@/views/LoginView.vue";
import { useAuthStore } from "@/store/auth";
import GreenifiedSalesView from "@/views/GreenifiedSalesView.vue";
import InternalSalesView from "@/views/InternalSalesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    name: "login",
    path: "/login",
    component: LoginView,
    meta: {
      hasFilter: false,
      middleware: "guest",
      mainHeader: null,
      subHeader: null,
      title: `Login`,
    },
  },
  {
    path: "/project",
    name: "projectOverview",
    component: HomeView,
    meta: {
      hasFilter: false,
      middleware: "auth",
      mainHeader: 5,
      subHeader: null,
      title: `Dashboard`,
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      hasFilter: false,
      middleware: "auth",
      mainHeader: 5,
      subHeader: null,
      title: `Home`,
    },
  },
  {
    path: "/project/users",
    name: "projectUsers",
    component: UsersView,
    meta: {
      hasFilter: false,
      middleware: "auth",
      mainHeader: 6,
      subHeader: null,
      title: `Users`,
    },
  },
  {
    path: "/project/settings",
    name: "projectSettings",
    component: ProjectSettingsView,
    meta: {
      hasFilter: false,
      middleware: "auth",
      mainHeader: 7,
      subHeader: null,
      title: `Project settings`,
    },
  },
  {
    path: "/project/settings/room",
    name: "projectRoom",
    component: ProjectRoomView,
    meta: {
      hasFilter: false,
      middleware: "auth",
      mainHeader: 7,
      subHeader: null,
      title: `Project rooms`,
    },
  },
  {
    path: "/item/project/:status?",
    name: "projects",
    meta: {
      hasFilter: false,
      middleware: "auth",
      mainHeader: null,
      subHeader: null,
      title: `Projects`,
    },
    props: true,
    component: () => import("../views/ProjectModal.vue"),
  },
  {
    path: "/item/project/action/:filterQuery?",
    name: "projectAction",
    component: ProjectAction,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 1,
      subHeader: null,
      title: `Handle products`,
    },
  },

  {
    path: "/item/project/repurpose/:filterQuery?",
    name: "projectRepurpose",
    component: ProjectRepurpose,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 2,
      subHeader: 1,
      title: `Repurpose`,
    },
  },
  {
    path: "/item/project/recondition/:filterQuery?",
    name: "projectRecondition",
    component: ProjectRecondition,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 2,
      subHeader: 2,
      title: `Repurpose`,
    },
  },
  {
    path: "/item/project/repurpose/overview/:filterQuery?",
    name: "projectRepurposeOverview",
    component: ProjectRepurposeOverview,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 2,
      subHeader: 3,
      title: `Repurpose overview`,
    },
  },
  {
    path: "/item/project/recycle/:filterQuery?",
    name: "projectRecycle",
    component: ProjectRecycle,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 4,
      subHeader: 1,
      title: `Recycle`,
    },
  },
  {
    path: "/item/project/recycle/overview/:filterQuery?",
    name: "projectRecycleOverview",
    component: ProjectRecycleOverview,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 4,
      subHeader: 2,
      title: `Recycle overview`,
    },
  },
  {
    path: "/item/project/sell/:filterQuery?",
    name: "projectSell",
    component: SalesView,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 3,
      subHeader: 1,
      title: `Sell`,
    },
  },
  {
    path: "/item/project/sell/greenified/:setTab?/:filterQuery?",
    name: "greenifiedSaleOverview",
    component: GreenifiedSalesView,
    props: (route: RouteLocationNormalized) => ({
      setTab: route.params.setTab,
      filterQuery: route.params.filterQuery,
    }),
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 3,
      subHeader: 2,
      title: `Greenified sales`,
    },
  },
  {
    path: "/item/project/sell/internal/:setTab?/:filterQuery?",
    name: "internalSaleOverview",
    component: InternalSalesView,
    props: (route: RouteLocationNormalized) => ({
      setTab: route.params.setTab,
      filterQuery: route.params.filterQuery,
    }),
    meta: {
      hasFilter: true,
      middleware: "auth",
      mainHeader: 3,
      subHeader: 3,
      title: `Internal sales`,
    },
  },

  {
    name: "guestInternalSell",
    path: "/sell/guest/:token/:filterQuery?",
    component: SalesOverviewGuestView,
    props: true,
    meta: {
      hasFilter: true,
      middleware: "guest",
      mainHeader: null,
      subHeader: null,
      title: `Sell`,
    },
  },

  {
    path: "/reset-password",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      hasFilter: false,
      mainHeader: null,
      subHeader: null,
      middleware: "guest",
      title: `Reset`,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      hasFilter: false,
      mainHeader: null,
      subHeader: null,
      middleware: "guest",
      title: `Register`,
    },
  },

  {
    name: "notFound",
    path: "/not-found",
    component: NotFound,
    meta: {
      hasFilter: false,
      mainHeader: null,
      subHeader: null,
      middleware: "guest",
      title: `404 Not found`,
    },
  },
  {
    name: "adminLabelOrder",
    path: "/admin/label-order/:token",
    component: AdminTokenLabelOrderView,
    meta: {
      hasFilter: false,
      mainHeader: null,
      subHeader: null,
      middleware: "guest",
      title: `Admin Label order`,
    },
  },
  { path: "/:catchAll(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

router.beforeResolve(async (to, from, next) => {
  const authStore = useAuthStore();

  document.title = `${to.meta.title} - ${process.env.VUE_APP_TITLE}`;

  if (to.meta.middleware === "guest") {
    return next();
  }

  if (!authStore.isAuthenticated) return next({ name: "login" });

  return next();
});

export default router;
