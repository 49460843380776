import { useI18n } from "vue-i18n";
import { I18nMessage } from "../types/locales/i18nMessage";
import { ProductGroupSale } from "../types/product/productGroup";
import { ReconditionInformation } from "../types/product/productRecondition";

function getReconditionText(
  reconditionLocales: I18nMessage["list"]["reconditions"],
  productReconditions: ReconditionInformation,
  productGroup: ProductGroupSale
) {
  if (productGroup.status === "R") {
    return reconditionLocales[
      productReconditions.type as keyof I18nMessage["list"]["reconditions"]
    ].name;
  } else if (productGroup.status === "M") {
    return reconditionLocales[
      productReconditions.type as keyof I18nMessage["list"]["reconditions"]
    ].past;
  }
}

export const getTranslatedSubActions = (productGroup: ProductGroupSale) => {
  const { messages, locale } = useI18n();

  let reconditionText = "";
  let tooltip = "";
  const reconditionLocales = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).reconditions;

  if (productGroup.recondition) {
    productGroup.recondition.forEach(recondition => {
      const productReconditions = recondition;
      reconditionText =
        reconditionText +
        getReconditionText(
          reconditionLocales,
          productReconditions,
          productGroup
        ) +
        ", ";
      tooltip =
        tooltip +
        "<b>" +
        getReconditionText(
          reconditionLocales,
          productReconditions,
          productGroup
        ) +
        "</b>: " +
        (recondition.info ?? messages.value.label.missionDescription) +
        "<br/>";
    });
  }

  reconditionText = reconditionText.substring(0, reconditionText.length - 2);
  if (reconditionText === "") {
    if (productGroup.comment) {
      reconditionText = productGroup.comment;
    } else {
      reconditionText = (
        messages.value[locale.value.toLocaleLowerCase()]
          .label as I18nMessage["label"]
      ).currentCondition;
    }
  } else {
    if (productGroup.comment) {
      tooltip =
        tooltip +
        "<br/><b>" +
        (
          messages.value[locale.value.toLocaleLowerCase()]
            .label as I18nMessage["label"]
        ).comment +
        "</b>: " +
        productGroup.comment;
    }
  }
  return { text: reconditionText, tip: tooltip };
};
