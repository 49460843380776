<template>
  <div
    class="w-100"
    v-if="messageField"
    v-html="getMessage(messageField)"
  ></div>
</template>

<script setup lang="ts">
import { MessageFieldType } from "@/library/types/structure/subfieldTypes";
import { withDefaults, defineProps } from "vue";

type MessageFieldProps = {
  messageField: MessageFieldType | null;
};

withDefaults(defineProps<MessageFieldProps>(), {
  messageField: null,
});

const getMessage = (messageField: MessageFieldType) => {
  return messageField.message.replace(
    'href="https://vimeo.com/',
    'target="_blank" href="https://player.vimeo.com/video/',
  );
};
</script>

´
