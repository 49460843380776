<template>
  <MainFilter
    :filter-title="t('title.roomsHandler')"
    :filter-text="t('paragraph.roomsHandler')"
  />

  <room-form :key="currentProject"></room-form>
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import RoomForm from "@/components/form/RoomForm.vue";
import { useIndexStore } from "@/store";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const indexStore = useIndexStore();

const currentProject = indexStore.currentProjectNumber;
</script>
<style lang="scss" scoped></style>
