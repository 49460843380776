import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid table-footer py-4 border-top mt-5 ps-0 pe-0" }
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "subheading-small" }
const _hoisted_5 = { class: "col-9 d-flex justify-content-end" }
const _hoisted_6 = {
  key: 0,
  class: "ms-3"
}

import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import InformationTooltip from "@/components/common/InformationTooltip.vue";
import { tableFooterButtons } from "@/library/helpers/tableFooterButtons";
import { ActionStatus } from "@/library/types/actions/actions";

import { useI18n } from "vue-i18n";

type TableFooterProps = {
  footerText?: string;
  actionStatuses?: ActionStatus[];
  toggleDisabledButtons?: boolean;
  toggleDisableCombineProductsButton?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TableFooter',
  props: {
    footerText: { default: "" },
    actionStatuses: { default: () => [] },
    toggleDisabledButtons: { type: Boolean, default: false },
    toggleDisableCombineProductsButton: { type: Boolean, default: false }
  },
  emits: ["action"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;
const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.footerText), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableFooterButtons), (button, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (_ctx.actionStatuses.includes(button.status))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (button.tooltipText && _ctx.toggleDisabledButtons)
                    ? (_openBlock(), _createBlock(InformationTooltip, {
                        key: 0,
                        infoText: _unref(t)(button.tooltipText),
                        tooltipWidth: 'small'
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(GreenifiedButton, {
                            btnStyle: button.color,
                            disabled: button.toggleDisabled && _ctx.toggleDisabledButtons,
                            text: 
                    button.buttonText.translate
                      ? _unref(t)(button.buttonText.text)
                      : button.buttonText.text
                  ,
                            size: 'thin',
                            onClick: ($event: any) => (emit('action', button))
                          }, null, 8, ["btnStyle", "disabled", "text", "onClick"])
                        ]),
                        _: 2
                      }, 1032, ["infoText"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (button.action === 'combineProducts')
                          ? (_openBlock(), _createBlock(GreenifiedButton, {
                              key: 0,
                              btnStyle: button.color,
                              disabled: 
                    button.toggleDisabled && _ctx.toggleDisableCombineProductsButton
                  ,
                              text: _unref(t)(button.buttonText.text).replace(/^.*?:\s*/, ''),
                              size: 'thin',
                              onClick: ($event: any) => (emit('action', button))
                            }, null, 8, ["btnStyle", "disabled", "text", "onClick"]))
                          : (
                  button.action === 'availableInternal' ||
                  button.action === 'soldInternal'
                )
                            ? (_openBlock(), _createBlock(GreenifiedButton, {
                                key: 1,
                                btnStyle: button.color,
                                disabled: button.toggleDisabled && _ctx.toggleDisabledButtons,
                                text: _unref(t)(button.buttonText.text).replace(/^.*?:\s*/, ''),
                                size: 'thin',
                                onClick: ($event: any) => (emit('action', button))
                              }, null, 8, ["btnStyle", "disabled", "text", "onClick"]))
                            : (_openBlock(), _createBlock(GreenifiedButton, {
                                key: 2,
                                btnStyle: button.color,
                                disabled: button.toggleDisabled && _ctx.toggleDisabledButtons,
                                text: 
                    button.buttonText.translate
                      ? _unref(t)(button.buttonText.text)
                      : button.buttonText.text
                  ,
                                size: 'thin',
                                type: button.action === 'saveRoom' ? 'submit' : 'button',
                                form: button.action === 'saveRoom' ? 'room-id' : '',
                                onClick: ($event: any) => (emit('action', button))
                              }, null, 8, ["btnStyle", "disabled", "text", "type", "form", "onClick"]))
                      ], 64))
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ]))
}
}

})