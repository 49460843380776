<template>
  <div class="d-flex flex-column justify-content-between h-100">
    <div
      class="main-image-wrapper gr-color position-relative d-flex justify-content-center align-items-center"
    >
      <p v-if="!currentMainImage?.hash" class="missing-text">
        {{ t("label.missingImage") }}
      </p>
      <template v-else>
        <div
          class="d-flex justify-content-between arrow-box-wrapper w-100 px-2"
        >
          <div class="arrow-box">
            <img
              src="https://greenified.se/wp-content/themes/input/arrow_next.svg"
              alt="gallery-left-arrow"
              class="gallery-left-arrow"
              @click="currentMainImage = changeMainImage('left')"
            />
          </div>

          <div class="arrow-box">
            <img
              src="https://greenified.se/wp-content/themes/input/arrow_next.svg"
              alt="gallery-right-arrow"
              class="gallery-right-arrow"
              @click="currentMainImage = changeMainImage('right')"
            />
          </div>
        </div>

        <img
          :src="`${contentUrl}${currentMainImage?.hash}`"
          alt="main-image"
          class="main-image"
        />
      </template>

      <div class="action-box-wrapper">
        <div class="action-box me-3">
          <img
            src="@/assets/img/download-icon.svg"
            alt="download icon"
            class="action-icon"
            @click="
              downloadImage(currentMainImage?.url, currentMainImage?.hash)
            "
          />
        </div>
        <div class="action-box">
          <img
            src="@/assets/img/resize-icon.svg"
            alt="change size icon"
            class="action-icon size-icon"
            @click="openImage(currentMainImage?.url, currentMainImage?.hash)"
          />
        </div>
      </div>
    </div>
    <div class="gallery-image-wrapper">
      <div
        class="gr-color gallery-image"
        v-for="(image, i) in productImages"
        :key="i"
      >
        <img
          v-if="image.hash"
          @click="currentMainImage = image"
          :src="`${contentUrl}${image.hash}`"
          alt="gallery-image"
          class="w-100 h-100 object-fit-contain"
          :class="currentMainImage.hash === image.hash && 'active-border'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, ref, computed, onMounted, Ref } from "vue";
import {
  ProductImage,
  ProductImages
} from "@/library/types/product/productImage";
import { downloadImageApi } from "@/library/api/media";
import { useI18n } from "vue-i18n";

type ProductImageContainerProps = {
  mainImage: { url: string; hash: string };
  itemImages: ProductImages;
};

const productImageContainerProps = withDefaults(
  defineProps<ProductImageContainerProps>(),
  {
    mainImage: () => ({ url: "", hash: "" }),
    itemImages: () => ({
      bild_1: { url: "", hash: "" },
      bild_2: { url: "", hash: "" }
    })
  }
);

const { t } = useI18n();
const productImages: Ref<ProductImage[]> = ref([]);
const currentMainImage = ref(productImageContainerProps.mainImage);

const contentUrl = computed(() => {
  return process.env.VUE_APP_CONTENT_URL;
});

onMounted(() => {
  if (!productImageContainerProps.itemImages) return;
  const sortedImageKeys = Object.keys(
    productImageContainerProps.itemImages
  ).sort((a, b) => {
    const numA = parseInt(a.split("_")[1]);
    const numB = parseInt(b.split("_")[1]);
    return numA - numB;
  });

  sortedImageKeys.forEach(key => {
    productImages.value.push(
      productImageContainerProps.itemImages[key as keyof ProductImages] ?? {
        hash: "",
        url: ""
      }
    );
  });

  if (productImages.value.length < 5) {
    let imagesMissing = 5 - productImages.value.length;
    for (let i = 0; i < imagesMissing; i++) {
      productImages.value.push({ hash: "", url: "" });
    }
  }
});

const downloadImage = async (imageUrl: string, hash: string) => {
  const result = await downloadImageApi(imageUrl);

  if (!result.success) return;

  const url = window.URL.createObjectURL(new Blob([result.result]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", hash + ".jpg");
  document.body.appendChild(link);
  link.click();
};

const openImage = (url: string, hash: string) => {
  window.open(url ?? contentUrl.value + hash, "_blank", "noreferrer");
};

const changeMainImage = (direction: "left" | "right"): ProductImage => {
  const currentImageIndex = productImages.value.findIndex(
    image => image.hash === currentMainImage.value.hash
  );

  if (direction === "left") {
    let lastAvailableIndex = null;

    for (let i = productImages.value.length - 1; i !== 0; i--) {
      if (productImages.value[i].hash.length) {
        lastAvailableIndex = i;
        break;
      }
    }

    if (!lastAvailableIndex) return { hash: "", url: "" };

    return currentImageIndex === 0
      ? productImages.value[lastAvailableIndex]
      : productImages.value[currentImageIndex - 1];
  }

  const validImages: ProductImage[] = productImages.value.filter(
    image => image.hash.length
  );

  const firstAvailableIndex = productImages.value.findIndex(
    productImage => productImage.hash.length
  );

  return currentImageIndex === validImages.length - 1
    ? productImages.value[firstAvailableIndex]
    : productImages.value[currentImageIndex + 1];
};
</script>

<style scoped>
.action-icon {
  width: 2.2rem;
}

.size-icon {
  width: 1.6rem;
}

.action-box-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
}

.action-box {
  border-radius: 50%;
  background-color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
}

.arrow-box-wrapper {
  position: absolute;
}

.arrow-box {
  background-color: white;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.arrow-box:hover,
.action-box:hover {
  border: 1px solid black;
  transition: 0.2s;
}

.gallery-left-arrow,
.gallery-right-arrow {
  width: 1.2rem;
  height: 1.2rem;
}

.gallery-left-arrow {
  transform: rotate(180deg);
}

.empty-image {
  width: 73%;
  aspect-ratio: 1/1;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 60vh;
  aspect-ratio: 1/1;
}

.custom-col-5 {
  flex: 0 0 20%;
  height: 100%;
}

.gallery-image {
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid transparent;
}

.active-border {
  border: 1px solid black;
}

.main-image-wrapper {
  width: 100%;
  height: 60vh;
  aspect-ratio: 1/1;
}

.left-container {
  height: 100%;
  aspect-ratio: 1/1;
}

.gallery-image-wrapper {
  display: flex;
  gap: 10px;
}
</style>
