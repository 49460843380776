import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 d-flex justify-content-end" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "text-tiny ms-2 emphasis" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12" }

import "vuetify/styles";
import { VRangeSlider } from "vuetify/lib/components/index.mjs";
import {
  Ref,
  ref
} from "vue";
import { FilterInterval } from "@/library/types/filter/filter";
import InputField from "./InputField.vue";
import { useI18n } from "vue-i18n";
type DoubleRangeSliderProps = {
  maxValue: number;
  intervalsSettings: FilterInterval;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DoubleRangeSlider',
  props: {
    maxValue: { default: 999 },
    intervalsSettings: { default: () => ({
      to: 999,
      from: 1
    }) }
  },
  emits: ["update:intervalsSettings"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const doubleRangeSliderProps = __props;
const { t } = useI18n();
const emit = __emit;
const currentIntervalSettings = ref(doubleRangeSliderProps.intervalsSettings);
const range: Ref<number[]> = ref([1, doubleRangeSliderProps.maxValue]);

const sliderValueIsUpdated = ref(false);

const resetIntervalRange = () => {
  range.value = [1, doubleRangeSliderProps.maxValue];
};
__expose({ resetIntervalRange });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputField, {
            "field-value": currentIntervalSettings.value.from,
            "onUpdate:fieldValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((currentIntervalSettings.value.from) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => {
            range.value[0] = currentIntervalSettings.value.from;
            emit('update:intervalsSettings', currentIntervalSettings.value);
          })
            ],
            disabled: _ctx.maxValue === 1,
            rounded: "",
            type: "number",
            "min-value": 1,
            "max-value": _ctx.maxValue
          }, null, 8, ["field-value", "disabled", "max-value"]),
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "fw-bolder ms-1 me-1" }, "-", -1)),
          _createVNode(InputField, {
            "field-value": currentIntervalSettings.value.to,
            "onUpdate:fieldValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((currentIntervalSettings.value.to) = $event)),
              _cache[3] || (_cache[3] = ($event: any) => {
            range.value[1] = currentIntervalSettings.value.to;
            emit('update:intervalsSettings', currentIntervalSettings.value);
          })
            ],
            disabled: _ctx.maxValue === 1,
            rounded: "",
            type: "number",
            "min-value": 1,
            "max-value": _ctx.maxValue
          }, null, 8, ["field-value", "disabled", "max-value"]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)("label.pcs")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(VRangeSlider), {
          modelValue: range.value,
          "onUpdate:modelValue": [
            _cache[4] || (_cache[4] = ($event: any) => ((range).value = $event)),
            _cache[5] || (_cache[5] = ($event: any) => {
          sliderValueIsUpdated.value = !sliderValueIsUpdated.value;
          currentIntervalSettings.value.from = range.value[0];
          currentIntervalSettings.value.to = range.value[1];
          emit('update:intervalsSettings', currentIntervalSettings.value);
        })
          ],
          max: _ctx.maxValue,
          "track-fill-color": 'black',
          "track-size": '1px',
          "thumb-size": '10px',
          disabled: _ctx.maxValue === 1,
          min: 1,
          step: 1,
          strict: "",
          class: "align-center",
          "hide-details": ""
        }, {
          prepend: _withCtx(() => _cache[7] || (_cache[7] = [])),
          append: _withCtx(() => _cache[8] || (_cache[8] = [])),
          _: 1
        }, 8, ["modelValue", "max", "disabled"])
      ])
    ])
  ], 64))
}
}

})