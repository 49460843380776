import { useI18n } from "vue-i18n";
import {
  GreenifiedProductHeader,
  InternalGroupSale,
  OrderGroupSale,
  ProductGroupSale,
  ReservedProduct
} from "../types/product/productGroup";
import {
  Dimensions,
  GreenifiedItem,
  ProductItemSale
} from "../types/product/productItem";
import { TableColumns, TableTypes } from "../types/tables/columns";
import { getTranslatedSubActions } from "./translations";
import { convertCurrencyCodeToSymbol } from "./convertCurrencyCodeToSymbol";
import { useIndexStore } from "@/store";
import { RoomObject } from "../types/room/rooms";
import { ActionOption } from "../types/actions/actions";

export interface SelectableAction extends ActionOption {
  selectable: boolean;
}

export const getRoomTableColumns = (tableData: RoomObject): TableColumns => {
  return {
    type: "room",
    columns: {
      hus: {
        value: "",
        columnStyling: "col-3",
        actions: [],
        isSortable: true,
        headerText: "tableTitle.building",
        tooltip: null,
        inputSettings: {
          placeholder: "placeholders.building",
          type: "",
          maxlength: "",
          required: false
        },
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      van: {
        value: tableData.van,
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "tableTitle.floor",
        tooltip: null,
        inputSettings: {
          placeholder: "placeholders.floor",
          type: "number",
          maxlength: "",
          required: false
        },
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      rumskod: {
        value: tableData.rumskod,
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "tableTitle.roomCode",
        tooltip: null,
        inputSettings: {
          placeholder: "placeholders.roomCode",
          type: "text",
          maxlength: "10",
          required: false
        },
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      rumsnamn: {
        value: tableData.rumsnamn,
        columnStyling: "col-2",
        actions: ["checkbox", "delete"],
        isSortable: true,
        headerText: "tableTitle.room",
        tooltip: null,
        inputSettings: {
          placeholder: "placeholders.room",
          type: "text",
          maxlength: "100",
          required: true
        },
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      new_room: {
        value: "",
        columnStyling: "col-3",
        actions: ["checkbox", "delete"],
        isSortable: true,
        headerText: "tableTitle.newBuilding",
        tooltip: null,
        inputSettings: {
          placeholder: "placeholders.room",
          type: "text",
          maxlength: "100",
          required: true
        },
        selectOptions: [],
        isEditable: true,

        translate: false
      }
    }
  };
};

export const getMainTableColumnsByTableType = (
  tableData: ProductGroupSale | InternalGroupSale,
  tableType: TableTypes
): TableColumns => {
  const { t, locale } = useI18n();
  const indexStore = useIndexStore();
  const tableColumns: TableColumns = {
    type: tableType,
    columns: {
      benamn: {
        value: tableData.benamn,
        columnStyling: "col-3 fw-bolder",
        actions: ["checkbox"],
        isSortable: true,
        headerText: "tableTitle.benamn",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      levkod: {
        value: getTranslatedColumnValue("levkod", tableData.levkod),
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.levkod",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: true
      },
      besk: {
        value: tableData.besk,
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.besk",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      kategori_id: {
        value: getTranslatedColumnValue("kategori_id", tableData.kategori_id),
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.kategori_id",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: true
      },
      antal: {
        value: tableData.antal,
        columnStyling: "col-1",
        actions: [],
        isSortable: true,
        headerText: "label.quantity",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      actions: {
        value: "",
        columnStyling: "col-2 d-flex justify-content-end",
        actions: ["handleVariant"],
        isSortable: false,
        headerText: "",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      }
    }
  };

  if (tableType === "other") {
    return {
      type: tableType,
      columns: {
        benamn: {
          value: tableData.benamn,
          columnStyling: "col-5 fw-bolder",
          actions: [],
          isSortable: false,
          headerText: "tableTitle.benamn",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        kategori_id: {
          value: getTranslatedColumnValue("kategori_id", tableData.kategori_id),
          columnStyling: "col-4",
          actions: [],
          isSortable: false,
          headerText: "label.kategori_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: true
        },

        antal: {
          value: tableData.antal,
          columnStyling: "col-3",
          actions: [],
          isSortable: false,
          headerText: "label.quantity",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  if (tableType === "purchase") {
    const currentData = tableData as InternalGroupSale;
    return {
      type: tableType,
      columns: {
        benamn: {
          value: currentData.benamn,
          columnStyling: "col-3 fw-bolder",
          actions: [],
          isSortable: true,
          headerText: "tableTitle.benamn",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,
          translate: false
        },
        kategori_id: {
          value: getTranslatedColumnValue(
            "kategori_id",
            currentData.kategori_id
          ),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.kategori_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,
          translate: true
        },
        sell_pris: {
          value: t("label.salesPriceUnit", {
            price: parsePrice(
              String(currentData?.sale_info?.pris ?? currentData.sell_pris),
              locale.value.toLocaleLowerCase()
            ),
            currency: convertCurrencyCodeToSymbol(
              indexStore.projectDetails.valuta,
              locale.value.toLocaleLowerCase()
            )
          }),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.pris",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        antal: {
          value: getSoldQuantity(currentData),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.quantity",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        nar_ar_produkten_tillganglig: {
          value:
            currentData?.sale_info?.nar_ar_produkten_tillganglig ??
            currentData.nar_ar_produkten_tillganglig ??
            "",
          columnStyling: "col-1",
          actions: [],
          isSortable: true,
          headerText: "label.nar_ar_produkten_tillganglig",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: true
        },
        actions: {
          value: "",
          columnStyling: "col-2 d-flex justify-content-end",
          actions: ["purchase"],
          isSortable: false,
          headerText: "",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }
  if (
    tableType === "internal" ||
    tableType === "internalSold" ||
    tableType === "internalOngoing"
  ) {
    const currentData = tableData as InternalGroupSale;
    return {
      type: tableType,
      columns: {
        benamn: {
          value: currentData.benamn,
          columnStyling: "col-3 fw-bolder",
          actions:
            tableType === "internal" || tableType === "internalSold"
              ? ["checkbox"]
              : [],
          isSortable: true,
          headerText: "tableTitle.benamn",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: currentData.orders?.length === 0,
          translate: false
        },

        kategori_id: {
          value: getTranslatedColumnValue(
            "kategori_id",
            currentData.kategori_id
          ),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.kategori_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,
          translate: true
        },
        sell_pris: {
          value: t("label.salesPriceUnit", {
            price: parsePrice(
              String(currentData?.sale_info?.pris ?? currentData.sell_pris),
              locale.value.toLocaleLowerCase()
            ),
            currency: convertCurrencyCodeToSymbol(
              indexStore.projectDetails.valuta,
              locale.value.toLocaleLowerCase()
            )
          }),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.pris",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },

        antal: {
          value: getSoldQuantity(currentData),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.quantity",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        nar_ar_produkten_tillganglig: {
          value:
            currentData?.sale_info?.nar_ar_produkten_tillganglig ??
            currentData.nar_ar_produkten_tillganglig ??
            "",
          columnStyling: "col-1",
          actions: [],
          isSortable: true,
          headerText: "label.nar_ar_produkten_tillganglig",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: true
        },
        actions: {
          value: getSaleStatusText(currentData as OrderGroupSale),
          columnStyling: "col-2 justify-content-end",
          actions:
            tableType === "internal"
              ? ["purchase", "handleVariant"]
              : tableType === "internalOngoing"
              ? ["button"]
              : ["handleVariant"],
          isSortable: false,
          headerText: "",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  if (tableType === "recondition") {
    return {
      type: tableType,
      columns: {
        benamn: {
          value: tableData.benamn,
          columnStyling: "col-2 fw-bolder",
          actions: ["checkbox"],
          isSortable: true,
          headerText: "tableTitle.benamn",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        levkod: {
          value: getTranslatedColumnValue("levkod", tableData.levkod),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.levkod",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: true
        },
        besk: {
          value: tableData.besk,
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.besk",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        kategori_id: {
          value: getTranslatedColumnValue("kategori_id", tableData.kategori_id),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.kategori_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: true
        },
        antal: {
          value: tableData.antal,
          columnStyling: "col-1",
          actions: ["edit"],
          isSortable: true,
          headerText: "label.quantity",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        status: {
          value: tableData?.recondition?.[0]?.status ?? "",
          columnStyling: "col-1 no-wrap",
          actions: [],
          isSortable: true,
          headerText: "tableTitle.recondition",
          tooltip: {
            tip: getTranslatedSubActions(tableData).tip,
            text: getTranslatedSubActions(tableData).text
          },
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },

        actions: {
          value: "",
          columnStyling: "col-2 justify-content-end",
          actions: ["handleVariant"],
          isSortable: false,
          headerText: "",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  return tableColumns;
};

export const getSaleTableColumns = (
  tableData: GreenifiedProductHeader,
  tableType: "sale" | "sold"
): TableColumns => {
  const { t, locale } = useI18n();
  const indexStore = useIndexStore();

  return {
    type: tableType,
    columns: {
      benamn: {
        value: tableData.benamn,
        columnStyling: "col-3 fw-bolder",
        actions: ["checkbox"],
        isSortable: true,
        headerText: "tableTitle.benamn",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      levkod: {
        value: getTranslatedColumnValue("levkod", tableData.levkod),
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.levkod",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: true
      },
      kategori_id: {
        value: getTranslatedColumnValue("kategori_id", tableData.kategori_id),
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.kategori_id",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: true
      },
      pris: {
        value: t("label.salesPriceUnit", {
          price: parsePrice(
            tableData.pris ?? "0",
            locale.value.toLocaleLowerCase()
          ),
          currency: convertCurrencyCodeToSymbol(
            indexStore.projectDetails.valuta,
            locale.value.toLocaleLowerCase()
          )
        }),
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.pris",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      quantity: {
        value:
          tableData.status === "pending"
            ? t("label.awaitingApproval")
            : soldQuantityValue(
                tableType === "sale" ? tableData.quantity : tableData.salda,
                tableData
              ),
        columnStyling: "col-1",
        actions: ["edit"],
        isSortable: true,
        headerText: "label.quantity",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      actions: {
        value: "",
        columnStyling: "col-2",
        actions: ["select", "handleVariant"],
        isSortable: false,
        headerText: "",
        tooltip: null,
        inputSettings: null,
        selectOptions: getSaleActions(tableData),
        isEditable: true,

        translate: false
      }
    }
  };
};

export const getSubTableColumnsByTableType = (
  tableGroup: ProductGroupSale,
  tableData: ProductItemSale,
  tableType: TableTypes
): TableColumns => {
  const measurements: Dimensions = {
    langd: tableData.langd,
    bredd: tableData.bredd,
    djup: tableData.djup,
    hojd: tableData.hojd,
    diameter: tableData.diameter,
    sitthojd: tableData.sitthojd
  };

  const standardTableColumns: TableColumns = {
    type: tableType,
    columns: {
      comp_id: {
        value: String(tableData.comp_id),
        columnStyling: "col-3",
        actions: ["checkbox"],
        isSortable: true,
        headerText: "tableTitle.comp_id",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      skick2: {
        value: tableData.skick2,
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "placeholders.condition",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      rum: {
        value: tableData.rum,
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.rum",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      kommentar: {
        value: tableData.skador ?? "",
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.specificInformation",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      dimensions: {
        value: dimensionsWithValues(measurements),
        columnStyling: "col-3",
        actions: ["history", "edit"],
        isSortable: false,
        headerText: "tableTitle.matt",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      }
    }
  };

  if (tableType === "internal") {
    const currGroupData = tableGroup as InternalGroupSale;
    return {
      type: tableType,
      columns: {
        comp_id: {
          value: tableData.comp_id,
          columnStyling: "col-3",
          actions: [],
          isSortable: true,
          headerText: "tableTitle.comp_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        rum: {
          value: tableData.rum,
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.rum",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        sell_tillganglig: {
          value:
            currGroupData.sale_info?.nar_ar_produkten_tillganglig ??
            currGroupData.sell_tillganglig ??
            "",
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.nar_ar_produkten_tillganglig",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        skador: {
          value: tableData.skador ?? "",
          columnStyling: "col-2",
          actions: [],
          isSortable: false,
          headerText: "label.specificInformation",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        status: {
          value: getSaleStatusTextByProductId(tableGroup, tableData) ?? "",
          columnStyling: "col-1 fst-italic",
          actions: [],
          isSortable: false,
          headerText: "",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: true
        },
        actions: {
          value: "",
          columnStyling: "col-2",
          actions: ["history", "edit"],
          isSortable: false,
          headerText: "",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  if (tableType === "internalSold") {
    return {
      type: tableType,
      columns: {
        comp_id: {
          value: tableData.comp_id,
          columnStyling: "col-3",
          actions: [],
          isSortable: true,
          headerText: "tableTitle.comp_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        rum: {
          value: tableData.rum,
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.rum",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        kopt_av: {
          value: tableData.kopt_av ?? "",
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.kopt_av",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        kopt_nar: {
          value: tableData.kopt_nar?.substring(0, 10) ?? "",
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.kopt_nar",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        kopt_kommentar: {
          value: tableData.kopt_kommentar ?? "",
          columnStyling: "col-3",
          actions: ["history"],
          isSortable: true,
          headerText: "label.kopt_kommentar",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  if (tableType === "recondition") {
    return {
      type: tableType,
      columns: {
        comp_id: {
          value: tableData.comp_id,
          columnStyling: "col-2",
          actions: ["checkbox"],
          isSortable: true,
          headerText: "tableTitle.comp_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        skick2: {
          value: tableData.skick2,
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "placeholders.condition",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },

        kommentar: {
          value: getReconditionComment(tableData),
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.specificInformation",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        dimensions: {
          value: dimensionsWithValues(measurements),
          columnStyling: "col-2",
          actions: ["history", "edit"],
          isSortable: false,
          headerText: "tableTitle.matt",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        rum: {
          value: tableData.rum,
          columnStyling: "col-1",
          actions: [],
          isSortable: true,
          headerText: "label.rum",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        till_rum: {
          value: tableData?.till_rum ?? "",
          columnStyling: "col-3",
          actions: ["edit", "history"],
          isSortable: true,
          headerText: "label.till_rum",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  return standardTableColumns;
};

export const getSubSaleColumns = (
  tableData: GreenifiedItem,
  tableType: "sale" | "sold"
): TableColumns => {
  if (tableType === "sale") {
    return {
      type: tableType,
      columns: {
        comp_id: {
          value: tableData.comp_id,
          columnStyling: "col-3",
          actions: ["checkbox"],
          isSortable: true,
          headerText: "tableTitle.comp_id",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        rum: {
          value: tableData.rum,
          columnStyling: "col-2",
          actions: [],
          isSortable: true,
          headerText: "label.rum",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        },
        skador: {
          value: tableData.skador ?? "",
          columnStyling: "col-7",
          actions: ["history"],
          isSortable: false,
          headerText: "label.specificInformation",
          tooltip: null,
          inputSettings: null,
          selectOptions: [],
          isEditable: true,

          translate: false
        }
      }
    };
  }

  return {
    type: tableType,
    columns: {
      comp_id: {
        value: tableData.comp_id,
        columnStyling: "col-3",
        actions: ["checkbox"],
        isSortable: true,
        headerText: "tableTitle.comp_id",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      rum: {
        value: tableData.rum,
        columnStyling: "col-2",
        actions: [],
        isSortable: true,
        headerText: "label.rum",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      skador: {
        value: tableData.skador ?? "",
        columnStyling: "col-4",
        actions: [],
        isSortable: false,
        headerText: "label.specificInformation",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: false
      },
      soldProducts: {
        value: tableData.sold === "1" ? "label.sold" : "",
        columnStyling: "col-3 ",
        actions: ["checkbox", "history"],
        isSortable: false,
        headerText: "label.markSold",
        tooltip: null,
        inputSettings: null,
        selectOptions: [],
        isEditable: true,

        translate: true
      }
    }
  };
};

const dimensionsWithValues = (dimensions: Dimensions): Dimensions => {
  const filteredDimensions: Dimensions = {
    langd: "",
    bredd: "",
    djup: "",
    hojd: "",
    diameter: "",
    sitthojd: ""
  };
  Object.keys(dimensions).forEach(dimensionKey => {
    if (dimensions[dimensionKey as keyof Dimensions]) {
      filteredDimensions[dimensionKey as keyof Dimensions] =
        dimensions[dimensionKey as keyof Dimensions];
    }
  });
  return filteredDimensions;
};

const soldQuantityValue = (
  soldQuantity: number,
  tableData: GreenifiedProductHeader
) => {
  const reservedText = reservedQuantity(tableData.reserved);
  return tableData.items.length !== tableData.itemQuantity
    ? `${soldQuantity} (${tableData.itemQuantity})${reservedText}`
    : `${soldQuantity}${reservedText}`;
};

const reservedQuantity = (reservedProducts: ReservedProduct[]) => {
  const { t } = useI18n();

  if (!reservedProducts.length) return "";

  let totalOfReservedProducts = 0;
  reservedProducts.forEach(product => {
    totalOfReservedProducts += Number(product.qty);
  });
  return `, ${t("label.reserved")} ${totalOfReservedProducts}`;
};

const parsePrice = (amount: string, locale: string) => {
  return parseInt(amount).toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

const getSaleStatusText = (productGroup: OrderGroupSale) => {
  const { t } = useI18n();

  const currentSaleStatus = productGroup.order_status;
  if (currentSaleStatus === "O") {
    return `1/3 ${t("label.awaitingApproval")}`;
  }

  if (currentSaleStatus === "A") {
    return `2/3 ${t("label.transportBooked")}`;
  }

  return `3/3 ${t("label.delivered")}`;
};

const getSaleStatusTextByProductId = (
  productGroup: ProductGroupSale,
  productItem: ProductItemSale
) => {
  if (!productGroup.internalSaleOrders) return "";

  const orderWithCurrentProductItem = productGroup.internalSaleOrders.find(
    order =>
      "products" in order &&
      order.products.some(product => product.article_id === productItem.id)
  );

  if (!orderWithCurrentProductItem) return "";

  return "label.sold";
};

const getTranslatedColumnValue = (
  columnKey: string,
  columnValue: string | number | Dimensions
): string => {
  if (!columnValue) return "";

  if (columnKey === "levkod" && String(columnValue).substring(0, 2) === "ex") {
    return "label.unkownSupplier";
  }

  return `${columnKey}.${columnValue}`;
};

const getSoldQuantity = (productGroup: InternalGroupSale) => {
  const { t } = useI18n();
  if (
    productGroup?.sale_info?.saljs_styckvis_eller_i_grupp === "single" ||
    productGroup?.sell_grupp === "single"
  )
    return productGroup.quantity ?? productGroup.antal;

  const soldQuantity =
    productGroup?.sale_info?.["ange-antal"] ?? productGroup.sell_antal;
  const productQuantity = productGroup.quantity ?? productGroup.antal;

  if (!soldQuantity) return productQuantity;
  const currentGroupQuantity = Number(soldQuantity);

  if (currentGroupQuantity > productQuantity)
    return t("label.sellByGroupFinal", {
      quantity: productQuantity
    });

  return t("label.sellByGroup", {
    quantity: productQuantity,
    group: currentGroupQuantity
  });
};

const getSaleActions = (
  productHeader: GreenifiedProductHeader
): SelectableAction[] => {
  const { t } = useI18n();

  const actionOptions = [
    {
      label: t("label.see"),
      value: 1,
      selectable: actionIsSelectable(1, productHeader)
    },
    {
      label: t("label.edit"),
      value: 2,
      selectable: actionIsSelectable(2, productHeader)
    },
    {
      label: t("label.remove"),
      value: 3,
      selectable: actionIsSelectable(3, productHeader)
    }
  ];
  return actionOptions;
};

const actionIsSelectable = (
  actionValue: SelectableAction["value"],
  product: GreenifiedProductHeader
) => {
  return (
    !((actionValue === 1 || actionValue === 2) && !product["permalink"]) &&
    !(
      product.reserved.length > 0 &&
      (actionValue === 2 || actionValue === 3)
    ) &&
    !(0 === product.quantity && (actionValue === 3 || actionValue === 2))
  );
};

const getReconditionComment = (productItem: ProductItemSale) => {
  const { t } = useI18n();
  const contentUrl = process.env.VUE_APP_CONTENT_URL;
  let reconditionComment = productItem.kommentar ?? "";

  if (Object.keys(productItem.unique_images).length) {
    let index = 0;
    for (const image in productItem.unique_images) {
      index += 1;
      const currentImage = productItem.unique_images[image];
      const comma =
        index < Object.keys(productItem.unique_images).length - 1 ? "," : "";
      reconditionComment = `<p>${
        productItem.skador ?? ""
      }</p> <a href="${contentUrl}${currentImage.hash}" target="_blank">${t(
        "label.image"
      )}${index}${comma}</a>`;
    }
  }

  return reconditionComment;
};
