<template>
  <div class="container mt-1">
    <div
      class="row manual-option"
      v-for="price in priceSuggestions"
      :key="price.estimatedvalue"
      @click="emit('update:currentPrice', price.estimatedvalue)"
    >
      <div class="col d-flex">
        <div>{{ t("label.priceSuggestionFromEval") }}:</div>
        <strong class="ms-1 bold">
          {{ getCurrencySymbol(price.estimatedvalue) }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FormFields } from "@/library/types/structure/formStructure";
import { useIndexStore } from "@/store";
import { withDefaults, defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

type PriceFieldProps = {
  priceSuggestions: { estimatedvalue: string }[];
  // eslint-disable-next-line vue/no-unused-properties
  currentPrice: FormFields["key"];
};

withDefaults(defineProps<PriceFieldProps>(), {
  priceSuggestions: () => [],
  currentPrice: () => ({})
});

const { t, locale } = useI18n();
const indexStore = useIndexStore();

const emit = defineEmits(["update:currentPrice"]);

const getCurrencySymbol = (price: string) => {
  return parseInt(price).toLocaleString(locale.value.toLocaleLowerCase(), {
    style: "currency",
    currency: indexStore.projectDetails.valuta,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};
</script>
