<template>
  <OnClickOutside @trigger="emit('update:toggleDropdown', false)">
    <div
      class="container-fluid mt-3 vs__dropdown-menu"
      v-if="addressField && toggleDropdown"
    >
      <div
        class="row manual-option mb-2"
        v-for="address in suggestedAddresses"
        :key="address.id"
        @click="changeAddress(address.address)"
      >
        <div class="col">
          <strong class="bold">
            {{ address.address.adress }}
          </strong>
          <div>
            {{
              address.address.postnummer +
              ", " +
              address.address.ort +
              ", " +
              address.address.land
            }}
          </div>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>

<script lang="ts" setup>
import { getProjectStructureFieldKey } from "@/library/helpers/getProjectStructureFieldKey";
import { AddressInformation } from "@/library/types/room/rooms";
import { FormFields } from "@/library/types/structure/formStructure";
import { TextFieldType } from "@/library/types/structure/subfieldTypes";
import { OnClickOutside } from "@vueuse/components";
import { withDefaults, defineProps, defineEmits, computed } from "vue";

type AddressFieldProps = {
  addressField: TextFieldType | null;
  addressFieldValues: FormFields;
  addressSuggestions: AddressInformation[];
  toggleDropdown: boolean;
};

const addressFieldProps = withDefaults(defineProps<AddressFieldProps>(), {
  addressField: null,
  addressFieldValues: () => ({}),
  addressSuggestions: () => [],
  toggleDropdown: false
});

const emit = defineEmits([
  "update:addressFieldValues",
  "update:toggleDropdown"
]);

const changeAddress = (newAddress: AddressInformation["address"]) => {
  let updatedAddress = {};

  Object.keys(newAddress).forEach(addressKey => {
    const updatedFieldKey = getProjectStructureFieldKey(addressKey);
    const addressValue =
      newAddress[addressKey as keyof AddressInformation["address"]];

    updatedAddress = Object.assign(addressFieldProps.addressFieldValues, {
      [updatedFieldKey]: addressValue
    });
  });

  emit("update:addressFieldValues", updatedAddress);
  emit("update:toggleDropdown", false);
};

const suggestedAddresses = computed((): AddressInformation[] => {
  if (
    Array.isArray(addressFieldProps.addressFieldValues.adress) &&
    addressFieldProps.addressFieldValues.adress.length > 0
  ) {
    const filteredAdressSuggestions =
      addressFieldProps.addressSuggestions.filter(() => {
        return true;
      });
    return filteredAdressSuggestions.slice(0, 5);
  }
  return addressFieldProps.addressSuggestions.slice(0, 5);
});
</script>

<style scoped>
.address-container {
  position: absolute;
  top: 0;
  left: 0;
  width: auto !important;
}
</style>
