import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_2 = ["name"]

import CustomSelect from "@/components/common/CustomSelect.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { ProjectSupplier } from "@/library/types/project/project";
import { FormFields } from "@/library/types/structure/formStructure";
import { TextFieldType } from "@/library/types/structure/subfieldTypes";
import { useIndexStore } from "@/store";
import {
  computed,
  ref,
  watch
} from "vue";
import { useI18n } from "vue-i18n";

type SupplierFieldProps = {
  currentSupplierCode: string;
  presetSupplierField: TextFieldType | null;
  supplierFieldValues: FormFields["key"];
  showError: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SupplierField',
  props: {
    currentSupplierCode: { default: "" },
    presetSupplierField: { default: null },
    supplierFieldValues: { default: () => ({}) },
    showError: { type: Boolean, default: false }
  },
  emits: [
  "showSupplierModal",
  "update:supplierFieldValues",
  "update:currentSupplierCode"
],
  setup(__props: any, { emit: __emit }) {

const supplierFieldProps = __props;

const indexStore = useIndexStore();
const { t } = useI18n();
const localSupplierFieldValues = ref(supplierFieldProps.supplierFieldValues);
const emit = __emit;

const projectSuppliers = computed(() => {
  const filteredSupplierList: ProjectSupplier[] = [];
  indexStore.projectDetails.lev.forEach((supplier: ProjectSupplier) => {
    if (!supplier.levkod.includes("ex")) {
      filteredSupplierList.push(supplier);
    } else if (supplier.levkod === "ex01") {
      filteredSupplierList.push({
        levkod: supplier.levkod,
        varumarke: t("label.unkownSupplier")
      });
    }
  });
  return filteredSupplierList;
});

const supplierCode = ref(
  projectSuppliers.value.find(
    supplier => supplier.levkod === supplierFieldProps.currentSupplierCode
  ) ?? ""
);

const inputSupplier = (supplier: ProjectSupplier) => {
  localSupplierFieldValues.value = supplier.varumarke;
  emit("update:supplierFieldValues", localSupplierFieldValues.value);
  emit("update:currentSupplierCode", supplier.levkod);
};

watch(
  () => supplierFieldProps.currentSupplierCode,
  newSupplierCode => {
    localSupplierFieldValues.value = newSupplierCode;
    supplierCode.value = newSupplierCode;

    emit("update:supplierFieldValues", localSupplierFieldValues.value);
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.presetSupplierField)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(CustomSelect, {
            class: "w-100 me-3",
            options: projectSuppliers.value,
            label: "varumarke",
            modelValue: supplierCode.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((supplierCode).value = $event)),
              _cache[1] || (_cache[1] = (supplier) => inputSupplier(supplier))
            ],
            clearable: false,
            placeholder: String(localSupplierFieldValues.value),
            appendToBody: true,
            showError: _ctx.showError
          }, null, 8, ["options", "modelValue", "placeholder", "showError"]),
          _createVNode(GreenifiedButton, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('showSupplierModal'))),
            size: "thin",
            noWrap: "",
            text: _unref(t)('button.add')
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.presetSupplierField)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          required: "true",
          name: _ctx.presetSupplierField.key,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localSupplierFieldValues).value = $event)),
          style: {"height":"0px","width":"0px","padding":"0px","margin":"0px","display":"block","border":"0px !important"}
        }, null, 8, _hoisted_2)), [
          [_vModelText, localSupplierFieldValues.value]
        ])
      : _createCommentVNode("", true)
  ], 64))
}
}

})