import { useIndexStore } from "@/store";

export const getProjectStructureFieldKey = (fieldName: string) => {
  const indexStore = useIndexStore();
  const projectStructure = indexStore.projectDetails.struc;
  let fieldKey = "";

  projectStructure.forEach(GroupFields => {
    if (GroupFields.type !== "group" && GroupFields.name === fieldName) {
      fieldKey = GroupFields.key;
      return;
    }

    GroupFields.sub_fields.forEach(subField => {
      if (subField.name === fieldName) {
        fieldKey = subField.key;
        return;
      }
    });
  });

  return fieldKey;
};
