<template>
  <GreenifiedModal
    :title="t('title.project')"
    :type="'small'"
    noOuterClose
    :closable="false"
    :isLoading="isLoading"
  >
    <template #modalHeaderContent>
      <p class="form-label-paragraph">
        {{ t("paragraph.selectProject") }}
      </p>
    </template>
    <template #container>
      <ProjectList
        @projectIsSelected="navigateToOverview"
        :isExpandable="closedProjects.length ? true : false"
        :projects="activeProjects"
        :projectType="0"
        :title="t('label.activeProjects')"
        :accordionBg="'#f5f2eb'"
        :collapse="expandedField === 1"
        @expandedField="(fieldIndex: number) => (expandedField = fieldIndex)"
        @isLoading="(loading: boolean) => (isLoading = loading)"
      />

      <ProjectList
        v-if="closedProjects.length"
        :projects="closedProjects"
        :projectType="1"
        :title="t('label.completedProjects')"
        :accordionBg="'#f5f2eb'"
        :collapse="expandedField === 0"
        @expandedField="(fieldIndex: number) => (expandedField = fieldIndex)"
      />
    </template>
    <template #footer>
      <div class="row justify-content-end ms-0 me-0">
        <div class="col-4 ps-0 pe-0">
          <GreenifiedButton :text="t('menu.signOut')" @click="logout()" />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    :type="'response'"
    :closable="false"
    :title="t('responseTitle.completedProject')"
    :text="
      t('paragraph.completedProjectInfo', [
        bolderFont(store.currentProject.namn)
      ])
    "
    @close="showInactiveModal = false"
    v-if="showInactiveModal"
  />

  <GreenifiedModal
    :type="'response'"
    :closable="false"
    :title="t('responseTitle.completedProject')"
    :text="t('paragraph.missingActiveProjectInfo')"
    :declineText="t('menu.signOut')"
    @confirm="logout()"
    v-if="userIsMissingActiveProjects"
  />
</template>

<script setup lang="ts">
import ProjectList from "@/components/project/ProjectList.vue";
import { onMounted, Ref, ref, watch } from "vue";
import { bolderFont } from "../library/helpers/typography";
import { useRouter } from "vue-router";
import { SelectableProject } from "@/library/types/project/project";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { getClientProjects } from "@/library/api/project";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import { useI18n } from "vue-i18n";

const authStore = useAuthStore();
const store = useIndexStore();
const router = useRouter();

const { t } = useI18n();

onMounted(() => {
  getProjects();
});

const showInactiveModal = ref(
  store.currentProjectNumber.length && store.currentProject.active === "0"
);

const isLoading = ref(false);
const showProjectsModal = ref(true);
const userIsMissingActiveProjects = ref(false);
const activeProjects: Ref<SelectableProject[]> = ref([]);
const closedProjects: Ref<SelectableProject[]> = ref([]);
const expandedField = ref(0);

const navigateToOverview = () => {
  showProjectsModal.value = false;
  router.push({ name: "projectOverview" });
};

const getProjects = async () => {
  isLoading.value = true;

  const result = await getClientProjects();

  isLoading.value = false;
  if (!result.success) return;

  result.result.forEach((project: SelectableProject) => {
    if (project.active === "1") {
      activeProjects.value.push(project);
    } else {
      closedProjects.value.push(project);
    }
  });

  if (!activeProjects.value.length) {
    userIsMissingActiveProjects.value = true;
  }
};

const logout = async () => {
  authStore.logout();
};

watch(
  () => showProjectsModal.value,
  () => {
    setInterval(() => {
      showProjectsModal.value = true;
    });
  }
);
</script>
