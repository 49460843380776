<template>
  <OnClickOutside @trigger="showSort = false" :key="currentSortingSettings.col">
    <div ref="sortingHeader">
      <GreenifiedButton
        @click="showSort = !showSort"
        :btnStyle="'yellow'"
        :textAlign="'left'"
        :overflow="'ellipsis'"
        smallerPadding
        ><template #content>
          <p class="cursor-pointer">
            {{ t("button.orderedBy") }}:

            {{
              translatedOrderText ?? t(`label.${currentSortingSettings.col}`)
            }}
          </p>
        </template></GreenifiedButton
      >
    </div>

    <div class="sorting-container" v-if="showSort">
      <div
        v-for="sortingOption in currentSortingOptions"
        :key="sortingOption.name"
        class="d-flex flex-column"
        :style="`width: ${getMaxWidth}px`"
      >
        <GreenifiedButton
          :textAlign="'left'"
          :btnStyle="'yellow'"
          :size="'auto'"
          smallerPadding
          @click="handleSortOption(sortingOption.colValue.col, true)"
          ><template #content>
            <p class="sorting-option ellipsis cursor-pointer">
              {{
                `${t(sortingOption.name)} ${t(sortingOption.ascendingLocale)}`
              }}
            </p>
          </template></GreenifiedButton
        >
        <GreenifiedButton
          :btnStyle="'yellow'"
          :textAlign="'left'"
          :size="'auto'"
          smallerPadding
          @click="handleSortOption(sortingOption.colValue.col, false)"
          ><template #content>
            <p class="sorting-option ellipsis cursor-pointer">
              {{
                `${t(sortingOption.name)} ${t(sortingOption.descendingLocale)}`
              }}
            </p>
          </template></GreenifiedButton
        >
      </div>
    </div>
  </OnClickOutside>
</template>

<script lang="ts" setup>
import { FilterSortOption } from "../../library/types/filter/filter";
import {
  withDefaults,
  defineProps,
  ref,
  defineEmits,
  computed,
  Ref,
} from "vue";
import { OnClickOutside } from "@vueuse/components";
import GreenifiedButton from "./GreenifiedButton.vue";
import { useI18n } from "vue-i18n";
import { SortingSettings } from "@/library/types/sorting/sorting";

export type SortingDropdownOption = {
  col: string;
  asc: boolean;
};

type SortingDropdownProps = {
  sortOptions: FilterSortOption[];
  sortingSettings: SortingSettings;
};

const sortingDropdownProps = withDefaults(defineProps<SortingDropdownProps>(), {
  sortOptions: () => [],
  sortingSettings: () => ({ col: "", asc: true }),
});

const { t } = useI18n();
const showSort = ref(false);
const currentSortingOptions = ref(sortingDropdownProps.sortOptions);
const currentSortingSettings = ref(sortingDropdownProps.sortingSettings);
const sortingHeader: Ref<HTMLElement | null> = ref(null);

const emit = defineEmits(["setSort"]);

const getMaxWidth = computed(() => {
  if (!sortingHeader.value) return "100%";
  return sortingHeader.value.getBoundingClientRect().width;
});

const translatedOrderText = computed(() => {
  const currentSortOption = currentSortingOptions.value.find(
    option => currentSortingSettings.value.col === option.colValue.col,
  );
  if (currentSortOption) {
    return (
      t(currentSortOption.name) +
      " " +
      t(
        currentSortingSettings.value.asc
          ? currentSortOption.ascendingLocale
          : currentSortOption.descendingLocale,
      )
    );
  }
  return "";
});

const handleSortOption = (column: string, isAscending: boolean) => {
  showSort.value = false;
  currentSortingSettings.value = {
    col: column,
    asc: isAscending,
  };
  emit("setSort", currentSortingSettings.value);
};
</script>

<style scoped>
.left {
  text-align: start;
}

.sorting-option:hover {
  text-decoration: underline;
}

.sorting-container {
  position: absolute;
  z-index: 5;
}
</style>
