import { ProductStatuses } from "../types/product/productHeader";

export const getStatusText = (status: ProductStatuses) => {
  let statusText = "";

  if (status === "B" || status === "I" || status === "G") {
    return (statusText = "title.overviewSell");
  }

  switch (status) {
    case "C":
      statusText = "title.recycleOverview";
      break;
    case "J":
      statusText = "title.handleProducts";
      break;
    case "Å":
      statusText = "title.repurpose";
      break;
    case "S":
      statusText = "title.sell";
      break;
    case "K":
      statusText = "title.recycle";
      break;
    case "R":
      statusText = "title.recondition";
      break;
    case "M":
      statusText = "title.repurposeOverview";
      break;
  }

  return statusText;
};
