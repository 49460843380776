import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container mt-1" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "col d-flex" }
const _hoisted_4 = { class: "ms-1 bold" }

import { FormFields } from "@/library/types/structure/formStructure";
import { useIndexStore } from "@/store";

import { useI18n } from "vue-i18n";

type PriceFieldProps = {
  priceSuggestions: { estimatedvalue: string }[];
  // eslint-disable-next-line vue/no-unused-properties
  currentPrice: FormFields["key"];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceField',
  props: {
    priceSuggestions: { default: () => [] },
    currentPrice: { default: () => ({}) }
  },
  emits: ["update:currentPrice"],
  setup(__props: any, { emit: __emit }) {



const { t, locale } = useI18n();
const indexStore = useIndexStore();

const emit = __emit;

const getCurrencySymbol = (price: string) => {
  return parseInt(price).toLocaleString(locale.value.toLocaleLowerCase(), {
    style: "currency",
    currency: indexStore.projectDetails.valuta,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceSuggestions, (price) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row manual-option",
        key: price.estimatedvalue,
        onClick: ($event: any) => (emit('update:currentPrice', price.estimatedvalue))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(_unref(t)("label.priceSuggestionFromEval")) + ":", 1),
          _createElementVNode("strong", _hoisted_4, _toDisplayString(getCurrencySymbol(price.estimatedvalue)), 1)
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}
}

})