<template>
  <div class="text-tiny emphasis d-flex cursor-pointer">
    <span class="delete-span me-1" v-if="deletable">✕</span>
    <span>{{ translate ? t(text) : text }}</span>
  </div>
</template>

<script lang="ts" setup>
import { withDefaults, defineProps } from "vue";
import { useI18n } from "vue-i18n";

type BreadcrumbProps = {
  deletable?: boolean;
  text: string;
  translate?: boolean;
};

withDefaults(defineProps<BreadcrumbProps>(), {
  deletable: true,
  text: "",
  translate: true
});

const { t } = useI18n();
</script>
