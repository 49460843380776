<template>
  <LoadingOverlay v-if="isLoading" />
  <div class="px-4" v-else>
    <MainFilter
      :filter-title="
        !showTokenError ? t('title.register') : t('title.urlHasExpired')
      "
      :filter-text="
        !showTokenError
          ? t('msg.passwordRequirements')
          : t('paragraph.urlExpiredInfo')
      "
      hideFilterOptions
    />

    <form
      ref="passwordForm"
      id="passwordForm"
      @submit.prevent="postNewPassword"
      v-if="!showTokenError"
    >
      <div class="row align-items-center">
        <div class="col-sm-3 d-flex align-items-center">
          <p class="fw-bolder">{{ t("label.enterPassword") + " *" }}</p>
          <RequiredWarning
            :warningText="t('msg.readAbovePasswordRequirements')"
            v-if="invalidPassword && password2 === password1"
          />
        </div>
        <div class="col-sm-4">
          <InputField
            type="password"
            v-model:field-value="password1"
            :placeHolder="t('placeholders.password')"
            required
            minlength="8"
            :password-has-toggle="true"
            @update:field-value="invalidPassword = false"
          />
        </div>
      </div>
      <div class="row mt-4 align-items-center">
        <div class="col-sm-3 d-flex align-items-center">
          <p class="fw-bolder me-2">
            {{ t("label.repeatPassword") + " *" }}
          </p>
          <RequiredWarning
            :warningText="t('msg.passwordNotTheSame')"
            v-if="invalidPassword && password2 !== password1"
          />
        </div>
        <div class="col-sm-4">
          <InputField
            type="password"
            v-model:field-value="password2"
            :placeHolder="t('placeholders.password')"
            required
            :password-has-toggle="true"
            minlength="8"
          />
        </div>
      </div>
      <div class="row mt-4 align-items-center">
        <div class="col-sm-3 d-flex align-items-center">
          <p class="fw-bolder me-2">
            {{ t("label.name") }}
          </p>
        </div>
        <div class="col-sm-4">
          <InputField
            type="text"
            v-model:field-value="name"
            :placeHolder="t('placeholders.firstAndLastname')"
            required
            minlength="150"
          />
        </div>
      </div>
      <div class="row mt-5 align-items-center">
        <div class="col-4">
          <GreenifiedButton
            size="thin"
            type="submit"
            btnStyle="black"
            :text="t('button.register')"
            :form="'passwordForm'"
          />
        </div>
      </div>
    </form>
  </div>

  <GreenifiedModal
    v-if="showError"
    type="response"
    :closable="false"
    :title="t('responseTitle.registerError')"
    :text="t('msg.registerError')"
    @close="showError = false"
  />
  <GreenifiedModal
    v-if="showSuccess"
    type="response"
    :closable="false"
    :title="t('responseTitle.registerSuccess')"
    :text="t('msg.registerSuccess')"
    @close="navToLogin()"
  />
</template>

<script setup lang="ts">
import RequiredWarning from "@/components/common/RequiredWarning.vue";
import { onMounted, Ref, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { postPassword, postTokenVerification } from "@/library/api/user";
import { useI18n } from "vue-i18n";
import MainFilter from "@/components/filter/MainFilter.vue";
import InputField from "@/components/common/InputField.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";

const authStore = useAuthStore();
const router = useRouter();

const { t } = useI18n();

const password1 = ref("");
const password2 = ref("");
const invalidPassword = ref(false);
const showError = ref(false);
const showSuccess = ref(false);
const showTokenError = ref(false);
const isLoading = ref(true);
const name = ref("");
const passwordForm: Ref<HTMLFormElement | null> = ref(null);

const route = useRoute();

const navToLogin = () => {
  authStore.logout();
};

const verifyToken = async () => {
  isLoading.value = true;
  const result = await postTokenVerification(route.query.token as string);
  isLoading.value = false;

  if (!result.success) {
    if (result.error.status !== 410) return router.push({ name: "notFound" });
    showTokenError.value = true;
  }

  showTokenError.value = false;
};

const verifyPasswordRequirements = (firstPw: string, secondPw: string) => {
  const digitsInPassword = firstPw.replace(/\D/g, "");
  return (
    firstPw.length >= 8 &&
    firstPw === secondPw &&
    /[A-Z]/.test(firstPw) === true &&
    /[a-z]/.test(firstPw) === true &&
    digitsInPassword.length >= 2
  );
};

const postNewPassword = async () => {
  const correctPassword = verifyPasswordRequirements(
    password1.value,
    password2.value
  );

  if (!correctPassword) {
    invalidPassword.value = true;
    return;
  }

  invalidPassword.value = false;

  const result = await postPassword(
    route.query.token as string,
    password1.value,
    name.value
  );

  if (!result.success) {
    showError.value = true;
    return;
  }

  showSuccess.value = true;
};

onMounted(() => {
  verifyToken();
});
</script>

<style scoped>
.req-list {
  position: fixed;
  z-index: 3;
  top: 18%;

  background-color: white;
  padding-left: 1.6rem;
  padding-right: 1rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  color: black;
  border-radius: 10px;

  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.req-list p {
  font-weight: bolder;
}
.reset-pw-wrapper {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-pw-container {
  width: 90%;
  height: 80%;
  background-color: #f8f5ee;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.reset-pw-content {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.reset-pw-content h2 {
  margin-bottom: 10%;
  text-align: center;
}

.reset-pw-content h2 {
  margin-bottom: 10%;
}

.pw-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.pw-row h3 {
  margin-bottom: 1rem;
}

.pw-row input {
  height: 3rem;
  border-radius: 10px;
  border: 1px solid grey;
  width: 18rem;
  font-size: 20px;
  padding-left: 1rem;
}

.save-btn {
  height: 42px;
  font-size: 16px;
}
.sub-header {
  width: 100%;
  height: 4rem;

  background-color: #f8f5ee;
  margin-bottom: 1rem;
}

.sub-header-container {
  width: 100%;
  height: 100%;
}

.sub-header h2 {
  margin-left: 1rem;
}

.navbar-container {
  width: 100%;
  display: flex;
  height: 10vh;
  align-items: center;
}

.logo {
  height: 40%;
  cursor: pointer;
}
</style>
