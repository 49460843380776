<template v-if="productSpecifics">
  <div class="row">
    <div class="col">
      <p class="subheading-tiny mt-1">
        {{ t("title.productSpecification") }} {{ productSpecifics?.comp_id }}
      </p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <p class="text-tiny">
        {{ t("paragraph.specifiedProductInformation") }}
      </p>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6">
      <InputTextField
        :label="t('label.productspecificComment')"
        v-model="currentProductSpecifics.damages"
        @update:modelValue="emit('update:damages', productDamages)"
        :placeHolder="t('placeholders.dentDescription')"
      />
    </div>
    <div class="col-6">
      <p class="text-tiny fw-bolder mb-1">{{ t("placeholders.condition") }}</p>
      <GreenifiedForm
        v-model:formValues="currentProductSpecifics.productSpecifics"
        hideTitle
        ref="mainItemDesc"
        :formFields="projectStructure"
        :includedFields="['info', 'skick']"
      ></GreenifiedForm>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <p class="text-tiny fw-bolder">{{ t("title.specifiedImages") }}</p>
    </div>
  </div>
  <div class="row mt-1 align-items-end">
    <div class="col-3">
      <GreenifiedImage
        :imageIndex="1"
        :imageLabel="'image1'"
        :key="image1"
        v-model:imageUrl="image1"
        @update:imageUrl="
          imageHash => {
            if (!imageHash) {
              removeProductImage(image1);
              return;
            }
            productImages[0] = { hash: image1, url: '' };
          }
        "
      />
    </div>
    <div class="col-3">
      <GreenifiedImage
        :selectable="productImages.length ? true : false"
        :imageIndex="2"
        :imageLabel="'image2'"
        :key="image2"
        v-model:imageUrl="image2"
        @update:imageUrl="
          imageHash => {
            if (!imageHash) {
              removeProductImage(image2);
              return;
            }
            productImages[1] = { hash: image2, url: '' };
          }
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, ref } from "vue";
import { ProductItemInformation } from "@/library/types/product/productItem";
import InputTextField from "@/components/common/InputTextField.vue";
import { useIndexStore } from "@/store";
import GreenifiedForm from "../GreenifiedForm.vue";
import GreenifiedImage from "@/components/common/GreenifiedImage.vue";
import { useI18n } from "vue-i18n";

const indexStore = useIndexStore();
const { t } = useI18n();

type SpecificProductInformationProps = {
  productSpecifics: ProductItemInformation;
};

const specificProductInformationProps = withDefaults(
  defineProps<SpecificProductInformationProps>(),
  {
    productSpecifics: () => ({
      comp_id: 0,
      damages: "",
      id: "",
      location: "",
      productSpecificImages: [],
      productSpecifics: {},
      room: {
        roomName: "",
        roomCode: "",
        floor: "",
        building: "",
      },
      status: "",
    }),
  },
);

const emit = defineEmits(["update:damages", "update"]);

const projectStructure = indexStore.projectDetails.struc;
const currentProductSpecifics = ref(
  specificProductInformationProps.productSpecifics,
);
const productDamages = ref(
  specificProductInformationProps.productSpecifics.damages,
);
const productImages = ref(
  specificProductInformationProps.productSpecifics.productSpecificImages,
);

const image1 =
  specificProductInformationProps.productSpecifics?.productSpecificImages?.[0]
    ?.hash ?? null;
const image2 =
  specificProductInformationProps.productSpecifics?.productSpecificImages?.[1]
    ?.hash ?? null;

const removeProductImage = (imageHash: string) => {
  const currentIndex = productImages.value.findIndex(
    image => image.hash === imageHash,
  );

  productImages.value.splice(currentIndex, 1);
};
</script>
