<template>
  <div
    class="checkbox-wrapper position-relative"
    :class="[
      showError && 'error-checkbox',
      `${tableMargin}-margin`,
      `checkbox-${size}`
    ]"
    @click="
      () => {
        if (selected === trueValue) {
          selected = falseValue;
        } else {
          selected = trueValue;
        }
      }
    "
  >
    <input
      type="checkbox"
      :required="required"
      :id="id"
      :name="name"
      :class="`checkbox-${size}`"
      :true-value="trueValue"
      :false-value="falseValue"
      :disabled="disabled"
      :value="value"
      v-model="selected"
      @input="
        event => {
          if (disabled) return;
          emit('update:modelValue', selected);
          emit('input', event);
        }
      "
      :checked="isChecked"
    />
  </div>
  <p
    v-if="label"
    class="subheading-tiny"
    :class="[vertical && 'mb-1', showError && 'error-text']"
  >
    {{ label }}
  </p>
</template>

<script setup lang="ts">
import { ref, withDefaults, defineProps, defineEmits, watch, Ref } from "vue";

type GreenifiedCheckboxProps = {
  required?: boolean;
  id?: string;
  name?: string;
  value?: string | number | boolean;
  modelValue?: string | number | boolean | null;
  label?: string;
  vertical?: boolean;
  showError?: boolean;
  trueValue?: string | number | boolean | null;
  falseValue?: string | number | boolean | null;
  tableMargin?: "main" | "sub" | "none";
  isChecked?: boolean;
  size?: "large" | "small";
  disabled?: boolean;
};

const greenifiedCheckboxProps = withDefaults(
  defineProps<GreenifiedCheckboxProps>(),
  {
    required: false,
    id: "",
    name: "",
    value: "",
    modelValue: null,
    label: "",
    vertical: false,
    showError: false,
    trueValue: true,
    falseValue: false,
    tableMargin: "none",
    isChecked: false,
    size: "small",
    disabled: false
  }
);

const emit = defineEmits(["update:modelValue", "input"]);

const selected: Ref<string | number | boolean | null> = ref(
  greenifiedCheckboxProps.modelValue ?? greenifiedCheckboxProps.value
);

watch(
  () => greenifiedCheckboxProps.modelValue,
  newValue => {
    selected.value = newValue;
  }
);
</script>

<style scoped>
.sub-margin {
  margin-right: 16px;
}

.main-margin {
  margin-right: 24px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-large {
  input[type="checkbox"] {
    width: 34px;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input[type="checkbox"]:before {
    width: 34px;
    height: 34px;
    content: "";
    background: url("@/assets/img/square.svg") no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .error-checkbox input[type="checkbox"]:before {
    width: 34px;
    height: 34px;
    background: url("@/assets/img/square-error.svg") no-repeat !important;
  }
  input[type="checkbox"]:checked:before {
    width: 34px;
    height: 34px;
    background: url("@/assets/img/check.svg") no-repeat !important;
  }
}

.checkbox-small {
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input[type="checkbox"]:before {
    width: 20px;
    height: 20px;
    content: "";
    background: url("@/assets/img/square-small.svg") no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .error-checkbox input[type="checkbox"]:before {
    width: 20px;
    height: 20px;
    background: url("@/assets/img/square-error.svg") no-repeat !important;
  }
  input[type="checkbox"]:checked:before {
    width: 20px;
    height: 20px;
    background: url("@/assets/img/check-small.svg") no-repeat !important;
  }
}
</style>
