<template>
  <img :src="dropArrow" alt="dropdown arrow" :class="[arrowDirection, size]" />
</template>

<script setup lang="ts">
import { withDefaults, defineProps, computed } from "vue";
import dropArrow from "../../assets/img/drop-arrow.svg";

type RotateArrowProps = {
  rotate: boolean;
  size: string;
};

const rotateArrowProps = withDefaults(defineProps<RotateArrowProps>(), {
  rotate: false,
  size: "full",
});

const arrowDirection = computed(() => {
  return rotateArrowProps.rotate
    ? "ascending-direction"
    : "descending-direction";
});
</script>

<style scoped>
.full {
  width: 44px;
  height: 44px;
}

.half {
  width: 18px;
  height: 18px;
}

.small {
  width: 14px;
  height: 14px;
}

.tiny {
  width: 12px;
  height: 12px;
}

.ascending-direction {
  transform: rotate(180deg);
  transition: 0.3s;
}

.descending-direction {
  transition: 0.3s;
}
</style>
