import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-3 mt-2" }
const _hoisted_2 = { class: "col-4 d-flex align-items-center" }
const _hoisted_3 = { class: "form-label" }
const _hoisted_4 = { class: "col-8" }
const _hoisted_5 = { class: "row mb-3" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "form-label" }
const _hoisted_8 = { class: "col-8" }
const _hoisted_9 = { class: "col-4" }
const _hoisted_10 = { class: "col-4" }

import { ref, Ref } from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { postUserInvite } from "@/library/api/user";
import CustomSelect from "../common/CustomSelect.vue";
import InputField from "../common/InputField.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { SelectableAction } from "@/library/helpers/structureTableColumns";

export type UserRoleOption = {
  label: string;
  value: string;
};

type InviteUserModalProps = {
  roleOptions: SelectableAction[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InviteUserModal',
  props: {
    roleOptions: { default: () => [] }
  },
  emits: ["close", "refresh"],
  setup(__props: any, { emit: __emit }) {



const { t } = useI18n();
const emit = __emit;
const isLoading = ref(false);
const invitedUser: Ref<{ email: string | null; role: string }> = ref({
  email: null,
  role: "S"
});
const inviteForm: Ref<HTMLFormElement | null> = ref(null);

const inviteUser = async () => {
  if (inviteForm.value && !inviteForm.value.reportValidity()) {
    return;
  }
  isLoading.value = true;
  if (invitedUser.value.email) {
    const result = await postUserInvite(
      invitedUser.value.email,
      invitedUser.value.role
    );

    isLoading.value = false;

    if (!result.success) return;

    emit("refresh");
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    onClose: _cache[4] || (_cache[4] = ($event: any) => (emit('close'))),
    title: _unref(t)('title.inviteUser'),
    type: "x-small",
    isLoading: isLoading.value
  }, {
    container: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (inviteUser()), ["prevent"])),
        ref_key: "inviteForm",
        ref: inviteForm,
        id: "invite-form"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_unref(t)("label.emailAddress")) + " *", 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(InputField, {
              type: "email",
              required: "",
              placeHolder: _unref(t)('placeholders.emailAdress'),
              "field-value": invitedUser.value.email,
              "onUpdate:fieldValue": _cache[0] || (_cache[0] = ($event: any) => ((invitedUser.value.email) = $event))
            }, null, 8, ["placeHolder", "field-value"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_unref(t)("label.role")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(CustomSelect, {
              required: "",
              modelValue: invitedUser.value.role,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((invitedUser.value.role) = $event)),
              reducedKey: 'value',
              options: _ctx.roleOptions,
              label: "label",
              clearable: false
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ], 544)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(GreenifiedButton, {
          text: _unref(t)('button.cancel'),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('close')))
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(GreenifiedButton, {
          text: _unref(t)('button.send'),
          disabled: !invitedUser.value.email || !invitedUser.value.role,
          type: 'submit',
          form: "invite-form",
          "btn-style": "black"
        }, null, 8, ["text", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["title", "isLoading"]))
}
}

})