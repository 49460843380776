import { Quantities } from "@/services/api/helpersApi";
import { useIndexStore } from "@/store";
import { useRouter } from "vue-router";

export type SubRoute = {
  subRouteName: string;
  subQuantities: Array<keyof Quantities>;
  subHeader: number;
  subButtonText: string;
};

export type SidebarButton = {
  showButton: boolean;
  routeName: string;
  buttonText: string;
  mainQuantities: Array<keyof Quantities>;
  isCompleted: boolean;
  mainHeader: number;
  isMainButton: boolean;
  subRoutes: SubRoute[];
};

export const productAmountInCurrentStatus = (
  quantityStatuses: Array<keyof Quantities>
) => {
  if (!quantityStatuses.length) return 1;

  const store = useIndexStore();
  let amount = 0;

  const projectQuantities = store.quantities;

  if (!projectQuantities) return amount;

  quantityStatuses.forEach(quantity => {
    amount += projectQuantities[quantity];
  });

  return amount;
};

const isSubRouteOfMainRoute = (subRoutes: string[]) => {
  const { currentRoute } = useRouter();
  return subRoutes.includes(String(currentRoute.value.name));
};

export const sidebarButtons = (): SidebarButton[] => [
  {
    showButton:
      productAmountInCurrentStatus(["Å", "R", "M", "F"]) !== 0 ||
      isSubRouteOfMainRoute([
        "projectRepurpose",
        "projectRecondition",
        "projectRepurposeOverview"
      ]),
    routeName: "projectRepurpose",
    buttonText: "button.repurpose",
    mainQuantities: ["Å", "R", "M", "F"],
    isCompleted: productAmountInCurrentStatus(["Å"]) === 0,
    mainHeader: 2,
    isMainButton: false,
    subRoutes: [
      {
        subRouteName: "projectRepurpose",
        subQuantities: ["Å"],
        subHeader: 1,
        subButtonText: "label.repurpose"
      },
      {
        subRouteName: "projectRecondition",
        subQuantities: ["Å", "R"],
        subHeader: 2,
        subButtonText: "label.recondition"
      },
      {
        subRouteName: "projectRepurposeOverview",
        subQuantities: ["Å", "R", "M"],
        subHeader: 3,
        subButtonText: "label.overviewRepurpose"
      }
    ]
  },
  {
    showButton:
      productAmountInCurrentStatus(["G", "S", "I", "B"]) !== 0 ||
      isSubRouteOfMainRoute(["projectSell"]),
    routeName: "projectSell",
    buttonText: "button.sell",
    mainQuantities: ["G", "S", "I", "B"],
    isCompleted: false,
    mainHeader: 3,
    isMainButton: false,
    subRoutes: [
      {
        subRouteName: "projectSell",
        subQuantities: ["S"],
        subHeader: 1,
        subButtonText: "label.sell"
      },
      {
        subRouteName: "greenifiedSaleOverview",
        subQuantities: [],
        subHeader: 2,
        subButtonText: "button.onGreenified"
      },
      {
        subRouteName: "internalSaleOverview",
        subQuantities: [],
        subHeader: 3,
        subButtonText: "button.otherSales"
      }
    ]
  },
  {
    showButton:
      productAmountInCurrentStatus(["K", "C"]) !== 0 ||
      isSubRouteOfMainRoute(["projectRecycle", "projectRecycleOverview"]),
    routeName: "projectRecycle",
    buttonText: "button.recycle",
    mainQuantities: ["K", "C"],
    isCompleted: productAmountInCurrentStatus(["K"]) === 0,
    mainHeader: 4,
    isMainButton: false,
    subRoutes: [
      {
        subRouteName: "projectRecycle",
        subQuantities: ["K"],
        subHeader: 1,
        subButtonText: "label.recycle"
      },
      {
        subRouteName: "projectRecycleOverview",
        subQuantities: [],
        subHeader: 2,
        subButtonText: "label.overviewRecycle"
      }
    ]
  }
];
