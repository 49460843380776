import { ExtendedSearch } from "@/library/types/search/search";
import api from "../api";
import { AxiosResponse } from "axios";

export type SearchTable = {
  projekt: string;
  text: string;
  nyckel: string;
  sokvarde: string;
  kol: string;
  sort: string;
};

export interface TranslatedFilter extends SearchTable {
  group: boolean;
  key: string;
  value: string;
}

export default {
  searchAllProducts(
    projectNumber: string,
    locale: string,
    searchFilters: { [key: string]: string[] },
    statuses: string[]
  ): Promise<AxiosResponse<ExtendedSearch[]>> {
    return api().post(
      `/api/project/${projectNumber}/searchAll`,
      JSON.stringify({
        searchValue: searchFilters,
        locale: locale,
        status: statuses
      })
    );
  },

  getGuestSearchValues(
    guestToken: string,
    search: string
  ): Promise<AxiosResponse<TranslatedFilter[]>> {
    return api().get("/api/guest/" + guestToken + "/search?value=" + search);
  },

  getSearchValues(
    projectNr: string,
    search: string
  ): Promise<AxiosResponse<TranslatedFilter[]>> {
    return api().get("/api/project/" + projectNr + "/search?value=" + search);
  },

  getSearchTable(
    projectNr: string,
    col: string[],
    guestToken?: string
  ): Promise<AxiosResponse<{ [key: string]: SearchTable[] }>> {
    if (guestToken) {
      return api().get(
        `/api/guest/${guestToken}/search/list?${encodeURI(
          "col[]=" + col.join("&col[]=")
        )}`
      );
    } else {
      return api().get(
        `/api/project/${projectNr}/search/list?${encodeURI(
          "col[]=" + col.join("&col[]=")
        )}`
      );
    }
  }
};
