import { useIndexStore } from "@/store";
import productsApi, {
  HistoryLog,
  ReconditionUpdate
} from "@/services/api/productsApi";
import { PresetFilter } from "../types/filter/filter";
import {
  AdvertisementProductHeader,
  ProductHeaderSale,
  ProductStatuses
} from "../types/product/productHeader";
import { AxiosResponse } from "axios";
import {
  ProductInformation,
  ProductGroupSale
} from "../types/product/productGroup";
import {
  ProductItem,
  ProductItemInformation,
  ProductItemSale
} from "../types/product/productItem";
import { RoomObject } from "../types/room/rooms";
import {
  CustomError,
  handleApiRequest,
  Result
} from "../helpers/handleApiRequests";

export type SoldProducts = { [key: string]: string | number };

export const getProductHeadersApi = async (
  filter: PresetFilter
): Promise<Result<ProductHeaderSale, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.getProductHeadersApi(indexStore.currentProjectNumber, filter),
    "products - getProductHeadersApi"
  );
};

export const getProductItemsApi = async (
  filter: PresetFilter
): Promise<Result<ProductItemSale[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.getProductItemsApi(indexStore.currentProjectNumber, filter),
    "products - getProductItemsApi"
  );
};

export const getGuestAdvertisements = async (
  token: string
): Promise<Result<AdvertisementProductHeader, CustomError>> => {
  return handleApiRequest(
    productsApi.getGuestAdvertisements(token),
    "products - getGuestAdvertisements"
  );
};

export const getItemLogHistory = async (
  productId: string
): Promise<Result<HistoryLog[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.getItemLogHistory(indexStore.currentProjectNumber, productId),
    "products - getItemLogHistory"
  );
};

export const getPublishedItemApi = async (
  greenifiedId: string
): Promise<Result<ProductGroupSale, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.getPublishedItemApi(
      indexStore.currentProjectNumber,
      greenifiedId
    ),
    "products - getPublishedItemApi"
  );
};

export const postProductItemsAsSold = async (
  soldProducts: SoldProducts
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.postProductItemsAsSold(
      indexStore.currentProjectNumber,
      soldProducts
    ),
    "products - postProductItemsAsSold"
  );
};

export const updateStatus = async (
  productIds: ProductItem["id"][],
  productStatus: ProductStatuses
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.updateStatus(
      indexStore.currentProjectNumber,
      productIds,
      productStatus
    ),
    "products - updateStatus"
  );
};

export const updateRecondition = async (
  productIds: ProductItem["id"][],
  reconditionInformation: ReconditionUpdate
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.updateRecondition(
      indexStore.currentProjectNumber,
      productIds,
      reconditionInformation
    ),
    "products - updateRecondition"
  );
};

export const getProductInformationApi = async (
  productId: ProductItem["id"]
): Promise<Result<ProductInformation, CustomError>> => {
  return handleApiRequest(
    productsApi.getProductInformationApi(productId),
    "products - getProductInformationApi"
  );
};

export const updateProductItemStructure = async (
  productId: string,
  productGroup: ProductInformation
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    productsApi.updateProductItemStructure(productId, productGroup),
    "products - updateProductItemStructure"
  );
};

export const postCombineProducts = async (
  productHeaderHash: string[],
  productIds: string[],
  productInformation: ProductInformation
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.postCombineProducts(
      indexStore.currentProjectNumber,
      productHeaderHash,
      productIds,
      productInformation
    ),
    "products - postCombineProducts"
  );
};

export const updateProductItemSpecifics = async (
  productItemInformation: ProductItemInformation
): Promise<Result<AxiosResponse, CustomError>> => {
  return handleApiRequest(
    productsApi.updateProductItemSpecifics(
      productItemInformation.id,
      productItemInformation
    ),
    "products - updateProductItemSpecifics"
  );
};

export const getProductItemsInRoom = async (
  room: RoomObject["rum"]
): Promise<Result<ProductHeaderSale, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.getProductItemsInRoom(indexStore.currentProjectNumber, room),
    "products - getProductItemsInRoom"
  );
};

export const updateProductsSoldStatus = async (productsToUpdate: {
  [key: string]: "0" | "1";
}): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    productsApi.updateProductsSoldStatus(
      indexStore.currentProjectNumber,
      productsToUpdate
    ),
    "products - updateProductsSoldStatus"
  );
};
