<template>
  <div class="container-fluid login-window gr-color p-5 mt-5">
    <div class="row">
      <p class="subheading-large">{{ t("title.signIn") }}</p>
    </div>
    <form @submit.prevent="handleLoginFormSubmit" id="loginForm">
      <div class="row mt-4">
        <InputField
          type="text"
          v-model:fieldValue="loginFields.name"
          name="email"
          required
          :placeHolder="t('placeholders.emailAdress')"
        />
      </div>
      <div class="row mt-4">
        <InputField
          type="password"
          v-model:fieldValue="loginFields.password"
          name="password"
          required
          :placeHolder="t('placeholders.password')"
          :passwordHasToggle="true"
        />
      </div>
    </form>
    <div class="row">
      <p class="text-tiny cursor-pointer mt-3" @click="verifyReset = true">
        {{ t("label.haveYouForgottenThePassword") }}
      </p>
    </div>

    <div class="mt-3 row">
      <div class="col-4">
        <GreenifiedButton
          type="submit"
          form="loginForm"
          :text="processing ? t('button.pleaseWait') : t('button.signIn')"
        />
      </div>
    </div>
  </div>
  <ResetPasswordModal
    v-if="verifyReset"
    @accept="verifyReset = false"
    @cancel="verifyReset = false"
    @close="verifyReset = false"
  />

  <GreenifiedModal
    v-if="showError"
    @close="showError = false"
    type="response"
    :closable="false"
    :title="t('responseTitle.loginError')"
    :text="t('msg.loginError')"
  />
</template>

<script setup lang="ts">
import axios from "axios";
import ResetPasswordModal from "@/components/modal/ResetPasswordModal.vue";
import { getCurrentInstance, ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import InputField from "@/components/common/InputField.vue";
import GreenifiedModal from "@/components/modal/GreenifiedModal.vue";
const authStore = useAuthStore();
const instance = getCurrentInstance();
const router = useRouter();
const { t } = useI18n();

const loginFields = ref({
  name: "",
  password: "",
  rememberMe: false
});
const processing = ref(false);
const showError = ref(false);
const verifyReset = ref(false);

const handleLoginFormSubmit = async () => {
  processing.value = true;

  await axios.get("/sanctum/csrf-cookie").catch(({ response: { data } }) => {
    if (instance) {
      instance.appContext.config.globalProperties.$handleAxiosError(
        data.message,
        "LoginView - login"
      );
    }
  });

  await axios
    .post("/api/login", loginFields.value)
    .then(async ({ data }) => {
      localStorage.setItem("token", data.access_token);
      await authStore.login();
      if (!authStore.isAuthenticated) {
        showError.value = true;
        processing.value = false;
        return;
      }
      router.push({ name: "projectOverview" });
    })
    .catch(() => {
      showError.value = true;
      processing.value = false;
      return;
    });
};
</script>

<style scoped>
.small-text {
  cursor: pointer;
}
.remember-text {
  margin-left: 0.6rem;
  font-size: 14px;
}
.standard-btn {
  width: 10rem;
}
.inlog-logo {
  height: 3rem;
  width: fit-content;
  margin-top: 2rem;
}
.login-section {
  color: black;
  font-family: sans-serif;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.login-container {
  background-color: #f8f5ee;
  padding-bottom: 4rem;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 10%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.login-form h2 {
  margin-bottom: 1rem;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
}

.input-wrapper input {
  height: 1.6rem;
  width: 100%;
  border-radius: 6px;
  border: 1px solid grey;
  font-size: 16px;
  height: 42px;
  margin-top: 0.4rem;
  padding-left: 0.4rem;
}

.m-submit-btn {
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
}

.login-window {
  width: 36rem;
}

@media screen and (max-width: 600px) {
  .login-container {
    width: 94%;
  }
}
</style>
