import { useIndexStore } from "@/store";
import searchApi, {
  SearchTable,
  TranslatedFilter,
} from "@/services/api/searchApi";
import { ExtendedSearch } from "../types/search/search";
import {
  CustomError,
  handleApiRequest,
  Result,
} from "../helpers/handleApiRequests";

export type ExtendedSearchFilters = Record<string, string[]>;

export const searchAllProducts = async (
  searchFilters: ExtendedSearchFilters,
  statuses: string[],
  locale: string,
): Promise<Result<ExtendedSearch[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    searchApi.searchAllProducts(
      indexStore.currentProjectNumber,
      locale,
      searchFilters,
      statuses,
    ),
    "search - searchAllProducts",
  );
};

export const getGuestSearchValues = async (
  eventTargetValue: string,
  guestToken: string,
): Promise<Result<TranslatedFilter[], CustomError>> => {
  return handleApiRequest(
    searchApi.getGuestSearchValues(guestToken, eventTargetValue),
    "search - getGuestSearchValues",
  );
};

export const getSearchValues = async (
  eventTargetValue: string,
): Promise<Result<TranslatedFilter[], CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    searchApi.getSearchValues(
      indexStore.currentProjectNumber,
      eventTargetValue,
    ),
    "search - getSearchValues",
  );
};

export const getSearchTable = async (
  tableHeaders: string[],
  token: string,
): Promise<Result<{ [key: string]: SearchTable[] }, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    searchApi.getSearchTable(
      indexStore.currentProjectNumber,
      tableHeaders,
      token,
    ),
    "search - getSearchTable",
  );
};
