import { AxiosResponse } from "axios";
import api from "../api";
import { AddressInformation, RoomObject } from "@/library/types/room/rooms";

export interface ProjectSettingsAdress extends AddressInformation {
  rooms: RoomObject[];
}

export default {
  postNewAddress(
    projectNumber: string,
    building: AddressInformation["building"],
    address: AddressInformation["address"],
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/address`,
      JSON.stringify({ building, address }),
    );
  },

  updateAddress(
    projectNumber: string,
    building: AddressInformation["building"],
    address: AddressInformation["address"],
    id: string,
  ): Promise<AxiosResponse> {
    return api().post(
      `/api/project/${projectNumber}/address/${id}`,
      JSON.stringify({ building, address }),
    );
  },

  deleteAddress(
    projectNumber: string,
    id: string,
  ): Promise<AxiosResponse<string>> {
    return api().delete(`/api/project/${projectNumber}/address/${id}`);
  },

  getAddressesFromIds(
    projectNumber: string,
    ids: string[],
  ): Promise<AxiosResponse<ProjectSettingsAdress[]>> {
    return api().get(
      `/api/project/${projectNumber}/address?${encodeURI(
        "ids[]=" + ids.join("&ids[]="),
      )}`,
    );
  },

  getAddresses(
    projectNumber: string,
  ): Promise<AxiosResponse<ProjectSettingsAdress[]>> {
    return api().get(`/api/project/${projectNumber}/address`);
  },
};
