<template>
  <div @click="!noOuterClose && closeModal()" class="modal-wrapper">
    <div
      :class="type"
      v-if="showContent"
      class="gr-color modal-container position-relative"
      @click.stop
      ref="modalWrapper"
    >
      <div
        class="container-fluid ps-0 pe-0 modal-content-wrapper d-flex flex-column h-100"
      >
        <LoadingOverlay v-if="isLoading" :overlay="true" />

        <img
          v-if="closable"
          src="@/assets/img/close.svg"
          alt="close icon"
          class="close-icon"
          @click="closeModal()"
        />
        <div class="row mb-3" ref="modalHeader" v-if="type !== 'large'">
          <div class="col-12">
            <slot name="modalHeader">
              <p class="subheading-medium cursor-default">
                {{ title }}
              </p>
            </slot>
          </div>
        </div>

        <div
          class="row"
          ref="modalHeaderContent"
          :class="
            !headerSpacing ? '' : modalHeaderContentHeight > 3 ? 'pb-3' : ''
          "
        >
          <div class="col-12">
            <slot name="modalHeaderContent"></slot>
          </div>
        </div>

        <div
          class="row"
          :class="[(type === 'large' || fullHeight) && 'h-100']"
          :style="`${currentContainerHeight}`"
        >
          <div class="col-12 overflow-auto h-100">
            <slot name="container">
              <div class="row">
                <div class="col text-small" v-html="text"></div></div
            ></slot>
          </div>
        </div>

        <div class="row pt-3 modal-footer" ref="modalFooter">
          <slot name="footer">
            <div v-if="type === 'verification' || type === 'response'">
              <div
                class="d-flex align-items-end"
                :class="type === 'response' && 'justify-content-center'"
              >
                <div class="col-4 me-4">
                  <GreenifiedButton
                    @click.prevent="
                      emit('decline');
                      closeModal();
                    "
                    :btnStyle="type === 'response' ? 'black' : 'normal'"
                    :text="currentDeclineText"
                  />
                </div>
                <div class="col-4" v-if="type === 'verification'">
                  <GreenifiedButton
                    :disabled="disabledConfirm"
                    btnStyle="black"
                    @click="emit('confirm')"
                    :text="currentConfirmText"
                  />
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  withDefaults,
  defineProps,
  defineEmits,
  ref,
  watch,
  onMounted,
  Ref,
  computed
} from "vue";
import { useI18n } from "vue-i18n";
import LoadingOverlay from "../common/LoadingOverlay.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";

type ModalType =
  | "large"
  | "medium"
  | "small"
  | "smaller"
  | "auto"
  | "response"
  | "verification"
  | "x-small";

type GreenifiedModalProps = {
  verification?: boolean;
  title?: string;
  type?: ModalType;
  confirmText?: string | null;
  declineText?: string | null;
  text?: string;
  isLoading?: boolean;
  fullHeight?: boolean;
  closable?: boolean;
  noOuterClose?: boolean;
  disabledConfirm?: boolean;
  headerSpacing?: boolean;
};

const greenifiedModalProps = withDefaults(defineProps<GreenifiedModalProps>(), {
  verification: false,
  title: "",
  type: "medium",
  confirmText: null,
  declineText: null,
  text: "",
  isLoading: false,
  fullHeight: false,
  closable: true,
  noOuterClose: false,
  disabledConfirm: false,
  headerSpacing: true
});
const { t } = useI18n();
const modalHeader: Ref<HTMLElement | null> = ref(null);
const modalHeaderContent: Ref<HTMLElement | null> = ref(null);
const modalFooter: Ref<HTMLElement | null> = ref(null);
const modalWrapper: Ref<HTMLElement | null> = ref(null);
const emit = defineEmits(["close", "confirm", "decline"]);
const showContent = ref(false);
const currentPosition = ref(0);
const currentConfirmText = greenifiedModalProps.confirmText
  ? greenifiedModalProps.confirmText
  : t("button.yes");

const currentDeclineText = greenifiedModalProps.declineText
  ? greenifiedModalProps.declineText
  : greenifiedModalProps.type === "verification"
  ? t("button.cancel")
  : t("button.close");

const closeModal = () => {
  showContent.value = false;
  window.setTimeout(() => {
    emit("close");
    document.body.classList.remove("modal-open");
    window.scrollTo({
      top: currentPosition.value,
      left: 0,
      behavior: "auto" // Ensures the scroll is instant
    });
  }, 200);
};
const modalHeaderContentHeight = computed(() => {
  return modalHeaderContent.value
    ? modalHeaderContent.value.getBoundingClientRect().height
    : 0;
});

const modalFooterHeight = computed(() => {
  return modalFooter.value
    ? modalFooter.value.getBoundingClientRect().height
    : 0;
});

const modalHeaderHeight = computed(() => {
  return modalHeader.value
    ? modalHeader.value.getBoundingClientRect().height
    : 0;
});

const containerHeight = (modalType: ModalType) => {
  if (modalType === "verification" || modalType === "response") {
    return "min-height: 4rem";
  }

  if (modalType === "x-small") return "min-height: 10rem";

  const totalElementHeight =
    modalHeaderContentHeight.value +
    modalFooterHeight.value +
    modalHeaderHeight.value +
    32;

  const currentHeight = `height: calc(100% - ${totalElementHeight}px)`;
  switch (greenifiedModalProps.type) {
    case "smaller":
      return currentHeight;
    case "small":
      return currentHeight;
    case "medium":
      return currentHeight;
    case "large":
      return "height: 100%";
    default:
      return "height: auto";
  }
};

const currentContainerHeight = ref(containerHeight(greenifiedModalProps.type));

const openModal = () => {
  currentPosition.value = window.scrollY;

  document.body.classList.add("modal-open");
  document.body.style.top = `-${currentPosition.value}px`;
};

watch(
  () => greenifiedModalProps.verification,
  closingModal => {
    if (closingModal) {
      closeModal();
    }
  }
);

watch(
  () => modalHeaderContentHeight.value,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

watch(
  () => modalFooterHeight.value,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

watch(
  () => modalHeaderHeight.value,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

watch(
  () => greenifiedModalProps.isLoading,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

onMounted(() => {
  showContent.value = true;
  openModal();
});
</script>

<style scoped>
.close-icon {
  width: 1.2rem;
  position: absolute;
  top: 44px;
  right: 44px;
  z-index: 12;
}
.close-icon:hover {
  transform: scale(1.1);
  transition: 0.1s;
  cursor: pointer;
}

.large {
  height: 88vh;
  width: 80vw;
  background-color: white !important;
}

.medium {
  height: 88vh;
  width: 56rem;
}

.small {
  height: 88vh;
  width: 34rem;
}

.smaller {
  height: 60vh;
  width: 34rem;
}

.auto {
  height: auto;
  width: 56rem;
}

.verification,
.response,
.x-small {
  width: 30rem;
  height: auto;
}

.modal-container {
  padding: 44px;
}

.modal-content-wrapper {
  height: 100%;
  width: 100%;
}

.modal-footer {
  flex: 3;
  justify-content: flex-start;
}

.modal-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
