import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, unref as _unref, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _hoisted_1 = { class: "container-fluid ps-0 pe-0 modal-content-wrapper d-flex flex-column h-100" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "subheading-medium cursor-default" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12 overflow-auto h-100" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-4 me-4" }
const _hoisted_10 = {
  key: 0,
  class: "col-4"
}

import {
  ref,
  watch,
  onMounted,
  Ref,
  computed
} from "vue";
import { useI18n } from "vue-i18n";
import LoadingOverlay from "../common/LoadingOverlay.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";

type ModalType =
  | "large"
  | "medium"
  | "small"
  | "smaller"
  | "auto"
  | "response"
  | "verification"
  | "x-small";

type GreenifiedModalProps = {
  verification?: boolean;
  title?: string;
  type?: ModalType;
  confirmText?: string | null;
  declineText?: string | null;
  text?: string;
  isLoading?: boolean;
  fullHeight?: boolean;
  closable?: boolean;
  noOuterClose?: boolean;
  disabledConfirm?: boolean;
  headerSpacing?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedModal',
  props: {
    verification: { type: Boolean, default: false },
    title: { default: "" },
    type: { default: "medium" },
    confirmText: { default: null },
    declineText: { default: null },
    text: { default: "" },
    isLoading: { type: Boolean, default: false },
    fullHeight: { type: Boolean, default: false },
    closable: { type: Boolean, default: true },
    noOuterClose: { type: Boolean, default: false },
    disabledConfirm: { type: Boolean, default: false },
    headerSpacing: { type: Boolean, default: true }
  },
  emits: ["close", "confirm", "decline"],
  setup(__props: any, { emit: __emit }) {

const greenifiedModalProps = __props;
const { t } = useI18n();
const modalHeader: Ref<HTMLElement | null> = ref(null);
const modalHeaderContent: Ref<HTMLElement | null> = ref(null);
const modalFooter: Ref<HTMLElement | null> = ref(null);
const modalWrapper: Ref<HTMLElement | null> = ref(null);
const emit = __emit;
const showContent = ref(false);
const currentPosition = ref(0);
const currentConfirmText = greenifiedModalProps.confirmText
  ? greenifiedModalProps.confirmText
  : t("button.yes");

const currentDeclineText = greenifiedModalProps.declineText
  ? greenifiedModalProps.declineText
  : greenifiedModalProps.type === "verification"
  ? t("button.cancel")
  : t("button.close");

const closeModal = () => {
  showContent.value = false;
  window.setTimeout(() => {
    emit("close");
    document.body.classList.remove("modal-open");
    window.scrollTo({
      top: currentPosition.value,
      left: 0,
      behavior: "auto" // Ensures the scroll is instant
    });
  }, 200);
};
const modalHeaderContentHeight = computed(() => {
  return modalHeaderContent.value
    ? modalHeaderContent.value.getBoundingClientRect().height
    : 0;
});

const modalFooterHeight = computed(() => {
  return modalFooter.value
    ? modalFooter.value.getBoundingClientRect().height
    : 0;
});

const modalHeaderHeight = computed(() => {
  return modalHeader.value
    ? modalHeader.value.getBoundingClientRect().height
    : 0;
});

const containerHeight = (modalType: ModalType) => {
  if (modalType === "verification" || modalType === "response") {
    return "min-height: 4rem";
  }

  if (modalType === "x-small") return "min-height: 10rem";

  const totalElementHeight =
    modalHeaderContentHeight.value +
    modalFooterHeight.value +
    modalHeaderHeight.value +
    32;

  const currentHeight = `height: calc(100% - ${totalElementHeight}px)`;
  switch (greenifiedModalProps.type) {
    case "smaller":
      return currentHeight;
    case "small":
      return currentHeight;
    case "medium":
      return currentHeight;
    case "large":
      return "height: 100%";
    default:
      return "height: auto";
  }
};

const currentContainerHeight = ref(containerHeight(greenifiedModalProps.type));

const openModal = () => {
  currentPosition.value = window.scrollY;

  document.body.classList.add("modal-open");
  document.body.style.top = `-${currentPosition.value}px`;
};

watch(
  () => greenifiedModalProps.verification,
  closingModal => {
    if (closingModal) {
      closeModal();
    }
  }
);

watch(
  () => modalHeaderContentHeight.value,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

watch(
  () => modalFooterHeight.value,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

watch(
  () => modalHeaderHeight.value,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

watch(
  () => greenifiedModalProps.isLoading,
  () => {
    currentContainerHeight.value = containerHeight(greenifiedModalProps.type);
  }
);

onMounted(() => {
  showContent.value = true;
  openModal();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[4] || (_cache[4] = ($event: any) => (!_ctx.noOuterClose && closeModal())),
    class: "modal-wrapper"
  }, [
    (showContent.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.type, "gr-color modal-container position-relative"]),
          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
          ref_key: "modalWrapper",
          ref: modalWrapper
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(LoadingOverlay, {
                  key: 0,
                  overlay: true
                }))
              : _createCommentVNode("", true),
            (_ctx.closable)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _imports_0,
                  alt: "close icon",
                  class: "close-icon",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal()))
                }))
              : _createCommentVNode("", true),
            (_ctx.type !== 'large')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "row mb-3",
                  ref_key: "modalHeader",
                  ref: modalHeader
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "modalHeader", {}, () => [
                      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1)
                    ])
                  ])
                ], 512))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["row", 
            !_ctx.headerSpacing ? '' : modalHeaderContentHeight.value > 3 ? 'pb-3' : ''
          ]),
              ref_key: "modalHeaderContent",
              ref: modalHeaderContent
            }, [
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "modalHeaderContent")
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["row", [(_ctx.type === 'large' || _ctx.fullHeight) && 'h-100']]),
              style: _normalizeStyle(`${currentContainerHeight.value}`)
            }, [
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "container", {}, () => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                      class: "col text-small",
                      innerHTML: _ctx.text
                    }, null, 8, _hoisted_7)
                  ])
                ])
              ])
            ], 6),
            _createElementVNode("div", {
              class: "row pt-3 modal-footer",
              ref_key: "modalFooter",
              ref: modalFooter
            }, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                (_ctx.type === 'verification' || _ctx.type === 'response')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["d-flex align-items-end", _ctx.type === 'response' && 'justify-content-center'])
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(GreenifiedButton, {
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
                      emit('decline');
                      closeModal();
                    }, ["prevent"])),
                            btnStyle: _ctx.type === 'response' ? 'black' : 'normal',
                            text: _unref(currentDeclineText)
                          }, null, 8, ["btnStyle", "text"])
                        ]),
                        (_ctx.type === 'verification')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(GreenifiedButton, {
                                disabled: _ctx.disabledConfirm,
                                btnStyle: "black",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('confirm'))),
                                text: _unref(currentConfirmText)
                              }, null, 8, ["disabled", "text"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 512)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})