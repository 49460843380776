import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-label-paragraph mt-3 mb-4 text-danger fw-bolder"
}
const _hoisted_2 = {
  key: 1,
  class: "form-label-paragraph mt-3 mb-4"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }

import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import GreenifiedModal from "./GreenifiedModal.vue";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import CustomSelect from "../common/CustomSelect.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { removeProductGreenified } from "@/library/api/greenifiedProducts";

type StatusOption = {
  label: string;
  value: ProductStatuses;
};

type RemoveGreenifiedProductModal = {
  greenifiedProductHeaderToRemove: GreenifiedProductHeader | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RemoveGreenifiedProductModal',
  props: {
    greenifiedProductHeaderToRemove: { default: null }
  },
  emits: ["close", "refresh"],
  setup(__props: any, { emit: __emit }) {


const { t } = useI18n();
const emit = __emit;

const statusOptions: StatusOption[] = [
  { label: t("label.moveToRepurpose"), value: "Å" },
  { label: t("label.moveToSell"), value: "S" },
  { label: t("label.moveToRecycle"), value: "K" },
  { label: t("label.productIsDiscarded"), value: "C" },
  { label: t("title.removeProduct"), value: "N" }
];
const selectedStatusOption: Ref<StatusOption["value"] | null> = ref(null);
const isLoading = ref(false);
const removeProduct = async (
  greenifiedProductHeader: GreenifiedProductHeader
) => {
  isLoading.value = true;
  if (selectedStatusOption.value) {
    const result = await removeProductGreenified(
      greenifiedProductHeader.id,
      selectedStatusOption.value
    );
    isLoading.value = false;

    if (!result.success) return;

    emit("refresh");
    emit("close");
  }
};

return (_ctx: any,_cache: any) => {
  return (_ctx.greenifiedProductHeaderToRemove)
    ? (_openBlock(), _createBlock(GreenifiedModal, {
        key: 0,
        title: _unref(t)('title.removeProduct'),
        onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
        isLoading: isLoading.value,
        type: 'verification',
        disabledConfirm: !selectedStatusOption.value,
        confirmText: _unref(t)('button.save'),
        onConfirm: _cache[2] || (_cache[2] = ($event: any) => (removeProduct(_ctx.greenifiedProductHeaderToRemove)))
      }, {
        container: _withCtx(() => [
          (selectedStatusOption.value === 'N')
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.greenifiedProductHeaderToRemove.items.length > 1
            ? _unref(t)("msg.verifyProductsDelete", [
                _ctx.greenifiedProductHeaderToRemove.items.length,
                _ctx.greenifiedProductHeaderToRemove.benamn
              ])
            : _unref(t)("msg.verifyProductDelete", [
                _ctx.greenifiedProductHeaderToRemove.benamn,
                _ctx.greenifiedProductHeaderToRemove.items[0].comp_id
              ])), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(t)("paragraph.moveAfterRemove")), 1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(CustomSelect, {
                modelValue: selectedStatusOption.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedStatusOption).value = $event)),
                options: statusOptions,
                placeholder: _unref(t)('placeholders.selectCategoryToMoveTo'),
                reducedKey: 'value',
                label: "label",
                clearable: false
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "isLoading", "disabledConfirm", "confirmText"]))
    : _createCommentVNode("", true)
}
}

})