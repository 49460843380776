import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import RoomHandlerLine from "@/components/RoomHandlerLine.vue";
import { onMounted, Ref, ref } from "vue";
import { SortingSettings } from "../../library/types/sorting/sorting";
import { RoomObject } from "../../library/types/room/rooms";
import { RoomsToPost } from "../../services/api/roomsApi";
import { ProjectSettingsAdress } from "../../services/api/addressApi";
import { ProductHeaderSale } from "../../library/types/product/productHeader";
import { useIndexStore } from "@/store";
import { getAddresses } from "@/library/api/address";
import { getProductItemsInRoom } from "@/library/api/products";
import { getProjectRooms, updateRooms } from "@/library/api/rooms";
import TableHeaderRow from "../table/tableHeader/TableHeaderRow.vue";
import { getRoomTableColumns } from "@/library/helpers/structureTableColumns";
import TableFooter from "../table/tableFooter/TableFooter.vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/library/types/actions/actions";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TableFooterButton } from "@/library/types/tables/footer";
import { sortedRoomRows } from "@/library/helpers/sortTableRows";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import { bolderFont } from "@/library/helpers/typography";
import ProductsInDeletedRoomModal from "../modal/ProductsInDeletedRoomModal.vue";
import { CustomSetLocaleMessage } from "@/library/types/locales/i18nUtilities";


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomForm',
  setup(__props) {

const indexStore = useIndexStore();
const { t, messages, locale, setLocaleMessage } = useI18n();
const isLoading = ref(false);
const rooms: Ref<RoomObject[]> = ref([]);
const roomsToPost: Ref<RoomsToPost> = ref({
  update: [],
  delete: [],
  insert: []
});
const sortingSettings: Ref<SortingSettings> = ref({ col: "rum", asc: false });
const productsInDeletedRoom: Ref<ProductHeaderSale> = ref({});
const projectAddresses: Ref<ProjectSettingsAdress[]> = ref([]);
const roomToDelete: Ref<RoomObject | null> = ref(null);
const deletedIndex: Ref<null | number> = ref(null);
const verifyDelete = ref(false);
const showProductsInDeletedRoomModal = ref(false);
const roomForm: Ref<HTMLFormElement | null> = ref(null);

onMounted(() => {
  getRooms();
  getProjectAddress();
});

const handleSelectAction = (action: Actions) => {
  if (action === "addRoom") {
    roomsToPost.value.insert.push({
      van: "",
      hus: "",
      rumskod: "",
      rumsnamn: "",
      enhet: "",
      rum: "",
      new_room: "0"
    });

    return;
  }
  postRooms();
};

const removeRoom = (room: RoomObject) => {
  if (!room) return;

  const indexUpdate = findIDIndex(roomsToPost.value.update, room.rum);
  const index = findIDIndex(rooms.value, room.rum);

  if (indexUpdate !== -1) {
    roomsToPost.value.update.splice(indexUpdate, 1);
  }
  if (index !== -1) {
    rooms.value.splice(index, 1);
  }
  roomsToPost.value.delete.push(room);
  postRooms();
};

const update = (room: RoomObject) => {
  if (room.rum) {
    const indexUpdate = findIDIndex(roomsToPost.value.update, room.rum);
    if (indexUpdate !== -1) {
      roomsToPost.value.update[indexUpdate] = room;
    } else {
      roomsToPost.value.update.push(room);
    }
  }
};

const findIDIndex = (rooms: RoomObject[], roomId: RoomObject["rum"]) => {
  return rooms.findIndex(room => room.rum === roomId);
};

const verifyRoomDelete = async (room: RoomObject, index: number) => {
  roomToDelete.value = room;
  deletedIndex.value = index;

  const result = await getProductItemsInRoom(room.rum);

  if (!result.success) return;

  productsInDeletedRoom.value = result.result;
  if (Object.keys(productsInDeletedRoom.value).length) {
    return (showProductsInDeletedRoomModal.value = true);
  }
  return (verifyDelete.value = true);
};

const getRooms = async () => {
  isLoading.value = true;
  roomToDelete.value = null;

  const result = await getProjectRooms();
  isLoading.value = false;
  if (!result.success) return;

  rooms.value = result.result;
};

const getProjectAddress = async () => {
  const result = await getAddresses();

  if (!result.success) return;

  projectAddresses.value = result.result;
};

const postRooms = async () => {
  if (roomForm.value && !roomForm.value.reportValidity()) {
    return;
  }

  isLoading.value = true;
  const result = await updateRooms(roomsToPost.value);
  isLoading.value = false;

  if (!result.success) return;

  indexStore.getProjectDetails(
    indexStore.currentProjectNumber,
    setLocaleMessage as CustomSetLocaleMessage,
    messages.value[locale.value]
  );
  roomsToPost.value = { update: [], delete: [], insert: [] };
  getRooms();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    (rooms.value.length && !isLoading.value)
      ? (_openBlock(), _createElementBlock("form", {
          key: 1,
          id: "room-id",
          ref_key: "roomForm",
          ref: roomForm,
          class: "pb-4",
          onSubmit: _withModifiers(postRooms, ["prevent"])
        }, [
          _createVNode(TableHeaderRow, {
            "table-headers": _unref(getRoomTableColumns)(rooms.value[0]).columns,
            hideHeaderCheckbox: "",
            sortingOptions: sortingSettings.value,
            "onUpdate:sortingOptions": _cache[0] || (_cache[0] = ($event: any) => ((sortingSettings).value = $event)),
            isSticky: ""
          }, null, 8, ["table-headers", "sortingOptions"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(roomsToPost.value.insert, (room, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "border-bottom",
              key: room.rum
            }, [
              _createVNode(RoomHandlerLine, {
                roomColumns: _unref(getRoomTableColumns)(room).columns,
                projectAddresses: projectAddresses.value,
                roomToUpdate: room,
                onUpdate: (room) => update(room),
                onDelete: ($event: any) => (roomsToPost.value.insert.splice(index, 1)),
                onRefreshAddress: _cache[1] || (_cache[1] = ($event: any) => (getProjectAddress()))
              }, null, 8, ["roomColumns", "projectAddresses", "roomToUpdate", "onUpdate", "onDelete"])
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedRoomRows)(rooms.value, sortingSettings.value), (room, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "border-bottom",
              key: room.rum
            }, [
              _createVNode(RoomHandlerLine, {
                roomColumns: _unref(getRoomTableColumns)(room).columns,
                projectAddresses: projectAddresses.value,
                roomToUpdate: room,
                onUpdate: (room) => update(room),
                onDelete: ($event: any) => (verifyRoomDelete(room, i)),
                onRefreshAddress: _cache[2] || (_cache[2] = ($event: any) => (getProjectAddress()))
              }, null, 8, ["roomColumns", "projectAddresses", "roomToUpdate", "onUpdate", "onDelete"])
            ]))
          }), 128))
        ], 544))
      : _createCommentVNode("", true),
    (!isLoading.value)
      ? (_openBlock(), _createBlock(TableFooter, {
          key: 2,
          footerText: 
      roomsToPost.value.insert.length + roomsToPost.value.update.length > 0
        ? _unref(t)('label.roomsTransactionChanged', {
            count: roomsToPost.value.insert.length + roomsToPost.value.update.length
          })
        : ''
    ,
          "action-statuses": ['AR', 'SR'],
          onAction: _cache[3] || (_cache[3] = (action) => handleSelectAction(action.action))
        }, null, 8, ["footerText"]))
      : _createCommentVNode("", true),
    (showProductsInDeletedRoomModal.value)
      ? (_openBlock(), _createBlock(ProductsInDeletedRoomModal, {
          key: 3,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showProductsInDeletedRoomModal.value = false)),
          onRemoveRoom: _cache[5] || (_cache[5] = 
      () => {
        if (!roomToDelete.value) return;
        removeRoom(roomToDelete.value);
      }
    ),
          productsInDeletedRoom: productsInDeletedRoom.value,
          roomToDelete: roomToDelete.value
        }, null, 8, ["productsInDeletedRoom", "roomToDelete"]))
      : _createCommentVNode("", true),
    (verifyDelete.value && roomToDelete.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 4,
          type: "verification",
          closable: false,
          title: _unref(t)('title.removeRoom'),
          text: _unref(t)('msg.verifyRemove', [_unref(bolderFont)(roomToDelete.value.rumsnamn)]),
          onClose: _cache[6] || (_cache[6] = ($event: any) => (verifyDelete.value = false)),
          onConfirm: _cache[7] || (_cache[7] = 
      () => {
        verifyDelete.value = false;
        if (!roomToDelete.value) return;
        removeRoom(roomToDelete.value);
      }
    )
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})