import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-label-paragraph" }
const _hoisted_2 = { class: "form-label mb-1" }
const _hoisted_3 = {
  key: 0,
  class: "form-label-paragraph mb-2"
}
const _hoisted_4 = { class: "row row-gap-2" }
const _hoisted_5 = { class: "col-4" }

import { computed, Ref, ref, onBeforeMount } from "vue";
import { I18nMessage } from "../../library/types/locales/i18nMessage";
import { useI18n } from "vue-i18n";
import { postLabelOrderApi } from "@/library/api/labels";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import InputField from "../common/InputField.vue";
import CustomSelect from "../common/CustomSelect.vue";
import InputTextField from "../common/InputTextField.vue";
import { LabelGroup, PostLabelOrder } from "@/library/types/labels/labels";

type Country = {
  value: string;
  label: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderLabelsForm',
  emits: ["refresh", "close"],
  setup(__props, { emit: __emit }) {

const { t, locale, messages } = useI18n();
const formIsPosted = ref(false);

const defaultLabelOrder: Ref<PostLabelOrder> = ref({
  phone: "",
  country: "",
  address: "",
  zip: "",
  city: "",
  who: "",
  comment: "",
  quantity: 0
});

const labelOrderFields = (labelOrder: PostLabelOrder): LabelGroup => {
  return {
    address: {
      paragraph: "paragraph.addressToSendLabels",
      fields: [
        {
          labelOrderKey: "address",
          fieldValue: labelOrder.address,
          placeholder: "placeholders.address",
          type: "text",
          maxlength: "200",
          required: true
        },
        {
          labelOrderKey: "zip",
          fieldValue: labelOrder.zip,
          placeholder: "placeholders.zip",
          type: "text",
          maxlength: "20",
          required: true
        },
        {
          labelOrderKey: "city",
          fieldValue: labelOrder.city,
          placeholder: "placeholders.city",
          type: "text",
          maxlength: "100",
          required: true
        },
        {
          labelOrderKey: "country",
          fieldValue: labelOrder.country,
          placeholder: "",
          type: "select",
          maxlength: "",
          required: false
        }
      ]
    },
    who: {
      paragraph: "paragraph.sendToWho",
      fields: [
        {
          labelOrderKey: "who",
          fieldValue: labelOrder.who,
          placeholder: "placeholders.name",
          type: "text",
          maxlength: "100",
          required: true
        },
        {
          labelOrderKey: "phone",
          fieldValue: labelOrder.phone,
          placeholder: "placeholders.phoneNumber",
          type: "phone",
          maxlength: "",
          required: false
        }
      ]
    },
    quantityLabels: {
      paragraph: null,
      fields: [
        {
          labelOrderKey: "quantity",
          fieldValue: labelOrder.quantity,
          placeholder: "",
          type: "number",
          maxlength: "",
          required: true
        }
      ]
    },
    note: {
      paragraph: null,
      fields: [
        {
          labelOrderKey: "comment",
          fieldValue: labelOrder.comment,
          placeholder: "",
          type: "textarea",
          maxlength: "",
          required: false
        }
      ]
    }
  };
};

const isLoading = ref(false);
const labelOrderForm: Ref<HTMLFormElement | null> = ref(null);

const currentCountry = computed(() => {
  return navigator.language.slice(-2);
});

const allCountries = computed((): Country[] => {
  const currentCountries = (messages.value[locale.value.toLocaleLowerCase()] as I18nMessage).list
    .countries;

  return Object.keys(currentCountries).map(country => ({
    value: country,
    label:
      currentCountries[country as keyof I18nMessage["list"]["countries"]].name
  }));
});

const postLabelOrder = async () => {
  formIsPosted.value = true;

  if (defaultLabelOrder.value.quantity <= 0) return;

  if (labelOrderForm.value && !labelOrderForm.value.reportValidity()) return;

  isLoading.value = true;

  const result = await postLabelOrderApi(defaultLabelOrder.value);
  isLoading.value = false;

  if (!result.success) return;

  emit("close");
  emit("refresh");
};

const emit = __emit;

onBeforeMount(() => {
  defaultLabelOrder.value.country = currentCountry.value.toLocaleUpperCase();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GreenifiedModal, {
    title: _unref(t)('title.orderLabels'),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
    type: "small",
    isLoading: isLoading.value
  }, {
    modalHeaderContent: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)("paragraph.orderLabels")), 1)
    ]),
    container: _withCtx(() => [
      _createElementVNode("form", {
        id: "label-order",
        ref_key: "labelOrderForm",
        ref: labelOrderForm,
        onSubmit: _withModifiers(postLabelOrder, ["prevent"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(labelOrderFields(
            defaultLabelOrder.value
          ), (orderField, orderFieldValue, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mb-2",
            key: index
          }, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_unref(t)(`label.${orderFieldValue}`)) + " " + _toDisplayString(orderField.fields.find(field => field.required) && "*"), 1),
            (orderField.paragraph)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)(`${orderField.paragraph}`)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderField.fields, (field, fieldIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-12",
                  key: fieldIndex
                }, [
                  (field.type === 'number')
                    ? (_openBlock(), _createBlock(InputField, {
                        key: 0,
                        fieldValue: field.fieldValue,
                        "onUpdate:fieldValue": [($event: any) => ((field.fieldValue) = $event), ($event: any) => (
                  defaultLabelOrder.value[field.labelOrderKey] = field.fieldValue
                )],
                        placeHolder: _unref(t)(field.placeholder),
                        type: field.type,
                        required: field.required && field.fieldValue > 0,
                        errorWithInputValue: field.fieldValue <= 0 && formIsPosted.value,
                        maxlength: field.maxlength
                      }, null, 8, ["fieldValue", "onUpdate:fieldValue", "placeHolder", "type", "required", "errorWithInputValue", "maxlength"]))
                    : _createCommentVNode("", true),
                  (field.type === 'text' || field.type === 'phone')
                    ? (_openBlock(), _createBlock(InputField, {
                        key: 1,
                        fieldValue: field.fieldValue,
                        "onUpdate:fieldValue": [($event: any) => ((field.fieldValue) = $event), ($event: any) => (
                  defaultLabelOrder.value[field.labelOrderKey] = field.fieldValue
                )],
                        placeHolder: _unref(t)(field.placeholder),
                        type: field.type,
                        required: field.required,
                        errorWithInputValue: 
                  field.fieldValue.length === 0 && formIsPosted.value
                ,
                        maxlength: field.maxlength
                      }, null, 8, ["fieldValue", "onUpdate:fieldValue", "placeHolder", "type", "required", "errorWithInputValue", "maxlength"]))
                    : _createCommentVNode("", true),
                  (field.type === 'textarea')
                    ? (_openBlock(), _createBlock(InputTextField, {
                        key: 2,
                        modelValue: field.fieldValue,
                        "onUpdate:modelValue": [($event: any) => ((field.fieldValue) = $event), ($event: any) => (
                  defaultLabelOrder.value[field.labelOrderKey] = field.fieldValue
                )],
                        rows: 2
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true),
                  (field.type === 'select')
                    ? (_openBlock(), _createBlock(CustomSelect, {
                        key: 3,
                        modelValue: field.fieldValue,
                        "onUpdate:modelValue": [($event: any) => ((field.fieldValue) = $event), ($event: any) => (
                  defaultLabelOrder.value[field.labelOrderKey] = field.fieldValue
                )],
                        options: allCountries.value,
                        clearable: false,
                        label: "label",
                        reducedKey: "value"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
        }), 128))
      ], 544)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(GreenifiedButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (formIsPosted.value = true)),
          text: _unref(t)('button.send'),
          type: 'submit',
          form: 'label-order',
          "btn-style": "green"
        }, null, 8, ["text"])
      ])
    ]),
    _: 1
  }, 8, ["title", "isLoading"]))
}
}

})