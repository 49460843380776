import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 d-flex justify-content-end" }

import GrLayoutSimple from "@/layouts/GrLayoutSimple.vue";
import FooterContact from "@/components/FooterContact.vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import SimpleSelect from "@/components/common/SimpleSelect.vue";
import { I18nLocale } from "@/library/types/locales/locales";
import { useI18n } from "vue-i18n";

export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleLayout',
  setup(__props) {

const indexStore = useIndexStore();
const { messages, locale } = useI18n();

const { currentRoute } = useRouter();

const currentLanguage = ref(indexStore.currentProjectLanguage);

const selectLanguage = (selectedLanguage: I18nLocale) => {
  indexStore.changeProjectLanguage(selectedLanguage, locale);
  if (currentRoute.value.params.token) {
    indexStore.getGuestLocaleTranslations(
      indexStore.currentProject,
      currentRoute.value.params.token as string,
      messages.value[locale.value]
    );
  }
};

onMounted(() => {
  if (currentLanguage.value === null) {
    selectLanguage("sv");
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GrLayoutSimple, null, {
    "top-navbar": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(SimpleSelect)
      ])
    ]),
    container: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    footer: _withCtx(() => [
      _createVNode(FooterContact)
    ]),
    _: 3
  }))
}
}

})