import {
  ProjectDetails,
  SelectableProject,
} from "@/library/types/project/project";
import api from "../api";
import { AxiosResponse } from "axios";
import { User } from "@/library/api/project";
import { ProductStatuses } from "@/library/types/product/productHeader";

type ProductActionQuantity = { status: ProductStatuses; total: string };

export type ProjectProductQuantities = {
  project_desc: string;
  project_name: string;
  project_nr: string;
  tot_actions: Record<ProductStatuses, ProductActionQuantity>;
  tot_greenified: number;
  tot_greenified_sub: {
    Q: { total: number };
    QS: { total: number };
    V: { total: number };
    VS: { total: number };
  };
  tot_label: number;
  tot_per_supp: { levkod: string; total: string }[];
  tot_priced: string;
  tot_priced_action: Record<ProductStatuses, ProductActionQuantity[]>;
  tot_prod: number;
  tot_sub_actions: string;
  tot_sub_actions_per: [];
  tot_sum_priced: string;
  tot_sum_priced_action: Record<ProductStatuses, ProductActionQuantity[]>;
  tot_supp: number;
};

export default {
  getClientProjects(): Promise<AxiosResponse<SelectableProject[]>> {
    return api().get("/api/projects");
  },
  getProject(projectNr: string): Promise<AxiosResponse<ProjectDetails>> {
    return api().get("/api/project/" + projectNr);
  },

  getProjectInformation(
    projectNr: string,
  ): Promise<AxiosResponse<{ data: ProjectProductQuantities }>> {
    return api().get("/api/projects/details/" + projectNr);
  },

  getProjectUsersApi(projectNr: string): Promise<AxiosResponse<User[]>> {
    return api().get("/api/project/" + projectNr + "/user");
  },
};
